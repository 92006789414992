$main-menu-width: 330px;
$main-menu-handle-width: 70px;

.main-menu {
	@include textMix('Roboto', 1rem, 400);

	align-items: stretch;
	background-color: $color-mercury-gray;
	border-right: $main-menu-handle-width solid $color-ship-gray;
	cursor: pointer;
	flex: 0 0 auto;
	float: left;
	width: $main-menu-width;
	z-index: 9089; // Sitecore ribbon = 9090 z-index. Stay below that!

	// Start off translated on-screen just enough to see the handle
	transform: translate3d($main-menu-handle-width, 0, 0);
	transition: all 300ms cubic-bezier(0.450, 0.520, 0.220, 1.085);

	&.is-active {
		cursor: auto;
		transform: translate3d($main-menu-width, 0, 0);
	}
	&.fixed {
		position: fixed;
		top: 0;
		height: 100%;
	}
	// At smaller widths, we use the `--mini` toggler instead
	// Remove the thick handle, don't show permatoggler on the left when closed
	@include breakpoint('ipad') {
		border-right: 1px solid $color-light-gray;
		margin-right: $main-menu-handle-width;
		transform: translate3d(0, 0, 0);
		width: 260px;
		position: fixed;
		top: 40px;
		height: calc(100% - 28px);
		overflow: scroll;
		z-index: 998; // 1 below menu toggler
	}

	&__section {
		border-bottom: 1px solid $color-light-gray;
		margin: 0;
		padding: 1rem 0;
		background-color: $color-concrete-gray;
	}

	&__section-title,
	&__section-link {
		padding: 0.625rem 0;
		display: inline-block;
	}

	&__section-title {
		color: $color-zeus-black;
		cursor: pointer;
		font-weight: 600;
		letter-spacing: 1.2px;
		padding-left: 1.5rem;
		text-transform: uppercase;
		width: 100%;

		a {
			@include on-event(true) {
				@extend .u-inherit-text-color;
			}
		}
	}
	&__arrow-icon {
		@include svg-fill($color-abbey-gray);
		position: absolute;
		right: 1rem;
		top: 0.5rem;
		transition: transform 250ms ease-out;
	}

	&__section-link {
		margin: 0;
		color: $color-abbey-gray;

		@include on-event(true) {
			@extend .u-inherit-text-color;
			text-decoration: none;
		}
	}

	&__section-wrapper {
		transition: all 150ms ease-out;
		transform: scaleY(0);
		transform-origin: top;
		max-height: 0;
	}

	&__footer-link {
		padding: 0 0 0 1.5rem;
		line-height: 2rem;
		margin: 0;
		font-size: 0.9375rem;

		a {
			color: $color-abbey-gray;

			&:hover,
			&:active {
				color: $color-cardinal-red;
			}
			&:after {
				content: "\a0 ›"; // "\ao " is a non-breaking space
				display: inline;
				font-size: 1rem;
			}
		}
	}
}

// Toggle menu section link visibility on-click

.main-menu__section{
	p{
		font-size: 13px;
		padding: 0 22px;
		margin: 8px 0 6px;
		line-height: 20px;
	}
}
.main-menu__section-title {
	padding-left: 30px;
	padding-right: 2.0625rem;
}
.main-menu__section-title.is-active {

	// For some reason, this is required for the transform to render properly
	// on iOS (and potentially other mobile browsers). Dumb.
	-webkit-perspective: 500px;

	.main-menu__arrow-icon {
		-webkit-transform: rotateX(-180deg);
		transform: rotateX(-180deg);
	}

	&~ .main-menu__section-wrapper {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		max-height: 999px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
           transform:none;
        }
	}
	&:before {
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 6px;
	}
}

.main-menu__hoverable {
	position: relative;

	.main-menu__section-link {
		padding-left: 2.5rem;
	}
	&:hover:before , &.activate:before {
		background-color: $color-tree-poppy-orange;
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 6px;
	}
}
.load-spinner {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    .loading {
        position: fixed;
        display: block;
        img {
            display: block;
        }
        em {
            display: block;
            text-align: center;
            position: relative;
            top: 60px;
            font-weight: 700;
            color: #fff;
        }
    }
} 