.our-analysts {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	max-width: 1260px;
	height: 575px;
	padding: 0 map-get($spacers, 1)*1.5;
	margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
		padding: 0 map-get($spacers, 1)*0.75;
      	margin: map-get($spacers, 1)*3.5 0;
		flex-direction: column;
		height: auto;
		gap: 0;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
	&-meet,
	&-director,
	&-ask {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 1;
		padding: map-get($spacers, 1)*1.5;
		color: $color-white;
		@include breakpoint('medium') { 
			min-height: 375px;
			padding: map-get($spacers, 1)*1.5 map-get($spacers, 1)*1.25;
		}
		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
		&__content {
			position: relative;
			z-index: 1;
			h2 {
				@include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
				text-transform: capitalize;
				@include breakpoint('medium-small') {
					text-transform: none;
				}
			}  
			p {
				@include font($font-size-16, $font-weight-400, $line-height-125, $letter-spacing-02);
				padding: map-get($spacers, 1)/2 0px;
				margin-bottom: map-get($spacers, 1)/2;
				@include breakpoint('medium-small') {
					line-height: $line-height-13;
				}
			}
		}
	}
	&-meet {
		& > h2 {
			@include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-02);
			text-transform: capitalize;
			position: relative;
			z-index: 1;
			@include breakpoint('medium-small') {
				@include font($font-size-31, $font-weight-bold, $line-height-normal, $letter-spacing-02);
				text-transform: none;
			}
		}
	}
	&-director {
		justify-content: end;
	}  
	&-ask {
		background: #f4f4f4;
		color: $color-black;
		@include breakpoint('medium') {
			min-height: initial;
			padding-bottom: map-get($spacers, 1)*6;
		}
		& > img {
			max-height: 163px;
			bottom: 0;
			top: auto;
			object-position: center;
			transition: max-height 300ms ease;
			@include breakpoint('medium') {
				max-height: 163px;
			}
			@include breakpoint('medium-small') {
				max-height: 90px;
			}
		}
		&:hover {
			& > img {
				max-height: 224px;
				@include breakpoint('medium') {
					max-height: 163px;
				}
				@include breakpoint('medium-small') {
					max-height: 90px;
				}
			}

		}
	}
  }
  