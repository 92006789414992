.solutions {
    display: flex;
    flex-direction: column;
    max-width: 1260px;
    padding: 0px $spacer-2*1.25;
    gap: $spacer-3*1.25;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
        gap: map-get($spacers, 1)*0.75;
        margin: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
    }
    @include breakpoint("medium-small") {
        gap: map-get($spacers, 1)/2;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
  
    &__banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        height: 130px;
        padding: $spacer-2*1.25;
        overflow: hidden;
        @include breakpoint('medium') {
            height: auto;
        }
        & > img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
            inset: 0;
            position: absolute;
        }
        &__title {
            display: block;
            color: $omd-white;
            @include font($font-size-40, $font-weight-bold, $line-height-normal, $letter-spacing-05);
            position: relative;
            z-index: 2;
            @include breakpoint('small') { 
                padding: map-get($spacers, 1) map-get($spacers, 1)*1.25; 
                font-size: $font-size-31;
                line-height: $line-height-36;
            }
        }
        &__instructions {
            color: $omd-white;
            z-index: 2;
        }
    }
  
    &__tile {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: 1fr;
        gap: 10px;
        @include breakpoint("ipad") {
          grid-template-columns: repeat(5, 1fr);
        }
        @include breakpoint("medium") {
          grid-template-columns: repeat(4, 1fr);
        }
        @include breakpoint("medium-small") {
          grid-template-columns: repeat(2, 1fr);
          word-break: break-word;
        }
        @include breakpoint("small") {
          grid-template-columns: 1fr;
        }
        &__wrapper {
            text-align: center;
            padding: map-get($spacers, 1) map-get($spacers, 1)/4;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            position: relative;
            text-decoration: none;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $omd-light-grey;
                z-index: -1;
            }
            @include breakpoint("medium-small") {
                padding: map-get($spacers, 1)*0.75 map-get($spacers, 1)/3;
                gap: 10px;
            }
            & > img {
                width: 65px;
                height: 65px;
                filter: invert(84%) sepia(63%) saturate(3853%) hue-rotate(265deg) brightness(93%) contrast(90%);
                @include breakpoint("medium-small") {
                    width: 36px;
                    height: 36px;
                }
            }
            & > span {
                color: $omd-black;
                @include font($font-size-13, $font-weight-bold, $line-height-133, $letter-spacing-1);
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:hover {
                &::before {
                    background: $omd-purple-black-gradient;
                }
                & > img {
                    filter: none;
                }
                & > span {
                    color: $color-white;
                }
            }
        }
    }

    &.industry {
        margin-bottom: $spacer-2*1.5;
        .solutions__tile {
            &__wrapper {
                &:hover {
                    &:first-of-type {
                        &::before {
                            background: $omd-consumer-gradient;
                        }
                    }
                    &:nth-of-type(2) {
                        &::before {
                            background: $omd-enterprise-gradient;
                        }
                    }
                    &:nth-of-type(3) {
                        &::before {
                            background: $omd-industrial-gradient;
                        }
                    }
                    &:nth-of-type(4) {
                        &::before {
                            background: $omd-telecoms-gradient;
                        }
                    }
                    &:nth-of-type(5) {
                        &::before {
                            background: $omd-channel-gradient;
                        }
                    }
                    &:nth-of-type(6) {
                        &::before {
                            background: $omd-consumer-gradient;
                        }
                    }
                    &:nth-of-type(7) {
                        &::before {
                            background: $omd-enterprise-gradient;
                        }
                    }
                    &:nth-of-type(8) {
                        &::before {
                            background: $omd-industrial-gradient;
                        }
                    }
                    &:nth-of-type(9) {
                        &::before {
                            background: $omd-telecoms-gradient;
                        }
                    }
                    &:nth-of-type(10) {
                        &::before {
                            background: $omd-consumer-gradient;
                        }
                    }
                }
            }
        }
    }

    &.coverage {
        margin-top: $spacer-2*1.5;
        .solutions__tile {
            &__wrapper {
                word-break: break-word;
                &::before {
                    transform: rotate(180deg);
                }
                @include breakpoint('medium-small') { 
                    word-break: break-word;
                }
            }
        }
    }
}
