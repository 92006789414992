/*---
title: Account Access - Headers
section: Home/Topic Page Components
---

```example:html
<div class="general-header">
    <div class="general-header__section-title">
        Name of Section
    </div>
    <div class="general-header__navigation">
        <a href="#" class="is-active">General Content Page V1</a>
        <a href="#">Page Name</a>
        <a href="#">Page Name</a>
        <a href="#">Page Name</a>
    </div>
</div>
```
*/

.general-header {
    &__section-title {
        @include textMix('Roboto', 1.25rem, 500);
        background-color: $color-zeus-black;
        color: $color-concrete-gray;
        padding: 1rem 2rem;

        @include breakpoint('medium') {
            padding: 0.5rem;
        }

        a {
            @include on-event(true) {
                color: inherit;
            }
        }
    }

    &__navigation {
        background-color: $color-concrete-gray;
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 0 2rem;
        position: relative;
        white-space: nowrap;
        // Smooth/inertia scroll on mobile
        -webkit-overflow-scrolling: touch;

        a {
            @include textMix('Roboto', 0.875rem, 600);
            color: $color-abbey-gray;
            display: inline-block;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-right: 3rem;
            padding: 1rem 0 0.75rem;

            &.is-active,
            &:hover {
                border-bottom: 4px solid $color-tree-poppy-orange;
            }
        }

        @include breakpoint('medium') {
            padding: 0 1rem;
            height: 2.375rem;

            a {
                font-size: 0.75rem;
                margin-right: 1.5rem;
                padding: 0.75rem 0 0.5rem;
            }
        }

        @include breakpoint('small') {
            overflow-x: auto;
        }

        &-scroller--right,
        &-scroller--left {

            background: -moz-linear-gradient(left, rgba(241,242,242,0) 0%, rgba(241,242,242,1) 66%, rgba(241,242,242,1) 100%);
            background: -webkit-linear-gradient(left, rgba(241,242,242,0) 0%,rgba(241,242,242,1) 66%,rgba(241,242,242,1) 100%);
            background: linear-gradient(to right, rgba(241,242,242,0) 0%,rgba(241,242,242,1) 66%,rgba(241,242,242,1) 100%);
            cursor: pointer;
            font-size: 1.5rem;
            height: 2.375rem;
            margin-top: -2.625rem;
            padding-right: 0.5rem;
            position: absolute;
            right: 0;
            text-align: right;
            width: 4rem;
            @include prefix(animation, fadeOutFromIn 200ms ease-out);
            animation-fill-mode: forwards;
            pointer-events: none;

            &.is-visible {
                @include prefix(animation, fadeInFromNone 200ms ease-out);
                animation-fill-mode: forwards;
                pointer-events: all;
            }

            > svg {
                @include svg-fill($color-zeus-black);
                vertical-align: middle;
                height: 14px;
                width: 14px;

                @include prefix(transform, rotate(90deg));
            }

            @include breakpoint('medium') {
                margin-top: -2.375rem;
            }
        }

        &-scroller--left {
            background: -moz-linear-gradient(left, rgba(241,242,242,0) 100%, rgba(241,242,242,1) 33%, rgba(241,242,242,1) 0%);
            background: -webkit-linear-gradient(left, rgba(241,242,242,0) 100%,rgba(241,242,242,1) 33%,rgba(241,242,242,1) 0%);
            background: linear-gradient(to left, rgba(241,242,242,0) 0%,rgba(241,242,242,1) 66%,rgba(241,242,242,1) 100%);
            left: 70px;
            right: auto;
            text-align: left;
            padding-left: 0.5rem;

            > svg {
                @include prefix(transform, rotate(-90deg));
            }

            @include breakpoint('medium') {
                left: 0;
            }
        }
    }
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
@-webkit-keyframes fadeOutFromIn {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
@keyframes fadeOutFromIn {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}
