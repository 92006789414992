/*START OVERRIDE STYLE FOR MICROSITE*/
.microsite {

  main {
    max-width: 100% !important;
    .general-header {
      max-width: 100% !important;
    }
    .main__content {
      max-width: 100% !important;
    }
  }

  /*START OVERRIDE STYLE FOR HERO BANNER*/
  #hero-banner {
    max-width: 100%;
    min-height: auto;
    padding-top: 45px;
    padding-bottom: 45px;
    position: relative;
    margin-top: 0px;
    &.light {
      h2,
      p {
        color: #fff !important;
      }
    }
    &.dark {
      h2,
      p {
        color: #1f1f1f !important;
      }
    }
    .container {
      max-width: 940px;
      padding: 0px;
      @include breakpoint('medium-ipad') {
        padding: 20px;
      }
      img {
        margin-bottom: 20px;
        max-width: 100%;
      }
    }
  }
  /*END OVERRIDE STYLE FOR HERO BANNER*/

}
/*END OVERRIDE STYLE FOR MICROSITE*/