.image-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 1260px;
    width: 100%;
    margin: map-get($spacers, 1)*5 auto;
    padding: 0 map-get($spacers, 1)*1.5;
    @include breakpoint("medium") {
        margin-top: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
        gap: 35px;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    &-heading {
        @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-05);
        text-transform: capitalize;
        text-align: center;
        @include breakpoint("medium") {
            @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-025);
        }
    }
    &.large-heading {
        & > .image-grid-heading {
            @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-05);
        }
    }
    &.small-heading {        
        & > .image-grid-heading {
            @include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
        }
    }
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        width: 100%;
    }
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: calc(33.33% - 40px);
        @include breakpoint("ipad") {            
            flex-basis: calc(33.33% - 40px);
        }
        @include breakpoint("medium") {            
            flex-basis: calc(50% - 30px);
        }
        @include breakpoint("medium-small") {
            flex-basis: 100%;
        }
        &-img {
            width: 225px;
            height: 225px;
            & > img {
                border: 2px solid $omd-very-light-purple;
                border-radius: 50%;
                height: 100%;
                object-fit: cover;
            }
        }        
        &-content {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            text-align: center;
            height: 100%;
            &-title {
                @include font($font-size-25, $font-weight-bold, $line-height-30, $letter-spacing-025);
                margin: map-get($spacers, 1)*0.75 0;
                & > a {
                    color: $omd-black;
                    &:hover {
                        color: $omd-dark-purple-2;
                        text-decoration: none;
                    }
                }
            }
            & > p {
                @include font($font-size-12, $font-weight-600, $line-height-normal, $letter-spacing-125);
                text-transform: uppercase;
                margin-bottom: auto;
            }
            & > .cta {
                margin: map-get($spacers, 1) auto 0;
            }
        }
    }

    &.one-column {
        .image-grid-item {
            flex-basis: 100%;
            @include breakpoint("ipad") {            
                flex-basis: calc(33.33% - 40px);
            }
            @include breakpoint("medium") {            
                flex-basis: calc(50% - 30px);
            }
            @include breakpoint("medium-small") {
                flex-basis: 100%;
            }
        }
    }

    &.two-column {
        .image-grid-item {
            flex-basis: calc(50% - 30px);
            @include breakpoint("ipad") {            
                flex-basis: calc(33.33% - 40px);
            }
            @include breakpoint("medium") {            
                flex-basis: calc(50% - 30px);
            }
            @include breakpoint("medium-small") {
                flex-basis: 100%;
            }
        }
    }

    &.four-column {
        .image-grid-item {
            flex-basis: calc(25% - 45px);
            @include breakpoint("ipad") {            
                flex-basis: calc(33.33% - 40px);
            }
            @include breakpoint("medium") {            
                flex-basis: calc(50% - 30px);
            }
            @include breakpoint("medium-small") {
                flex-basis: 100%;
            }
        }
    }
}