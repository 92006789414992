#productDirectoryTop,
.insight-list {
  & > .search__content {
    flex-direction: column;
    .search-results,
    .search-metadata,
    .search-pagination__wrapper {
      margin-left: 0;
    }
    .search-pagination {
      &__wrapper {
        padding: 0;
        border-top: none;
        @include breakpoint("medium") {
          order: 7;
        }
      }
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        @include spacer(padding-top, map-get($spacers, 3));
        @include spacer(padding-bottom, map-get($spacers, 3));
        @include breakpoint("medium") {
          display: block;
        }
        select {
          margin-left: 20px;
          padding: 3px 9px;
          border: 1px solid $color-black;
          width: 64px;
          font-family: inherit;
          font-weight: 600;
          font-size: 0.875rem;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          border-radius: 16px;
        }
      }
      &__pagination-list {
        margin-left: auto;
        @include breakpoint("medium") {
          width: auto;
          margin-left: -15px;
          margin-right: -15px;
          @include spacer(margin-top, map-get($spacers, 3));
          text-align: inherit;
        }
        li {
          margin: 0 14px;
          padding: 0;
          font-family: inherit;
          font-weight: 600;
          font-size: inherit;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          a {
            padding: 0 1px 1px;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-transform: inherit;
            line-height: inherit;
            text-decoration: none;
            border-bottom: 2px solid $color-black;
          }
          &.is-active {
            a {
              background: transparent;
              color: $omd-purple;
              border-color: $omd-purple;
            }
          }
        }
      }
    }
    & > .search__body {
      @include inf-container();
      padding: 0 30px;
      margin: 0 auto;
      & > .search__facets {
        @include inf-col-fluid();
        width: 100%;
        min-width: auto;
        max-width: none;
        margin-right: 0;
        @include spacer(margin-bottom, map-get($spacers, 1));
        font-family: inherit;
        padding-right: 0px;
        padding-left: 0px;
        border: none;
        .facets__content {
          width: 100%;
          padding: 0;
        }
        .facets__section {
          font-size: inherit;
          line-height: inherit;
        }
        .facets__fieldset {
          font-size: 0;
          margin-bottom: 0;
          & > * {
            vertical-align: middle;
            @include font(
              $font-size-20,
              $font-weight-600,
              $line-height-15,
              -$letter-spacing-05
            );
            @include breakpoint("medium") {
              @include spacer(margin-bottom, map-get($spacers, 0));
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          & > .filter-label {
            font-style: $font-style-normal;
            font-weight: $font-weight-600;
            font-size: $font-size-36;
            line-height: 50px;
            text-transform: capitalize;
          }
          select {
            margin-left: 20px;
            padding: 10.5px 50px 10.5px 20px;
            border-color: $color-black;
            border-radius: 50px;
            appearance: none;
            background-image: url(img/omd/chevron_down_black.svg);
            background-repeat: no-repeat;
            background-position: center right 20px;
            background-size: 20px 20px;
            @include breakpoint("medium") {
              width: 100%;
              margin-left: 0;
            }
            &.disabled {
              pointer-events: none;
              opacity: 0.5;
            }
            &[multiple] {
              overflow-y: hidden;
              overflow-x: auto;
              max-height: 53px;
              option {
                padding: 0;
                background: transparent;
                font-weight: inherit;
                color: inherit;
              }
            }
          }
        }
        .selectivity-input {
          width: calc(50% - 60px);
          margin-left: 20px;
          @include breakpoint("medium") {
            width: 100%;
            margin-left: 0;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
        .selectivity-single-select,
        .selectivity-multiple-input-container {
          position: relative;
          overflow: hidden;
          min-height: 50px;
          max-height: none;
          font-size: inherit;
          border: none;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $color-black;
            border-radius: 25px;
            pointer-events: none;
          }
          &.hover,
          &.open {
            &::after {
              border-width: 2px;
            }
          }
          &.open {
            .selectivity-arrow::before {
              transform: rotate(180deg);
            }
          }
          & > .selectivity-caret {
            display: none;
          }
        }
        .selectivity-multiple-input-container{
          padding: 10.5px 50px 10.5px 20px;
        }
        .selectivity-multiple-selected-item {
          margin: 0;
          line-height: inherit;
          .selectivity-multiple-selected-item-remove {
            color: inherit;
          }
          &[data-item-id=all] {
            .selectivity-multiple-selected-item-remove {
              display: none;
            }
          }
        }
        .selectivity-multiple-input {
          height: auto;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .selectivity-single-select-input {
          display: none;
        }
        .selectivity-single-result-container {
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 10.5px 50px 10.5px 20px;
          & > .selectivity-single-selected-item {
            & > .selectivity-single-selected-item-remove {
              display: none;
            }
          }
        }
        .selectivity-arrow {
          top: calc(50% - 10px);
          right: 0px;
          width: 50px;
          height: 20px;
          background: white;
          &::before {
            @include inf-icon("img/omd/chevron_down_black.svg", 100%, 100%);
          }
          .alert__icon {
            display: none;
          }
        }
        .selectivity-dropdown {
          position: absolute;
          margin-top: 15px;
          padding: 0;
          background-color: transparent;
          border: none;
          &::before,
          &::after {
            content: "";
            position: absolute;
            right: 25px;
            top: -4px;
            width: 13px;
            height: 13px;
            transform: rotate(-45deg) skew(7deg, 7deg);
            z-index: 1;
          }
          &::before {
            background: $color-black;
            margin-top: -2.5px;
          }
          &::after {
            background: $color-white;
          }
        }
        .selectivity-results-container {
          overflow: hidden;
          max-height: 295px;
          font-size: 1rem;
          font-weight: 400;
          background-color: $color-white;
          border: 2px solid $color-black;
          border-radius: 10px;
        }
        .selectivity-results-wrapper {
          overflow: auto;
          height: 100%;
          max-height: 295px;
        }
        .selectivity-load-more,
        .selectivity-result-item {
          position: relative;
          padding: 14px 20px;
          user-select: none;
          z-index: 2;
          &.highlight {
            color: $omd-purple;
          }
        }
      }
      .search-bar {
        min-height: auto;
        padding: 0;
        @include spacer(margin-bottom, map-get($spacers, 3));
        background: transparent;
        &__label,
        &__footer {
          display: none;
        }
        &__content {
          @include spacer(margin-top, map-get($spacers, 1));
        }
        &__input-wrapper {
          width: 100%;
          margin-right: 0;
        }
        &__field {
          height: 68px;
          padding: 0 30px;
          @include heading5();
          border: solid 1px $color-black;
          border-right: none;
          border-radius: 34px 0 0 34px;
        }
        &__clear {
          right: 18px;
          top: 50%;
          width: 19px;
          height: 19px;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.2s;
          pointer-events: none;
          &.active {
            opacity: 1;
            pointer-events: all;
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            top: 0px;
            position: absolute;
            background-image: url(img/omd/icon_close_black.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
        &__icon {
          display: none;
          &--info,
          &--clear {
            display: inline-block;
          }
        }
        &__tips {
          @include spacer(margin-left, map-get($spacers, 2));
          &-button {
            font-family: $font-family-opensans;
            font-size: $font-size-16;
            color: $color-black;
            font-weight: $font-weight-600;
            color: $omd-purple;
            background: url(img/omd/light-bulb.svg) no-repeat left center;
            background-size: 16px;
            @include spacer(padding-left, map-get($spacers, 3));
            display: block;
            &:hover {
              text-decoration: none;
            }
            & > svg {
              display: none;
            }
            & > span {
              position: relative;
              &:hover:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -3px;
                width: 100%;
                height: 2px;
                background-color: $omd-purple;
              }
            }
          }
          &-panel {
            h3 {
              font-size: $font-size-24;
              letter-spacing: $letter-spacing-094;
            }
            *:not(h3) {
              font-family: $font-family-opensans;
              font-size: $font-size-16;
              color: $color-black;
              font-weight: revert;
            }
          }
        }
        &__submit {
          position: relative;
          width: 70px;
          height: 70px;
          margin-right: 0;
          padding: 0;
          background-color: $color-black;
          border: solid 1px $color-black;
          border-left: none;
          border-radius: 0 34px 34px 0;
          cursor: pointer;
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(img/omd/search_white.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px 24px;
          }
        }
        &__headline-label {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
        &__footer-option {
          @include breakpoint("medium") {
            display: block;
            margin-right: 0;
          }
        }
      }
      .search-results {
        .search-result {
          &__description {
            font-size: $font-size-16;
            a {
              color: $omd-purple;
              &:hover {
                color: $body-text-colour;
              }
            }
          }
          &__entitlement {
            font-weight: inherit;
            font-size: inherit;
            letter-spacing: inherit;
            text-transform: none;
            a {
              color: $body-text-colour;
              text-decoration: underline;
              &:hover {
                color: $omd-purple;
              }
            }
          }
          &__access-enddate {
            margin-bottom: 0;
            @include breakpoint("medium-small") {
              margin-bottom: 1rem;
            }
          }
          &__viewall {
            margin-left: auto;
            @include breakpoint("medium-small") {
              width: 100%;
              margin-left: 0;
            }
            a {
              color: $body-text-colour;
              text-decoration: underline;
              &:hover {
                color: $omd-purple;
              }
            }
          }
          &__heading {            
            @include header-style ($h5-font-size, $font-weight-600, $line-height-125, -$letter-spacing-094);
          }
        }
      }
    }
  }
  .productDirectoryFilter {
    float: none;
    .facets__fieldset {
      display: flex;
      @include breakpoint("lg") {
        flex-direction: column;
      }
      & > .filter-field {
        padding-left: 10px;
        @include breakpoint("lg") {
          @include spacer(margin-bottom, map-get($spacers, 0));
        }
        & > .information__tips {
          position: relative;
          white-space: nowrap;
          @include breakpoint("lg") {
            display: none;
          }
          & > .information__tips-panel {
            display: none;
            position: absolute;
            border: 1px solid $omd-medium-grey;
            white-space: normal;
            z-index: 1000;
            right: auto;
            left: 6px;
            top: calc(100% + 5px);
            transform: translateX(-50%);
            width: 450px;
            background: $omd-light-grey;
            box-shadow: 0 2px 10px $omd-dark-grey;
            @include spacer(padding, map-get($spacers, 0));
            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 15px;
              height: 15px;
              top: -3px;
              left: 50%;
              right: auto;
              transform: rotate(45deg) translateX(-50%);
              border-left: 1px solid $omd-medium-grey;
              border-top: 1px solid $omd-medium-grey;
              background: #eee;
            }
            & > p {
              line-height: normal;
              font-family: $font-family-opensans;
              font-size: $font-size-12;
            }
          }
          &.open {
            position: relative;
            z-index: 1;
            & > .information__tips-panel {
              display: block;
            }
          }
          & > svg {
            max-width: 18px;
            fill: $color-light-black;
          }
        }
        & > .selectivity-input,
        & > select {
          flex: 1 0 auto;
          order: 2;
          margin-left: 10px;
          @include breakpoint("medium") {
            margin-left: 0px;
          }
        }
      }
      & > .filter-field,
      & > .filter-label {
        flex: 1;
        display: flex;
        align-items: center;
        width: 25%;
        @include breakpoint("lg") {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

#productDirectoryTop {
  .search__content {
    .search__body {
      .search-results {
        .search-result {
          .search-result__body {
            & > * {
              order: initial;
            }
            .search-result__desc {
              margin-bottom: map-get($spacers, 0);
              & > * {
                display: inline;
              }
              a {
                color: $omd-dark-purple-2;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
