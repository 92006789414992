.omd-sorry-text {
  @include font($font-size-20, $font-weight-normal, $line-height-15, -$letter-spacing-025);
  text-align: center;
  padding-bottom: 48px;
}
.omd-404-text-container {
  min-height: 500px;
}
.omd-404-btns {
  @include breakpoint("small") {
    text-align: center;
  }
  a:first-child {
    margin-right: 30px;
  }
  a {
    @include breakpoint("small") {
      margin-bottom: 25px;
      width: 100%;
    }
  }
}
.omd-asset-subheader {
  @include font($font-size-14, $font-weight-300, $line-height-157, -$letter-spacing-054);
}

.inf-container {
  &.powerbi-dashboard-container {
    max-width: none;
    .omd-col-content-side {
      display: none;
    }
    .omd-col-main {
      width: 100%;
      max-width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      @include breakpoint("lg") {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  &.tableau-dashboard-container {
    max-width: none;
    .omd-col-content-side {
      display: none;
      &:has(.part-of-collection) {
        display: block;
        width: 100%;
        max-width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        @include breakpoint("lg") {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .omd-col-main {
      width: 100%;
      max-width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      @include breakpoint("lg") {
        padding-left: 15px;
        padding-right: 15px;
      }
      .root {
        margin: 0 auto;
      }
      .tableau-app {
        font-weight: $font-weight-400;
        &:not(.checked) {
          display: none;
        }
        .page-title {
          float: none;
          @include font($h4-font-size, $font-weight-600, $line-height-125, -$letter-spacing-2);
          // //Only h2 is scaled to smaller fonts in mobile only

          @include breakpoint("small") {
            font-size: $h4-font-size/1.25;
          }

          //Below header padding spacer-2 is used
          @include spacer(padding-bottom, map-get($spacers, 0));
        }
        .page-description {
          @include spacer(padding-bottom, map-get($spacers, 0));
        }
        .bookmarks__list {
          ul {
            margin: 0 !important;
            padding: 0 !important;
            .bookmark {
              &::before {
                display: none;
              }
              .bookmark__label button {
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }
        .button {
          padding: 0;
        }
        .downloads-cont__tableau-iframe-dl-button {
          height: 26px;
          border-bottom: solid 1px #d4d4d4;
          .icon-download {
            display: block;
          }
        }
      }
    }
  }
}

// legacy tableau
.article-data-tool-placeholder {
  .data-tool-placeholder {
    float: none;
    width: 100%;
    .data-tool-heading {
      text-align: center;
    }
    .tableauPlaceholder {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.omd-col-main,
.nav {
  a:not(.button--filled):not(.button--outline):not(.download-btn):not(.selectivity-single-selected-item-remove):not(.article-preview__headline):not(.signin-link):not(.cta):not(.search-result__heading) {
    @include omd-a;
  }
}

.featured_af .inf-col-fluid {
  position: relative;
}
.omd-col-main {
  @include font($font-size-16, $font-weight-400, $line-height-2, -$letter-spacing-02);
  color: inherit;
  position: relative;

  .fade-required {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // p line number x line-height
      height: 8rem;
			max-height: 100%;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
  }

  .table-bordered td p {
    padding: 10px;
  }

  //Level1 - H2 to Level3-H4 for body with no scaling as per breakpoints
  h2 {
    @include font($h4-font-size, $font-weight-600, $line-height-125, -$letter-spacing-2);
    // //Only h2 is scaled to smaller fonts in mobile only

    @include breakpoint("small") {
      font-size: $h4-font-size/1.25;
    }

    //Below header padding spacer-2 is used
    @include spacer(padding-bottom, $spacer-2);
  }

  h3 {
    @include font($h5-font-size, $font-weight-600, $line-height-125, -$letter-spacing-1);

    //Below header padding spacer-2 is used
    @include spacer(padding-bottom, $spacer-2);
  }

  h4 {
    @include font($h6-font-size, $font-weight-600, $line-height-125, -$letter-spacing-08);

    //Below header padding spacer-2 is used
    @include spacer(padding-bottom, $spacer-2);
  }

  .methodologies-container {
    ul {
      list-style: none;
      padding: 0px;
      margin-left: 0px;
      li {
        @include spacer(margin-bottom, $spacer-2);
        &::before {
          content: none;
        }
        a {
          text-decoration: none;
          border-bottom: 0px none !important;
        }
      }
    }
  }

  .article-paragraph {
    margin-bottom: $spacer-3 !important;
    @include font($font-size-16, $font-weight-400, $line-height-2, -$letter-spacing-02);
    color: $body-text-colour;
  }

  .article-list--ul {
    @include font($font-size-16, $font-weight-400, $line-height-2, -$letter-spacing-02);
    padding-bottom: 16px !important;
    margin-left: 20px;
    .article-list--ul {
      padding-bottom: 0px !important;
    }
  }

  ul {
    @include font($font-size-16, $font-weight-400, $line-height-2, -$letter-spacing-02);
    padding-bottom: 16px !important;
    margin-left: 20px !important;
    ul {
      padding-bottom: 0px !important;
    }
  }

  iframe {
    max-width: 100%;
  }

  .omd-bullet-container {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.2px;
  }

  p.nomargin {
    margin: 0 !important;
  }

  p {
    @include spacer(margin-bottom, $spacer-2);
  }

  li.nested-ul::before {
    display: none;
  }

  ul {
    list-style: outside !important;
    padding-bottom: 16px !important;
  }

  ol {
    margin: 0 0 0 15px;
    padding-bottom: 16px !important;
    list-style: none;
    counter-reset: li;
  }

  .article-list--ul li::before ,
  ul li::before {
    content: "\2022";
    color: $omd-purple;
    margin: 0;
    background: none;
    margin-left: -20px;
    padding-right: 10px;
    height: 22px;
    width: 16px;
    display: inline-block;
    position: absolute;
  }

  ul li p {
    margin: 0px !important;
  }

  ul li,
  ol li,
  ul {
    margin: 0px;
    padding: 0px;
  }

  ol li::before {
    content: counter(li);
    color: $omd-purple;
    display: inline-block;
    width: 1em;
    margin-left: -1.5em;
    margin-right: 0.5em;
    text-align: right;
    direction: rtl;
  }

  ol li {
    list-style-type: none;
    counter-increment: li;
  }

  ol li p {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 10px;
  }

  p + h2,
  p + h3,
  p + h4,
  ol + h2,
  ol + h3,
  ol + h4,
  ul + h2,
  ul + h3,
  ul + h4 {
    padding-top: 1rem;
  }

  img {
    // @include spacer(padding-top, $spacer-3);
    //@include spacer(padding-bottom, $spacer-2);
    max-width: 100%;
    height: auto;
  }

  .article-exhibit {
    padding: 0px;
    margin: 0px;
    flex-direction: column;
    @include spacer(padding-bottom, $spacer-2);
    float: none;

    .article-exhibit__title {
      @include spacer(padding-bottom, $spacer-2);
      color: $color-black;
    }

    .article-exhibit__caption {
      @include font($font-size-16, $font-weight-normal, $line-height-15, -$letter-spacing-02);
      color: $body-text-colour;
    }

    .enlarge {
      border: 0px none !important;
    }

    .article-exhibit__source {
      width:100%;
      @include font($font-size-16, $font-weight-bold, $line-height-156, -$letter-spacing-02);
      color: $body-text-colour;
      text-align: left;
    }
  }
}

.root {
  position: relative;
}

.omd-col-content-side {
  [revealer-component][data-container-selector="#longReadJumpToSection"] {
    #longReadJumpToSection {
      overflow: visible !important;
      @include breakpoint("medium") {
        overflow: hidden !important;
      }

      .longread-content a,
      .longread-figures a {
        word-break: break-word;
      }
    }
    .omd-box-bottom,
    .omd-box-bottom::before {
      // hide border in wider views from 800px
      @include breakpoint("wide") {
        border: 0px none;
      }
      @include breakpoint("medium") {
        border-left-width: 0px;
        border-right-width: 0px;
        height: 26px;
      }
    }
    [revealer-trigger] {
      display: none;
      @include breakpoint("medium") {
        display: inline-block;
      }
    }
  }
}

.omd-mkt-img-container .omd-content-container .mkt-content-title{
  @include spacer(margin-top, map-get($spacers, 2));
}
