.inf-standard-form-field {
	padding: 8px;
	border: 1px solid $color-light-gray;
	font-size: 15px;

	// NOTE — Each of these need to be their own rule, cannot be combined.
	// An "invalid" selector inside a group will invalidate the entire group
	// http://stackoverflow.com/a/2610741
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	    font-style: italic;
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		font-style: italic;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-style: italic;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	 	font-style: italic;
	}
}

.form--single-row {
	margin-top: 1rem;
	
	.sign-in__submit-wrapper a{float: right;}
	.inf-standard-form-field {
		float: left;
	}
	button {
		float: right;
	}

	&:after {
		clear: both;
		display: block;
		content: "";
	}
}

.asterisk-required:before {
	color: $color-tree-poppy-orange;
	content: "*";
	display: inline-block;
	font-size: 1rem;
}

.checkbox-label {
	@include textMix('Roboto', 0.9375rem, 400);
	color: $color-abbey-gray;
	margin-bottom: 2rem;
	margin-left: 0.25rem;
	vertical-align: middle;
}

.labeled-checkbox {
	margin-bottom: 1rem;
}

input[type=checkbox] {
	appearance: none;
	width: 22px;
	height: 22px;
	background-color: white;
	border: 1px #adadad solid;
	cursor: pointer;
	user-select: none;
	&::before {
		content: '';
    display: block;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    height: 80%;
    width: 40%;
    transform: rotate(45deg) skew(10deg);
    transform-origin: 80% 110%;
	}
	&:checked {
		&::before {
			border-color: white;
		}
	}
}