#hero-banner {
  position: relative;
  align-items: center;
  width: 100%;
  height: 450px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include breakpoint('medium') {
    padding: 0;
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: auto;

      @supports (object-fit: cover) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 40px;
    @include breakpoint('medium') {
      padding: 1rem;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    /* for test */
    font-family: 'PT Serif', serif;
    font-size: 18px;
    line-height: 30px;
    text-align: center;

    &.text-align-left {
      text-align: left;
    }

    &.text-align-center {
      text-align: center;
    }

    &.text-align-right {
      text-align: right;
    }

    h2 {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 30px;
      font-weight: 700;
      line-height: 2.175rem;
      margin-bottom: .5rem;
      color: inherit;
    }

    .cta, .btn {
      display: inline-block;
      padding: 0.5em 1em;
      margin-top: .5rem; 
      color: inherit;
      text-decoration: none;
    }

    @supports (display: flex) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }

  &.light {
    color: white;
  }

  &.dark {
    color: #231f20;
  }

  @supports (display: flex) {
    display: flex;
    height: auto;
    min-height: 450px;
  }
}
