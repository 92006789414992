.topics-page{

  .banner-content-col{
    padding-right: 30px !important;
    padding-left: 30px !important;
    width:50% !important;
    margin-left: -15px;
    @include breakpoint('medium') {
      width:100% !important;
    }
  }
  .banner-image-col{
    padding-right: 30px !important;
    padding-left: 30px !important;
    width:50% !important;
    margin-right: -15px;
    @include breakpoint('medium') {
      width:100% !important;
    }
  }
}



.omd-page-banner {
  & > .inf-row {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint('lg') {
      flex-direction: column-reverse;
      text-align: center;
    }
    @include breakpoint('small') {
      text-align: left;
    }
    & > [class*=inf-col] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .banner-content-col {
    @include colwidth(6.5, 'lg');
    .subscribe {
      & > *:last-child {
        margin-bottom: 0;
      }
    }
    .inf-ctas {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      @include breakpoint('lg') {
        flex-direction: column;
      }
      .btn {
        margin-left: 0;
        margin-right: 0;
        @include breakpoint('lg') {
          margin-left: auto;
          margin-right: auto;
        }
        @include breakpoint('small') {
          margin-left: 0;
          margin-right: 0;
        }
        & + .btn {
          margin-left: 1rem;
          margin-right: 0;
          @include breakpoint('lg') {
            @include spacer(margin-top, map-get($spacers, 0));
            margin-left: auto;
            margin-right: auto;
          }
          @include breakpoint('small') {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
  .banner-image-col {
    @include colwidth(5.5, 'lg');
    .banner-image {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      @include breakpoint('lg') {
        @include spacer(margin-bottom, map-get($spacers, 0));
      }
      @include breakpoint('small') {
        &.hide-mobile-img {
          display: none;
        }
      }
    }
  }
}
