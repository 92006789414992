//Layout level styles
.manually-curated-content {
    @include inf-container();
    flex-direction: row;
    flex-wrap: wrap;

    @include isIE {
        font-size: 0;
    }

    @include breakpoint("lg") {
        flex-direction: column;
    }

    .article-preview.article-preview--small {
        padding-bottom: 0;
    }
}

//Main well occupies 2/3 columns - 8 of 12 columns
.manually-curated-content__main-well {
    @include col-with-pad(8, "lg");
    float: none;

    @include isIE {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
    }

    @include breakpoint("lg") {
        padding-right: 0px;
    }
}

//Right rail occupies 1/3 of columns - 4 of 12 columns
.manually-curated-content__right-rail {
    @include col-with-pad(4, "lg");
    float: none;

    @include isIE {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
    }
}

.grid-main-row-dir {
    @include inf-row-custom-col(".flex_col");
    margin-bottom: 0;
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.grid-main-col-dir {
    @include inf-container;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.flex_col .article-summary.blur {
    p, span {
        @include font($font-size-16, $font-weight-400, $line-height-15, -0.02rem);
        color: $body-text-colour !important;

        &::before {
            display: none;
        }

        a {
            font-weight: $font-weight-600;
            color: inherit;
            text-decoration: underline;
            transition: all .2s;

            &:hover {
                text-decoration-color: $omd-purple;
            }
        }
    }
}

//Common styles of component across different variants
.article-preview.article-preview--small {
    padding-top: 0px;
    border: 0px none;

    .comp-headline {
        @include heading3();
        @include spacer(margin-bottom, map-get($spacers, 3));
        & > a {
            font-size: $font-size-14;
            color: $color-black;
            font-weight: normal;
            letter-spacing: $letter-spacing-02;
            text-decoration: underline;
            text-decoration: underline;
            float: right;
            margin-top: 15px;
            &:hover {
                color: $omd-purple;
            }
        }
    }

    .article-metadata {
        display: none;
    }

    .article-preview__headline {
        margin-top: 0px;
        @include spacer(margin-bottom, map-get($spacers, 0));

        a {
            display: block;
            @include font($font-size-20, $font-weight-600, $line-height-13, -$letter-spacing-1);
        }
    }

    .article-preview__byline {
        @include omd-label();
        @include spacer(margin-bottom, map-get($spacers, 0));
        color: $body-text-colour;

        a {
            text-decoration: none;
            color: $body-text-colour;
            @include omd-label();

            &:hover {
                color: $body-text-colour;
            }
            //border transition styles
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            // overflow: hidden;
            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 0;
                right: 100%;
                bottom: 0;
                background: #000000;
                height: 1px;
                -webkit-transition-property: right;
                transition-property: right;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }

            &:hover:before {
                right: 0;
            }
        }

        .action-flag {
            margin-left: 0px;
            svg {
                height: 18px;
                cursor: pointer;
            }
        }
    }

    .article_restricted_access {
        @include spacer(margin-bottom, map-get($spacers, 0));
        @include omd-restricted-small();
        & > .lock__icon,
        & > .lock-open__icon {
          fill: $omd-purple;
          width: 10px;
          height: 10px;
          vertical-align: middle;
        }
        & > span {
          vertical-align: middle;
        }
    }

    .full-diminished-button {
        display: inline-block;
        width: auto;
        padding: 10px 36px 10px 0;
        @include spacer(margin-top, map-get($spacers, 2));
        @include omd-view-more-cta();
        //Overriding legacy styles
        position: relative;
        margin-bottom: 0px;
        background-color: inherit;
        color: $body-text-colour;
        text-align: left;
        cursor: pointer;
        transition: color 0.2s;

        &::after {
            right: 0;
            width: 25px;
            height: 18px;
            background-position: center;
        }

        &:hover {
            color: $omd-purple;
            text-decoration: none;
        }
    }

    .grid-main-row-dir {
        color: $body-text-colour;

        .flex_col {
            //Overriding legacy styles
            border: 0px none;
        }

        .flex_col.blank {
            &:hover {
                background: none;
                cursor: default;
            }
        }
    }
}

/* flex-widget-white - white themed component that
   has no tile background colors and seperators
   Example : Free Research */
.flex-widget-white.article-preview.article-preview--small {
    @include inf-container;
    @include spacer(padding-bottom, map-get($spacers, 5));
    display: block;
    //Specific styles for row 1 and col 3
    .grid-main-row-dir {
        margin: -0.75rem -10px;

        .flex_col {
            width: 33.33%;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
            padding: 10px;
            overflow: hidden;
            @include breakpoint('medium') {
                width: 100%;
                margin-right: 0;
            }
            &.blank {
                display: none;
            }
            & > div {
                height: 100%;
                background-color: $omd-light-grey;
                padding: 15px;
                &:hover {
                    background-color: $omd-box-hover-purple;
                }
                .article-preview__headline {
                    transition: color 0.3s;
                    a, .a {
                      color: inherit;
                      text-decoration: none;
                      &:hover {
                          color: $omd-purple;
                      }
                    }
                }
            }
            & > div:nth-child(2) {
                & > *:last-child {
                    margin-bottom: 0;
                }
            }
            & > .article-preview__tags {
                display: none;
            }
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

/* flex-widget-white - grey themed component that
   has no tile background colors and seperators
   Example : Free Research */
.flex-widget-grey.article-preview.article-preview--small {
    .article-preview__headline a {
        @include font($font-size-24, $font-weight-600, $line-height-133, -$letter-spacing-1);
    }

    .article-summary {
        flex: 1 0 100%;
        p {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        // legacy overrides
        margin: 0px;

        &.blur, p, span {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            color: inherit !important;
        }

        &.blur p::before {
            display: none;
        }
    }

    .grid-main-row-dir {
        @include spacer(margin-bottom, map-get($spacers, 3));

        .flex_col {
            width: 100%;
            @include spacer(margin-top, map-get($spacers, 3));

            &:first-child {
                margin-top: 0;
            }

            &.blank {
                display: none;
            }

            div:nth-child(2) {
                padding: 28px;
                @include omd-grey-af-hover(".article-preview__headline");

                @include breakpoint("medium") {
                    padding: 1em;
                }

                & > *:last-child {
                    margin-bottom: 0;
                }
            }
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

/* flex-widget-seperator - grey themed component that
   has no tile background colors and seperators
   Example : Free Research */
.flex-widget-seperator.article-preview.article-preview--small {
    .grid-main-row-dir {
        @include spacer(margin-top, -(map-get($spacers, 3)));

        @include breakpoint("lg") {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .flex_col {
            width: 100%;
            @include spacer(margin-top, map-get($spacers, 3));

            @include breakpoint("lg") {
                width: 50%;
            }

            @include breakpoint("medium") {
                width: 100%;
            }

            &.blank {
                display: none;
            }

            &::after {
                content: "";
                display: block;
                @include spacer(height, map-get($spacers, 3));
                border-bottom: 1px solid $color-black;
            }

            div:nth-child(2) {
                @include omd-light-af-hover(".article-preview__headline");

                & > *:last-child {
                    margin-bottom: 0;
                }
            }
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.article-preview.article-preview--small.flex-widget-white.version-2,
.article-preview.article-preview--small.flex-widget-grey.version-2,
.article-preview.article-preview--small.flex-widget-seperator.version-2 {
    margin: map-get($spacers, 1)*5 auto;
    padding-bottom: 0;
    @include breakpoint("medium") {
        margin: map-get($spacers, 1)*2.5 auto;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    & > .comp-headline {
        @include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint("medium") {
            @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
        }
        & > a {
            padding-right: 0;
            text-transform: initial;
            float: none;
            margin-top: 0;
            white-space: nowrap;
            margin-left: 20%;
            &:hover {
                color: $omd-dark-purple-2 !important;
            }
            &::after {
                display: none;
            }
        }
    }
    & > .grid-main-row-dir {
        margin: 0;
        gap: 36px 20px;
        @include breakpoint("medium") {
			flex-direction: column;
			gap: 20px 15px;
		}
        & > .flex_col {
            margin: 0;
            padding: 0;
            & > div {
                gap: 5px;
                transition: background-color 300ms ease;
                position: relative;
                &.article-metadata,
                &.article-preview__tags {
                    display: none;
                }
                & > .article-preview__headline {
                    margin: 0;
                    padding-right: map-get($spacers, 1)*1.5;
                    & > a {
                        @include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-normal);
                        color: $color-black;
                        &:hover {
                            color: $omd-dark-purple-2 !important;
                            text-decoration: none;
                        }
                        @include breakpoint("medium") {
                            @include font($font-size-21, $font-weight-600, $line-height-125, $letter-spacing-normal);
                        }
                    }
                }
                & > .article-preview__byline {
                    margin: 0;
                    @include font($font-size-13, $font-weight-500, $line-height-135, $letter-spacing-normal);
                    & > .action-flag {
                        padding: 0;
                    }
                    & > a {
                        letter-spacing: normal;
                        font-weight: $font-weight-500;
                        &:hover {
                            color: $omd-dark-purple-2 !important;
                        }
                        &::before {
                            display: none;
                        }
                    }
                    
                }
                & > .article-preview__freelabel {
                    order: 99;
                }
                & > .article_restricted_access {
                    display: flex;
                    align-items: center;
                    min-height: 21px;
                    gap: 5px;
                    margin: 0 8px 0 0;
                    line-height: inherit;
                    & > .lock__icon,
                    & > .lock-open__icon {
                      fill: $omd-purple;
                      width: 10px;
                      height: 10px;
                      vertical-align: middle;
                    }
                    & > span {
                      margin: 0;
                      vertical-align: middle;
                    }
                }
            }
        }
    }
}

.article-preview.article-preview--small.flex-widget-white.version-2 {
    .grid-main-row-dir {
        .flex_col {
            flex-basis: calc(100%/3 - 14px);
            & > div {
                @include spacer(padding, map-get($spacers, 1));
                background-color: $omd-light-grey;
                &:hover {
                  background-color: $omd-very-light-purple;
                }
            }
        }
    }
}
.article-preview.article-preview--small.flex-widget-white,
.article-preview.article-preview--small.flex-widget-grey,
.article-preview.article-preview--small.flex-widget-seperator {
    .grid-main-row-dir {
        .flex_col {
            & > div {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                & > .action-flag {
                    padding: 0;
                    position: absolute;
                    right: map-get($spacers, 1);
                    z-index: 9;
                    @include breakpoint("small") {
                        margin: 0;
                    }
                }
                .article-preview__byline {
                    margin: 0 8px 0 0;
                }
                .article_restricted_access {
                    margin-right: 12px;
                }
                .article-preview__freelabel {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
.article-preview.article-preview--small.flex-widget-grey,
.article-preview.article-preview--small.flex-widget-seperator {
    .grid-main-row-dir {
        .flex_col {
            & > div {
                & > .action-flag {
                    right: 0;
                }
            }
        }
    }
}
.article-preview.article-preview--small.flex-widget-white,
.article-preview.article-preview--small.flex-widget-seperator {
    .grid-main-row-dir {
        .flex_col {
            & > div {
                .article-preview__freelabel {
                    order: 99;
                }
            }
        }
    }
}