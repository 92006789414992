
/*---
title: Font Families
section: Typography
---

```example:html
<h1 class="styleguide__roboto">Roboto</h1>
<h1 class="styleguide__robotocond">Roboto Condensed</h1>
<h1 class="styleguide__ptserif">PT Serif</h1>
```
*/

.styleguide__roboto {
    @include textMix('Roboto', 3rem, 500);
}

.styleguide__robotocond {
    @include textMix('RobotoCondensed', 3rem, 500);
}

.styleguide__ptserif {
    @include textMix('PTSerif', 3rem, 500);
}
