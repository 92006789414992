.two-column-accordion {
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-117);
    display: flex;
    flex-wrap: wrap;
    gap: 20px 60px;
    @include breakpoint("medium") {
        margin: map-get($spacers, 1)*3.5 auto;
    }
    @include breakpoint("medium-small") {
        @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-117);
        padding: 0 map-get($spacers, 1)*0.75;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    & > .left,
    & > .right {
        float: left;
        width: calc(50% - 30px);
        @include breakpoint("medium") {
            width: 100%;
        }
    }
    
    .heading {
        width: 100%;
        @include font($font-size-31, $font-weight-bold, $line-height-normal, $letter-spacing-05);
        margin-bottom: map-get($spacers, 1)*3;
        text-align: center;
        @include breakpoint("medium") {
            margin-bottom: map-get($spacers, 1)*1.75;
            line-height: $line-height-36;
            text-align: left;
        }
    }
}