.insight-list {
	@extend .inf-col;
	max-width: 1260px;
	padding: 0 map-get($spacers, 1)*1.5;
	margin: map-get($spacers, 1)*5 auto;
	display: flex;
	gap: 36px;
	@include breakpoint('medium') {
		padding: 0 map-get($spacers, 1)*0.75;
		margin: map-get($spacers, 1)*3.5 auto;
	}
	&.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }

	.selectivity {
		&-input {
			position: relative;
			width: 320px;
			@include breakpoint("medium") {
				width: 100%;
				margin-left: 0;
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		&-single-select {
			position: relative;
			height: 48px;
			font-size: $font-size-18;
			border: none;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid $color-black;
				border-radius: 48px;
			}
			&-input {
				&:focus {
					outline: none;
				}
			}
			&.open {
				color: $omd-dark-purple-2;
				.selectivity-arrow::before {
					transform: rotate(180deg);
				}
			}
			& > .selectivity-caret {
				display: none;
			}
		}
		.selectivity-single-select-input {
			display: none;
		}
		&-single-result-container {
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			padding: 13px 50px 13px 22px;
			& > .selectivity-single-selected-item {
				@include font($font-size-18, $font-weight-600, $line-height-normal, $letter-spacing-normal);
				& > .selectivity-single-selected-item-remove {
				display: none;
				}
			}
		}
		&-arrow {
			top: calc(50% - 10px);
			right: 20px;
			width: 20px;
			height: 20px;
			&::before {
				@include inf-icon("img/omd/chevron_down_black.svg", 100%, 100%);
			}
			&.open {
				&::before {
					@include inf-icon("img/omd/chevron_down_purple.svg", 100%, 100%);
				}
			}
			.alert__icon {
				display: none;
			}
		}
		&-dropdown {
			position: absolute;
			margin-top: 15px;
			padding: 0;
			background-color: transparent;
			border: none;
			width: 100%;
			&::before,
			&::after {
				content: "";
				position: absolute;
				right: 25px;
				top: -4px;
				width: 13px;
				height: 13px;
				transform: rotate(-45deg);
				z-index: 1;
			}
			&::before {
				background: $color-black;
				margin-top: -2.5px;
			}
			&::after {
				background: $color-white;
			}
		}
		&-results-container {
			overflow: hidden;
			max-height: 295px;
			font-size: 1rem;
			font-weight: 400;
			background-color: $color-white;
			border: 1px solid $color-black;
			border-radius: 10px;
		}
		&-results-wrapper {
			overflow: auto;
			height: 100%;
			max-height: 295px;
		}
		&-load-more,
		&-result-item {
			position: relative;
			padding: 14px 20px;
			user-select: none;
			z-index: 2;
			&.highlight {
				color: $omd-purple;
			}
		}
	}
	.information__tips {
		position: relative;
		display: flex;
		align-items: center;
		white-space: nowrap;
		& > .information__tips-panel {
			display: none;
			position: absolute;
			border: 1px solid transparent;
			white-space: normal;
			z-index: 1000;
			right: auto;
			left: 6px;
			top: calc(100% + 5px);
			transform: translateX(-50%);
			width: 450px;
			background: $omd-light-grey;
			box-shadow: 0 2px 10px $omd-medium-grey;
			@include spacer(padding, map-get($spacers, 0));
			@include breakpoint('medium-small') {
				left: 0;
				transform: none;
				width: 320px;
			}
			&::before {
				content: "";
				display: block;
				position: absolute;
				width: 15px;
				height: 15px;
				top: -3px;
				left: 50%;
				right: auto;
				transform: rotate(45deg) translateX(-50%);
				border-left: 1px solid $omd-medium-grey;
				border-top: 1px solid $omd-medium-grey;
				background: $omd-light-grey;
				@include breakpoint('medium-small') {
					left: 10px;
				}
			}
			& > p {
				line-height: normal;
				font-family: $font-family-opensans;
				font-size: $font-size-12;
			}
		}
		&.open {
			position: relative;
			z-index: 3;
			& > .information__tips-panel {
				display: block;
			}
		}
		& > svg {
			max-width: 18px;
			fill: $color-light-black;
		}
	}
	
	&-pagination {
		@extend .pagination-list;
	}

	&-header {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		@include breakpoint('medium-small') {
			flex-direction: column;
			gap: 15px;
		}
		&-title {
			@include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
		}
	}
	&-filter {
		display: flex;
		gap: 10px;
		@include breakpoint('medium-small') {
			flex-direction: column;
			gap: 15px;
		}
		&-item {
			display: flex;
			gap: 10px;
		}
	}
	&-box {
		display: flex;
		flex-wrap: wrap;
		gap: 36px 20px;
		& > .card-list {			
			flex-basis: calc(100%/3 - 14px);
			@include breakpoint('medium') {
				flex-basis: calc(100%/2 - 10px);
			}
			@include breakpoint('medium-small') {
				flex-basis: 100%;
			}
		}
	}
	&-bottom {
		display: flex;
		gap: 40px;
		@include breakpoint('medium-small') {
			flex-direction: column-reverse;
			gap: 20px;
		}
	}
}