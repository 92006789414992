.subscribe.version-2 {
    display: flex;
    flex-direction: column;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: auto;
    @include breakpoint('medium') {
        padding: 0 map-get($spacers, 1)*0.75;
        h6 {
            font-size: $font-size-20;
        }
    }
    h6 {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
        margin-top: map-get($spacers, 0);
    }
    
    .article_restricted_access.access-large {
        margin-top: 0;
        & > * {
            display: inline;
            vertical-align: middle;
        }
        & > .lock__icon,
        & > .lock-open__icon {
          fill: $omd-purple;
          width: 14px;
          height: 14px;
          margin-right: 0.5rem;
        }
    }
}