.pop-out__save-search {
    &-email-alert {
        & input[type=checkbox] {
            vertical-align: middle;
        }
    }
}

.action-flags.ng-scope ul li svg {
    position: relative;
    z-index: 99997;
}