@import "./mixins";
@import "./variables";
@import "./variables-marketing-enhancements";

// inherited styles
& {
  @include font($font-size-16, $font-weight-400, $line-height-15, -0.02rem);
  color: $body-text-colour;
}

h1, .h1{
  @include header-style($font-size-48,$font-weight-600, $line-height-125, - $letter-spacing-2);
  color: inherit;
}

h2, .h2 {
  @include heading2();
}

h3, .h3 {
  @include heading3();
}

h4, .h4 {
  @include header-style($h4-font-size, $font-weight-600, $line-height-125, - $letter-spacing-125);
  color: inherit;
}

h5, .h5 {
  @include heading5();
}

h6, .h6 {
  @include heading6();
}


.omd-subtitle {
  @include omd-subtitle();
}


.omd-subtitle-product {
  @include font(
    $font-size-20,
    $font-weight-400,
    $line-height-15,
    -$letter-spacing-025
  );

  @include breakpoint("small") {
    font-size: $font-size-18;
  }
}

.omd-label {
  @include omd-label();
  text-transform: uppercase;
}

.omd-subscribe {
  color: $omd-purple;
}

.omd-body{
  @include omd-body();  
}
