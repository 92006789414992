.video-card {
    display: flex;
    flex-direction: column;
    &-top {
        position: relative;
        & > img,
        & > iframe {
            width: 100%;
            height: 234px;
            object-fit: cover;
        }
        &::after {
            content: "";
            position: absolute;
            height: 55px;
            width: 55px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url(/dist/img/omdia/play-button.svg) no-repeat;
            transition: transform 300ms ease;
        }
        &:hover {
            &:after {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
    }
    &-content {
        display: flex;
        flex-direction: column;
        background: $omd-pink-blue-gradient;
        padding: map-get($spacers, 1);
        gap: 11px;
        color: $color-black;
        flex: 1;
        &-title {
            @include font($font-size-25, $font-weight-700, $line-height-125, $letter-spacing-normal);
        }
        p {
            @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-normal);
            margin-bottom: map-get($spacers, 1)/2;
            flex: 1 auto;
        }
        & > .cta {
            margin-left: auto;
        }
    }
    &.dark-theme {
        .video-card-content {
            background: $color-black;
            color: $color-white;
            & > .cta {
                margin-left: auto;
                @extend  .cta-primary;
            }
        }
    }
    &.no-video {
        & > .video-card-top {
            display: none;
        }
        & > .video-card-content {
            @include breakpoint("medium-small") {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
            }
            & > .video-card-content-title {
                color: $omd-dark-purple;
            }
            & > svg,
            & > img {
                width: 84px;
                height: 84px;
                margin-bottom: map-get($spacers, 1);
                @include breakpoint("medium-small") {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 0;
                    margin-right: 9px;
                }
            }
            & > p {
                @include breakpoint("medium-small") {
                    width: 100%;
                    margin-left: map-get($spacers, 1)*3;
                    margin-bottom: 0;
                    overflow: visible;
                    -webkit-line-clamp: initial;
                }
            }
        }
    }
}