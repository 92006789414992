/* This may be reusable, break it out */
.deal-sponsor {
	@include textMix('Roboto', 1.1rem, 300);
	border-bottom: 1px solid $color-x11-gray;
	margin-bottom: 3rem;
	padding-bottom: 1.25rem;

	img {
		display: inline-block;
		padding-bottom: 16px;
		margin-left: 0.5rem;
		vertical-align: middle;
		width: auto;
	}
}
