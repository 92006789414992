// buttons used in search facet panel
.facets__button {

    @extend .button--plain; // unstyles buttons
    cursor: pointer;

    &:focus {
        outline: 0;
    }

    // by default, mobile button displays instead of facets at/below med
    &--mobile {
        display: none;

        @include breakpoint('medium') {
          display: block;
          width: 100%;
          margin-bottom: 30px;
          border: 0;
          background-color: $color-yellow-orange;
          text-align: center;

          h6 {
            padding: 10px;
          }
        }
    }

    // in facets__header, clears all selected facets & fields
    &--clear {
      float: right;
      line-height: 29px;
      cursor: pointer;
      color: $color-cardinal-red;
    }

    &--clear-company {
      padding: 0;
      margin-right: 11px;

      svg {
        @include svg-fill($color-cardinal-red);
        margin: 0;
        width: 15px;
        height: 15px;
      }
    }

    &--toggle-section {
        @extend .main-menu__arrow-icon;
        @include svg-fill($color-old-silver-gray);
        top: 0;
        right: 0;

        &.collapsed {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
        }
    }

    &--toggle-subcat {
      float: right;

      svg {
        @include svg-fill($color-light-gray);
        width: 18px;
      }
    }

    &--more,
    &--less,
    &--add {
        margin: 0 auto 0.5rem;
        width: 100%;
        color: $color-cardinal-red;

        svg {
            @include svg-fill($color-cardinal-red);
            width: 10px;
            vertical-align: middle;
            margin-left: 3px;
        }
    }

    &--add svg {
        vertical-align: text-top;
    }
}


.facet-second-level-button {
    font-size: 13px;
}