.omd-banner{
  justify-content: center;
  max-width: $banner-width-770; //770px
  .omd-label {
    & + .toolbar {
      @include spacer(margin-top, map-get($spacers, 2));
    }
  }
  .toolbar {
    & > ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .action-flag {
      &--social {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        .action-flag__label {
          overflow: hidden;
          margin: 0;
          font-size: 0;
          line-height: 0;
          text-indent: -999px;
        }
        a {
          display: block;
          position: relative;
          width: 18px;
          height: 16px;
          margin-right: 30px;
          color: rgba(0,0,0,0);
          svg {
            display: none;
          }
          &:last-child {
            margin-right: 0;
          }
          &::after {
            content: '';
            position: absolute;
            top: -7.5px;
            left: -6px;
            width: 30px;
            height: 30px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &.twitter::before {
            background-image: url(img/omd/twitter_white.svg);
          }
          &.linkedin::before {
            background-image: url(img/omd/linkedin_white.svg);
          }
        }
      }
    }
  }
}
.download-dropdown {
  @include spacer(margin-top, map-get($spacers, 3));
  @include spacer(margin-bottom, map-get($spacers, 3));
  position: relative;
  
  &:has(select#downloadType)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(95px, -50%);
    width: 20px;
    height: 20px;
    background-image: url(/dist/img/omd/chevron_dotted_down_black.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }

  select#downloadType {
    position: relative;
    width: 232px;
    height: 52px;
    border: 1px solid $color-white;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: 0.05rem;
    border: none;
    color: $color-black;
    border-radius: 50px;
    padding: 0 3.1rem;
    text-transform: uppercase;
    appearance: none;
    pointer-events: none;
  }

  .selectivity-input {
    min-width: 230px;
    width: auto;
    @include breakpoint('medium') {
      margin-left: 0;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .selectivity-single-select {
    position: relative;
    height: 50px;
    font-size: inherit;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $color-white;
      background: $color-white;
      border-radius: 50px;
    }

    &.open {
      .selectivity-arrow::before {
        transform: rotate(180deg);
      }
    }
  }
  .selectivity-single-select-input {
    display: none;
  }
  .selectivity-single-result-container {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 14.5px 50px 10.5px 20px;
    color: $color-black;
    z-index: 2;
    text-transform: uppercase;
    letter-spacing:1.07px;
  }
  .selectivity-arrow {
    top: calc(50% - 10px);
    right: 20px;
    width: 20px;
    height: 20px;
    z-index: 2;
    &::before {
      @include inf-icon('img/omd/chevron_dotted_down_black.svg', 100%, 100%);
    }
    .alert__icon {
      display: none;
    }
  }
  .selectivity-dropdown {
    position: absolute;
    margin-top: 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    &::before, &::after {
      content: none;
      position: absolute;
      right: 25px;
      top: -4px;
      width: 13px;
      height: 13px;
      transform: rotate(-45deg) skew(7deg, 7deg);
      z-index: 1;
    }
    &::before {
      background: $color-black;
      margin-top: -2.5px;
    }
    &::after {
      background: $color-white;
    }
  }
  .selectivity-results-container {
    overflow: hidden;
    max-height: 295px;
    font-size: 1rem;
    font-weight: 400;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }
  .selectivity-results-wrapper {
    overflow: auto;
    height: 100%;
    max-height: 295px;
  }
  .selectivity-load-more, .selectivity-result-item {
    position: relative;
    padding: 20px;
    user-select: none;
    color: $color-black;
    z-index: 2;
    letter-spacing:1.07px;
    &.highlight {
      background-color: $omd-purple;
      color: $color-white;
    }
  }  
  .selectivity-result-item:first-child {
    display: none;
  }
}
