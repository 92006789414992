.horizontal-menu {
  .header__wrapper {
    border-bottom-width: 2px;
  }
  header .header-account-access .myView-placeholder {
    display: none;
  }
  @include breakpoint('desktop') {
    .menu-toggler {
      display: none !important;
    }
  
    main {
      display: block;
      width: auto;
      margin-left: 0;
  
      .main__wrapper {
        width: 100%;
        margin-left: 0 !important;
        padding-left: 0;
      }
  
      .main-menu {
        overflow: visible;
        position: static;
        width: 100%;
        height: auto;
        margin-right: 0;
        border-right: none;
        transform: none;
        background-color: #f1f2f2;
        cursor: auto;
        user-select: none;
        &.fixed {
          position: fixed;
          height: auto;
          z-index: 9999999;
          & + * {
            padding-top: 50px;
          }
          & + .advertising--leaderboard.hidden {
            padding-top: 0;
            & + * {
              padding-top: 50px;
            }
          }
        }

        .main-menu__container {
          display: flex;
          position: relative;
          max-width: 1240px;
          margin: 0 auto;
          padding: 0 20px;
        }

        .main-menu__home-icon {
          display: block;
          width: 22px;
          height: 22px;
          transition: fill 200ms;
        }

        .main-menu__section {
          position: relative;
          padding: 0;
          border-bottom: none;
          &.main-menu__home {
            .main-menu__section-title {
              height: 50px;
              padding: 0;
              a {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 20px;
                background-color: transparent;
                transition: background-color 200ms;
                &:active, &:focus, &:hover {
                  outline: none;
                  .main-menu__home-icon {
                    fill: $color-white;
                  }
                }
              }
            }
          }
          &.main-menu__myview {
            position: absolute;
            right: 20px;
            .pop-out__trigger {
              cursor: pointer;
            }
          }
        }

        .main-menu__section-title {
          display: flex;
          align-items: center;
          padding: 0;
          font-size: 0.875em;
          font-weight: 600;
          color: #231f20;
          letter-spacing: normal;
          text-transform: none;
          background-color: transparent;
          transition: all 200ms;
          cursor: auto;
          &.is-active:before {
            display: none;
          }
          &.js-toggle-menu-section {
            height: 50px;
            padding: 0 52px 0 20px;
            text-align: center;
            a {
              width: auto;
              height: auto;
              padding: 0;
              text-align: left;
            }
            &.active {
              color: $color-white;
              .main-menu__arrow-icon {
                fill: $color-white;
              }
              & + .main-menu__section-wrapper {
                opacity: 1;
                pointer-events: all;
              }
            }
          }
          .main-menu__arrow-icon {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 6px;
            fill: #454956;
            transition: fill 200ms;
          }
          a {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 20px;
            text-align: center;
            &:active, &:focus, &:hover {
              color: $color-white;
            }
          }
        }
  
        .main-menu__hoverable {
          &:hover:before , &.activate:before {
            display: none;
          }
        }

        .main-menu__section-link {
          &:active, &:focus, &:hover {
            text-decoration: underline;
          }
        }

        .main-menu__myview {
          display: flex;
          align-items: center;
          height: 100%;
        }
        
        .titleMyView {
          padding: 0;
          cursor: auto;
          transition: background-color 200ms;
          .myviewLink {
            padding: 12px 20px;
            font-weight: 700;
            font-size: 0.75em;
            color: $color-white;
            text-align: center;
            white-space: nowrap;
            pointer-events: none;
            &[href] {
              pointer-events: all;
            }
            &:active, &:focus, &:hover {
              text-decoration: none;
            }
          }
        }
        
        .main-menu__section-wrapper {
          display: table;
          position: absolute;
          transform: scaleY(1);
          min-width: 100%;
          max-height: none;
          padding: 20px 5px;
          background-color: $color-white;
          box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.13);
          z-index: 9999998;
          opacity: 0;
          transition: opacity 200ms;
          pointer-events: none;
          & > * {
            display: table-cell;
            padding: 0 15px;
          }
          span, a {
            display: block;
            padding: 10px 0;
            font-size: 0.750em;
            color: #231f20;
            white-space: nowrap;
          }
        }

        .main-menu__second-tier-title {
          &.hidden-menu-item {
            display: none;
            & + .main-menu__second-tier-wrapper {
              padding-left: 0;
            }
          }
          span, a {
            font-weight: bold;
          }
        }

        .main-menu__second-tier-wrapper {
          padding-left: 20px;
        }
      }
    }
  }

  @include breakpoint('ipad') {
    .header__wrapper .header__logo.tabheader_logo {
      margin-bottom: -1px;
    }
    .header-search__wrapper {
      position: relative;
      max-width: calc(100% - 360px);
      margin: 0 0 -1px 0;
    }
    .header-search__field {
      position: static;
      width: 100%;
      box-sizing: border-box;
    }
    .header__wrapper .header-account-access {
      margin: 10px 0 10px 12px;
    }
    header .header-account-access .header-account-right-access {
      clear: both;
      padding-left: 0;
      border-left: 0;
    }
    .menu-toggler.is-sticky {
      & ~ .header-search__wrapper .header-search__field {
        left: 0;
      }
    }
    main {
      .main-menu {
        display: flex;
        flex-direction: column;
        user-select: none;
        .main-menu__container {
          display: flex;
          flex-direction: column;
        }
        .main-menu__section {
          order: 3;
          &.main-menu__home {
            order: 1;
            .main-menu__section-title {
              padding-top: 0;
              padding-bottom: 0;
              a {
                display: block;
                padding: 8.5px 0;
              }
              .main-menu__home-icon {
                display: block;
                width: 22px;
                height: 22px;
              }
            }
          }
          &.main-menu__myview {
            order: 2;
          }
          .main-menu__section-title {
            display: block;
            position: relative;
            text-transform: none;
            .main-menu__arrow-icon {
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .main-menu__section-wrapper {
            font-size: 0.875em;
          }
          .titleMyView {
            padding: .625rem 30px;
            text-transform: none;
            .main-menu__section-link {
              padding: 0;
              color: $color-zeus-black;
              &:active, &:focus, &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .main-menu__hoverable .main-menu__section-link {
          display: block;
          padding-left: 30px;
          padding-right: 30px;
        }
        .main-menu__second-tier-title {
          font-weight: bold;
		      color: $color-abbey-gray;
          span, a {
            display: block;
            color: inherit;
            padding: .625rem 30px;
            &:active, &:focus, &:hover {
              text-decoration: none;
            }
          }
        }
        .main-menu__second-tier-wrapper .main-menu__section-link {
          padding-left: 50px;
        }
      }
    }
    .pop-out__tab {
      opacity: 0;
    }
  }

  @include breakpoint('small') {
    .header__wrapper {
      border-bottom-width: 1px;
    }
    .header-search__wrapper {
      max-width: none;
      margin: 10px 12px 0 0;
      &.is-active {
        position: static;
      }
      .header-search__field {
        position: absolute;
        width: calc(100% - 62px);
        height: 49px;
        border-left: none;
        border-right: none;
      }
    }
  }
}