.omd-further-reading {
  width: 100%;
  h3 {
    @include spacer(padding, map-get($spacers, 1));
    font-size: $font-size-20;
    text-transform: uppercase;
    background: $omd-purple;
    color: $color-white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    letter-spacing: $letter-spacing-2;
    display: flex;
    align-items: center;
    svg {
      fill: $color-white;
      margin-right: 10px;
    }
  }
  .information__tips {
    position: relative;
    white-space: nowrap;
    @include breakpoint("lg") {
      display: none;
    }
    & > .information__tips-panel {
      display: none;
      position: absolute;
      border: 1px solid $omd-medium-grey;
      white-space: normal;
      z-index: 1000;
      right: auto;
      left: -20px;
      top: calc(100% + 15px);
      width: 450px;
      background: $omd-light-grey;
      color: $color-black;
      box-shadow: 0 2px 10px $omd-dark-grey;
      @include spacer(padding, map-get($spacers, 0));
      text-transform: initial;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        top: -3px;
        left: 25px;
        right: auto;
        transform: rotate(45deg) translateX(-50%);
        border-left: 1px solid $omd-medium-grey;
        border-top: 1px solid $omd-medium-grey;
        background: #eee;
      }
      & > p {
        line-height: normal;
        font-family: $font-family-opensans;
        font-size: $font-size-12;
        letter-spacing: .5px;
        font-weight: 600;
      }
    }
    &.open {
      position: relative;
      z-index: 2;
      & > .information__tips-panel {
        display: block;
      }
    }
  }
  .further-reading-cols {
    padding: 0px $spacer-2*1.5;
    @include breakpoint("small"){
      padding: 0px map-get($spacers, 0)*1.5;
    }
    .inf-row.owl-carousel {
      width: auto;
      margin: -10px;
      &.owl-loaded {
        margin: 0;
        .owl-stage {
          margin: -10px;
        }
        .inf-col-fluid {
          width: auto;
          height: 100%;
        }
      }
      .owl-stage {
        display: flex;
      }
      .owl-item {
        float: none;
      }
      .inf-col-fluid {
        width: auto;
        flex-grow: 0;
        flex-basis: 33.33333%;
        padding: 10px;
        @media (max-width: 1279px){
          flex-basis: 50%;
        }
        @include breakpoint("medium"){
          flex-grow: 1;
          flex-basis: auto;
        }
      }
      .featured-research-container {
        .asset_family_mod {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          height: 100%;
          & > * {
            margin-bottom: 0;
            &:empty {
              display: none;
            }
          }
          .entitlement {
            margin-top: auto;
          }
          .article_restricted_access {
            margin-top: 0;
          }
        }
      }
      .owl-dots {
        pointer-events: none;
      }
      .owl-next, .owl-prev {
        display: block;
        width: 16px;
        height: 32px;
        position: absolute;
        top: 50%;
        text-indent: -100px;
        overflow: hidden;
        background: url(/dist/img/omdia/chevron_dotted_right_black.svg) no-repeat center;
        &.disabled {
          display: none;
        }
      }
      .owl-prev {
        transform: translateY(-50%) rotate(180deg);
        left: -40px;
        @include breakpoint("small"){
          left: -30px;
        }
      }
      .owl-next {
        transform: translateY(-50%);
        right: -40px;
        @include breakpoint("small"){
          right: -30px;
        }
      }
      &.owl-responsive-1280 {
        .owl-prev {
          transform: translateY(-50%);
          left: auto;
          right: -40px;
        }
        .owl-next {
          transform: translateY(-50%) rotate(180deg);
          right: auto;
          left: -40px;
        }
      }
    }
    .featured-research-container {
      .asset_family_mod {
        cursor: auto
      }
    }
    .inf-col-fluid {
      @include colwidth(4, 'medium');
      & > .content-box {
        & > .asset_family_mod {
          @include spacer(padding, map-get($spacers, 1));
          background: $omd-light-grey;
          z-index: 1;
          & > * {
            position: relative;
          }
          &:hover a {
            color: $omd-purple;
          }
          .entitlement {
            color: $omd-purple;
            text-transform: uppercase;
            font-size: $font-size-12;
            font-weight: $font-weight-600;
            letter-spacing: $letter-spacing-1;
          }
        }
      }
    }
  }
}
.inf-bg-black{
  .further-reading-cols {
    .inf-col-fluid {
      @include breakpoint('small') {
        @include spacer(margin-bottom, map-get($spacers, 1));
        &::last-child {
          margin-bottom: 0px;
        }
      }
      p.entitlement {
        color: $omd-light-purple;
        text-transform: uppercase;
        font-size: $font-size-12;
        font-weight: $font-weight-600;
        letter-spacing: $letter-spacing-1;
      }
    }
    a{
      text-decoration: none;
      color:#FFFFFF;
    }
    p.entitlement {
      color: $omd-light-purple;
      text-transform: uppercase;
      font-size: $font-size-12;
      font-weight: $font-weight-600;
      letter-spacing: $letter-spacing-1;
    }
  }


}
