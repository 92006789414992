.pop-out.js-pop-out__ask-the-analyst {
	display: flex !important;
	color: $color-black !important;
	font-family: $font-family-opensans !important;
	font-size: 16px;
	width: 360px;
	position: fixed;
	padding: 0 25px;
	right:0;
	background: $color-white;
	z-index: 10050 !important;
	top: 0;
	left: calc(100% + 360px);
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	@include spacer(padding-bottom, map-get($spacers, 4));
	letter-spacing: 0.2px;
	transition: all 0.8s;
	transform: none !important;

	&::after {
		content: '';
		opacity: 0;
	}

	.pop-out__details {
		color:$color-black;
		font-style: normal;
		font-family: $font-family-opensans;
	}

	.alert-success p {
		font-weight: normal !important;
		font-size: 14px !important;
		margin-left: 0 !important;
		line-height: 1.8;
		letter-spacing: 1px;
	}

	.alert-success {
		background-color: $color-white !important;
		padding:0;
		color: $color-black;
	}

	.dismiss-button.js-dismiss-ask-the-analyst {
		.dismiss-button__icon {
			display: none;
		}
	}

	.pop-out__internal {
	background: none;
	border: none;
	padding: 0;
	}

	@include breakpoint('small') {
		width: auto;
	}

	a {
		color: $color-black;
		text-decoration: none;
		border-bottom: 1px solid $color-black;

		&:hover {
			color:#731aed;
			border-bottom: 1px solid #731aed;
			transition: all 0.5s;
		}
	}

	h4 , h3.pop-out__header {
		@include spacer(margin-bottom, map-get($spacers, 1));
		font-size: 32px !important;
	}

	h3.pop-out__header {
		letter-spacing: -1.5px;
	}

	.pop-out__form-actions-wrapper {
		.dismiss-button {
			display: none;
		}
		.sign-in__submit-wrapper {
			width: 100%;
		}
	}

	&.is-active {
		right:0;
		transition: right $followerMoveDuration;
		transition: all 0.8s;
		min-width: auto;
		left: calc(100% - 360px);
		transform: none !important;

		@include breakpoint('small') {
			left: calc(100% - 100vw);
		}
	}

	input, textarea, button, .selectivity-input {
		width: 100%;

		&:focus {
			outline-color:#B88CF6 !important;
		}
		&::placeholder { 
			color:#ACACB0;
			opacity: 1;
			font-style: normal; 
		  }
		  
		  :-ms-input-placeholder { 
			color: #ACACB0;
			font-style: normal;
		  }
		  
		  ::-ms-input-placeholder {
			color: #ACACB0;
			font-style: normal;
		  }
	 }

	 .selectivity-single-select-input {
		background-image: url(img/omd/chevron_down_black.svg);
		background-repeat: no-repeat;
		background-position: center right 18px;
		background-size: 13px 13px;
		line-height: 30px;
	 }

	 .selectivity-input {
		 .selectivity-single-result-container {
			 top:0.8rem;
			 padding: 0 15px;
		 }
		svg {
			display: none;
		}
	 }

	 .page-account__text-label {
		 font-size: 14px;
		 line-height: 14px;
		 letter-spacing: 1.17px;
		 @include spacer(margin-top, map-get($spacers, 2));
		 text-transform: uppercase;
	 }

	 input, textarea,.selectivity-single-select {
		 border-radius: 3px;
		 padding: 15px;
		 border: 1px solid #acacb0;
	 }

	//  .selectivity-single-select {
	// 	 border: none;
	//  }

	 .ask-an-analyst-container, .pop-out__internal {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 10000 !important;
		padding-top: 64px;

		@include breakpoint('small') {
			transition: width 2s;
		}
	 }

	 .ask-an-analyst-container.show, .pop-out__internal.show {
		width: 100%;
		opacity: 1;
		min-width: auto;

		@include breakpoint('small') {
			width: calc(100vw - 50px);
			transition: width 2s;
		}
	 }

	a.close-btn, .dismiss-button {
	width: 17px;
	height: 17px;
	display: block;
	position: absolute;
	left:calc(100% - 20px);
	border: none;
	z-index: 2000;
	top:20px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-image: url(img/omd/icon_close_black.svg);
		background-repeat: no-repeat;
		background-size: 17px auto;
		background-position: center;
		width: 17px;
		height: 17px;
		}
	}
	.selectivity-single-selected-item a {
		border-bottom: none;
	}
	.submit-btn, .sign-in__submit-wrapper {
		@include spacer(margin-bottom, map-get($spacers, 3));
		@include spacer(margin-top, map-get($spacers, 3));
	}
}



.pop-out.js-pop-out__ask-the-analyst.is-active {
	display: flex !important;

	.pop-out__internal {
		min-width: auto;
	}
}
.ask-an-analyst-button, [data-pop-out-type="ask-the-analyst"], 
.omdia-recommends-button, [data-pop-out-type="omdia-recommends"] {
	position: fixed;
	top: 50%;
	right: 0;
	left: auto;
	background-color: $omd-purple;
	color: $color-white;
	transform-origin: 100% 50%;
    transform: rotate(-90deg) translate(0, -50%);
	text-transform: uppercase;
	font-family: inherit;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.07px;
	padding: 10px 30px;
	cursor: pointer;
	z-index: 500;
	transition: all 1.8s;
	display: inline-table;
	text-align: center;
	width: 150px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;

	@include breakpoint('medium') {
		top: 48%;
	}

	.action-flag__label {
		color: $color-white;
		display: block;
		font-family: $font-family-opensans !important;
	}

	@include breakpoint('small') {
		left: 50%;
		transform: rotate(0) translateX(calc(-100% - 30px));
		right:auto;
		top:auto;
		bottom: 0;
		min-width: auto;
	}
	svg {
		display: none;
	}
 }

 .omdia-recommends-button {
	transform: rotate(-90deg) translate(200px, -50%);
	@include breakpoint('small') {
		left: 50%;
		transform: rotate(0);
		right:auto;
		top:auto;
		bottom: 0;
		min-width: auto;
	}
 }

 .analyst-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:600;
	background-color: $color-black;
	opacity: 0;
	pointer-events: none;
	transition: opacity $followerMoveDuration;

	&.active {
		opacity: 0.7;
		pointer-events: all;
		}
	}

.pop-out.js-pop-out__ask-the-analyst.is-active {
	.alert-success {
		.background-color {
			color: $color-black;
			background-color: $color-white;
			font-size: 16px;
		}
		.alert__icon {
			display: none;
		}
	}
}

.selectivity-backdrop{
	display:none;
}
.pop-out__error {
	font-family: $font-family-opensans !important;
	font-style: normal !important;
}
.pop-out__run-in {
	font-family: $font-family-opensans !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	text-transform: uppercase;
    letter-spacing: 1.17px;
}

.pop-out__details.ata-footer {
	@include spacer(padding-top, map-get($spacers, 2));
	@include spacer(padding-bottom, map-get($spacers, 2));
}

.pop-out__input.pop-out__textarea.error {
	border: 1px solid #ED0000;
}

.ask-an-analyst-error {
	display: none;
}

/*To display bookmark icon when user is logged out - updated display none to inline-block*/
.action-flag.pop-out__trigger.js-pop-out-trigger[data-pop-out-type="sign-in"] {
	display: inline-block;
}
