.page-account {
  .form-remove-saved-document {
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      @include breakpoint('small') {
          text-align: left;
      }
  }
  .page-preferences.js-preferences-user-container {
    > table.sortable-table.js-sortable-table {
      margin-bottom: 4.5rem;
    }
    .js-no-articles {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }
  .sortable-table__col {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  &.page-account-bookmark,
  &.page-account-saved-searches {
    .sortable-table__header {
      cursor: initial;
      .js-sortable-table-sorter {
          cursor: pointer;
      }
    }
    .lightbox-modal__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sortable-table__col--title a {
      &:hover {
        color: $omd-purple;
      }
    }
  }
  &.page-account-saved-searches {
    .page-account__introduction {
      margin-bottom: 1.5rem;
      strong {
        font-weight: $font-weight-bold;
      }
    }
  }
}