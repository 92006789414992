.register-banner-cta {
    @extend .inf-col;
    color: $omd-white;
    margin: map-get($spacers, 1)*5 0;
    position: relative;
    @include breakpoint("medium") {
        margin: map-get($spacers, 1)*3.5 0;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-wrapper {
        @extend .inf-col;
        max-width: 1260px;
        width: 100%;
        padding: map-get($spacers, 1)*5 map-get($spacers, 1)*1.5;
        margin: 0 auto;
        position: relative;
        gap: 25px;
        @include breakpoint("medium") {
            padding: map-get($spacers, 1)*3.5 map-get($spacers, 1)*1.25;
            width: 90%;
            margin: 0;
            gap: 44px;
        }
        & > h3 {
            @include font($font-size-48, $font-weight-700, $line-height-56, $letter-spacing-05);
            width: 80%;
            @include breakpoint("medium") {
                width: 100%;
            }
            @include breakpoint("medium-small") {
                @include font($font-size-31, $font-weight-700, $line-height-36, $letter-spacing-025);
                width: 100%;
            }
        }
        & > p {
            @include font($font-size-20, $font-weight-600, $line-height-30, $letter-spacing-02);
            width: 50%;
            margin-bottom: 0;
            @include breakpoint("medium") {
                width: 100%;
            }
            @include breakpoint("medium-small") {
                @include font($font-size-20, $font-weight-600, $line-height-30, $letter-spacing-02);
                width: 100%;
            }
        }
        & > .cta {
            @include breakpoint("medium-small") {
                margin-top: map-get($spacers, 1)/2;
            }
        }
    }
    &.small {
        & > .register-banner-cta-wrapper {
            width: 100%;
            padding: map-get($spacers, 1)*1.25;
            gap: 28px;
            & > h3 {
                @include font($font-size-25, $font-weight-700, $line-height-13, $letter-spacing-025);
                margin-bottom: map-get($spacers, 0);
                width: 100%;
            }
            & > p {
                @include font($font-size-20, $font-weight-600, $line-height-15, $letter-spacing-02);
                margin-bottom: 0;
                width: 100%;
            }
        }
    }
}