.product-headline {
  @include heading3();
  margin-bottom: 16px;
}

.product-section{
  padding-top:72px;
  padding-bottom: 72px;
  &.version-2 {
    margin-top: map-get($spacers, 1)*5;
    margin-bottom: map-get($spacers, 1)*5;
    @include breakpoint("medium") {
      margin-top: map-get($spacers, 1)*3.5;
      margin-bottom: map-get($spacers, 1)*3.5;
    }
  }
  &.no-top-margin {
    margin-top: 0;
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

.omd-rel-prod-name{
  @include spacer(margin-top, map-get($spacers, 3));
  @include spacer(margin-bottom, map-get($spacers, 0));
}

.omd-product-name{
  @include omd-body();
  color : $body-text-colour;
  text-decoration: none;;
  font-weight: 600;
  transition: all .2s;
  &:hover{
    color:$omd-purple;
  }
}

.inf-bg-black{
  .omd-product-name{
    color : #ffffff;
    &:hover{
      color:$omd-purple;
    }
  }

}

.omd-restricted-small{
  @include omd-restricted-small();
  @include spacer(margin-bottom, map-get($spacers, 0));
}

.inf-bg-black{
  .omd-restricted-small{
    color: $omd-light-purple !important;
  }
}

.omd-view-prod-cta{
  height: 20px;
  position: relative;

  .omd-right-arrow-white{
      top: 8px;
      left:0px;
      @include omd-right-arrow-white();
  }

}

.omd-prod-row{
  @include inf-row();
}

.omd-prod-col3{
  @include colwidth(3, "lg");
  max-width: 25%;

  @media (max-width: 992px){
    width: 50%;
    max-width: 50%;
  }

  @include breakpoint('small'){
    width : 100%;
    max-width: 100%;
  }

}
