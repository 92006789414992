.video-carousel {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    gap: 36px;
    @include breakpoint('medium') {
      padding: 0 map-get($spacers, 1)*0.75;
      margin: map-get($spacers, 1)*3.5 0;
      flex-direction: column;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    
    & > a {
      color: #000;
      text-decoration: underline;
      white-space: nowrap;
      &:hover {
        color: $omd-dark-purple-2;
      }
    }
  }
  &-title {
    @include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-normal);
    color: #000;
    @include breakpoint('medium') {
      max-width: 70%;
    }
    @include breakpoint('small') { 
      font-size: $font-size-25;
    }
  }

  &-box {
    padding: 0px $spacer-2*1.5;
    @include breakpoint("small"){
      padding: 0px map-get($spacers, 0)*1.5;
    }
    .owl-carousel {
      position: relative;
      padding: 0px;
      .owl-stage {
        display: flex;
        & > .owl-item {
          display: block;
          & > .item {
            height: 100%;
            display: flex;
            @include breakpoint("medium-small"){
              flex-direction: column;
            }
            gap: 20px;
            & > .video-card {              
              height: 100%;
            }
          }
        }
      }
    }      
    .owl-prev,
    .owl-next {
      display: block;
      background: none;
      width: 16px;
      height: 32px;
      position: absolute;
      top: 50%;
      text-indent: -100px;
      overflow: hidden;
      background: url(/dist/img/omdia/chevron_dotted_right_black.svg) no-repeat center;
      &:hover {
        background: url(/dist/img/omdia/chevron_dotted_right_black.svg) no-repeat center;
      }
    }
    .owl-prev {
      transform: translateY(-50%) rotate(180deg);
      left: -40px;
      @include breakpoint("small"){
        left: -30px;
      }
    }
    .owl-next {
      transform: translateY(-50%);
      right: -40px;
      @include breakpoint("small"){
        right: -30px;
      }
    }
  }  
  .video-card {
    &-top {
      cursor: pointer;
    }
  }
  
  display: flex;
  gap: 20px;
  overflow: hidden;
  &.owl-loaded {
    display: inherit;
    flex-direction: inherit;
    height: inherit;
    overflow: inherit;
  }
  & > .item {
    display: none;
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3){
      display: flex;
    }
    @include breakpoint("medium"){
      &:nth-of-type(2),
      &:nth-of-type(3){
        display: none;
      }
    }
  }
}
#custom-popup-video {
  background-color: transparent !important;
  max-width: 1200px;
  .custom-popup-content {
    padding: map-get($spacers, 0)*3.6 map-get($spacers, 0)*2.8 map-get($spacers, 0)*3.6 map-get($spacers, 0)*1.8;
    @include breakpoint("ipad"){
      padding: map-get($spacers, 0)*4.2;
    }
    @include breakpoint("medium"){
      padding: map-get($spacers, 0)*2.2;
    }
    @include breakpoint("medium-small"){
      padding: map-get($spacers, 0)*1.5;
    }
    & > iframe {
      background-color: $color-black;
      height: 560px;
      @include breakpoint("small"){
        height: 300px;
      }
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: -6px;
    z-index: 9;
    border: none;
    text-indent: -110px;
    font-size: 0px;
    width: 30px;
    height: 46px;
    overflow: hidden;
    transform: rotate(45deg);
    &::after,
    &::before {
      content: "";
      background-color: $color-white;
      position: absolute;
      width: 26px;
      height: 2px;
    }
    &::after {
      transform: rotate(90deg);
    }
    &:hover {
      background-color: transparent;
      color: $color-white;
    }
    @include breakpoint("small") {
      top: -10px;
      right: -15px;
    }
  }
}