.case-studies-carousel {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    gap: 36px;
    @include breakpoint('medium') {
      padding: 0 map-get($spacers, 1)*0.75;
      margin: map-get($spacers, 1)*3.5 0;
      flex-direction: column;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    
    & > a {
      color: #000;
      text-decoration: underline;
      white-space: nowrap;
      &:hover {
        color: $omd-dark-purple-2;
      }
    }
  }
  &-title {
    @include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-normal);
    color: #000;
    @include breakpoint('medium') {
      max-width: 70%;
    }
    @include breakpoint('small') { 
      font-size: $font-size-25;
    }
  }

  &-box {
    padding: 0px $spacer-2*1.5;
    @include breakpoint("small"){
      padding: 0px map-get($spacers, 0)*1.5;
    }
    .owl-carousel {
      position: relative;
      padding: 0px;
      .owl-stage {
        & > .owl-item {
          display: block;
          & > .item {
            display: flex;
            @include breakpoint("medium-small"){
              flex-direction: column;
              justify-content: flex-start;
              height: fit-content;
            }
            gap: 20px;
            & > .details-card {
              height: auto;
              width: calc(33.33% - 13.33px);
              @include breakpoint("medium-small"){
                width: 100%;
              }
              & > .details-card-content {
                position: relative;
                padding-top: map-get($spacers, 1)*6.75;
                @include breakpoint("medium-small"){
                  padding-top: map-get($spacers, 1)*1.25;
                }
                &::before {
                  content: "";
                  width: 84px;
                  height: 84px;
                  margin-bottom: 1.25rem;
                  position: absolute;
                  top: 25px;
                  left: 20px;
                  @include breakpoint("medium-small"){
                    width: 40px;
                    height: 40px;
                  }
                }
              }
              
              &:first-of-type {
                & > .details-card-content {
                  &::before {
                    background: url(/dist/img/omdia/brif.svg) no-repeat;
                    background-size: 100%;
                  }
                }
              }                
              &:nth-of-type(2) {
                & > .details-card-content {
                  &::before {
                    background: url(/dist/img/omdia/setting.svg) no-repeat;
                    background-size: 100%;
                  }
                }
              }
              &:last-of-type {
                & > .details-card-content {
                  &::before {
                    background: url(/dist/img/omdia/delivery.svg) no-repeat;
                    background-size: 100%;
                  }
                }
              }
              
            }
          }
        }
      }
    }      
    .owl-prev,
    .owl-next {
      display: block;
      background: none;
      width: 16px;
      height: 32px;
      position: absolute;
      top: 50%;
      text-indent: -100px;
      overflow: hidden;
      background: url(/dist/img/omdia/chevron_dotted_right_black.svg) no-repeat center;
      &:hover {
        background: url(/dist/img/omdia/chevron_dotted_right_black.svg) no-repeat center;
      }
    }
    .owl-prev {
      transform: translateY(-50%) rotate(180deg);
      left: -40px;
      @include breakpoint("small"){
        left: -30px;
      }
    }
    .owl-next {
      transform: translateY(-50%);
      right: -40px;
      @include breakpoint("small"){
        right: -30px;
      }
    }
  }
  
  display: flex;
  gap: 20px;
  overflow: hidden;
  &.owl-loaded {
    display: inherit;
    flex-direction: inherit;
    height: inherit;
    overflow: inherit;
  }
}