.article-bookmark {
    @extend .action-flag__icon;
    display: none;

    &:hover {
        @include svg-fill($color-zeus-black);
    }

    &.is-visible {
        display: inline-block;
    }
}
