.mkt-container-fluid{
  position: relative;
  overflow: hidden;
  @include spacer(padding-bottom, map-get($spacers, 5));

  .inf-col-fluid{
    position:relative;
  }

  .mkt-container{
    width : 100%;
    z-index: 1;
  }

  .mkt-title{
    @include colwidth(8,'lg');
  }

  .mkt-title-center{
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .mkt-hero-content-title{
    @include spacer(margin-bottom, map-get($spacers, 0));
  }
  .mkt-hero-content-title{
    display:none;
  }

  .omd-content-container iframe{
      max-width: 100%;
      padding-right:10px;
  }

  .omd-mkt-col-1{
    @include inf-justify-center();

    .inf-col-fluid{
      flex-grow: 0;
      @include colwidth(6,'lg');
    }

  }

  .omd-mkt-col-2{
    @include breakpoint('small'){
      flex-flow: wrap;
    }
  }

  .omd-mkt-col-3{
    .inf-col-fluid{
      flex-grow: 0;
      @include colwidth(4,'lg');

    }
    @include breakpoint('small'){
      flex-flow: wrap;
    }
  }
  .omd-content-container{
    display: flex;
    flex-direction: column;
  }

  .omd-content-vert-center{
    align-items: center;
    justify-content: center;
    display: flex;
    @include isIE {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .omd-content-align-center{
    justify-content: center;
    align-items: center;
    text-align: center;
    .mkt-hero-content-title{
      text-align: center;
    }
  }

  .omd-content-align-right{
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }

  .omd-content-align-left{
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .mkt-bg-image{
    max-width: 100%;
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0px;
    position: absolute;
  }

  .omd-mkt-emphasis{
    padding : 32px;
    background-color: $omd-light-grey;
    color: #000000;
    @include breakpoint('medium'){
      padding : 16px;
    }
  }
  .omd-mkt-col-1 .inf-col-fluid,
  .omd-mkt-col-2 .inf-col-fluid,
  .omd-mkt-col-3 .inf-col-fluid{
    @include breakpoint('small'){
      padding-right: 0px;
      margin-left: 9px;
    }
  }

  .omd-mkt-col-1 .inf-col-fluid{

    @media (max-width: 992px){
      width : 100% !important;
    }
    @include breakpoint('small'){
      width:100% !important;
    }
    .content-box{
      width :100% !important;
    }
  }

  .inf-col-fluid{
    .content-box{
      width :100% !important;
      @include breakpoint('small'){
        padding-bottom: 20px;
        margin-right: 0px;
        margin-left: 0px;
      }
      .asset_family_mod{
        @include breakpoint('small'){
          margin:0px;
        }
      }
    }
    .bdr-top::before{
      content:none;
      padding:0px;
      margin:0px;
    }
  }

  .omd-right-on-top{
    @include breakpoint('small'){
      flex-direction: column-reverse;
    }
    div.inf-col-fluid:nth-child(1) {
      @include breakpoint('small'){
        padding-bottom:0px !important;
      }
    }
    div.inf-col-fluid:nth-child(2) {
      @include breakpoint('small'){
        padding-bottom:32px;
      }
    }

  }
  .omd-mkt-col-2{
    div.inf-col-fluid:nth-child(1) {
      @include breakpoint('small'){
        padding-bottom:32px;
      }
    }
    .inf-col-fluid {
      @include colwidth(6,'lg');
      @media (max-width: 992px){
        width:50% !important;
      }
      @include breakpoint('small') {
        width:100% !important;
      }
      .content-box {
        width :100% !important;
      }
    }
  }

  .omd-mkt-col-3 {
    div.inf-col-fluid:nth-child(1),
    div.inf-col-fluid:nth-child(2) {
      @include breakpoint('small'){
        padding-bottom:32px;
      }
    }
   .inf-col-fluid{
      @include colwidth(4,'lg');
      @media (max-width: 992px){
        width:33.3333% !important;
      }
      @include breakpoint('small'){
        width:100% !important;
      }
      .content-box{
        width :100% !important;
      }
    }
  }
  .omd-mkt-img-container {
    .omd-mkt-img,
    img{
      max-width: 100%;
      height: auto;
    }
    &+.omd-content-container {
      @include spacer(margin-top, map-get($spacers, 2));
    }
  }

  .blog-details {
    & > .omd-mkt-img-container {
      & > img {
        border: 5px solid $omd-dark-purple-2;
      }
    }
    & > .omd-content-container {
      & > .omd-body {
        h2 {
          @include font($font-size-48, $font-weight-bold, $line-height-56, $letter-spacing-05);
        }
      }
    }
  }

  .omd-mkt-content-title{
    @include spacer(margin-bottom, map-get($spacers, 0));
  }

  .omd-mkt-body{
    @include omd-body();
  }

  .hero-mkt-cta{
    display:none;
  }

  .omd-mkt-col-1 .inf-col-fluid .omd-content-mod-container .omd-content-container .omd-body a,
  .omd-mkt-col-2 .inf-col-fluid .omd-content-mod-container .omd-content-container .omd-body a,
  .omd-mkt-col-3 .inf-col-fluid .omd-content-mod-container .omd-content-container .omd-body a {
    color: inherit !important;
    text-decoration: none !important;
    border-bottom: 1px solid;
  }

}


#ServiceProvidersMarkets{
  .inf-col-fluid.content-box.bdr-top.featured-research-container:first-child {
    padding-left: 20px;
  }
  .inf-col-fluid.content-box.bdr-top.featured-research-container:last-child {
    padding-left: 15px;
  }
}

.inf-bg-light-grey .omd-mkt-emphasis{
  background-color: #E9E7ED !important;
  color: #000000 !important;
}

.inf-bg-dark .omd-mkt-emphasis {
  background-color: #322F3C !important;
  color: #ffffff !important;
}

.mkt-content-title{
  @include spacer(margin-bottom, map-get($spacers, 2));
}

.omd-mkt-hero {
  .mkt-title{
    display:none;
  }
  .omd-body{
    @include omd-subtitle();
  }
  .mkt-content-title{
    display:none;
  }

  .inf-ctas{
    min-height:47px;
  }

  .mkt-cta{
    display:none;
  }
  .hero-mkt-cta{
    display:inline-flex;
  }
  .mkt-hero-content-title{
    @include spacer(margin-bottom, map-get($spacers, 2));
    display:inline;
  }

  .omd-mkt-col-1{
    .inf-col-fluid{
      flex-grow: 0;
      @include colwidth(8,'lg');
    }
  }
}

.natureOfEnquiryLabel{
  @include font(
    $font-size-14,
    $font-weight-600,
    $line-height-normal,
    $letter-spacing-117);
  text-align: left;
  margin-bottom: 10px;
}

#natureOfEnquiry .selectivity-arrow,
.form-update-account-contact .selectivity-arrow{
  background-position: right 18px center;
  background-size: 13px 13px;
  line-height: 30px;
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  width: 50px;
  height: 50px;
  background-image: url(img/omd/chevron_down_black.svg);
  background-repeat: no-repeat;
  .alert__icon{
    display:none;
  }
}

.inf-bg-black h5.article-preview__headline a {
  color: #ffffff;
}

.inf-bg-dark #natureOfEnquiry .selectivity-arrow,
.inf-bg-dark  .form-update-account-contact .selectivity-arrow,
.inf-bg-black #natureOfEnquiry .selectivity-arrow,
.inf-bg-black  .form-update-account-contact .selectivity-arrow{
  background-image: url(img/omd/chevron_left_white.svg);
  transform: rotate(-90deg);
}
#natureOfEnquiry{
  width:100% !important;
  @include breakpoint('small'){
    margin-left:-10px;
  }
  input{
    display : none;
  }

  .selectivity-single-result-container{
    text-align: left;
    padding-left: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: -9px;
    margin-left: -3px;
    .selectivity-single-select-input{
      display:none;
    }
  }
  .selectivity-results-container{
    text-align: left;
  }

  .selectivity-single-select-input{
    width:100%;
  }
}


.inf-bg-black #natureOfEnquiry .selectivity-dropdown.open,
.inf-bg-black #natureOfEnquiry .selectivity-single-select-input{
  background-color: #000000;
}

.inf-bg-black #natureOfEnquiry,
.inf-bg-dark #natureOfEnquiry {
    .selectivity-single-select-input {
      background-image: url(img/omd/chevron_down_white.svg);
      background-repeat: no-repeat;
      background-position: center right 18px;
      background-size: 13px 13px;
      line-height: 30px;
  }
}

.inf-bg-dark #natureOfEnquiry .selectivity-dropdown.open,
.inf-bg-dark #natureOfEnquiry .selectivity-single-select-input{
    background-color: #1D1A28;
}

.inf-bg-light-grey #natureOfEnquiry .selectivity-dropdown.open,
.inf-bg-light-grey #natureOfEnquiry .selectivity-single-select-input{
    background-color: $omd-light-grey;
}

.inf-bg-black #natureOfEnquiry .selectivity-dropdown.open,
.inf-bg-black #natureOfEnquiry .selectivity-single-select-input{
    background-color: #000000;
}

omd-content-container {
  position : relative;
}

#mktIframe{
  overflow: hidden;
  height: 1080px;
  width: 100%;
  padding: 0;
  border: 0px;
  @include breakpoint('small'){
    margin-left:-5px;
  }
}

.general-header .general-header__navigation a{
  color: #828282;

}

.general-header .general-header__navigation a.is-active{
  color : #000000;
}
.omd-content-container{
  position : relative;
  z-index: 0;
}
.featured-research-container {
  position : relative;
  z-index: 0;
}

.form-overlay{
  content: '';
  width: 90%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

#mktForm{
  @include spacer(margin-bottom, map-get($spacers, 4));

}
