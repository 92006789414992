.product-listing {
  &-wrapper {
    @include spacer(padding-top, map-get($spacers, 3));
    position: relative;
    width: 370px;
    margin: auto;
    @include breakpoint('small') {
      width: auto;
      margin: auto 20px;
    }
  }
  &-cta {
    display: flex;
    align-items: center;
    padding: 7px 36px 7px 32px;
    background: $color-black;
    border: 1px solid $color-white;
    color: $color-white;
    text-overflow: ellipsis;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-white;
    }
    & > .circle-plus {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  }
  &-modal {
    @include spacer(margin-top, map-get($spacers, 3));
    background-color: $color-black;
    border: 1px solid $color-white;
    border-radius: 16px;
    position: absolute;
    top: 0;
    z-index: 999;
    display: none;
    width: 100%;
    &.show {
      display: block;
      & > .modal-dialog {
        opacity: 1;
      }
    }
    &__content {
      margin-top: 0;
      color: $color-white;
      & > .modal-body {
        padding: 24px 0;
      }
      & > .close {
        color: $color-black;
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        z-index: 9;
        transform: rotate(45deg);
      }
    }
  }
}

.product-name-list {
  text-align: left;
  p {
    padding-left: 24px;
    color: $omd-light-grey;
    font-size: 14px;
    font-weight: 700;
  }
  ul {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-light-black;
      border: 5px solid $color-black;
      border-radius: 10px;
    }
    li {
      padding: 10px 0px;
      position: relative;
      &:hover {
        background: $color-white;
        a {
          color: $color-black !important;
        }
        &::before {
          color: $color-black;
          font-size: 12px;
        }
      }
      &::before {
        content: "\25CF";
        position: absolute;
        left: 24px;
        font-size: 12px;
        top: 14px;
      }
      a {
        color: $color-white;
        display: block;
        padding: 0 50px;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        &:hover {
          color: $color-black !important;
          text-decoration: underline;
        }
      }
    }
  }
}

.modal-backdrop-bg {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}