.inf-bold {
  font-weight:bold;
}
.inf-center {
  text-align:center;
}
.inf-right {
  text-align:right;
}
.inf-italic {
  font-style:italic;
}
.inf-border {
  border:0.75pt solid;
}
.inf-top {
  vertical-align:top;
}

table {
  width:100%;
  border-collapse: collapse;
}

.af-table, .af-table th, .af-table td {
  border: 1px solid black;
}

.af-table td p {
  margin-bottom: 0 !important;
}