// TODO: change to follow naming convention for search results section

.search-metadata {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  @include textMix('Roboto', 18px, 400);
  color: $color-zeus-black;
  @include search-page-structure();
  margin-bottom: 1rem;

  @include breakpoint('medium') {
    order: 4;
  }

  .search-header__results-meta {
    flex: 1 0 auto;
    padding-top: 1px;
  }

  .search-header__facet-tag-list {
    width: 100%;
    margin: 0;
  }
}
