.register{
	.register_newsletter{
		.page-registration__introduction {
			margin-bottom: 35px;
		}
		div.page-registration{
			display: block;
			position: relative;
			padding: 60px 0 5px 0;
			.page-registration__header{
				display: inline-flex;
			}
			.next_right{
				display: inline;
				float: right;
				position: relative;
				margin-right: 1rem;
				top: 15px;
				.step{
					@include textMix('Roboto', 1rem, 400);
					font-style: normal;
					margin-right: 15px;
					vertical-align: middle;
				}
				button{
				   padding: 0.7rem 3.3rem;
				   @include textMix('Roboto', 0.8125rem, 700);
				   font-style: normal;
				   color: $color-zeus-black;
				   text-transform: uppercase;
				   background:  $color-pumpkin-orange;
				   background-position: 0 -51px;
				   background-repeat: no-repeat;
				   position: relative;
				   &:after {
				   	content: '';
				   	background: url('/dist/img/sprite-image.png') no-repeat -17px -563px;
					width: 6px;
					height: 11px;
					position: absolute;
				    top: 13px;
   					right: 30px;
				   }
				}
				@include breakpoint('small'){
					display: none;
				}
				@include breakpoint('medium-small'){
					display: none;
				}
			}
		}
		.newsletter_box{
			background-color:$color-concrete-gray;
			border: 1px solid $color-light-gray;
			clear: both;
			margin-bottom: 40px;
			padding: 40px 1rem 5px;
			float:left;
			position: relative;
			width: 100%;
			p{
			    @include textMix('Roboto', 0.875rem, 400);
				font-style: normal;
				line-height: 30px;
				.latest_newsletter{
					color: $color-persian-red;
				}
			}
			.site_div{
				padding-right: 117px;
				@include breakpoint('small') {
					padding-right: 0;
				}
				h1{ display: inline-flex;}
				.newsletter_checkbox{
				    @include breakpoint('small'){
						display: none;
						input {
							width: 1px;
							height: 1px;
						}
					}
					@include breakpoint('medium-small'){
						display: none;
					}
				}
			}
				.newsletter_checkbox{
					display: inline;
					float: right;
					border: 1px solid $color-pumpkin-orange;
					background: $color-white;
					padding: 0.3125rem 0.5625rem;
					text-transform:  uppercase;
					@include textMix('Roboto', 0.75rem, 700);
				    font-style: normal;
					position: relative;
					input[type="checkbox"]{
						margin-right: 5px;
						position: relative;
						left: -99999px;
					}
					.chk_box{
						background: url('/dist/img/sprite-image.png') no-repeat -7px -512px;
						width:16px;
						height:16px;
						position: absolute;
						left:5px;
						cursor: pointer;
					}
					&.wcs-c-on{
						background-color: $color-pumpkin-orange;
						color: $color-white;
					  .chk_box{
						background-position: -7px -529px;
						background-color: $color-pumpkin-orange;
					  }
					}

				}

			.checkbox-wrapper{
				clear: both;
			    position: absolute;
			    top: 40px;
			    right: 1rem;
			    hr {
			    	display: none;
			    	@include breakpoint('small') {
			    		display: block;
			    	}
			    }
				@include breakpoint('small') {
					display: block;
					position: static;
					.newsletter_checkbox{
						float: left;
						margin-top:10px;
						margin-bottom:5px;
					}
				}
				@include breakpoint('medium-small'){
					display: block;
					.newsletter_checkbox{
						float: left;
						margin-top:10px;
						margin-bottom:5px;
					}
				}
			}


		}
		.registrationSubmit_div{
			clear: both;
			display: block;
			width: 100%;
			float: left;
			position: relative;
			.page-registration__labeled-checkbox{
				width: 50%;
				display: inline;
				float: left;
				input[type=checkbox] {
				    position: relative;
    				left: -99999999px;
				}
				.checkbox-label{
					margin-left: 1.7rem;
					@include textMix('Roboto', 0.875rem, 400);
				    font-style: normal;
					display: table;
					margin-top: -1.7rem;
					line-height:30px;
				}
				@include breakpoint('small'){
					width: 100%;
					clear: both;
					display: block;
					input {
						width: 1px;
						height: 1px;
					}
				}
				.chk_box{
					background: url('/dist/img/sprite-image.png') no-repeat -7px -512px;
					background-repeat: no-repeat;
					width:16px;
					height:16px;
					position: absolute;
					left:0;
					top:1px;
					cursor: pointer;
				}
				&.wcs-c-on{
					  .chk_box{
						background-position: -7px -529px;;
						background-color: $color-pumpkin-orange;
					  }
				}
				@include breakpoint('small'){
					width: 100%;
					clear: both;
					display: block;
				}
				 @include breakpoint('medium-small'){
					width: 100%;
					clear: both;
					display: block;
				}
			}
			.page-account__submit-wrapper{
			   float: right;
			   width: 40%;
			   position: relative;
			   margin-right: 1rem;
			   @include breakpoint('small'){
					width: 100%;
					clear: both;
					display: block;
					margin-right: 0;
				}
				 @include breakpoint('medium-small'){
					width: 100%;
					clear: both;
					display: block;
				}
				.step{
					@include textMix('Roboto', 1rem, 400);
					font-style: normal;
					margin-right: 15px;
					vertical-align: middle;
				}
				button{
				   padding: 0.7rem 3.3rem;
				   @include textMix('Roboto', 0.8125rem, 700);
				   font-style: normal;
				   color: $color-zeus-black;
				   text-transform: uppercase;
				   background:  $color-pumpkin-orange;
				   background-position: 0 -51px;
				   background-repeat: no-repeat;
				   position: relative;
				   &:after {
				   	content: '';
				   	background: url('/dist/img/sprite-image.png') no-repeat -17px -563px;
					width: 6px;
					height: 11px;
					position: absolute;
				    top: 13px;
   					right: 30px;
				   }
				}
			}
		}
	}

	.pendingformdata{
		input[value=""] {
			border: 1px solid $color-concrete-table-gray;
		}
		input {
			padding-left: 3px;
			padding-right: 3px;
			width:250px;
		}
		.register_newsletter{

			.field-group {
				display: flex;
				margin: 0 0 1rem;
				label {
				width: 110px;
				}
				@include breakpoint('small') {
					display:block;
				}

			}
		}
		.selectivity-single-select{
			width: 250px;
		}
		input.selectivity-single-select-input{
			width: auto;
			opacity: 0;
		}
	}
}
.email-verification-section {
	display: inline-block;
	width:100%;
}
.email-verification-error-message{
	display: none;
	color: red;
}

.email-verification-next-cta-error-message {
	display: none;
	color: red;
}
.email-verification-buttons{
	float:right;
	margin-bottom: 1rem;
}
#complete-email-verification-for-trial, .resend-email-verification{
	min-width: 142px;
	display: inline-block;
	text-align: center;
}
.resend-email-verification{
margin-right: 15px;
}
.email-verification-description{
	padding: 20px 0;
}
.email-verification-banner{
	text-align: center;
	background-color: $color-concrete-table-gray;
	.banner__wrapper{
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	.banner-text{
		margin: 0 18px;
		img{
			margin: 0 8px -10px;
			height: 20px;
		}

	}
	.banner-email-verify-btn{
		min-width: 142px;
	}
}
.page-account-contact_username{
	display: inline-flex;
    align-items: center;
	.page-account__text-label{
		display: flex;
		margin: 0;

	}
	.button--filled{
		margin-left: 2em;
	}

}
