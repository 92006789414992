@import "./mixins";
@import "./variables";
@import "./variables-marketing-enhancements";

.btn {
  display: inline-block;
  position: relative;
  padding: 13px 30px;
  margin: 0 auto;
  @include font(15px, $font-weight-600, 1.2, 1.07px);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 60px;
  border: solid 2px #000000;
  transition: all .2s;
  &.disabled {
    color: rgba($color: white, $alpha: 0.64) !important;
    background: #939393 !important;
    border-color: #939393 !important;
    pointer-events: none !important;
    &:hover {
      color: #939393 !important;
      background: white !important;
    }
  }
  &.tag {
    padding: 11px 19px 10px;
    @include font(14px, $font-weight-600, 0.93, 1.17px);
    color: black;
    border-width: 1px;
    border-color: rgba($color: #000000, $alpha: 0.5);
    &:hover {
      border-color: #000000;
    }
  }
  &.primary {
    color: white;
    background: black;
    &:hover {
      color: black;
      background: white;
      svg {
        fill: black;
      }
    }
    &[disabled] {
      background-color: #969696;
      border-color: #969696;
      &:hover {
        color: white;
      }
    }
    svg {
      fill: white;
    }
  }
  &.secondary {
    color: black;
    background: inherit;
    &:hover {
      color: white;
      background: black;
    }
  }
  &.btn-small {
    font-size: 12px;
    padding: .5rem 1rem;
    border-width: 1px;
  }
  svg {
    transition: all .2s;
  }
}

// legacy overrides
main .button {
  // duplicates primary cta
  &--filled:not([disabled]) {
    display: inline-block;
    position: relative;
    padding: 13px 30px;
    margin: 0 auto;
    @include font(15px, $font-weight-600, 1.2, 1.07px);
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 60px;
    border: solid 2px white;
    color: white;
    background: black;
    transition: all .2s;
    &:hover {
      color: black;
      background: white;
      border-color: black;
    }
    &.disabled {
      color: rgba($color: white, $alpha: 0.64) !important;
      background: #939393 !important;
      border-color: #939393 !important;
      &:hover {
        color: #939393 !important;
        background: white !important;
      }
    }
  }
  // duplicates secondary cta
  &--outline:not([disabled]) {
    display: inline-block;
    position: relative;
    padding: 13px 30px;
    margin: 0 auto;
    @include font(15px, $font-weight-600, 1.2, 1.07px);
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 60px;
    border: solid 2px #000000;
    color: black;
    background: inherit;
    transition: all .2s;
    &:hover {
      color: white;
    }
    &.disabled {
      color: rgba($color: white, $alpha: 0.64) !important;
      background: #939393 !important;
      border-color: #939393 !important;
      &:hover {
        color: #939393 !important;
        background: white !important;
      }
    }
  }
}

// icons
@include btn-icon('omd-icon-down-chevron', 'chevron_dotted_down', 22px, 20px);
@include btn-icon('omd-icon-arrow', 'arrow_right', 26px, 20px);

.omd-icon-down-chevron[revealer-trigger] {
  &.revealed::after {
    transform: translateY(-50%) rotate(180deg);
    margin-top: -1px;
  }
}


.download-btn{
  @include font(
    $font-size-16,
    $font-weight-600,
    $line-height-normal,
    -$letter-spacing-02
  );
  word-break: break-word;
  padding-left: 76px;
  padding-top: 22px;
  padding-bottom: 17px;
  margin-bottom: 32px;
  color: #000;
  background: #fff;
  padding-right: 60px;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
  border-radius: 32.5px;
  border: 1px solid #000;
  position: relative;
  &::after{
    content: '';
    background: url(img/omd/file-download.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 29px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
  }
  &[class*=download-doc],
  &[class*=download-docx] {
    &::after{
      content: '';
      background: url(img/omd/file-word.svg) no-repeat;
    }
  }
  &[class*=download-excel] {
    &::after{
      content: '';
      background: url(img/omd/file-excel.svg) no-repeat;
    }
  }
  &[class*=download-pdf] {
    &::after{
      content: '';
      background: url(img/omd/file-pdf.svg) no-repeat;

    }
  }
  &[class*=download-alt] {
    &::after{
      content: '';
      background: url(img/omd/file-alt.svg) no-repeat;
    }
  }
  &[class*=download-filedwld] {
    &::after{
      content: '';
      background: url(img/omd/file-download.svg) no-repeat;
    }
  }
  &[class*=download-archive] {
    &::after{
      content: '';
      background: url(img/omd/file-archive.svg) no-repeat;
    }
  }
  &[class*=download-link] {
    &::after{
      content: '';
      background: url(img/omd/link.svg) no-repeat;
    }
  }
  &[class*=download-pptx] {
    &::after{
      content: '';
      background: url(img/omd/file-powerpoint.svg) no-repeat;
    }
  }
  &[class*=download-powerpoint] {
    &::after{
      content: '';
      background: url(img/omd/file-powerpoint.svg) no-repeat;
    }
  }
  &[class*=download-video] {
    &::after{
      content: '';
      background: url(img/omd/file-video.svg) no-repeat;
    }
  }
  &[class*=download-dwld] {
    &::after{
      content: '';
      background: url(img/omd/download.svg) no-repeat;
    }
  }
  &[class*=download-youtube] {
    &::after{
      content: '';
      background: url(img/omd/youtube.svg) no-repeat;
    }
  }
  &[class*=download-soundcloud] {
    &::after{
      content: '';
      background: url(img/omd/soundcloud.svg) no-repeat;
    }
  }
  &[class*=download-calendar] {
    &::after{
      content: '';
      background: url(img/omd/calendar.svg) no-repeat;
    }
  }
  &:hover {
    text-decoration: none;
    color: $omd-purple !important;
    border-color: $omd-purple;
    -o-transition:all .2s ease-out, all .3s ease-in;
    -ms-transition:all .2s ease-out, all .3s ease-in;
    -moz-transition:all .2s ease-out, all .3s ease-in;
    -webkit-transition:all .2s ease-out, all .3s ease-in;
    transition:all .2s ease-out, all .5s ease-in;
  }
}

.omd-inline-anchor {
  @include font( $font-size-16, $font-weight-600, inherit, -0.2px);
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid black;
  padding-bottom: 3px;
  transition: all .2s;
  &:hover {
    color: inherit;
    border-bottom-color: $omd-purple;
  }
}

.banner-arrow-btn{
  @include font(
    $font-size-15,
    $font-weight-600,
    0.93,1.07
  );
  padding-left: 30px;
  padding-top: 18px;
  padding-bottom: 18px;
  color: #fff;
  background: #000;
  width: 326px;
  display: inline-block;
  margin: 0;
  border-radius: 32.5px;
  border: 1px solid #000;
  position: relative;
  &::after{
    content: '';
    background: url(img/omd/arrow_right_white.svg) no-repeat;
    position: absolute;
    right: 54px;
    top: 15px;
    width: 32px;
    height: 32px;
  }
  &:hover {
    text-decoration: none;
    color: black !important;
    border-color: black;
    background-color: white;
    -o-transition:color .2s ease-out, color 2s ease-in;
    -ms-transition:color .2s ease-out, color 2s ease-in;
    -moz-transition:color .2s ease-out, color 2s ease-in;
    -webkit-transition:color .2s ease-out, color 2s ease-in;
    transition:all .2s;
  }
}

.methodology {
  @extend .omd-inline-anchor;
  position: relative;
  display: inline-block;
  padding-left: 40px;
  border: none;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 24px;
    height: 32px;
    background-image: url(img/omd/file-pdf.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &::after{
    content: '';
    position: absolute;
    right: 0;
    bottom: -2px;
    width: calc(100% - 40px);
    height: 2px;
    background-color: black;
    transition: background-color .2s;
  }
  &:hover::after {
    background-color: $omd-purple;
  }
}

.inf-sr-asset {
  @extend .methodology;
  position: relative;
  z-index: 2;
  padding-top: 4px;
  padding-bottom: 4px;
  word-break:  break-word;
  &::before {
    top: 0;
    width: 32px;
    height: 32px;
    background-image: url(img/omd/file-download.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  &::after {
    display: none;
  }
  &:hover {
    color: $omd-purple;
  }
  &[class*=download] {
    &::before, &::after, * {
      pointer-events: none;
    }
  }
  &[class*=download-doc] {
    &::before{
      background-image: url(img/omd/file-word.svg);
    }
  }
  &[class*=download-xls] {
    &::before{
      background-image: url(img/omd/file-excel.svg);
    }
  }
  &[class*=download-ppt] {
    &::before{
      background-image: url(img/omd/file-powerpoint.svg);
    }
  }
  &.download-pdf {
    &::before{
      background-image: url(img/omd/file-pdf.svg);
    }
  }
  &.download-mp4 {
    &::before{
      background-image: url(img/omd/file-video.svg);
    }
  }
  &.download-zip {
    &::before{
      background-image: url(img/omd/file-archive.svg);
    }
  }
  &.download-subsys {
    &::before{
      background-image: url(img/omd/link.svg);
      background-size: 25px auto;
    }
  }
  .file-details {
    font-size: 0.75em;
    letter-spacing: -0.15px;
  }
}

// overides for dark theme
.inf-bg-black,
.inf-bg-dark {
  a.primary{
    color : #000000 !important;
    &:hover{
      color : #fff !important;
    }
  }
  a.secondary{
    color : #fff !important;
    &:hover{
      color : #000000 !important;
    }
  }
  .btn {
    &.primary {
      color: black;
      background: white;
      border-color: #FFFFFF;
      &:hover {
        color: white;
        background: black;
      }
    }
    &.secondary {
      color: white;
      background: inherit;
      border-color: white;
      &:hover {
        color: black;
        background: white;
      }
    }
    &.disabled:hover {
      background: black !important;
    }
  }

  .omd-inline-anchor {
    border-bottom-color: white;
    &:hover {
      border-bottom-color: $omd-purple;
    }
  }

  .methodology {
    &::before {
      background-image: url(img/omd/pdf_white.svg);
    }
    &::after {
      background-color: white;
    }
    &:hover::after {
      background-color: $omd-purple;
    }
  }
}


