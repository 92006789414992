// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $color-cardinal-red;
  text-decoration: none;

  @include on-event {
    color: $color-cardinal-red;
    text-decoration: underline;
  }

  &.link__no-hover {
      @include on-event {
          color: inherit;
          text-decoration: none;
      }
  }
}
.link-hint-arrow {
  @include svg-fill($color-cardinal-red);
  height: 9px;
  width: 10px;
}

// List Resets
ul, li, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

// Override list resets for lists in a RTE component
.GeneralRTEComponent {
  ul > li {
    margin-left: 20px;
    list-style: disc;
  }
  ol > li {
    margin-left: 20px;
    list-style: decimal;
  }
}

// Normalize SVG icons
svg {
  @include svg-fill(black);
  height: 16px;
  width: 16px;
}

.icon-external-link {
    @include svg-fill($color-tree-poppy-orange);
    vertical-align: text-top;
    display: inline-block;
    margin-left: 0.5rem;
}

// Freeze the page/body to prevent scrolling when menu is open on tablet/phone
body.is-frozen {
    @include breakpoint('medium') {
        overflow-y: hidden;
    }
}

hr {
    background-color: $color-light-gray;
	border: 0;
	clear: both;
    height: 1px;
}

.bottomSpace{
	display:none;
}

@media (max-width: 480px){
	
	.bottomSpace{
		display:block;
		height: 20px;
	}
}
