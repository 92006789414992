.pop-out.js-pop-out__omdia-recommends {
	display: flex;
	color: $color-black;
	font-family: $font-family-opensans;
	font-size: 16px;
	width: 360px;
	position: fixed;
	right:0;
	background: $color-white;
	top: 50%;
	left: 100%;
	max-height: 90vh;
	overflow-y: auto;
	overflow-x: hidden;
	letter-spacing: 0.2px;
	transition: all 0.8s;
	transform: translate3d(0, -50%, 0px) !important;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    z-index: 999992;
	&::after {
		content: '';
		opacity: 0;
	}
	.pop-out__internal {
        background: none;
        border: none;
        padding: 0;
        min-width: auto;
	}
	@include breakpoint('small') {
		width: auto;
	}
	&.is-active {
		right:0;
		transition: right $followerMoveDuration;
		transition: all 0.8s;
		min-width: auto;
		left: calc(100% - 360px);
		display: flex;
		@include breakpoint('small') {
			left: calc(100% - 100vw);
		}
		.pop-out__internal {
			min-width: auto;
		}
	}
    .pop-out__internal {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10000 !important;
        @include breakpoint('small') {
            transition: width 2s;
        }
    }
	.pop-out__internal.show {
		width: 100%;
		opacity: 1;
		min-width: auto;
		@include breakpoint('small') {
			width: calc(100vw - 50px);
			transition: width 2s;
		}
	}
	a.close-btn, .dismiss-button {
        display: block;
        position: absolute;
        left: calc(100% - 30px);
        border: none;
        z-index: 9;
        top: 25px;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 8px;
            width: 2px;
            height: 15px;
            background: $color-white;
        }
        &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 1px;
            width: 15px;
            height: 2px;
            background: $color-white;
		}
        &:hover {
            &::after,
            &::before {
                background: $color-black;
            }    
        }
        &__icon {
            display: none;
        }
	}
    .omd-further-reading {
        padding: 0;
        margin: 0;
        h3 {
            font-size: $font-size-16;
            margin: 0;
            border-top-right-radius: 0;
        }
        h5 {
            font-size: $font-size-16;
            margin-bottom: 10px;
        }
        .omd-label {
            font-size: $font-size-12;
            line-height: normal;
        }
        & > .further-reading-cols {
            flex-direction: column;
            row-gap: 1rem;
            padding-block: 1rem;
            & > .inf-col-fluid {
                width: 100%;
                & > .content-box {
                    & > .asset_family_mod {
                        &:hover {
                            &::before {
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
	.search-bar__tips-panel {
		left: -10px;
		max-width: 330px;
		top: 30px;
		&::before {
			top: -20px;
			left: 10px;
			border-right-color: transparent;
			border-bottom: 10px solid #f4f3f6;
		}
	}
}
.omdia-recommends-box-popout-button {
    display: none;
    padding: 10px 15px;
	& > span{
		margin: 0;
	}
}