.action-flag {
	&__icon {
		&.article-bookmark {
			-webkit-transition: all .2s;
  			transition: all .2s;
			@include svg-fill($color-black);
			&:hover {
				@include svg-fill($color-black);
			}
			&.article-bookmark__bookmarked {
				@include svg-fill($color-light-black);
				&:hover {
					@include svg-fill($color-light-black);
				}
			}
		}
	}
}

.omd-banner {
	.toolbar {
		.action-flag {
			&__icon {
				&.article-bookmark {
					@include svg-fill($color-white);
					&:hover {
						@include svg-fill($color-white);
					}
					&.article-bookmark__bookmarked {
						@include svg-fill($color-white);
						&:hover {
							@include svg-fill($color-white);
						}
					}
				}
			}
		}
	}
}
