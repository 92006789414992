@import "./variables";
@import "./variables-marketing-enhancements";
@import "./mixins";

@each $number, $value in $spacers {
  .omd-vspacer-#{$number} {
    @include spacer(height, $value);
  }
  .omd-marg-t-#{$number} {
    @include spacer(margin-top, $value);
  }
  .omd-marg-b-#{$number} {
    @include spacer(margin-bottom, $value);
  }
  .omd-pad-t-#{$number} {
    @include spacer(padding-top, $value);
  }
  .omd-pad-b-#{$number} {
    @include spacer(padding-bottom, $value);
  }
}

.hide {
  display: none;
}

.omd-hide {
  display: none !important;
}

.omd-show {
  display: block !important;
}

.omd-bg-purple {
  background-color: $omd-purple;
  color: #ffffff !important;
}

.omd-seperator {
  border-bottom: solid 1px rgba(255, 255, 255, 0.4);
}

.inf-backslash::after {
  content: "|";
  padding: 0 10px;
}

.inf-comma::after {
  content: ",";
}

/* Main column */
//To DO : Change to 9grids
.omd-col-main {
  width: 51.67%;
  color: inherit;
  @include isIE {
    display: inline-block;
    vertical-align: top;
    font-size: 1rem;
  }

  @include breakpoint("medium") {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Sidebar/left column - TO DO : change to 3 grids*/
.omd-col-content-side {
  width: 33.33%;
  padding: 0 30px;
  @include isIE {
    display: inline-block;
    vertical-align: top;
    font-size: 1rem;
  }

  @include breakpoint("lg") {
    padding: 0 15px 28px 15px;
  }

  @include breakpoint("medium") {
    width: 100%;
    padding: 0 15px 28px 15px;
  }

  &:not(.is-active) {
    display: none;
    & + .omd-col-main {
      margin-inline: auto;
    }
  }
}
