/*---
title: Success Alert
section: Alerts
---

```example:html
<div class="alert-success" style="display: block !important;">
    <svg class="alert__icon">
        <use xlink:href="svg-sprite.svg#check"></use>
    </svg>
    <p>Good work! The thing you did was a success.</p>
</div>
```
*/

.alert-success {
    background-color: $color-iceberg-green;
    color: $color-lime-green;
    display: none;
    padding: 1.25rem;

    p {
        @extend h5;
        font-weight: 400;
        line-height: 1.75rem;
        margin-left: calc(24px + 0.75rem);
    }
    .alert__icon {
        height: 24px;
        width: 24px;
        float: left;
        @include svg-fill($color-lime-green);
    }
}

/*---
title: Error Alert
section: Alerts
---

```example:html
<div class="alert-error" style="display: block !important;">
    <svg class="alert__icon">
        <use xlink:href="svg-sprite.svg#alert"></use>
    </svg>
    <p>Uh oh, something didn't work. Please try again.</p>
</div>
```
*/

.alert-error {
    background-color: $color-misty-pink;
    color: $color-cardinal-red;
    display: none;
    padding: 1.25rem;

    p {
        @extend h5;
        font-weight: 400;
        line-height: 1.75rem;
        margin-left: calc(24px + 0.75rem);
    }
    .alert__icon {
        height: 24px;
        width: 24px;
        float: left;
        @include svg-fill($color-cardinal-red);
    }
}

.a-fade-alert {
    animation-duration: 4s;
    animation-name: fade-alert;
}

@keyframes fade-alert {
  0% {
    opacity: 1;
  }

  50% {
      opacity: 1;
  }

  100% {
    opacity: 0;
  }
}