@media print {
    // Resets and normalization for printing
    * {
        color: black !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
        -webkit-transition: none !important;
        transition: none !important;
        font-size: 90%;
    }

    body {
        width: 100%;
        max-width: 100%;
    }
    html,body{
        height: auto !important;
        page-break-after: avoid !important;
        page-break-before: avoid !important;
    }
    @page {
        margin: 0.5in;
    }

    // Hide this stuff.
    .main-menu,
    .menu-toggler,
    .header-account-access,
    .header-search__wrapper,
    .banner,
    .article-prologue__share-bar,
    .advertising--leaderboard,
    .header__informa-permabanner,
    .informa-ribbon,
    .article-preview__ad,
    .header__logo,
    .pop-out__trigger,
    .js-pop-out-trigger,
    .debugToolbar,
    footer,
    .action-flags-bar,
    .pop-out.is-active,
    .ContentRecomm-ReadNext,
    .suggested-article,
    /*.article-preview,*/
    .article-right-rail,
    .article-related-content__img,
    .article-preview__image,
    .topic-featured-article__image,
    .manually-curated-content__right-rail,
    .search-bar,
    .facets__mobile-button,
    .l-right-rail,
    .search__left-sidebar,
    .tooltip,
    .package-img-wrapper,
    .hideRow,
    .article-preview.latest-news-flexi,
    .article-author__photo{
        display: none !important;
    }

    a, a:visited {
        text-decoration: none;
    }

    header,
    main {
        margin-left: 0;
        width: 100%;
        max-width: 100%;
    }

    header {
        max-height: 400px;
        height: auto;
    }
    main {
        // page-break-before: always;
         display: inline;
        //overflow: auto;
    }

    .main__content {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        position: relative;
        top: 0px;
        max-width: 100%;
    }
    .main__wrapper {
        margin-left: 0 !important;
        padding-left: 0;
        width: 100%;
        float: none;
    }
    .shift-content{
        margin-left: 0;
    }
    .search-results{
        margin-left: 0;
    }
    .header__wrapper {
        border: none;
        height: auto;
        max-height: 400px;
        padding: 1.5rem;
    }

    .header-logo-print {
        display: block;
        margin-left: 0;
        position: relative;
        left: 0px;
        flex: 0 0 auto;
        align-self: center;
    }

    .header-logo-print img {
        margin-left: 0px;
        max-width: 200px;
        max-height: 120px;
    }

    .boxout-print-info {
        @include textMix('Roboto', 0.875rem, 400);
        display: block;
        height: auto;
        flex: 0 0 auto;
        font-size: 0.75rem;
        width: 60%;
        margin-right: 0;
    }

    .boxout-print-info.footer {
       /* margin: 0px auto 0.5rem;
        margin-right: auto;
        float: none;*/
        display: none;
    }

    .article-prologue {
        margin-bottom: 1.5rem;
    }
    .article-tags-bar a,
    .article-tags-bar a:nth-last-child(3):first-child {
        width: unset;
        color: purple;
    }

    .article-tags-bar {
        width: unset;
    }

    .article-author,
    .article-sidebar {
        background: none;
        border: 1px solid $color-light-gray;
        border-top-width: 2px;
    }
    .article-author{
        border: none;
        padding: 0;
        margin-bottom: 1rem;
        .article-author__photo{
            display: none;
        }
        .article-author__name{
            display: inline-block;
            width: auto;
            font-size: 1rem;
        }
        .article-author__name a:first-child{
            margin-right: 0.25rem;
            padding-right: 0.625rem;
            position: relative;
            &:after{
                border-right: 1px solid #d1d3d4;
                content: "";
                height: 120%;
                position: absolute;
                right: 0;
                top: 0%;
                width: 2px;
            }
        }
        .article-author__contact a:first-child:not(:only-child){
            margin-right: 0.25rem;
            padding-right: 0.625rem;
            &:after{
                height:100%;
                top:0%;
            }    
        }
    }
    .article-sidebar {
        border-top-width: 4px;
    }

    .article-tags-bar {
        background: none;
        padding-left: 0;
    }

    .bar-separated-link-list {
        padding-left:10px;
    }
    
    .article-left-rail {
        width: 100%;
        max-width: 100%;
    }

    .article-right-rail,.manually-curated-content__main-well {
        width: 100%;
        max-width: 100%;
    }

    .article-executive-summary p,.article-paragraph,.article-sidebar p,.quick-facts p,.article-body-content p{
        line-height: 1.5rem !important;
        font-size: 1rem !important;
        margin-bottom: 0.5rem;
    }
    .article-body-content table:not(.article-table--mobile-link) {
        display: block;
        max-width: 100% !important;
    }

    .article-body-content table.article-table--mobile-link {
        display: none;
    }

    .article-inline-image img{
        width: 50%;
        height: 50%;
    }

    .article-exhibit--pull-right{
        width: 25%;
    }

    .subscriptionPan {
        .title,.pubSubscribe,.pubName,.sortable-table__col{
            font-size: 1rem;
        }    
    }
    
    .myview-settings{
        .table-responsive{
            table{
                span {
                    font-size: 0.75rem !important;  
                }
                .wd-55,.saveoption .saveview{
                    font-size: 1rem !important;   
                }
                thead{
                    tr td, th p{
                         font-size: 1rem !important;
                    }
                }
            }
        }
    }

    .topic-featured-article,.article-preview--small{
        min-height: auto;
        min-height: initial;
        height: auto!important;
        .topic-featured-article__inner-wrapper,.article-preview__inner-wrapper{
            height: 200px;
            .article-summary{
                font-size: 0.75rem;
            }
        }
    }
    .longread-article {
        .lra-body {
            p {
                line-height: 2em;
            }
            .meta {
                float: left;
                width: 350px;
                margin: 0 2rem 2rem 0;
                .nav {
                    display: none !important;
                }
            }
            .content {
                .inline-related-content {
                    display: none;
                }
            }
        }
    }
    .article-exhibit,
    .article-exhibit--pull-left,
    .article-exhibit--pull-right,
    .article-inline-image,
    .article-inline-image--pull-left,
    .article-inline-image--pull-right {
        .enlarge::after {
            display: none;
        }
    }
    /*

    //.header__logo {
      //  margin-left: 0;
//        height: 0.55in;
        //flex: 0 0 auto;
        //align-self: center;
        //background-color: blue;
//
        //img {
            //height: 100%;
            //margin-right: 0;
        //}
    //}



  */
}
