.blogs {
	@extend .inf-col;
	& > .tile-list-banner-header {
		@include breakpoint('medium-small') {
			flex-direction: column;
    		gap: 15px;
		}
		& > .custom-select {
			width: auto;
			@include breakpoint('medium-small') {
				width: 100%;
			}
		}
	}
	& > .pagination-list {
		margin-left: auto;
		margin-top: map-get($spacers, 1)*2;
		@include breakpoint('medium') {
			margin-top: map-get($spacers, 1)*1.75;
		}
	}
}