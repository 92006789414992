@mixin font($font-size, $font-weight,
  $line-height, $letter-spacing) {
  font-size: $font-size;
  font-family: $font-family-opensans;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin heading2(){
  @include header-style($font-size-40,$font-weight-600, $line-height-125, - $letter-spacing-2);
  color: inherit;
}

@mixin heading3(){
  @include header-style($h3-font-size, $font-weight-600, $line-height-125, - $letter-spacing-2);
  color: inherit;
}

@mixin heading5(){
    @include header-style($h5-font-size, $font-weight-600, $line-height-125, -$letter-spacing-125);
    color: inherit;
}

@mixin heading6(){
  @include header-style($h6-font-size, $font-weight-600, $line-height-15, - $letter-spacing-05);
  color: inherit;
  text-transform: none;
}


@mixin header-style($font-size, $font-weight,
                    $line-height, $letter-spacing){

  @include font($font-size, $font-weight,
                $line-height, $letter-spacing);
  font-stretch: $font-stretch-normal;
  font-style: $font-stretch-normal;

  @include breakpoint('medium'){
    font-size: $font-size/1.2;
  }

  @include breakpoint('medium-small') {
    font-size: $font-size/1.5;
  }

  @include breakpoint('small') {
    font-size: $font-size/1.5;
  }
}


@mixin spacer($spacer-type, $spacer-value){

  #{$spacer-type}: $spacer-value;

  //If spacer large 6, 5, 4 then reduce by below times at breakpoints
  @if ($spacer-value == $spacer-5) or
      ($spacer-value == $spacer-4) {
        @include breakpoint('medium'){
          #{$spacer-type} : $spacer-value/1.5;
        }

        @include breakpoint('medium-small') {
          #{$spacer-type} : $spacer-value/1.5;
        }

        @include breakpoint('small') {
          #{$spacer-type} : $spacer-value/2.25;
        }

  } @else if ($spacer-value == $spacer-3) {
    //All 3 breakpoints its 24px
    @include breakpoint('medium'){
      #{$spacer-type} : $spacer-2;
    }

    @include breakpoint('medium-small') {
      #{$spacer-type} : $spacer-2; //24px
    }

    @include breakpoint('small') {
      #{$spacer-type} : $spacer-2; //24px
    }
  }
}

// mixin for omdia dotted icons on ctas
// @param $selector: The unique class defined in the html
// @param $icon-name: The name of the asset, assumes .svg format and suffixes _white and _black for different states
// @param $width: The width of the image asset
// @param $height: The height of the image asset
@mixin btn-icon($selector, $icon-name, $width, $height) {
  .btn.#{$selector} {
    padding-right: 65px;
    &::after {
      content: '';
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: $width;
      height: $height;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 200ms;
    }
    &.primary {
      &::after {
        background-image: url(img/omd/#{$icon-name}_white.svg);
      }
      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_black.svg);
      }
    }
    &.secondary {
      &::after {
        background-image: url(img/omd/#{$icon-name}_black.svg);
      }
      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_white.svg);
      }
    }
    &.disabled {
      &::after {
        background-image: url(img/omd/#{$icon-name}_white.svg) !important;
        opacity: 0.5;
      }
      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_black.svg) !important;
      }
    }
    &.btn-small {
      padding-right: 50px;
      &::after {
        right: 24px;
        width: $width * 0.8;
        height: $height * 0.8;
      }
    }
  }

  .inf-bg-dark .btn.#{$selector},
  .inf-bg-black .btn.#{$selector} {
    &.primary {
      &::after {
        background-image: url(img/omd/#{$icon-name}_black.svg);
      }
      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_white.svg);
      }
    }
    &.secondary {
      &::after {
        background-image: url(img/omd/#{$icon-name}_white.svg);
      }
      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_black.svg);
      }
    }
    &.disabled {
      &::after {
        background-image: url(img/omd/#{$icon-name}_white.svg) !important;
        opacity: 0.5;
      }

      &:hover::after {
        background-image: url(img/omd/#{$icon-name}_white.svg) !important;
      }
    }
  }
}

// mixin for omdia dotten icons on legacy buttons (to be applied within the selector)
// @param $btntype: string 'filled' or 'outline'
@mixin btn-legacy-icon($btntype, $icon-name, $width, $height) {
  position: relative;
  padding-right: 65px;
  &::after {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 200ms;
  }
  @if $btntype == 'filled' {
    &::after {
      background-image: url(img/omd/#{$icon-name}_white.svg);
    }
    &:hover::after {
      background-image: url(img/omd/#{$icon-name}_black.svg);
    }
  }
  @else if $btntype == 'outline' {
    &::after {
      background-image: url(img/omd/#{$icon-name}_black.svg);
    }
    &:hover::after {
      background-image: url(img/omd/#{$icon-name}_white.svg);
    }
  }
  &.disabled {
    &::after {
      background-image: url(img/omd/#{$icon-name}_white.svg) !important;
      opacity: 0.5;
    }
    &:hover::after {
      background-image: url(img/omd/#{$icon-name}_black.svg) !important;
    }
  }
}

@mixin link-hover-transform($color){
  color: $color;
  border-color: $color;
  -o-transition:color .2s ease-out, color 2s ease-in;
  -ms-transition:color .2s ease-out, color 2s ease-in;
  -moz-transition:color .2s ease-out, color 2s ease-in;
  -webkit-transition:color .2s ease-out, color 2s ease-in;
  transition:color .2s ease-out, color 2s ease-in;
}

@mixin omd-body(){
  @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-117);
  @include breakpoint("medium-small") {
      @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-117);
  }
}


@mixin omd-label{
  @include font(
    $font-size-14,
    $font-weight-600,
    $line-height-15,
    $letter-spacing-125
  );
}

@mixin omd-label-small{
  @include font(
    $font-size-12,
    $font-weight-600,
    $line-height-1,
    $letter-spacing-1
  );
  text-transform: uppercase;
}


@mixin omd-view-more-cta {
  @include font(
    $font-size-16,
    $font-weight-600,
    $line-height-088,
    $letter-spacing-2
  );

  text-transform: uppercase;
  &::after{
    right: 30px;
    top: 50%;
    @include right-arrow-black();
  }
}

@mixin omd-main-menu-links {
  @include font(
    $font-size-18,
    $font-weight-600,
    $line-height-125,
    -$letter-spacing-05
  );
}

// TODO: potentially make global
@mixin inf-icon($asset_url, $width, $height) {
  content: '';
  position: absolute;
  left: calc(50% - #{$width / 2});
  top: calc(50% - #{$height / 2});
  width: $width;
  height: $height;
  background-image: url($asset_url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-transition: all .2s;
  transition: all .2s;
}

@mixin right-arrow-black{
  content: '';
  position: absolute;
  transform: translateY(-50%);
  width: 26px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: all .2s;
  transition: all .2s;
  background-image: url(img/omd/arrow_right_black.svg);
}

@mixin omd-right-arrow-white{
  @include omd-right-arrow();
  background-image: url(img/omd/arrow_right_white.svg);
  &:hover{
    background-image: url(img/omd/arrow_right_purple.svg);
  }
}

@mixin omd-right-arrow-black{
  @include omd-right-arrow();
  background-image: url(img/omd/arrow_right_black.svg);
  &:hover{
    background-image: url(img/omd/arrow_right_purple.svg);
  }
}

@mixin omd-right-arrow{
  content: '';
  position: absolute;
  transform: translateY(-50%);
  width: 26px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: all .2s;
  transition: all .2s;
}

@mixin omd-a{
  border-bottom: 2px solid black;
    color: $color-black !important;
    text-decoration: none;
    &:hover{
      @include link-hover-transform($omd-purple);
    }
}

//Mixin for all restricted access text
@mixin omd-restricted-small {
  @include omd-label-small();
  color: $omd-purple;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin inf-bg-black {
  background-color: #000000;
  color: #ffffff !important;
}

@mixin omd-account-btn {
  display: block;
  padding: 7px 16px;
  @include font(
    $font-size-12,
    $font-weight-600,
    $line-height-1,
    $letter-spacing-1
  );
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 28px;
  transition: all 0.2s;
  &:hover, &.hover {
    color: $omd-light-purple;
  }
  &.primary {
    color: $color-black;
    background-color: $color-white;
    &:hover, &.hover {
      color: $omd-light-purple;
    }
  }
}

@mixin omd-af-hover($headline, $headline-color) {
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
    z-index: -1;
  }
  #{$headline} {
    transition: color 0.3s;
    a, .a {
      color: inherit;
      text-decoration: none;
    }
  }
  &:hover {
    &::before {
      top: -15px;
      bottom: -15px;
      left: -15px;
      right: -15px;
      opacity: 1;
    }
    #{$headline} {
      color: $headline-color;
    }
  }
}

@mixin omd-light-af-hover($headline) {
  @include omd-af-hover($headline, $omd-purple);
  &::before {
    background-color: $omd-box-hover-purple;
  }
}

@mixin omd-light-af-hover-on-dark($headline) {
  @include omd-af-hover($headline, $omd-purple);
  &::before {
    background-color: rgba(255,255,255,.15);
  }
}

@mixin omd-grey-af-hover($headline) {
  @include omd-af-hover($headline, $omd-purple);
  &::before {
    background-color: $omd-light-grey;
    opacity: 1;
  }
  &:hover::before {
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    background-color: $omd-box-hover-purple;
  }
}

@mixin omd-dark-af-hover($headline) {
  @include omd-af-hover($headline, $omd-light-purple);
  &::before {
    background-color: (255, 255, 255, 0.15);
  }
}

// Redundant: to be replaced with above
@mixin divHoverStyle{
  background-color: rgba(115, 26, 237, 0.05);
  transition: background-color 0.2s;
}

@mixin divDarkHoverStyle{
  background-color: rgba(255, 255, 255, 0.15) !important;
  transition: background-color 0.2s;
}

@mixin omd-subtitle {
  @include font(
    $font-size-20,
    $font-weight-400,
    $line-height-15,
    -$letter-spacing-05
  );

  @include breakpoint("small") {
    font-size: $font-size-18;
  }
}

// cta button mixins
@mixin cta {  
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-family: $font-family-opensans;
  font-size: $font-size-15;
  font-weight: $font-weight-600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-175;
  line-height: $line-height-1;
  color: $color-black;
  border: 1px solid $color-black;
  border-radius: 60px;
  cursor: pointer;
  position: relative;
  transition: all 300ms ease;
  padding: 15px 26px;  
  &:hover, &:active, &:focus {
    color: $color-white;
    border-color: $color-black;
    background-color: $color-black;
    text-decoration: none;
  }
}
@mixin cta-arrow-right ($dark-theme: false) {
  padding-right: 56px;
  &::after {
    content: "";
    position: absolute;
    background: url(/dist/img/omd/arrow_right_black.svg) no-repeat center;
    right: 24px;
    width: 26px;
    height: 26px;
    top: 50%;
    transform: translateY(-50%);
    transition: background-image 300ms ease;
  }
  &:hover, &:focus, &:active {
    &::after {
      background: url(/dist/img/omd/arrow_right_white.svg) no-repeat center;
    }
  }
  @if $dark-theme {
    &::after {
      background: url(/dist/img/omd/arrow_right_white.svg) no-repeat center;
      transition: background-image 300ms ease;
    }
    &:hover, &:focus, &:active {
      &::after {
        background: url(/dist/img/omd/arrow_right_black.svg) no-repeat center;
      }
    }
  }
}
@mixin cta-solid ($theme-color: default) {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;
  &:hover, &:active, &:focus {
    background-color: transparent;
    border-color: $color-black;
    color: $color-black;
  }
  &.cta-arrow-right {
    @include cta-arrow-right(true);
    &:hover, &:active, &:focus {
      &::after {
        background-image: url(/dist/img/omd/arrow_right_white.svg);
      }
    }
  }

  
  @if $theme-color == 'primary' {
    border-color: $color-white;
    color: $color-black;
    background-color: $color-white;
    &:hover, &:active, &:focus {
      background-color: transparent;
      border-color: $color-white;
      color: $color-white;
    }
    &.cta-arrow-right {
      @include cta-arrow-right(false);
    }    
  }
  @else if $theme-color == 'secondary'{
    background-color: $omd-dark-purple-2;
    border-color: $omd-dark-purple-2;
    color: $color-white;
    &:hover, &:active, &:focus {
      background-color: $color-white;
      color: $color-black;
    }
    &.cta-arrow-right {
      @include cta-arrow-right(true);
    }
  }
}
@mixin cta-large {
  font-size: $font-size-18;
  padding: 18px 26px;
}
@mixin cta-small {
  font-size: $font-size-12;
  padding: map-get($spacers, 1)/2 map-get($spacers, 1);
}
@mixin cta-primary {
  color: $color-white;
  border-color: $color-white;
  &:hover, &:active, &:focus {
    color: $color-black;
    border-color: $color-white;
    background-color: $color-white;
    & > .cta-arrow-right {
      @include cta-arrow-right(false);
      &:hover, &:active, &:focus {
        &::after {
          background-image: url(/dist/img/omd/arrow_right_black.svg);
        }
      }
    }
  }
  &.cta-arrow-right,
  & > .cta-arrow-right {
    @include cta-arrow-right(true);
  }
  &.cta-solid {
    @include cta-solid('primary');
    & > .cta-arrow-right {
      @include cta-arrow-right(false);
    }
    &:hover, &:active, &:focus {
      & > .cta-arrow-right {
        @include cta-arrow-right(true);
        &:hover, &:active, &:focus {
          &::after {
            background-image: url(/dist/img/omd/arrow_right_white.svg);
          }
        }
      }
    }
  }
}
@mixin cta-secondary {
  color: $color-black;
  &:hover, &:active, &:focus {
    color: $color-white;
    border-color: $omd-dark-purple-2;
    background-color: $omd-dark-purple-2;
    & > .cta-arrow-right {
      @include cta-arrow-right(false);
      &:hover, &:active, &:focus {
        &::after {
          background-image: url(/dist/img/omd/arrow_right_black.svg);
        }
      }
    }
  }
  &.cta-arrow-right,
  & > .cta-arrow-right {
    @include cta-arrow-right(false);
  }
  &.cta-solid {
    @include cta-solid('secondary');
    & > .cta-arrow-right {
      @include cta-arrow-right(false);
    }
    &:hover, &:active, &:focus {
      & > .cta-arrow-right {
        @include cta-arrow-right(true);
        &:hover, &:active, &:focus {
          &::after {
            background-image: url(/dist/img/omd/arrow_right_white.svg);
          }
        }
      }
    }
  }
}