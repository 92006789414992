.accordion-tile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-panel {
        background: $omd-light-grey;
        transition: background 0.3s ease-in-out;
    }
    &-header {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        padding: map-get($spacers, 1);
        &-title {
            font-size: $font-size-24;
            font-weight: $font-weight-600;
            letter-spacing: $letter-spacing-02;
            padding-right: $spacer-2*1.25;
            &:before {
                content: "";
                background-image: url("/dist/img/omd/chevron_dotted_down_purple.svg");
                position: absolute;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                top: 50%;
                right: 20px;
                height: 10px;
                width: 20px;
                transform: translateY(-50%);
            }
            @include breakpoint("medium") {
                font-size: $font-size-20;
                line-height: $line-height-15;
            }
        }
    }
    &-body {
        max-height: 0px;
        overflow: hidden;
        padding: 0 map-get($spacers, 1);
        transition: max-height 0.3s ease-in-out;
    }
    &-expanded {
        background: $color-black;
        color: $color-white;
        & > .accordion-tile-body {
            max-height: 500px;
            & > .accordion-tile-body-content {
                position: relative;
                margin: map-get($spacers, 1) 0;
                &::before {
                    content: "";
                    height: 2px;
                    background: $omd-dark-grey-3;
                    width: 100%;
                    position: absolute;
                    top: -20px;
                }
            }
        }
        & > .accordion-tile-header {
            & > .accordion-tile-header-title:before {
                background-image: url("/dist/img/omd/chevron_dotted_down_purple.svg");
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}