.service-area-section {
  @include spacer(padding-top, map-get($spacers, 5));
  @include spacer(padding-bottom, map-get($spacers, 5));
  .bdr-top {
    &::before {
      content: "";
      border-bottom: 1px solid $color-black;
    }
  }

  .featured-section-title {
    @include spacer(margin-bottom, map-get($spacers, 3));
  }

  .product-headline {
    font-size: 32px;
    padding-top: 0;

    @include breakpoint("medium") {
      font-size: 26px;
    }
  }

  .featured-research-container {
    .asset_family_mod {
      @include spacer(margin-top, map-get($spacers, 3));
      @include spacer(margin-bottom, map-get($spacers, 3));
    }
  }
}

.inf-bg-white {
  .omd-view-prod-cta .omd-right-arrow-white {
    background-image: url(img/omd/arrow_right_black.svg);

    &:hover {
      background-image: url(img/omd/arrow_right_purple.svg);
    }
  }
}

.inf-bg-black {
  .omd-view-prod-cta .omd-right-arrow-white {
    background-image: url(img/omd/arrow_right_white.svg);
  }
}

.article-preview__byline {
  @include omd-label();
  color: inherit;
  a {
    text-decoration: none;
    color: $body-text-colour;
    @include omd-label();
    &:hover {
      color: $body-text-colour;
    }
    //border transition styles
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    // overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #000000;
      height: 1px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    &:hover:before {
      right: 0;
    }
  }
}
.content-sections.intro-sestion {
  .inf-col-fluid {
    width: 50%;
    @include breakpoint("small") {
      order: 2;
    }
    @include breakpoint("medium") {
      order: 2;
      width: 100%;
      padding-top: 30px;
    }

    &.img-container {
      img {
        max-width: 100%;
      }
      @include breakpoint("small") {
        order: 1;
        padding: 0px 15px !important;
      }
      @include breakpoint("medium") {
        order: 1;
        padding: 20px 0;
      }
    }
  }
}
.article_restricted_access {
  @include spacer(margin-top, map-get($spacers, 0));
  & > .lock__icon,
  & > .lock-open__icon {
    fill: $omd-purple;
    width: 10px;
    height: 10px;
    vertical-align: middle;
  }
  & > span {
    vertical-align: middle;
  }
}
.article-preview__headline {
  padding-bottom: 0px;
  margin-bottom: 0px;
  position: relative;
  a {
    @include spacer(padding-right, map-get($spacers, 3));
  }
  .action-flag {
    position: absolute;
    right: 0;
    top: 6px;
    margin: 0;
    padding: 0;
  }
}
.article-summary {
  -webkit-line-clamp: 4;
  -ms-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
}
//&.inf-bg-white {
.omd-restricted-small {
  color: $omd-purple !important;
}

.inf-bg-dark {
  .omd-restricted-small {
    color: $omd-light-purple !important;
  }
  .article-preview__byline {
    @include omd-label();
    color: #ffffff;
    a {
      text-decoration: none;
      color: #ffffff !important;
      @include omd-label();
      &:hover {
        color: #ffffff;
      }
      &:before {
        content: "";
        background: #ffffff;
      }
      &:hover:before {
        right: 0;
      }
    }
  }
}
.article-preview__byline {
  @include spacer(margin-top, map-get($spacers, 0));
  span, a {
    color: inherit;
  }

  p, div, a {
    background-color: transparent !important;
    color: $color-black !important;
  }

  .btn.secondary {
    &:hover {
      background-color: $color-black !important;
      color: $color-white !important;
    }
  }

  .omd-product-name {
    &:hover {
      text-decoration: none;
      color: $omd-purple !important;
    }
  }
}

.inf-bg-dark, .inf-bg-black {
  .asset_family_mod {
    &:hover {
      @include omd-dark-af-hover('.article-preview__headline');
      .omd-restricted-small {
        color: $omd-light-purple !important;
      }
    }
  }

  .bdr-top {
    &::before {
      content: "";
      border-bottom: 1px solid #ffffff;
    }
  }
  .omd-restricted-small > span {
    color: #b98df6 !important;
  }

  .omd-product-name {
    color: $color-white;

    &:hover {
      text-decoration: none;
      color: $omd-light-purple !important;
    }
  }
  .btn.secondary {
    color: $color-white;
    background: transparent;
    border-color: $color-white;

    &:hover {
      background-color: $color-white !important;
      color: $color-black !important;
    }
  }

  a.btn.omd-icon-arrow.secondary {
    &::after {
      background-image: url(img/omd/arrow_right_white.svg);
    }

    &:hover::after {
      background-image: url(img/omd/arrow_right_black.svg);
    }
  }

  .article-preview-byline {
    margin: 20px 0;
    span, a {
      color: $color-white;
    }
  }

  .featured-research-container h5 a {
    color: $color-white !important;
  }

  .product-section .omd-restricted-small > span {
    color: $omd-light-purple !important;
  }

  .content-box .asset_family_mod {
    @include omd-light-af-hover-on-dark('.article-preview__headline');
  }
}
.featured-research-container a {
  color: inherit;
  text-decoration: none;
}

.content-box {
  flex: 1;

  .article-preview {
    padding-bottom: 0;
  }
  .asset_family_mod {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    padding: 0;
    @include omd-light-af-hover('.article-preview__headline');
  }
}
