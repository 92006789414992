#eventTop,
#meetTheTeamTop {
  @include inf-container-fluid();
  margin-top: 28px;

  .search__body {
    margin-left: 0;
  }

  &[ng-cloak] {
    visibility: hidden;
  }

  a:active {
    color: $body-text-colour;
  }

  .search__content {
    @include inf-container();
    width: 100%;
    flex-flow: row;
    @include breakpoint("lg") {
      flex-direction: column;
      flex-flow: column;
    }

    .search__facets {
      @include inf-col-fluid();
      float: none;
      width: 100%;
      min-width: auto;
      max-width: none;
      margin-right: 0;
      @include spacer(margin-bottom, map-get($spacers, 4));
      padding-right: 0;
      padding-left: 0;
      font-family: inherit;
      border: none;
      .facets__content {
        width: 100%;
        padding: 0;
        @include spacer(margin-top, map-get($spacers, 1));
      }
      .facets__section {
        font-size: inherit;
        line-height: inherit;
      }
      .facets__fieldset {
        font-size: 0;
        margin-bottom: 0;
        & > * {
          display: inline-block;
          vertical-align: middle;
          @include font(
            $font-size-20,
            $font-weight-600,
            $line-height-15,
            -$letter-spacing-05
          );
          @include breakpoint("medium") {
            @include spacer(margin-bottom, map-get($spacers, 0));
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        select {
          width: 30%;
          margin-left: 20px;
          padding: 10.5px 50px 10.5px 20px;
          border-color: $color-black;
          border-radius: 50px;
          appearance: none;
          background-image: url(img/omd/chevron_down_black.svg);
          background-repeat: no-repeat;
          background-position: center right 20px;
          background-color: $color-white;
          background-size: 20px 20px;
          outline: none;
          text-transform: capitalize;
          @include breakpoint("medium") {
            width: 100%;
            margin-left: 0;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }

      .selectivity-result-item {
        &:first-child[data-item-id="all"] {
          text-transform: capitalize;
        }
      }
      .selectivity-input {
        min-width: 30%;
        margin-left: 20px;
        @include breakpoint("medium") {
          width: 100%;
          margin-left: 0;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      
      .selectivity-single-select {
        position: relative;
        height: 50px;
        font-size: inherit;
        border: none;
        text-transform: capitalize;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid $color-black;
          border-radius: 50px;
        }
        &.hover,
        &.open {
          &::after {
            border-width: 2px;
          }
        }
        &.open {
          .selectivity-arrow::before {
            transform: rotate(180deg);
          }
        }
      }
      .selectivity-single-select-input {
        display: none;
      }
      .selectivity-single-result-container {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 10.5px 50px 10.5px 20px;
      }
      .selectivity-arrow {
        top: calc(50% - 10px);
        right: 20px;
        width: 20px;
        height: 20px;
        &::before {
          @include inf-icon("img/omd/chevron_down_black.svg", 100%, 100%);
        }
        .alert__icon {
          display: none;
        }
      }
      .selectivity-dropdown {
        position: absolute;
        margin-top: 15px;
        padding: 0;
        background-color: transparent;
        border: none;
        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 25px;
          top: -4px;
          width: 13px;
          height: 13px;
          transform: rotate(-45deg) skew(7deg, 7deg);
          z-index: 1;
        }
        &::before {
          background: $color-black;
          margin-top: -2.5px;
        }
        &::after {
          background: $color-white;
        }
      }
      .selectivity-results-container {
        overflow: hidden;
        max-height: 295px;
        font-size: 1rem;
        font-weight: 400;
        background-color: $color-white;
        border: 2px solid $color-black;
        border-radius: 10px;
      }
      .selectivity-results-wrapper {
        overflow: auto;
        height: 100%;
        max-height: 295px;
      }
      .selectivity-load-more,
      .selectivity-result-item {
        position: relative;
        padding: 14px 20px;
        user-select: none;
        z-index: 2;
        &.highlight {
          background-color: $omd-purple;
          color: $color-white;
        }
      }
      .selectivity-caret,
      .selectivity-single-selected-item-remove {
        display: none;
      }
    }

    .search-results,
    .search-pagination__wrapper {
      margin-left: 0;
    }

    .no-result {
      @include spacer(padding-top, map-get($spacers, 2));
      @include spacer(padding-bottom, map-get($spacers, 2));
      border-top: 1px solid #8e879a;
      border-bottom: 1px solid #8e879a;
      @include breakpoint("lg") {
        order: 6;
      }
      p {
        @include spacer(margin-bottom, map-get($spacers, 2));
      }
    }

    .search-results {
      @include breakpoint("lg") {
        order: 6;
      }
    }
    .search-pagination {
      &__wrapper {
        padding: 0;
        border-top: none;
        @include breakpoint("lg") {
          order: 7;
        }
      }
      &__container {
        width: 100%;
        @include spacer(padding-top, map-get($spacers, 3));
        @include spacer(padding-bottom, map-get($spacers, 3));
        text-align: center;

        select {
          margin-left: 20px;
          padding: 3px 9px;
          border: 1px solid $color-black;
          width: 64px;
          font-family: inherit;
          font-weight: 600;
          font-size: 0.875rem;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          border-radius: 16px;
        }
      }
      &__pagination-list {
        margin-left: auto;
        @include breakpoint("lg") {
          width: auto;
          margin-left: -15px;
          margin-right: -15px;
          @include spacer(margin-top, map-get($spacers, 3));
          text-align: inherit;
        }
        li {
          margin: 0 14px;
          padding: 0;
          font-family: inherit;
          font-weight: 600;
          font-size: inherit;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          a {
            padding: 0 1px 1px;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-transform: inherit;
            line-height: inherit;
            text-decoration: none;
            border-bottom: 2px solid $color-black;
          }
          &.is-active {
            a {
              background: transparent;
              color: $omd-purple;
              border-color: $omd-purple;
            }
          }
        }
      }
    }
  }

  .events-collection {
    .results {
      @include inf-row-custom-col(".follow-wrap");
      @include inf-row-custom-col(".date-month");
      @include inf-row-custom-col(".date-range");
      @include inf-row-custom-col(".content-box");
      @include inf-row-custom-col(".sponser-logo");
      margin-left: -15px;
      margin-right: -15px;
      @include spacer(margin-top, map-get($spacers, 3));
      margin-bottom: 0;

      &:first-child {
        @include breakpoint("lg") {
          margin-top: 0;
        }
      }

      .date-month {
        width: 25%;
        margin-top: -6px;
        padding: 0 15px;

        @include breakpoint("lg") {
          width: 100%;
          margin-top: 0;
        }

        h4 {
          @include breakpoint("lg") {
            @include spacer(padding-bottom, map-get($spacers, 2));
          }
        }
      }

      .follow-wrap {
        width: 25%;
        margin-top: -6px;
        padding: 0 15px;

        @include breakpoint("lg") {
          width: 100%;
          margin-top: 0;
        }

        & > .date-month {
          width: 100%;
          margin: 0;
          padding-left: 0;
          padding-right: 0;
          font-family: inherit;

          @include breakpoint("lg") {
            width: 100%;
          }

          &.sticky.fixed {
            position: fixed;
            top: 40px;
            box-sizing: border-box;
            z-index: 0;
            margin-top: 0;
            width: auto;

            @include breakpoint("lg") {
              z-index: 1000;
              top: 0;
              background-color: $color-white;
              left: 0;
              padding: 15px;
              margin-top: 0;
              box-shadow: 0 8px 6px -6px #acacb0;
              width: 100%;

              h4 {
                padding-bottom: 0;
              }
            }
            @include breakpoint("medium") {
              top: 50px;
              margin-top: 17px;
              padding: 15px 48px;
            }
            @include breakpoint("small") {
              padding: 15px;
            }
          }

          &.sticky.fixed.absolute {
            position: absolute;
            z-index: -1;
          }

          &:empty {
            position: relative;
          }
        }
      }

      .date-range {
        font-family: inherit;
        width: 14%;
        padding: 0 15px;

        @include breakpoint("lg") {
          width: 100%;
          @include spacer(margin-bottom, map-get($spacers, 0));
        }

        &.emphasis {
          h6 {
            padding-top: 10px;
            @include breakpoint("lg") {
              padding: 10px 10px 0;
            }
          }
        }

        h6 {
          text-align: right;
          @include breakpoint("lg") {
            text-align: left;
          }
        }
      }

      .content-box {
        width: 47%;
        padding: 0 15px;
        @include breakpoint("lg") {
          width: 100%;
          @include spacer(margin-bottom, map-get($spacers, 3));
        }

        &::after {
          content: "";
          display: block;
          @include spacer(height, map-get($spacers, 3));
          border-bottom: 1px solid $color-black;

          @include breakpoint("lg") {
            display: none;
          }
        }

        &.emphasis .asset_family_mod {
          @include omd-grey-af-hover(".article-preview__headline");
          padding: 10px;
          @include breakpoint("lg") {
            padding-top: 0;
            &::before {
              top: -50px;
            }

            &:hover::before {
              top: -58px;
            }
          }
        }

        .asset_family_mod {
          margin: 0;

          @include breakpoint("lg") {
            margin-left: 0;
            margin-right: 0;
            border-bottom: none;

            &::before {
              top: -40px;
            }

            &:hover::before {
              top: -55px;
            }
          }

          .article-preview__byline {
            span {
              font-weight: normal;
              &:first-child {
                padding-right: 5px;
                font-weight: 600;
              }

              &:last-child {
                padding-left: 5px;
              }

              a {
                font-weight: normal;
              }

              a:hover {
                text-decoration-skip: ink;
                text-decoration: underline;

                &::before {
                  display: none;
                }
              }
            }
          }

          .article-summary {
            -webkit-line-clamp: initial;
          }
        }
      }

      .sponser-logo {
        position: relative;
        width: 14%;

        @include breakpoint("lg") {
          width: 100%;

          &::after {
            content: "";
            display: block;
            width: 100%;
            @include spacer(height, map-get($spacers, 3));
            border-bottom: 1px solid $color-black;
          }
          &.empty::after {
            height: 1px;
          }
        }

        img {
          max-width: 100%;
          padding-top: 5px;
          @include breakpoint("lg") {
            padding-top: 0;
            max-width: 200px;
          }
        }
      }
    }

    .article_restricted_access {
      color: #731aed;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  .search-pagination__container .btn.omd-icon-down-chevron.secondary {
    position: relative;
    left: 10px;

    @include breakpoint("lg") {
      left: 0;
    }

    &::after {
      background: url(img/omd/plus.svg);
      background-repeat: no-repeat;
    }
    &:hover {
      &::after {
        background: url(img/omd/plus_white.svg);
        background-repeat: no-repeat;
      }
    }
    cursor: pointer;
  }
  .search-results {
    width: 100%;
  }
}

/*Start meet the team page*/
#meetTheTeamTop {
  margin-top: 0px;
  .search-metadata {
    margin-left: 0px;
    text-align: center;
    @include breakpoint("medium") {
      order: 2;
    }
  }
  .search__content {
    .search__facets {
      .facets__content {
        margin-top: 0px;
      }
      .facets__fieldset {
        display: flex;
        gap: map-get($spacers, 3);
        @include breakpoint("medium") {
          display: block;
        }
        & > * {
          flex: 1 0 auto;
        }
      }
      .selectivity-input {
        min-width: auto;
        width: auto;
        margin: 0;
        @include breakpoint("medium") {
          width: 100%;
          margin: 15px 0 0;
          &:first-child {
            margin: 0px;
          }
        }
      }
    }
    .search-bar {
      min-height: auto;
      padding: 0;
      @include spacer(margin-bottom, map-get($spacers, 3));
      background: transparent;
      &__label,
      &__footer {
        display: none;
      }
      &__content {
        @include spacer(margin-top, map-get($spacers, 1));
      }
      &__input-wrapper {
        width: 100%;
        margin-right: 0;
      }
      &__field {
        height: 68px;
        padding: 0 30px;
        @include heading5();
        border: solid 1px $color-black;
        border-right: none;
        border-radius: 34px 0 0 34px;
      }
      &__clear {
        right: 18px;
        top: 50%;
        width: 19px;
        height: 19px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
        &.active {
          opacity: 1;
          pointer-events: all;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          top: 0px;
          position: absolute;
          background-image: url(img/omd/icon_close_black.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
      &__icon {
        display: none;
        &--info,
        &--clear {
          display: inline-block;
        }
      }
      &__tips {
        @include spacer(margin-left, map-get($spacers, 2));
        &-button {
          font-family: $font-family-opensans;
          font-size: $font-size-16;
          color: $color-black;
          font-weight: $font-weight-600;
          color: $omd-purple;
          background: url(img/omd/light-bulb.svg) no-repeat left center;
          background-size: 16px;
          @include spacer(padding-left, map-get($spacers, 3));
          display: block;
          &:hover {
            text-decoration: none;
          }
          & > svg {
            display: none;
          }
          & > span {
            position: relative;
            &:hover:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -3px;
              width: 100%;
              height: 2px;
              background-color: $omd-purple;
            }
          }
        }
        &-panel {
          h3 {
            font-size: $font-size-24;
            letter-spacing: $letter-spacing-094;
          }
          *:not(h3) {
            font-family: $font-family-opensans;
            font-size: $font-size-16;
            color: $color-black;
            font-weight: revert;
          }
        }
      }
      &__submit {
        position: relative;
        width: 70px;
        height: 70px;
        margin-right: 0;
        padding: 0;
        background-color: $color-black;
        border: solid 1px $color-black;
        border-left: none;
        border-radius: 0 34px 34px 0;
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-image: url(img/omd/search_white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px 24px;
        }
      }
      &__headline-label {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
      &__footer-option {
        @include breakpoint("medium") {
          display: block;
          margin-right: 0;
        }
      }
    }
    .no-result {
      margin: 0 auto;
      border: none;
    }
  }
  .search__facets {
    @include breakpoint("medium") {
      order: 1;
    }
  }
  .inf-row {
    &.omd-mkt-col-3 {
      @include breakpoint("medium") {
        order: 3;
      }
    }
  }
  .omd-mkt-col-3 {
    .inf-col-fluid {
      margin: 4.5rem 0;
      @include breakpoint("medium") {
        margin: 2rem 0;
      }
      @include breakpoint("small") {
        margin: 1rem 0;
      }
    }
  }
  .omd-mkt-img-container {
    img {
      border-radius: 50%;
      max-width: 220px;
      max-height: 220px;
    }
  }
}
/*End meet the team page*/
#eventTop {
  margin: map-get($spacers, 1)*5 0;
  @include breakpoint('medium') {
    margin: map-get($spacers, 1)*3.5 0;
  }
  .events-collection {
    .results {
      &:last-of-type{
        .content-box {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}