.image-icon-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
      margin: map-get($spacers, 1)*3.5 auto;
      padding: 0 map-get($spacers, 1)*0.75;
      gap: 15px;
    }
    @include breakpoint('medium-small') {
        gap: 20px;
        padding: 0 map-get($spacers, 1)*1.5;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    & > * {
        flex: 1 0 32%;
        min-height: 315px;
        @include breakpoint('medium') {
            min-height: initial;
        }
        @include breakpoint('medium-small') {
            flex: 1 0 100%;
        }
    }
    &__image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include breakpoint('medium-small') {
            height: 116px;
            @include spacer(margin-left, - map-get($spacers, 0));
            @include spacer(margin-right, - map-get($spacers, 0));
            margin-bottom: - map-get($spacers, 1)*3;
        }
    }
    &__icon {
        background-color: $omd-dark-purple-2;
        padding: map-get($spacers, 1)*1.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $color-white;
        position: relative;
        @include breakpoint('medium-small') {
            flex-direction: row;
            gap: 20px;
            padding: map-get($spacers, 1);
        }
        & > img {
            max-width: 75px;
            max-height: 80px;
            @include breakpoint('medium-small') {
                width: 45px;
                height: 47px;
            }
        }
        & > div {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            @include breakpoint('medium-small') {
                text-align: left;
                gap: 5px;
            }
            & > h3 {
                @include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-02);
                @include breakpoint('medium-small') {
                    @include font($font-size-32, $font-weight-bold, $line-height-125, $letter-spacing-02);
                    letter-spacing: 0;
                }
            }
            & > p {
                @include font($font-size-20, $font-weight-400, $line-height-125, $letter-spacing-150);
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 2px;
                @include breakpoint('medium-small') {
                    @include font($font-size-16, $font-weight-400, $line-height-125, $letter-spacing-125);
                    margin: 0;
                }
            }
        }
        &:hover,&:focus {
            text-decoration: none;
            & > div {
                & > h3,
                & > p {
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }
    }
    &__list {
        display: flex;
        padding: 30px;
        background-color: $omd-light-grey;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
}