.banner, .cookie-banner {
	margin-bottom: 2rem;

	// By default, all banners are hidden.
	// If banner not seen/dismissed, will be displayed when document ready.
	overflow: hidden;
	width: 100%;

	// To animate closing a banner nicely, need to define a `max-height`.
	// Without an explicitly defined height, `transform` won't actually animate
	// `max-height` lets us fake that by giving us an explicit value for the
	// browser to start from when animating.
	transition: max-height 250ms ease-out;
	height: auto;
	max-height: 0px;

	// Internal wrapper for banners
	// Allows for better arrangement and vertical alignment
	.banner__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		.dismiss-button {
			align-self: center;
			flex: 0 0 auto;
			order: 3;
		}
		.banner__lead-icon {
			align-self: center;
			flex: 0 0 auto;
			order: 1;
		}
		span {
			flex: 1 1 auto;
			order: 2;
		}
	}

	/*---
	title: Maintenance Banner
	section: Banners
	---

	```example:html
	<div class="banner banner--maintenance is-visible">
		<div class="banner__wrapper">
			<div class="dismiss-button js-dismiss-banner">
				<span>Dismiss</span>
				<svg class="dismiss-button__icon">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#x"></use>
				</svg>
			</div>
			<svg class="banner__lead-icon">
				<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#tool-maintenance"></use>
			</svg>
			<span>Our site will be undergoing system maintenance on Saturday 1—3 am EST, we apologize for any inconvenience.</span>
		</div>
	</div>
	```
	*/

	&--maintenance,
	&--cookie-policy {
		background-color: #f1f2f2;
		color: #58595b;
	}

	&--maintenance,
	&--register {

		@include breakpoint('medium') {
			display: none;
		}

		.banner__wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.dismiss-button {
				align-self: center;
				flex: 0 0 auto;
				order: 3;
			}
			.banner__lead-icon {
				align-self: center;
				flex: 0 0 auto;
				order: 1;
			}
			span {
				flex: 1 1 auto;
				order: 2;
			}
		}

		.banner__wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.dismiss-button {
				align-self: center;
				flex: 0 0 auto;
				order: 3;
			}
			.banner__lead-icon {
				align-self: center;
				flex: 0 0 auto;
				order: 1;
			}
			span {
				flex: 1 1 auto;
				order: 2;
			}
		}
	}

	&--register {
		background-color: $color-yellow-orange;

		& .button--outline:enabled {
			background: transparent;
			border: 1px solid $color-white;

			@include on-event() {
				border: 1px solid $color-tree-poppy-orange;
			}
		}
	}

	/*---
	title: Alert Banner
	section: Banners
	---

	```example:html
	<div class="banner banner--alert is-visible">
		<div class="banner__wrapper">
			<svg class="banner__lead-icon">
				<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#alert"></use>
			</svg>
			<span>Name, we could not send the verification email to the address provided.</span>
		</div>
	</div>
	```
	*/
	&--alert {
		background-color: $color-butterscotch-orange;

		span {
			color: $color-zeus-black;
		}
	}

	&--highlights {
		color: $color-zeus-black;
		margin: 32px 0;
		padding: 18px 0;
		border: 2px solid #d1d3d4;
		border-left: 0;
		border-right: 0;
		text-align: center;

		b, strong {
			@include textMix('Roboto', 20px, 600);
			margin-right: 8px;
		}
	}

	&__lead-icon {
		width: 26px;
		height: 22px;
		display: block;
		float: left;
		margin-right: 12px;
	}

	&__wrapper {
		padding: 12px 22px;
	}

	&.is-visible {
		max-height: 600px;
	}

	&--cookie-policy {
		margin-bottom: 0;
	}

	@include breakpoint('medium') {
		&--maintenance {
			display: none;
		}
	}
}
.resend-banner-maintenance {
	background-color: #f1f2f2;
	color: #58595b;
	@include breakpoint('medium'){
		margin-bottom: 1rem;
	}
}
