.analysts-col {
  width: 100%;
  padding: 0;
  @include notIE {
    @include colwidth(12, 'medium');
  }
  @include breakpoint('medium') {
    text-align: center;
  }
  @include spacer(margin-top, map-get($spacers, 4));
  @include spacer(margin-bottom, map-get($spacers, 4));
  &.version-2 {
    margin-top: map-get($spacers, 1)*5;
    margin-bottom: map-get($spacers, 1)*5;
    @include breakpoint("medium") {
      margin-top: map-get($spacers, 1)*3.5;
      margin-bottom: map-get($spacers, 1)*3.5;
    }
  }
  &.no-top-margin {
    margin-top: 0;
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
  @include breakpoint('medium') {
    text-align: left;
  }
  h5 {
    position: relative;
    @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-08);
    margin-bottom: map-get($spacers, 1)*2;
  }
  .analysts-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    gap: 30px;
    @include breakpoint('small') {
      flex-direction: column;
    }
    .analyst-item {
      display: flex;
      position: relative;
      width: 100%;
      margin: 0;
      .photo {
        margin: 0;
        margin-right: 15px;
        flex: 0 0 100px;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: 2px solid $omd-light-purple-2;
        overflow: hidden;
        @include breakpoint('medium') {
          margin-right: 15px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & > .info {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: map-get($spacers, 1)*1.25;
        @include breakpoint('medium') {
          margin-top: 0;
          justify-content: center;
        }
        & > * {
          margin-bottom: 0;
          &:empty {
            display: none;
          }
        }
        & > p {
          width: 100%;
        }
        & > h6 {
          @include font($font-size-25, $font-weight-bold, $line-height-13, $letter-spacing-025);
          &.name {
            word-break: keep-all;
          }
          & + .btn {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 5px;
            margin: 0;
            padding: 6px 20px;
            font-size: 11px;
            z-index: 1;
            svg {
              height: 18px;
              width: 18px;
            }
            &.analyst-following {
              background: #939393;
              border-color: #939393;
              svg {
                fill: white;
              }
              &:hover {
                color: #939393;
                background: white;
                svg {
                  fill: #939393;
                }
              }
            }
          }
        }
        & > .omd-label {
          @include font($font-size-12, $font-weight-600, $line-height-15, $letter-spacing-117);
        }
        .description {
          display: none;
        }
        .show-more-content-by {
          position: relative;
          font-size: $font-size-14;
          font-weight: $font-weight-600;
          line-height: $line-height-15;
          color: $omd-purple;
          z-index: 1;
        }
      }
      .further-details {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
      }
    }
  }
  .omd-box {
    padding-top:20px;
    padding-left: 30px;
    padding-right: 30px;
    @include breakpoint('medium') {
      padding-left: 24px;
      padding-right: 24px;
    }
    @include breakpoint('small') {
      padding: 0 map-get($spacers, 1);
    }
    & + .omd-box-bottom {
      .btn.disabled {
        display: none;
      }
    }
  }
  &.version-2 {
    & > .omd-box.omd-pad-t-4.omd-pad-b-4 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.state-preview {
    h5 {
      position: relative;
      text-align: center;
      @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-08);
      margin-bottom: map-get($spacers, 1)*2;
    }
    .analysts-container {
      gap: 60px;
      @include breakpoint('small') {
        gap: 30px;
      }
      .analyst-item {
        width: calc(25% - 45px);
        margin: 0;
        @include breakpoint('medium') {
          flex-direction: row;
          width: calc(50% - 30px);
        }
        @include breakpoint('small') {
          width: 100%;
        }
        & > .info {
          & > p {
            text-transform: uppercase;
          }
        }
      }
    }
    &.version-2 {
      .omd-box-bottom {
        & > .btn {
          margin-top: map-get($spacers, 1)*2.5;
          &.disabled {
            margin: 0;
          }
        }
      }
    }
  }
}

//Used in author and long article content side bar - Revealer component
.omd-box-bottom {
  position: relative;
  text-align: center;
  border-top: none;
  & > .btn {
    // half the button height - the 2nd borderline offset
    border-width: 1px;
    background:#FFFFFF;
  }
}

.inf-tags {
  width: 100%;
  margin-bottom: 50px;
  & > .inf-container {
    & > h5 {
      font-size: 2rem;
    }
  }  
  .tags-container {
    background: #faf9fb;
    @include spacer(padding-top, map-get($spacers, 0));
    @include spacer(padding-bottom, map-get($spacers, 0));
    &:nth-child(odd) {
      background: #ffffff;
    }
    .tags, .sub-tags {
      max-height: 40px;
      overflow: hidden;
      @include breakpoint('medium') {
        max-height: 90px;
      }
      li {
        display: inline-block;
        margin: 0 10px 10px 0;
        & > a {          
          background: #ffffff;
        }
      }
    }
    h6 {
      font-size: 1.5rem;
      color: #731aed;
      font-weight: 700;
      text-transform: capitalize;

      .showall {
        color: #000;
        text-decoration: underline;
        font-size: $font-size-16;
        margin-left: 20px;
        font-weight: 600;
      }
    }
  }
  .tags-categories {
    & > .inf-container {
      & > div {
        border-left: 4px solid #e3e3e3;
        padding-left: 15px;
        @include spacer(margin-top, map-get($spacers, 0));
        @include spacer(margin-bottom, map-get($spacers, 0));
  
        h6 {
          font-size: $font-size-20;
          color: #000;
          font-weight: 600;
  
          .showall {
            color: #000;
            text-decoration: underline;
            font-size: $font-size-16;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.commentary-container {
  .commentaries {
    li {
      float: left;
      padding-right: 30px;
      @include breakpoint('medium') {
        padding-right: 15px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}