.profile {
  @include spacer(margin-bottom, map-get($spacers, 5));
  @include spacer(padding-top, map-get($spacers, 5));
  @include spacer(padding-bottom, map-get($spacers, 5));
  @include breakpoint('medium') {
    @include spacer(padding-top, map-get($spacers, 3));
    @include spacer(padding-bottom, map-get($spacers, 3));
  }
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: $omd-light-grey;

  .profile__title {
    width: 100%;
    @include heading6();
    letter-spacing: -$letter-spacing-025;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__box {
    @include inf-container();
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    @include breakpoint('medium') {
      flex-direction: column;
      @include spacer(margin-top, 0);
    }
  }

  .profile__location {
    width: 100%;
    @include heading6();
    letter-spacing: -$letter-spacing-025;
    margin-top: 0;
    @include spacer(margin-bottom, map-get($spacers, 3));
  }
  .profile__img {
    float: none;
    max-width: 220px;
    width: auto;
    @include spacer(margin-bottom, map-get($spacers, 3));
    @include spacer(margin-right, 0);
    margin-left: 0;
    border-radius: 50%;
    
    @include breakpoint('medium') {
      max-width: 240px;
      width: 100%;
      margin: auto;
      @include spacer(margin-bottom, map-get($spacers, 3));
    }
  }
  .profile__links {
    display: flex;
    align-items: center;
    @include spacer(margin-top, map-get($spacers, 3));
    @include spacer(margin-bottom, map-get($spacers, 3));
    @include spacer(padding-bottom, map-get($spacers, 3));
    padding: 0;
    @include breakpoint('medium') {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    li {
      font-family: inherit;
      margin-bottom: 0;
      @include spacer(margin-right, map-get($spacers, 3));
      @include breakpoint('medium') {
        margin-bottom: inherit;
        margin-inline: 0;
      }
      svg {
        display: none;
      }
      a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        font-size: $font-size-14;
        font-weight: $font-weight-bold;
        letter-spacing: $letter-spacing-1;
        color: $color-black;
        line-height: 32px;
        &::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 32px;
          height: 32px;
          margin-right: 10px;
          background-color: $color-black;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px auto;
          border-radius: 50%;
        }
        &.email::before {
          background-image:url(img/omd/envelope_white.svg);
        }
        &.twitter::before {
          background-image:url(img/omd/twitter_white.svg);
        }
        &.linkedin::before {
          background-image:url(img/omd/linkedin_white.svg);
        }
      }
    }
  }
  .profile__profile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include spacer(margin-bottom, map-get($spacers, 3));
    @include spacer(padding-left, map-get($spacers, 5));
    padding-bottom: 0;
    color: inherit;
    border-bottom: none;

    @include breakpoint('ipad') {
      @include spacer(padding-left, map-get($spacers, 3));
    }
    
    & > .profile__name {
      width: 100%;
      @include spacer(margin-bottom, 0);
    }

    & > .btn.primary {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-left: 0;
      @include spacer(margin-right, map-get($spacers, 3));
      @include spacer(margin-top, map-get($spacers, 3));
      @include spacer(margin-bottom, map-get($spacers, 3));
      padding: 6px 20px;
      font-size: 11px;
      @include breakpoint('medium') {
        margin: 0;
        @include spacer(margin-top, map-get($spacers, 1));
      }
      svg {
        width: 25px;
        height: 25px;
      }
      &.analyst-following {
        background: #939393;
        border-color: #939393;
        svg {
          fill: white;
        }
        &:hover {
          color: #939393;
          background: white;
          svg {
            fill: #939393;
          }
        }
      }
    }
  }
  .profile__expertise {
    li {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;
    }
    .bar-separated-link-list--wrap {
      margin: -5px;
      line-height: inherit;
    }
    .bar-separated-link-list__label {
      display: block;
      margin-bottom: calc(1rem - 5px);
      padding: 5px 5px 0;
      font-size: $font-size-12;
      font-weight: $font-weight-600;
      line-height: $line-height-1;
      letter-spacing: $letter-spacing-1;
      color: $omd-purple;
      text-transform: uppercase;
    }
    // overriden to match .btn.tag
    a {
      display: inline-block;
      position: relative;
      padding: 7px 16px;
      margin: 5px;
      @include font($font-size-12, $font-weight-600, $line-height-125, 1.17px);
      color: black;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 60px;
      border: solid 1px rgba($color: #000000, $alpha: 0.5);
      transition: all .2s;
      &:hover {
        border-color: rgba($color: #000000, $alpha: 1);
      }
    }
  }
  
  .l-right-rail {
    width: 33.33%;
    max-width: none;
    padding-left: 30px;
    @include breakpoint('large') {
      margin-bottom: 0;
    }
    @include breakpoint('lg') {
      width: 100%;
      padding-left: 0;
    }
  }
}

.alert {
  position: relative;
  @include spacer(padding, map-get($spacers, 1));
  border: 1px solid transparent;
  border-radius: 0.25rem;
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  display: none;
}
.alert-dark.is-active {
  display: block;
}