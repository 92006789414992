.sortable-table {
	@include textMix('Roboto', 1rem, 400);
	border: 1px solid $color-light-gray;
	color: $color-abbey-gray;
	width: 100%;

	// When this is used with one ultra-wide column, it forces all other columns
	// to only be as wide as their content requires. Closest thing to a
	// flexbox-like table without actual flexbox.
	// See .sortable-table__col--stretch-to-fill below
	// Via: http://stackoverflow.com/a/26915414
	table-layout: auto;

	&__col {
		padding: 1rem 1.25rem;

		&--quarter-width {
			width: 25%;
		}

		&--half-width {
			width: 50%;
		}

		&--full-width {
			width: 100%;
		}

		.labeled-checkbox {
			margin-bottom: 0;
			display: inline-block;
		}
	}

	tbody tr {
		border-bottom: 1px solid $color-light-gray;
	}
	&__header {
		background-color: $color-concrete-gray;
		color: $color-zeus-black;
		cursor: pointer;
		font-size: 1.125rem;
		font-weight: 600;

		&--mobile {
			display: none;
		}
	}

	.sorttable_sorted .sorting-arrows__arrow--up,
	.sorttable_sorted_reverse .sorting-arrows__arrow--down {
		@extend .sorting-arrows__arrow.is-active;
	}

	// Center toggle switches in their column
	.onoffswitch {
		display: block;
    	margin: 0px auto;

		@include breakpoint('small') {
			margin: 0px;
		}
	}


	&__togglable-list-title {
		float: left;
	}
	&__togglable-list,
	&__list-toggler {
		font-size: 0.875rem;
		margin-left: 5rem;
	}
	&__togglable-list {
		display: none;
		margin-bottom: 0.5rem;
	}
	&__list-toggler {
		@extend a;
		cursor: pointer;
		display: block;

		&.show-when-active {
			display: none;
		}
	}
	&__togglable-list-wrapper.is-active {
		.sortable-table__list-toggler {
			display: none;
		}
		.sortable-table__togglable-list,
		.sortable-table__list-toggler.show-when-active {
			display: block;
		}
	}

	@include breakpoint('small') {

		border: 0;

		tbody tr {
			border: 1px solid $color-light-gray;
			border-bottom-width: 0;
			display: block;
			padding-bottom: 1rem;

			&:last-child {
				border-bottom-width: 1px;
			}
		}

		&__header {
			display: none;
		}
		&__header--mobile {
			display: block;
			background-color: transparent;
			color: $color-zeus-black;
			cursor: pointer;
			padding-bottom: 0.5rem;

			.sortable-table__col {
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 400;
				padding: 0 1rem 0.5rem 0rem;
				width: auto;
				vertical-align: middle;

				&:after {
					display: inline-block;
					content: "";
					height: 18px;
					width: 1px;
					margin-left: 1.75rem;
					background-color: $color-light-gray;
					vertical-align: middle;
				}
				&:last-child:after {
					width: 0;
				}

				.sorting-arrows {
					position: absolute;
					margin-top: -2px;
				}
			}

			.sortable-table__sort-by-header {
				display: block;
				padding-left: 0;

				&:after {
					display: none;
				}
			}
		}

		&__sort-arrows svg {
			@include svg-fill($color-light-gray);
		}

		&__col {
			display: block;
			font-size: 1.125rem;
			padding: 1rem 1rem 0;
			width: 100%;

			&--has-title {
				display: flex;
				flex-wrap: nowrap;

				&:before {
					font-weight: 500;
					height: 100%;
					padding-right: 1rem;
				}
			}

			&--title:before {
				content: "Title";
			}

			&--sources:before {
				content: "Sources";
			}

			&--date:before {
				content: "Date";
			}

			&--alert:before {
				content: "Email Alert";
			}

			&--publication:before {
				content: "Publication";
			}

			&--sub-type:before {
				content: "Subscription Type";
			}

			&--exp-date:before {
				content: "Expiration Date";
			}
		}

		&__submit-wrapper {
			text-align: right;
		}

	}
}
