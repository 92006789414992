.rss-feed {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	& > img,
	& > svg {
		width: 18px;
		height: 18px;
	}
	& > span {
		@include font($font-size-18, $font-weight-600, $line-height-135, $letter-spacing-normal);
		font-family: $font-family-opensans;
	}
	@include breakpoint('medium-small') {
		margin: 0 auto;
	}
}