.ID-responsive-table-modal {
    display: none;
    position: fixed;
    z-index: 90899;
    padding-top: 100px;
    padding-bottom: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid #d1d3d4;
    .footnote {
        float: none;
        margin-top: 0;
    }
    .container {
        background-color: #fff;
        margin: auto;
        padding: 20px;
        border: 1px solid #d1d3d4;
        width: 80%;
    }
    .table-wrapper {
        border: 1px solid #d1d3d4;
        border-top: none;
        overflow-x: scroll;
        .tableHead {
            text-align: right;
			@include textMix('Roboto', 1em, bold);
        }
    }
    &#modal-comparefinancialresults .table-wrapper {
        overflow: hidden;
    }
}
.idi-table-heading {
    color: $color-white;  
    font-style: normal;
    @include textMix('Roboto', 1em, 500);
    margin-top: 45px;
    padding: 5px;
    span{
        @include textMix('Roboto', 1em, 700);
        padding-left:10px;
    }
}
.sort {
    float: right;
    position: relative;
    width: 15px !important;
    &[type="ascending"] {
        position: absolute;
        top: 10px;
        right: 15px;
        transform: rotate(-180deg);
        @media only screen and (min-width: 767px) and (max-width: 1024px) {
            right: 10px;
        }
    }
    &[type="descending"] {
        position: absolute;
        top: 25px;
        right: 20px;
        @media only screen and (min-width: 767px) and (max-width: 1024px) {
            right: 15px;
        }
    }
    &.active {
        svg {
            fill: $color-white !important;
        }
    }
    svg {
        height: 25px;
        width: 20px;
        fill: $color-white !important;
    }
}
.table_close {
    color: #231f20;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
.table-wrapper {
    width: 100%;
    border-top: none;
    @media (max-width: 1024px) {
        overflow-x: scroll;
        border: 1px solid #d1d3d4;
    }
    .table { 
        display: table;
        width: 100%;
        border-collapse: inherit;
        border: 1px solid #d1d3d4;
        border: none !important;
        font-family: "Roboto", Helvetica, Arial, sans-serif;
    }
    .tableRow { 
        display: table-row;
        &:nth-child(odd) {background-color: $color-concrete-table-gray;}
        // &:hover {
        //     .tableCell {
        //         background-color: #efd0d8;
        //     }
        // }
    }
    .tableHeading { 
        display: table-header-group;
    }
    .tableHead {
        @include textMix('Roboto', 1em, 400);
        font-style: normal;
        font-family: "PT Serif", Georgia, serif;
        font-size: 16px;
        text-align: center;
        color:$color-white;
        position: relative;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        &:first-child {
            text-align: left;
        }
    }
    .tableBody { 
        display: table-row-group;
        
    }
    .tableFoot { display: table-footer-group; }
    .tableCell, .tableHead { 
        display: table-cell;
        padding: 5px;
    }
    .tableCell:first-child {
        text-align: left;
		@include textMix('Roboto', 1em, 500);
    }
    .tableCell {
        text-align: right;
    }
}
#modal-annualresults, #modal-comparefinancialresults {
    .tableHead {
        padding-right: 20px;
        position: relative;
        .sort-modal {
            position: absolute;
            right: 0;
            svg {
                fill: $color-white !important;
            }
            &[type="ascending"] {
                top: 5px;
                transform: rotate(180deg);
            }
            &[type="descending"] {
                top: 20px;
            }
            svg {
                height: 25px;
                width: 20px;
            }
            &.active {
                svg {
                    fill: #ffffff;
                }
            }
        }
    }
    .tableRow {
        .tableHead, .tableCell {
            &:first-child {
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 20px;
            }
        }
    }
    .tableHead:last-child {
        padding-right: 40px !important;
    }
}