.topic-subtopic {
  @include inf-container();
  display: block;
  @include spacer(margin-bottom, map-get($spacers, 5));
  .latest-news__header {
    @include heading2();
    @include spacer(margin-bottom, map-get($spacers, 4));
    text-align: left;
    @include breakpoint('medium') {
      border-bottom: none;
    }
    span {
      display: inline;
      position: static;
      padding: 0;
      background-color: transparent;
    }
    &::before {
      display: none;
    }
  }
  .latest-news__articles {
    margin: -15px -15px calc(map-get($spacers, 4) - 15px);
  }
  .article-preview {
    overflow: visible;
    position: relative;
    width: calc(50% - 30px);
    height: auto;
    margin: 15px;
    padding: 28px;
    @include breakpoint('lg') {
      width: calc(100% - 30px);
    }
    @include breakpoint('medium') {
      padding: 20px;
    }
    &::after {
      display: none;
    }
    // legacy items to remove
    .article-preview__bookmarker,
    .article-preview__tags {
      display: none;
    }
    .article-metadata {
      float: left;
      width: auto;
      font-family: inherit;
      line-height: 1rem;
      margin-top: 0;
      margin-bottom: 0;
      ul {
        li {
          order: 2;
          line-height: 1rem;
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid $color-black;
          &:nth-child(2) {
            order: 1;
            max-width: none;
          }
        }
      }
      h6 {
        font-size: $font-size-14;
        font-weight: $font-weight-600;
        letter-spacing: $letter-spacing-1;
        line-height: 1rem;
      }
    }
    .article-metadata__date {
      display: block;
      font-size: $font-size-14;
    }
    .article-preview__inner-wrapper {
      display: block;
      margin-bottom: 0;
    }
    .article-preview__headline {
      @include spacer(margin-bottom, map-get($spacers, 0));
      a {
        display: block;
        @include heading5();
        line-height: $line-height-15;
        text-decoration: none;
        pointer-events: all;
        transition: color .2s;
        &:hover {
          color: $omd-purple;
          &::before {
            transform: translate(-50%, -50%) scaleX(1.04) scaleY(1.06);
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%) scaleX(1) scaleY(1);
          background-color:rgba(30,20,80,.05);
          z-index: 1;
          transition: transform .2s;
        }
      }
    }
    .article-preview__byline {
      clear: none;
      width: auto;
      font-size: 0;
      line-height: 0;
      text-indent: -4px;
      margin-top: 0;
      @include spacer(margin-bottom, map-get($spacers, 0));
      a {
        display: inline-block;
        position: relative;
        font-size: $font-size-14;
        line-height: 1.1rem;
        text-indent: 0;
        z-index: 1;
        &:hover::before {
          right: 0.5rem;
        }
        &::after {
          content: ', ';
          position: static;
          background: transparent;
          height: auto;
          margin-right: .15rem;
        }
        &:last-of-type {
          &:hover::before {
            right: 0;
          }
          &::after {
            display: none;
          }
        }
      }
      & + * {
        clear: left;
      }
    }
    .article-summary {
      max-height: 4.688em;
      -webkit-line-clamp: 3;
    }
    .article-preview__entitlement {
      @include spacer(margin-top, map-get($spacers, 0));
      font-size: $font-size-12;
      font-weight: $font-weight-600;
      color: $omd-purple;
      letter-spacing: $letter-spacing-1;
      text-transform: uppercase; 
      p, span {
        font-size: inherit;
        color: inherit;
      }
    }
  }
  .full-diminished-button {
    @extend .btn;
    @extend .secondary;
    @extend .omd-icon-arrow;
    width: auto;
    margin: 0;
    @include spacer(margin-top, map-get($spacers, 3));
    text-align: left;
  }
}