// hide from screenreaders and browsers: h5bp.com/u
.is-hidden {
    display: none;
}
// added new hidden helper to avoid unknown regression
.hidden {
    display: none !important;
}

.is-active {
    display: block;
    visibility: visible;
}

.u-inherit-text-color {
    color: inherit;
}
