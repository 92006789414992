	.small_table{
		width: 100%;
		.table-responsive{
			
			.table{
				border-collapse: inherit;
				border: 1px solid $color-light-gray;
				width:100%;
				margin-bottom: 3rem;
				.table_head{
				  background-color: $color-ship-gray;
				  color: $color-white;
				  
				  th{
					@include textMix('Roboto', 1.125em, 700);
					font-style: normal;
					text-align: left;
					
				  }
				}
				.graybg{
				  background-color: $color-concrete-table-gray;
				  color: #000;
				}
				.R16{
				   @include textMix('Roboto', 1em, 400);
				   font-style: normal;
				   vertical-align: top;
				   border-bottom: 1px solid #d1d3d4;
				}
				.RB16{
				   font-family: "Roboto", Helvetica, Arial, sans-serif;
				   font-size: 1rem;
				   color:$color-red;
				   font-style: normal;
				   font-weight: 500;
				   vertical-align: top;
				   border-bottom: 1px solid #d1d3d4;
				}
				.R21_RedColor{
				   @include textMix('Roboto', 1.3125em, 400);
				   font-style: normal;
				   color:$color-red;
				   @include breakpoint('small'){
					vertical-align: top; 
				   }
				}
				.R21_GrayColor{
					&.border-right{
						@include textMix('Roboto', 1.125em, 500);
					}
				   @include textMix('Roboto', 1em, 400);
				   font-style: normal;
				   color:$color-ship-gray;
				   @include breakpoint('small'){
					vertical-align: top; 
				   }
				   a{
					   @include textMix('Roboto', 1.125em, 500);
				   }
				}
				.RB18{
					@include textMix('Roboto', 1.125em, 500);
					font-style: normal;
				}
				.visible-lg{
					margin-bottom: 20px;
					@include breakpoint('small') {
						display: none;
					}
					@include breakpoint('medium-small') {
						display: none;
					}
					
				}	
				
			}
			h1.primary_heading{
				@include textMix('Roboto Condensed', 2.9em, 400);
				 font-style: normal;
				 color: $color-zeus-black;
				 margin-top:45px;
			}
			h5.secondary_heading{
				@include textMix('PT Serif', 1.68em, 400);
				   font-style: italic;
				   color: $color-zeus-black;
				   margin-top:30px;
				@include breakpoint('small') {
					margin-bottom:30px;
				}
				@include breakpoint('medium') {
					margin-bottom:30px;
				}
				@include breakpoint('large') {
					margin-bottom:30px;
				}
			}
			.p-10{
				padding: 10px 16px;
			}
			.pad-10{
				padding: 20px 16px;
			}
			.pad-full-10{ padding: 10px 16px;}
			.pad-full-0{ padding: 0px 16px;}
			.pad-bottom-20{
				padding-bottom:20px;
			}
			.pad-top-10{
				padding-top: 10px;
			}
			hr{
			  margin: 35px 0 35px 0;
			  @include breakpoint('small') {
						display: none;
				}
				@include breakpoint('medium') {
						display: none;
				}
				@include breakpoint('large') {
						display: none;
			}
			}	
				
		}
		
		.visible-sm{
			display: none;
			@include breakpoint('small') {
						display: block;
						.border{
							border-top: 1px solid $color-light-gray;
						}
			}
			@include breakpoint('medium-small') {
						display: block;
						.border{
							border-top: 1px solid $color-light-gray;
						}
			}
		}
	}

.casualtyPan{
	.table{
		.R16{
		   strong{
			display: block !important;
		   }
		   p{
			margin-bottom: 12px !important;
		   }
		}
	}
}	 
.casualty-table-theme {
	.table {
		width: 790px !important;
		@media screen and (min-width: 801px) and (max-width: 1024px) {
			max-width: 594px !important;
		}
		@include breakpoint('medium') {
			width: 100% !important;
		}
		.table_head{
			color: #ffffff;
			th{
				font-size: 16px !important;
				font-weight: bold !important;
			}
			.visible-lg{
				color: #fff;
				font-weight: normal;
			}
		}
		.top {
			color: $color-abbey-gray !important;
			width: 45px;
			height: 40px;
			display: block;
			border-left: 1px solid $color-dusty-gray;
			text-decoration: none;
			font-size: 17px;
			span {
				background: url('img/sprite-image.png') no-repeat 0 -606px;
				width: 11px;
				height: 8px;
				display: inline-block;
				margin-right: 8px;
			}

		}
		hr {
			display: block !important;
			margin: 0 !important;
		}
	}
	.border-right {
		border-right: 1px solid #d1d3d4;
	}
}