.search-bar {
    @include textMix('Roboto', 15px, 400);
    min-height: 156px;
    padding: 20px;
    background-color: $color-concrete-gray;
    margin-bottom: 50px;
    @include search-page-structure();

    @include breakpoint('medium') {
        order: 1;
        margin-bottom: 1rem;
        min-height: auto;
    }

    &.tips-open {
        z-index: -1;
    }

    &__label {
        width: 100%;
        padding-bottom: 20px;
        line-height: 34px;
        color: $color-zeus-black;

        @include breakpoint('medium') {
            display: none;
        }
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
    }

    &.tips-open &__content {
        z-index: 1;
    }

    &__input-wrapper {
        display: inline-flex;
        position: relative;
        width:100%;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape){
            /* Supposedly for iPad */
            @include breakpoint('large') {
                width: 70%;
            }
            @include breakpoint('medium') {
                width: 100%;
            }
        }
        margin-right: 12px;
    }

    &__field {
        order: 1;
        display: inline-block;
        width: 100%;
        height: 35px;
        padding-right: 30px;
        padding-left: 13px;
        border: 1px solid $color-light-gray;

        &:placeholder {
            color: $color-abbey-gray;
        }
    }

    &__clear {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    &__submit {
        display: inline-flex;
        order: 2;
        width: 38px;
        min-width: 38px;
        padding: 9px;
        margin-right: 30px;
        background-color: $color-yellow-orange;
        vertical-align: middle;

        @include breakpoint('medium') {
            margin-right: 0;
        }
    }

    &__tips {
        order: 3;
        position: relative;
        white-space: nowrap;
         @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape){
            /* Supposedly for iPad */
            @include breakpoint('large') {
                padding-right:20px;
            }
             @include breakpoint('medium') {
                padding-right:0px;
            }
        }

        @include breakpoint('medium') {
            display: none;
        }

        &.open {
            position: relative;
            z-index:1;
        }

        &-button {
            cursor: pointer;
        }

        &.open &-button {
            cursor: pointer;

            &:before {
                content: "";
                display: block;
                width: 155px;
                height: 86px;
                position: absolute;
                top: -20px;
                right: -20px;
                background-color: $color-white;
                border: 1px solid #d1d3d4;
                z-index: -1;
            }
        }

        &-panel {
            display: none;
        }

        &.open &-panel {
            display: block;
            position: absolute;
            top: 65px;
            right: -20px;
            width: 470px;
            height: auto;
            background-color: $color-white;
            padding: 30px;
            border: 1px solid $color-light-gray;
            white-space: normal;
            z-index: 1000; // super high to go above bookmarks

            // white strip to hide border between open tip panel and button
            &:before {
                content: "";
                display: block;
                width: 153px;
                height: 10px;
                position: absolute;
                top: -5px;
                right: 0;
                background-color: $color-white;
            }

            .search-bar__close-tips {
                float: right;

                svg {
                    fill: $color-zeus-black;
                }
            }

            h3 {
                padding-bottom: 20px;
            }

            *:not(h3) {
                @include textMix('PT Serif', 15px, 400);
                line-height: 25px;
            }
        }
    }

    &__footer {
        display: block;
        padding-top: 20px;
        width: 100%;

        &-option {
            display: inline-block;
            margin-right: 1.5rem;
            vertical-align: middle;

            input[type=checkbox] {
                vertical-align: middle;
            }

            @include breakpoint('small') {
                display: block;
                margin-bottom: 0;
                margin-right: 0;
            }
        }
    }

    &__headline-label {
        @include textMix('Roboto', 14px, 400);
        display: inline-block;
        padding-left: 0.5rem;
        vertical-align: middle;
    }

    &__icon {
        cursor: pointer;

        &--search {
            @include svg-fill($color-white);
            height: 20px;
            width: 20px;
            //  vertical-align: middle;
        }

        &--clear {
            @include svg-fill($color-dusty-gray);
            width: 19px;
            height: 19px;
        }

        &--info {
            @include svg-fill($color-dusty-gray);
            height: 24px;
            width: 24px;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}
