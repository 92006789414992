
@import "./omdia/variables";
@import "./omdia/variables-marketing-enhancements";
@import "./omdia/mixins";
/******************** START - New Layout styles  ********************/
//Will be included all informa vertical pages

@include theme-links($color-primary-default);

.inf-container {
  @include inf-container();
}

.inf-container-fluid {
  @include inf-container-fluid();
}

/* Stacks all items horizontally*/
.inf-row-fixed {
  @include inf-row();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.inf-align-baseline {
  align-items: baseline;
}

.inf-justify-center {
  @include inf-justify-center();
}

/* Column Container */
.inf-row {
  @include inf-row();
}

/* Row container */
.inf-col-fluid {
  @include inf-col-fluid();
}

.inf-col {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

/* Style the top navigation bar */
.inf-navbar {
  display: flex;
  align-items: baseline;
}

.inf-bg-white {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.inf-bg-grey {
    background-color: #343a40 !important;
  color: #000000 !important;
}

.inf-bg-light-grey {
  background-color: #F4F4F4 !important;
  color: #000000 !important;

  a {
    color: #000000 !important;
  }

  span {
    text-decoration: none !important;
  }
}

.inf-bg-white {
  background-color: #fff !important;
  color: #000000 !important;
}

.inf-bg-blue {
  background-color: #1d1a28 !important;
  color: #fff !important;
}

.inf-bg-dark {
  background-color: #1D1A28 !important;
  color: #FFFFFF !important;
}

.inf-bg-collection {
  background-color: #58575c !important;
  color: #FFFFFF !important;
  a {
    color: #FFFFFF !important;
  }
  .product-listing-cta, .product-listing-modal {
    background-color: #58575c !important;
  }
  .action-flag__icon {
    fill: #FFFFFF !important;
    &--information-icon {
      width: 19px;
      height: 19px;
      margin-right: 5px;
      vertical-align: text-bottom;
    }
  }
  .information__tips {
    position: relative;
    display: inline-block;
    text-align: left;

    & > .information__tips-panel {
      display: none;
      position: absolute;
      border: 1px solid $omd-medium-grey;
      white-space: normal;
      z-index: 1000;
      right: auto;
      left: -20px;
      top: calc(100% + 15px);
      width: 450px;
      background: $omd-light-grey;
      color: $color-black;
      @include spacer(padding, map-get($spacers, 0));
      text-transform: initial;
      line-height: normal;
      font-family: $font-family-opensans;
      font-size: $font-size-12;
      font-weight: normal;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        top: -3px;
        left: 25px;
        right: auto;
        transform: rotate(45deg) translateX(-50%);
        border-left: 1px solid $omd-medium-grey;
        border-top: 1px solid $omd-medium-grey;
        background: #eee;
      }
      & > p {
        line-height: normal;
        font-family: $font-family-opensans;
        font-size: $font-size-12;
        font-weight: normal;
      }
    }
    &.open {
      position: relative;
      z-index: 1;
      & > .information__tips-panel {
        display: block;
        & > :not(h3) {
          font-family: "Open Sans",sans-serif;
          line-height: $line-height-125;
        }
      }
    }
  }
}

/* Style the navigation bar links */
.inf-navbar a {
  color: white !important;
  padding: 14px 20px;
  text-decoration: none;
  text-align: center;
}

/* Change color on hover */
.inf-navbar a:hover {
  background-color: #ddd;
  color: black !important;
}

.inf-col-align-right {
  display: flex;
  justify-content: flex-end;
}

/* Main column */
.inf-col-main {
  flex: 70%;
  padding: 20px;
}

/* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 700px) {
  .inf-navbar {
    flex-direction: column;
  }
}

.inf-text-center {
  text-align: center;
}

.inf-text-left {
  text-align: left;
}

.inf-text-right {
  text-align: right;
}

.inf-bg-black {
  background-color: #000000;
  color: #ffffff !important;
  a.secondary {
    color: #FFFFFF !important;
  }
}

.inf-bg-black .omd-banner a {
  color: #FFFFFF !important;
}

.inf-side-pad-10 {
  padding: 0 10px;
}

.inf-row-center {
  justify-content: center;
}

hr {
  background-color: black;
  width: 100%;
}

.omd-further-reading {
  .linedot {
    overflow: hidden;
  
    &::before, &::after {
      content: '';
      float: left;
      width: 36px;
      height: 3px;
      border-radius: 1.5px;
      background: #b085ea;
      background: -moz-linear-gradient(left, #0be8ee 0%, #49acf8 22%, #3472e4 53%, #b085ea 73%);
      background: -webkit-linear-gradient(left, #0be8ee 0%,#49acf8 22%,#3472e4 53%,#b085ea 73%);
      background: linear-gradient(to right, #0be8ee 0%,#49acf8 22%,#3472e4 53%,#b085ea 73%);
    }
  
    &::after {
      width: 3px;
      margin-left: 3px;
    }
  }
}

.rte {
  & > *:last-child {
    margin-bottom: 0;
  }
  
  a {
    @extend .omd-inline-anchor;
    font-size: inherit !important;
    letter-spacing: inherit !important;
    line-height: inherit !important;
  }
}

.main--solo iframe {
  max-width: 100%;
}

.theme-omdia {
  @import 'omdia/legacy'; //Overriding legacy layout classes
  /******************** START - New Omdia styles  ********************/
  @import 'omdia/base';
  @import "./omdia/type";
  @import "./omdia/btn";
  @import "./omdia/component/header";
  @import "./omdia/component/banner";
  @import "./omdia/component/author";
  @import "./omdia/component/product_banner";
  @import "./omdia/component/asset_body";
  @import "./omdia/component/further_reading";
  @import "./omdia/component/cadenced_assets_versions";
  @import "./omdia/component/accordian";
  @import "./omdia/component/inpage-nav";
  @import "./omdia/component/_service-area-component";
  @import "./omdia/component/_ask_an_analyst";
  @import "./omdia/component/events";
  @import "./omdia/component/marketlist";
  @import "./omdia/component/_footer";
  @import "./omdia/component/cookie-banner";
  @import "./omdia/component/word-pdf-generation";
  @import "./omdia/component/commissioned-research";
  @import "./omdia/component/product_listing";
  @import "./omdia/component/timeline";
  @import "./omdia/component/tabs";
  @import "./omdia/component/_collection-section";
  @import "./omdia/component/_omdia_recommends";
  @import "./omdia/component/_modal";
  @import "./omdia/component/_bookmark";
  @import "./omdia/component/fluid-carousel";
  @import "./omdia/component/cta-banner";
  @import "./omdia/component/image-icon-list";
  @import "./omdia/component/split-banner";
  @import "./omdia/component/solutions";
  @import "./omdia/component/statistics-bar";
  @import "./omdia/component/upcoming-events-webinar";
  @import "./omdia/component/insights";
  @import "./omdia/component/header-banner";
  @import "./omdia/component/our-analysts";
  @import "./omdia/component/custom-modal";
  @import "./omdia/component/tile-list-banner";
  @import "./omdia/component/insights-list";
  @import "./omdia/component/general-rte";
  @import "./omdia/component/press-center";
  @import "./omdia/component/blogs";
  @import "./omdia/component/card-list-banner";
  @import "./omdia/component/video-carousel";
  @import "./omdia/component/video-card";
  @import "./omdia/component/more-solutions";
  @import "./omdia/component/blog-single";
  @import "./omdia/component/register-banner-cta";
  @import "./omdia/component/contact";
  @import "./omdia/component/details-card.scss";
  @import "./omdia/component/case-studies-carousel";
  @import "./omdia/component/accordion";
  @import "./omdia/component/two-column-accordion-with-data";
  @import "./omdia/component/two-column-accordion";
  @import "./omdia/component/become-client-custom-popup";
  @import "./omdia/component/pr-single";
  @import "./omdia/component/rss-feed";
  @import "./omdia/component/featured-research";
  @import "./omdia/component/subscribe";
  // start comman omdia styles
  @import "./omdia/component/arrow-list";
  @import "./omdia/component/tile-list";
  @import "./omdia/component/card-list";
  @import "./omdia/component/pagination-list";
  @import "./omdia/component/custom-select";
  @import "./omdia/component/image-grid";
  // end comman omdia styles
  // start legacy component overrides
  @import "./omdia/component/article_call_to_action";
  @import "./omdia/component/article_call_to_action_v2";
  @import "./omdia/component/listable_flexible_widget";
  @import "./omdia/component/search-result";
  @import "./omdia/component/search";
  @import "./omdia/component/dashboard";
  @import "./omdia/component/related_products";
  @import "./omdia/component/mkt_modules";
  @import "./omdia/component/searchbar";
  @import "./omdia/component/enrolment";
  @import "./omdia/component/profile";
  @import "./omdia/component/topic-subtopic";
  @import "./omdia/component/dashboard-setting";
  @import "./omdia/component/informa-bar";
  @import "./omdia/component/article-preview";
  // end legacy component overrides
  @import "./omdia/component/_product-directory";
  @import "./omdia/component/action-flags";
  /******************** END - New Omdia styles  ********************/

  @include theme-links($color-primary-default);

  @include theme-tabing ($omd-medium-grey, $color-black, $omd-light-grey);

  @include theme-buttons(
    // $border
    $color-black,
    // $background
    $color-white,
    // $text
    $color-black,
    // $border-hover: $border
    $color-white,
    // $background-hover: $background
    $color-black,
    // $text-hover: $text
    $color-white
  );

  // Buttons
  .cta {
    @include cta();
    
    &-arrow-right {
      @include cta-arrow-right();
    }
    &-primary {
      @include cta-primary();
    }
    &-secondary {
      @include cta-secondary();
    }
    &-solid {
      @include cta-solid();
    }
    &-large {
      @include cta-large();
    }
    &-small {
      @include cta-small();
    }
    
    & > span {
      position: relative;
      &.cta-arrow-right {
        padding-right: map-get($spacers, 0)*2.25 !important;
        &::after {
          right: 0 !important;
        }
      }
    }
  }  
}
