/*---
title: Buttons
section: UI Elements
---
```example:html
<button class="button--filled">Button</button>
<button class="button--outline">Button</button>
```
*/

.button,
a.button {
	&,
	&--filled,
	&--outlined,
	&--outline,
	&--gilded {
		@include smooth-transition;
		@include textMix('Roboto', 12px, 700);
		border: 0;
		color: $color-zeus-black;
		letter-spacing: 1px;
		padding: 12px 16px;
		text-transform: uppercase;
	}

	&--filled {
		background-color: $color-yellow-orange;

		&:hover {
			background-color: $color-tree-poppy-orange;
            text-decoration:none;
		}

		&:disabled {
			background-color: $color-concrete-gray;
			color: $color-dove-gray;
		}
	}

	&--outline:enabled {
		@include smooth-transition(all, 50ms, ease-out);
		background-color: $color-white;
		border: 1px solid $color-yellow-orange;

		&:hover {
			border-color: $color-tree-poppy-orange;
			background-color: $color-tree-poppy-orange;
		}
	}
	&--outline:not([href]){
		border: 1px solid $color-yellow-orange;
        &:hover {
			border-color: $color-tree-poppy-orange;
			background-color: $color-tree-poppy-orange;
        }
	}

	&--gilded {
		background-color: $color-white;
		border: 1px solid $color-goldenrod;
	}

	// used for "unstyling" buttons
	&--plain:enabled {
		background: transparent;
		border: 0;
	}

	&--fly-right {
		float: right;
	}

	&:focus {
		outline: 0;
	}

}


/*---
title: Full Diminished Button
section: UI Elements
---
This isn't a "real" button; rather, a link styled like many other buttons on the site.
```example:html
<a class="full-diminished-button" href="#">
	See All
</a>
```
*/

.full-diminished-button {
	@extend .button;
	background-color: $color-concrete-gray;
	color: $color-zeus-black;
	cursor: pointer;
	display: block;
	margin-bottom: 3rem;
	text-align: center;
	width: 100%;
}


.onoffswitch {
	display: inline-block;
	vertical-align: middle;
	min-width: 47px;
    position: relative;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;

	&-checkbox {
		display: none;
	}

    // TODO: ask if colors need to be exact from designs, or should be reused from preexisting
	&-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
	    border: 1px solid $color-x11-gray; // also try $color-light-gray
		border-radius: 11px;
		background-color: $color-white;
	}

	&-inner {
		display: block;
		width: 200%;
		margin-left: -2px;
	    transition: margin 0.3s ease-in 0s;

		&:before,
		&:after {
			@include textMix('Roboto', 9px, 400);
		    display: block;
			float: left;
			width: 50%;
			height: 22px;
			padding: 0;
			line-height: 22px;
			color: $color-white;
		    box-sizing: border-box;
		}

		&:before {
			content: "OFF";
		    padding-left: 9px;
		    background-color: $color-white;
			color: $color-old-silver-gray; // also try $color-dusty-gray
		}

		&:after {
			content: "ON";
		    padding-right: 9px;
		    background-color: $color-white;
			color: $color-old-silver-gray; // also try $color-dusty-gray
		    text-align: right;
		}
	}

	&-switch {
	    display: block;
		width: 14px;
		height: 14px;
		margin: 4px;
	    background: $color-light-gray;
	    position: absolute;
		top: 1px;
		bottom: 0;
	    left: 24px;
	    border-radius: 50%;
	    transition: all 0.3s ease-in 0s;
	}

	&-checkbox:checked + &-label &-inner {
		margin-left: -46px;
	}

	&-checkbox:checked + &-label &-switch {
		left: 0;
		background: $color-yellow-orange;
	}

	// Submit button is hidden, only used
	&__submit {
		display: none;
	}
}

a.docdownload {
	&.button--filled {
		padding: 10px 12px 10px 8px;
	}
	svg {
		fill: white;
		display: inline-block;
		vertical-align: bottom;
		margin-right: .25rem;
	}
	span {
		display: inline-block;
		vertical-align: bottom;
		line-height: 1.3;
		text-transform: none;
	}
}