@mixin folow-btn($w, $h, $c, $bc){
	border: 1px solid $bc;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 0.75em;
	font-family: "Roboto";
	font-weight: 700;
	text-align: center;
	display: inline-block;
    line-height: $h;
	float: right;
	color: $c;
	width: $w;
	min-height: $h;
}
@mixin folow-btn-lbl($c){
	text-transform: uppercase;
	@include textMix('Roboto', 0.75rem, 700);
	display: inline-block; 
	float: right;
	text-align: right;
	color: $c;
}
@mixin tableData($px){ 
	padding: 0.75rem 1.25rem;
	line-height: 1.5rem;
	vertical-align: top;
	border-top: $px solid #ddd;
}
@mixin getMove($bg, $mtp){
	float: right;
	margin-top: $mtp;
	.pull-left{
		float: left;
		@include breakpoint('medium-small') {
			height: 1.5rem;
			width: 1.5rem;
			display: block;
		}
	}
	span{
		height: 0.125rem;
		width: 1.3125rem;
		background: $bg;
		display: block;
		margin-bottom: 3px;
	} 
}
@mixin unfollowBtnLbl($c){
	@include folow-btn(9.375rem, 1.875rem, $c, none);
	margin: 0.5rem 0 0 1.25rem;
	&.mtp{
		margin-top: 0px;
	}
}
@mixin followBtnLbl(){
	@include folow-btn(9.375rem, 1.875rem, #FF8F18, none);
	padding: 0 1rem;
	margin-top: 0.5rem;
	&.mtp{
		margin-top: 0px;
	}
}

.myview-settings {
	padding-bottom: 50px !important;
	margin-bottom: 3.125rem;
	
	.followAllBtn{
		@include followBtnLbl();
		min-width: 130px;
		margin-left: 12px;
		float: none;
		border: 1px solid #fab142;
		@include breakpoint('medium-small') {
			min-width: initial;
			min-width: auto;
		}
		@include breakpoint('small') {
			min-width: initial;
			min-width: auto;
		}
	}
	.followinglbl{
		@include folow-btn-lbl(#f7941e); 
		@include breakpoint('medium-small') {
			margin: 0px !important;
			float: left;
		}
	}
	.unfollowAllBtn{
		@include unfollowBtnLbl(#58595b);
		padding: 0 1rem;
		margin-left: 0.5rem;
		border: 1px solid #ADADAD;
		@media screen and (min-width: 600px){
			float: none;
		}
		@include breakpoint('medium-small') {
			min-width: initial;
			min-width: auto;
		}
		@include breakpoint('small') {
			min-width: initial;
			min-width: auto;
		}
		@media screen and (min-width: 667px) and (max-width: 767px){
			padding: 0 1.25rem;
		}
	}
	.followlbl{
		@include folow-btn-lbl(#58595B);
		padding: 0;
		cursor: text;
		//min-width: 130px;
		@include breakpoint('medium-small') {
			min-width: initial;
			min-width: auto;
		}
		@include breakpoint('small') {
			min-width: initial;
			min-width: auto;
		}
		@include breakpoint('medium-small') {
			margin: 0px !important;
			float: left;
		}
	}
	.followingBtn{
		@include folow-btn(9.375rem, 1.875rem, #58595b, $color-tree-poppy-orange);
		padding: 0 1rem;
		//background: $color-tree-poppy-orange;
		color: $color-white;
		&:hover{
			background: #a7a7a7;
			border: 1px solid #a7a7a7;
			@media screen and (min-width: 768px) and (max-width: 1024px){
				border: 1px solid $color-tree-poppy-orange;
				color: $color-white;
				background: $color-tree-poppy-orange;
			}
			@include breakpoint('small') {
				background: url(/dist/img/sprite-image.png) no-repeat -3px -235px;
			}
		} 
		@include breakpoint('medium-small'){
			background: url(/dist/img/sprite-image.png) no-repeat -3px -235px;
			height: 1.875rem;
			width: 1.875rem;
			border: none !important;
			padding: 0 1rem;
			display: inline-block;
			margin-left: 0.625rem;
			text-indent: -9999px;
		}
	}
	.followBtn{
		@include folow-btn(9.375rem, 1.875rem, #58595b, #ADADAD);
		padding: 0 1rem;
		@include breakpoint('medium-small'){
			background: url(/dist/img/sprite-image.png) no-repeat -3px -266px;
			height: 1.875rem;
			width: 1.875rem;
			display: inline-block;
			margin-left: 0.625rem;
			border: none !important;
			padding: 0 1rem;
			text-indent: -9999px;
		}
		&:hover{
			background: #ff8f18;
			color: #fff;
			border: 1px solid $color-tree-poppy-orange;
			@include breakpoint('medium-small'){
				background: url(/dist/img/sprite-image.png) no-repeat -3px -266px;
			}
			@media screen and (min-width: 768px) and (max-width: 1024px){
				border: 1px solid #ADADAD;
				color: #58595b;
				background: none;
			}
		}
	}
	&.myview-settings-registration {
		.publicationPan h2 {
			//width: auto;
		}
		.saveoption {
			width: 100%;
		}
	} 
	h1{
		margin-bottom: 0rem;
		float: left;
		@include breakpoint('medium-small') {
			font-size: 2rem;
		}
	}
	.onoffswitch {
		margin-top: 14px;
		margin-left: 10px;
		&-label {
			width: calc(50% + 22px);
		}
		&-inner {
			&:before,
			&:after {
				display: none;
			}
		}
		&-off, &-on {
			@include textMix('Roboto', 9px, 400);
		  display: block;
			float: left;
			width: 50%;
			height: 22px;
			padding: 0;
			line-height: 22px;
			color: $color-white;
		  box-sizing: border-box;
		}
		&-off {
			padding-left: 9px;
			background-color: $color-white;
			color: $color-old-silver-gray; // also try $color-dusty-gray
		}

		&-on {
			padding-right: 9px;
			background-color: $color-white;
			color: $color-old-silver-gray; // also try $color-dusty-gray
			text-align: right;
		}
		&-switch {
			left: 50%;
		}
		.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
			margin-left: -100%;
		}
	}
	.mysettingsHead{
		border-bottom: 1px solid #d1d3d4;
		padding-bottom: 15px;
		margin-bottom: 1.25rem;
		padding-top: 50px;
		@include breakpoint('small') {
			margin-bottom: 0rem;
			border-bottom: none;
			padding-top: 0;
		}
	}
	.mtop-10{
		margin-top: 0.625rem;
	}
	.mb-10{
		margin: 0.625rem 0 1.25rem;
	}
	.desktophide{
		display: none !important;
	}
	.sub-heading-text{
		font-size: 1.125em;
		margin-bottom: 50px;
		p{
			line-height: 1rem;
			font-style: normal;
			font-family: "PT Serif Caption";
			font-weight: 400;
			font-size: 1.125em;
			@include breakpoint('medium-small') {
				font-family: "Roboto";
				font-size: 0.85em;
				margin: 0rem;
				padding: 0rem;
				line-height: 1.375rem;
			}
		}
	}
	.publicationPan{
		margin-bottom: 0.625rem;
		border: 1px solid #d9dadb;
		&.active{
			border: 1px solid #EE9500;
		}
		h2{
			text-align: left;
			float: left;
			width: 26.25rem; 
			color: #58595B;
			@include breakpoint('medium-small') {
				font-size: 1.25em;
				margin-bottom: 0.75rem;
				margin-top: 0px;
				float: none;
				width: auto;
				line-height: 1.625rem;
			}
		}
		.subscribed{
			border-left: 1px solid #d9dadb;
			float: left;
			cursor: pointer;
			padding-left: 1.25rem;
			vertical-align: middle; 
			position: relative; 
			@include textMix('Roboto Condensed', 0.875rem, 700);
			
			@include breakpoint('medium-small') {
				font-size: 0.875em;
				border-left: none;				
				padding-left: 0rem;
				margin: 0rem;
			}
			a{
				//border-left: 1px solid #d9dadb;
				float: left;
				cursor: pointer;
				//padding-left: 1.25rem;
				vertical-align: middle; 
				position: relative; 
				color: #58595B;
				text-transform: uppercase;
				@include textMix('Roboto Condensed', 0.875rem, 700);
				&:hover{
					color: #58595B;
				}
			} 
		}
	}
	.table-responsive {
		width: 100%;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		.table{
			border-collapse: collapse;
			width: 100%;
			max-width: 100%;
			font-family: "Roboto Condensed";
			font-style: normal;
			@include breakpoint('small') {
				display: block;
				position: relative;
			}
			tbody{
				 tr{
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					-ms-transition: all 0.2s ease;
					transition: all 0.2s ease; 
					td{
						@include tableData(1px);
						vertical-align: middle;
					} 
					&.followingrow{
						.rowlines{
							@include getMove(#ff8f18, 0.4375rem);
							position: relative;
						} 
						&:hover{
							background: #FFF5ED;
							@include breakpoint('large') {
								background: #fff !important;
							}
							cursor: move; 
							& .rowlines{ 
								span{
									@include breakpoint('large'){
										background: #d1d3d5;
									}
								}  
							} 
						}
						.rowlines{ 
							.move{
								float: left;
								margin-right: 0.625rem;
								position: absolute;
								top: -4px; 
								right: 21px;
								color: #ff8f18;
								text-transform: uppercase;
								font-size: 0.875em;
								display: none;
								@include breakpoint('small') {
									z-index: -1;
								} 
							}
							&:hover{
								.move{
									display: block;
								}
							}
						}
					}
					&.followrow{
						background: #F6F6F6;
						@include breakpoint('small') {
							background: $color-white;
						}
						.rowlines{
							@include getMove(#979797, 0.4375rem);
						}
						&.frow{
							border-top: 1px solid #D9D9D9;
							@include breakpoint('small') {
								border-top: none;
							}
						}
						& .move{
							display: none;
						}
						&:hover{
							background: #EDEDED;
						}
					}
				 } 
			}
			thead{ 
				&.followbg{
					background: #f9f9f9;
					.expandTxt{
						color: #58595B;
					}
					.hideinDesk{ 
						background: url(/dist/img/drag-icon.jpg) no-repeat 0 0;
					}
				}
				&.followingbg{
					background: #fff;
					.expandTxt{
						color: #f7941e;
					}
					.rowlines{
						.mv{
							background: #f7941e;
						}
					}
					.hideinDesk{ 
						background: url(/dist/img/drag-icon.jpg) no-repeat 0 -51px;
					}
				}
				tr{
					&.expanded{
						@media screen and (min-width: 800px){
							h2{
								font-size: 30px !important;
								color: #000;
							}
							.subscribed a{
								font-size: 17px !important;
							} 
						}
					} 
					@include breakpoint('small') {
						&.expandHide{
							display: none;
							&.collapseshow{
								display: block !important;
							}
						}
					}
					
				}
				&.followinglbl-txt{
					.collapsed{
						.active{
							@media screen and (min-width: 1025px){
								background: #fff5ed;
							}
						}
					}
				}
				th{
					@media screen and (max-width: 600px){
						background: #f9f9f9;
					}
					@include tableData(0px);
					@include breakpoint('small') {
						padding: 0.5rem 0rem 0.625rem 0.625rem;
					}
					p{
						font-size: 1em; 
						text-align: left;
						padding: 0.75rem 0 0;
						font-weight: normal;
						@include breakpoint('medium-small') {
							padding: 0;
							font-size: 1em;
							font-weight: normal;
						}
						
						&.nosubscr{
							margin: -40px 0 0;
							font-weight: normal;
						} 
					} 
					&.firstrow{
						padding: 1.25rem;
						h2{
							@include textMix('Roboto Condensed', 1.25rem, 700);
						}
						@include breakpoint('medium-small') {
							padding: 1.25rem 0.625rem 0.625rem;
						}
						.frTxt{
							text-align: right;
							width: 200px;
						}
					}
					&.nopad{
						padding: 0rem 1.25rem;
						@include breakpoint('small') {
							padding:  0rem 0.625rem;
						}
					}
					&.pbot{
						padding: 0rem 1rem 1.25rem 1.25rem;
					}
					.mvTxt{
						@include textMix('Roboto Condensed', 0.875rem, 400);
						visibility: hidden;
						float: left;
						width: 50px;
						text-transform: uppercase;
						text-align: right; 
						color: #f7941e;
						@media screen and (min-width: 768px) and (max-width: 1024px){
                            display: none;
						}
					}
					& .rowlines{
						@include getMove(#979797, 0rem); 
						float: right;
						&.hideinDesk{
							display: none;
							@media screen and (min-width: 768px) and (max-width: 1024px){ 
								height: 50px;
								width: 21px;
								display: block;
								margin-top: -20px; 
							}
							@media screen and (max-width: 768px){
								margin-top: -40px; 
							}
							.mv{
								background: none !important;
							}
						}
						&.hideinTab{
							display: none;
							@media screen and (min-width: 1025px){ 
								display: block;
								margin-top: 3px;
							}
						}
						.mv{
							@include breakpoint('medium-small') {
								background: url(/dist/img/drag-icon2.jpg) no-repeat 0 0;
								height: 40px;
								width: 40px;
								position: relative;
								left: -15px;
							}
						} 
						@include breakpoint('medium-small') {
							top: 4px;
							margin-top: 0px;
						}
						@include breakpoint('large') {
							height: 30px;
							width: 30px;
						} 
					} 
					 .accordionImg{
						@media screen and (min-width: 800px){
							width: 120px;
						}
						float: left;
						text-align: right;
						@media screen and (max-width: 480px){
							float: right;
							margin-right: 20px;
						}
						.mobileMode { 
							height: 1.0625rem;
							width: 2rem;
						}
						.expandTxt{
							visibility: hidden;
							float: left;
							padding-left: 35px;
							text-align: right;
							width: 5.0625rem;
							position: relative;
							top: -1px;
							@include textMix('Roboto Condensed', 0.875rem, 400);
							text-transform: uppercase;
							@media screen and (min-width: 768px) and (max-width: 1024px){
								display: none;
							} 
						}
						@media screen and (max-width: 600px){
							span{
								background: url(/dist/img/sprite-image.png) no-repeat 0 -21px;
								height: 1.0625rem;
								width: 2rem;
								cursor: pointer;
								display: inline-block;
								margin-right: 10%;
								@include breakpoint('medium-small') {
									position: relative;
									left: -10px;
									&.expanded{
										position: relative;
										left: 0; 
										background-position: 0 0 !important;
									}
								} 
								&.expanded{
									background-position: 0 0;
								} 
							}
						}
						@media screen and (max-width: 480px){
							span{
								left: 0px;
							}
						}
						span.accImg{
							float: left;
							@media screen and (min-width: 600px){
								margin-top: -15px;
							}
							position: relative;
							top: 8px;
							@media screen and (min-width: 768px) and (max-width: 1024px){
								margin-left: 10px;
							}
							.sorting_arrow--up{
								height: 32px;
								width: 32px;
								fill: #ababab; 
								webkit-transform: rotate(-180deg);
								transform: rotate(-180deg);
								&.hide{
									display: none;
								}
							}
							.sorting_arrow--down{
								height: 32px;
								width: 32px; 
								fill: #ababab; 
								webkit-transform: rotate(0deg);
								transform: rotate(0deg);
								&.act{
									fill: #ff8f18;
								}
								&.hide{
									display: none;
								}
							}
							/*background: url(/dist/img/sprite-image.png) no-repeat -16px -142px;
							height: 10px;
							width: 12px;*/
							cursor: pointer;
							display: inline-block; 
							@include breakpoint('medium-small') {
								background: url(/dist/img/sprite-image.png) no-repeat 0 -21px;
								/*height: 0.75rem;
								width: 1.4375rem;*/
								position: relative;
								left: -10px;
								&.expanded{
									position: relative;
									left: 0;
									/*background-position: 0 -48px !important;*/
									background-position: 0 0 !important;
								}
							}
							@media screen and (min-width: 667px) and (max-width: 767px){
								margin-right: 3.125rem;
							}
							&.expanded{
								background-position: 0 -159px;
							}
							@media screen and (min-width: 801px) and (max-width: 1130px){
								margin-right: 50%;
							}
						}
					}
					&.notsubscr{
						text-align: left;
						padding-bottom: 1.5rem;
						padding-top: 0rem;
					}
				}
			}
			.wd-55{
				width: 55%;
				@include breakpoint('small') {
					width: 65%;
				}
				@include breakpoint('medium-small') {
					width: 45%;
					padding: 12px 20px 12px 10px;
				}
			}
			.wd-25{
				width: 20%;
				&.pbot{
					padding-left: 0rem;
				}
				@include breakpoint('small') {
					width: 20%;
					padding: 0.5rem 0rem 0rem 0.625rem;
					text-align: right;
				}
				@include breakpoint('medium-small') {
					width: 25%;
				}
			}
			.wd-15{
				width: 20%;
				@include breakpoint('small') {
					width: 10%;
				}
				@include breakpoint('medium-small') {
					width: 15%;
				}
			}
			.graybg{
				background: #f9fafa;
			}
		}
  }
  .hideBtn{
	  display: none !important;
	  visibility: hidden;
  }
  .saveoption{
	  float: right;
	  @include breakpoint('small') {
	  	width: 100%;
	  }
	  .gotoview{ 
		float: left;
		line-height: 2rem;
		a{
			padding: 9px 16px;
		}	
	  }
	  .complete-settings{
			display: inline-block;
			float: right;
		}
	  .saveview, .registrationBtn{
		padding: 0 2rem;
		 @include folow-btn(auto, 2rem, $color-white, $color-tree-poppy-orange);
		  margin-left: 1.25rem;
		  border: none;
		  @include breakpoint('small') {
			float: right;
			margin: 0px;
			padding: 0 2.5rem;
		  }
	  }
  }
  
  .dosubscribe{
	& .followrow{
		background: #f9f9f9;
		@include breakpoint('small') {
			background: $color-white;
		}
		& .rowlines{
			display: none;
		}
	}
  }
  & .hidden{
	display: none !important;
  }
  .vh{
	visibility: hidden;
	background: #f9f9f9;
  }
    .modal-view {
	    position: fixed;
		width: 27rem;
		height: 17.5rem;
		top: 50%;
		left: 50%;
		z-index: 9999;
		margin-left: -13.5rem;
		margin-top: -8.75rem;
		background: $color-white;
		padding: 4.375rem 2.5rem 2.5rem;
		display: none;
		font-family: "Roboto";
		@media screen and (max-width: 480px){
			width: 21.875rem;
			margin-left: -10.9375rem;
		}
		@media screen and (max-width: 320px){
			width: 90%;
			margin-left: 5%;
			left: 0px;
		}
		&.preferences{
			h3{ 
				@include textMix('Roboto', 1.2rem, 700);
				text-align: center;
				margin: 10px 0 20px;
				color: #231F20;
			}
			h4{
				@include textMix('Roboto', 0.875rem, 400);
				margin-bottom: 2rem;
				color: #626262;
				font-weight: normal;
				text-align: center;
			}
			.buttonsPan{
				width: 17.5rem;
				margin: 0px auto;
			}
			.saveview{ 
				@include textMix('Roboto', 0.6875rem, 700);
				text-align: center;
				float: left; 
				width: 4.375rem;
				text-transform: uppercase; 
				cursor: pointer;
				line-height: 2rem; 
				padding: 0px;
				float: left;
				width: 8.125rem;
				height: 2rem;
				color: #fff;
				margin-right: 20px;
				@media screen and (max-width: 320px){
					width: 90px;
				}
			}
			.registrationBtn.validationChk{
				@include textMix('Roboto', 0.6875rem, 700);
				text-align: center;
				float: left; 
				width: 4.375rem;
				text-transform: uppercase; 
				cursor: pointer;
				line-height: 2rem; 
				padding: 0px;
				float: left;
				width: 8.125rem;
				height: 2rem;
				// color: #f8; <<< unknown expected value
				margin-right: 20px;
			}
			.cancel{
				@include textMix('Roboto', 0.6875rem, 700);
				text-align: center;
				float: left;
				width: 8.125rem;
				height: 2rem;
				text-transform: uppercase; 
				cursor: pointer;
				line-height: 2rem; 
				padding: 0px;
				color: #747474;
				border: 1px solid #adadad;
				@media screen and (max-width: 320px){
					width: 90px;
				}
			}
		}
	  .modal-inner {
			display: inline-block;
			text-align: left;
			background-color: #eee;
			color: #000;
			padding: 1.25rem;
			
			@include breakpoint('small') {
				padding: 0.625rem;
			}
		}

		.close-modal {
			position: absolute;
			right: 0.625rem;
			top: 0.625rem;
			color: $color-zeus-black;
			font-family: "Roboto";
			font-weight: bold; 
			cursor: pointer;
			text-transform: uppercase;
			background: url(/dist/img/sprite-image.png) no-repeat right -72px top -592px;
			font-size: 0.625rem;
			padding-right: 1rem;
			height: 14px;
			width: 14px;
		}
	}
	.modal-overlay{
		&.in{
			background: #000;
			height: 100%;
			width: 100%;
			position: fixed;
			top: 0rem;
			left: 0rem;
			z-index: 9990;
			opacity: 0.5;
		}
	}
	.hidden-large{
		display: none;
	}
	
	.alert-success{
		margin-bottom: 35px;
	}
	.alert-error{
		margin-bottom: 35px;
		margin-top: -20px;
	}
	.tbodyhidden{
		display: none;
	}	
}

@include breakpoint('medium-small') {
	.myview-settings{ 
		.hidden-large{
			display: table-row-group !important;
		}
	}
}

@include breakpoint('medium') {
	.myview-settings{
		.desktopMode{
			margin-left: -1.875rem;
		}
		.firstrow{
			h2{
				width: auto;
			}
		}
	}
}
@media screen and (min-width: 800px) and (max-width: 1024px){
	.myview-settings{
		.firstrow h2{
			width: 20.625rem;
		}
	}
}
@media screen and (min-width: 767px) and (max-width: 768px){
	.myview-settings{
		.firstrow h2{
			width: 10rem;
		}
	}
}
@media screen and (min-width: 800px) and (max-width: 900px){
	.myview-settings{
		.desktopMode{
			margin-left: -2.5rem;
		}
	}
}
@media screen and (min-width: 640px) and (max-width: 900px){
	.myview-settings{
		.desktopMode{
			margin-left: -3.4375rem;
		}
	}
}

@media screen and (min-width: 480px) and (max-width: 640px){
	.myview-settings{
		.accordionStatus{
			&.hideRow{
				display: none;
			}
		}
	}
}
@include breakpoint('small') {
	.myview-settings{ 
		.smfollowingBtn{
			margin-top: -0.5rem;

			.unfollowAllBtn{
				background-position: -3px -236px;
				text-indent: -8.75rem;
				color: $color-abbey-gray !important;
				border: none !important;
				background-color: inherit !important;
				clear: both;
			} 
			.followAllBtn{
				background-position: -3px -266px;
				text-indent: -124px;
				border: none !important;
				padding: 0 0 !important;
				float: right;
				clear: both;
			}
		}
		.accordionStatus{
			&.hideRow{
				display: none;
			}
		}
	}
}
@include breakpoint('medium-small') {
	.hidden-xs{
		display: none !important;
	}
	.smfollowingBtn{
		margin: 0.1rem 0;
		span{
			border: none;
			height: 30px !important;
			width: 30px !important;
			line-height: 1.75rem !important;
			&.unfollowAllBtn{
				background: url(/dist/img/sprite-image.png) no-repeat -3px -236px;
				text-indent: -8.75rem;
				color: $color-abbey-gray !important;
				border: none !important;
				background-color: inherit !important; 
			} 
			&.followAllBtn{
				background: url(/dist/img/sprite-image.png) no-repeat -3px -266px;
				text-indent: -124px;
				border: none !important;
				background-color: inherit !important;
				padding: 0 0 !important;
			}
		}		
	} 
}
  
@include breakpoint('medium') {
	.desktophide{
		display: block !important;
	}
}
.pub-subscription-subtitle{
	text-align: left;
}

@media print {
	.messaging_webUsers_white {
		display: none !important;
	}
	#feedbacklite{
		display: none !important;
	}
}

@media screen and (max-width: 320px){	
	.registrationBtn.completeReg{ 
		line-height: 1.125rem !important;
		margin-bottom: 10px !important;
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
}
@media screen and (min-width: 321px) and (max-width: 600px){
	.registrationBtn.completeReg{ 
		margin-bottom: 10px !important;
	}
}

// TODO: move to theme files
@mixin apply-theme($tc, $b, $mobg){
	.myview-settings {
		.publicationPan{
			&.active{
				border: 1px solid $b;
			}
		}
		.followingBtn{ 
			@media screen and (min-width: 480px){
				background: $tc;
				border: 1px solid $tc;
				&:hover{
					background: #a7a7a7;
					border: 1px solid #a7a7a7;
					@media screen and (min-width: 768px) and (max-width: 1024px){
						border: 1px solid $color-tree-poppy-orange;
						color: $color-white;
						background: $color-tree-poppy-orange;
					}
				}
			}
		}
		.followBtn{
 			&:hover{
				@media screen and (min-width: 1025px){
					background: $tc;
					border: 1px solid $tc;
				}
				@media screen and (min-width: 768px) and (max-width: 1024px){
					border: 1px solid #ADADAD;
					color: #58595b;
					background: none;
				}
			}
		}
		.followingrow{
			.rowlines{
				@include getMove($tc !important, 0.4375rem);
			} 
			&:hover{
				background: $mobg !important;
			}
		}
		.followingbg{
			.sorting_arrow--up{
				&.act{
					fill: $tc !important;
				}
			}
			.expandTxt{
				color: $tc !important;
			}
			.rowlines{
				.mv{
					background: $tc !important;
				}
			}
		}
		.followinglbl, .mvTxt{
			color: $tc !important;
		}
		.followAllBtn{
			color: $tc;
		}
		.gotoview{
			a{
				color: $tc !important;
				border:  1px solid $tc !important;
				&:hover{ 
					color: #fff !important;
					//background: $mobg;
				}
			}
		}
		.followinglbl-txt{
			.collapsed{
				.active{
					@media screen and (min-width: 1025px){
						background: $mobg !important;
					}
				}
			}
		}
		.sorting_arrow--down{ 
			&.act{
				fill: $tc !important;
			}
		}
		.subscribed{
			a:hover{
				color: $tc;
			}
		}
		.rowlines{ 
			.move{ 
				color: $tc !important;
			}
		}
		.table{
			span{ 
				fill: $b;
				&.button--outline{
					border: 1px solid $b;
					&:hover{
						border: 1px solid $tc;
					}
				}
				&.button--filled{
					color: #fff;
					&:hover{
						border: 1px solid $tc;
					}
				}
			} 
		}
	}
	/************ drag and drop styles ***********/
	.drop {
		-webkit-animation: drop 1s ease;
		-moz-animation: drop 1s ease;
		-ms-animation: drop 1s ease;
		-o-animation: drop 1s ease;
		animation: drop 1s ease;
	}

	.followingrow.over{ 
		background: $tc;
	}
}
@mixin myview-scrip-medtech($bg, $hoverbg){
	.myview-settings .followAllBtn{
		&:hover{
			background: $hoverbg !important;
			color: #fff !important;
		} 
	}
	.myview-settings .saveoption .registrationBtn, 
	.myview-settings .saveoption .saveview,
	.myview-settings .modal-view.preferences .saveview{
		background: $bg;
		color: #fff;
		&:hover{
			background: $hoverbg;
			color: #fff;
		}
	}
	.myview-settings .gotoview a:hover{
		background: $hoverbg;
	}
	.myview-settings .modal-view.preferences .cancel{
		&:hover{
			background: $hoverbg;
			border: 1px solid $hoverbg;
			color: #fff;
		}
	}
}
.theme-lloydslist{
	@include apply-theme(#1191D1, #1191D1, #e7f4fa);
}
.theme-insurance-day{
	@include apply-theme(#DE4361, #DE4361, #fcecef);
}
.theme-commodities{
	@include apply-theme(#F5821F, #F5821F, #FFF5ED);
}