#searchTop {
  $fraction-change: 0.8;

  $h1-font-size: 3.75rem * $fraction-change;
  $h2-font-size: 3rem * $fraction-change;
  $h3-font-size: 2.5rem * $fraction-change;
  $h4-font-size: 2rem * $fraction-change;
  $h5-font-size: 1.5rem * $fraction-change;
  $h6-font-size: 1.25rem * $fraction-change;
  $font-size-24: 1.5rem * $fraction-change;
  $font-size-20: 1.25rem * $fraction-change;
  $font-size-17: 1.05rem * $fraction-change;
  $font-size-18: 1.125rem * $fraction-change;
  $font-size-16: 1rem * $fraction-change;
  $font-size-14: 0.875rem * $fraction-change;
  $font-size-15: 0.938rem * $fraction-change;
  $font-size-12: 0.75rem * $fraction-change;
  $font-size-36: 2.25rem * $fraction-change;
  $font-size-40: 2.5rem * $fraction-change;
  $font-size-48: 3rem * $fraction-change;
  
  $spacer-0: 1rem * $fraction-change;
  $spacer-1: 1.25rem * $fraction-change;
  $spacer-2: 1.5rem * $fraction-change;
  $spacer-3: 2rem * $fraction-change;
  $spacer-4: 3rem * $fraction-change;
  $spacer-5: 4.5rem * $fraction-change;
  $spacer-6: 5rem * $fraction-change;

  .search-ad-left {
    width: 300px;
    clear: both;
    float: left;
  }
  overflow: hidden;
  @include spacer(margin-bottom, $spacer-5);
  &[ng-cloak] {
    visibility: hidden;
  }
  header.search__header {
    position: relative;
    @include inf-container();
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include spacer(margin-bottom, $spacer-3);
    @include spacer(padding-top, $spacer-4);
    @include spacer(padding-bottom, $spacer-4);
    @include inf-bg-black();
    border-top: none;
    @include isIE {
      display: table;
      width: 100%;
      direction: rtl;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translateX(-50%);
      background-color: $color-black;
      z-index: -1;
    }
    .search__header-title {
      font-size: $font-size-40;
      order: 1;
      @include isIE {
        display: table-cell;
        vertical-align: middle;
        direction: ltr;
      }
      @include breakpoint("medium") {
        margin-top: 0;
      }
    }
    .action-flags {
      display: flex;
      align-items: center;
      order: 2;
      @include isIE {
        display: table-cell;
        vertical-align: middle;
        width: 200px;
        direction: ltr;
      }
      .action-flag {
        display: none;
        padding-top: 0;
        &.js-saved-search-controller {
          display: block;
          margin-left: 0;
          .pop-out__trigger {
            &:hover {
              .action-flag__label {
                border-color: $omd-light-purple;
              }
            }
          }
        }
        & > a {
          color: inherit;
          &.active {
            color: $color-black;
            .action-flag__label {
              border-bottom-color: $color-black;
            }
            .action-flag__icon {
              fill: $color-black;
            }
          }
        }
        &__label {
          margin-right: 4px;
          font-family: inherit;
          font-weight: $font-weight-600;
          color: inherit;
          text-transform: none;
          font-size: $font-size-16;
          letter-spacing: -0.2px;
          border-bottom: 2px solid $color-white;
          transition: all 0.2s;
          &:hover {
            border-color: $omd-light-purple;
          }
        }
        &__icon {
          fill: $color-white;
          z-index: 1;
        }
      }
      .lightbox-modal__title {
        color: $color-black;
        font-size: 24px;
        letter-spacing: -0.94px;
      }
    }

    .alert-success {
      order: 3;
      width: 100%;
      @include spacer(margin-top, $spacer-0);
      font-family: $font-family-opensans !important;
      background-color: $color-white !important;
      color: $color-black !important;
      background-color: #faf9fb;
      border: 1px solid #faf9fb;
      border-radius: 4px;
      p {
        line-height: inherit;
        width: 85%;
        vertical-align: middle;
        font-size: 16px !important;
        font-weight: 500 !important;
        padding: 0;
        margin-right: 0px;
        display: inline-block;
        margin-left: 10px;
      }

      .alert__icon {
        height: 24px;
        width: 24px;
        float: none;
        display: inline-block;
        vertical-align: middle;
        @include svg-fill($omd-purple);
        fill: $color-black !important;
      }
    }
  }
  .search__content {
    @include inf-container();
    flex-flow: row;
    @include breakpoint("lg") {
      flex-direction: column;
      flex-flow: column;
    }
    .search__body {
      margin-left: 0;
      .search-right {
        width: 337px;
        float: left;
        display: flex;
        flex-direction: column;
      }
      @include breakpoint("lg") {
        display: flex;
        flex-direction: column;
        .search-bar {
          order: 1;
        }
        [ng-controller^="InformaFacetController"] {
          order: 2;
        }
        .facets__mobile-button {
          order: 3;
        }
        .search-metadata {
          order: 4;
        }
        .search-sort {
          order: 5;
        }
        .search-results {
          order: 6;
        }
        .search-pagination__wrapper {
          order: 7;
        }
      }
    }
    .facets__mobile-button {
      @include breakpoint("lg") {
        display: block;
        width: 100%;
      }
      padding: 0;
      background-color: transparent;
      @extend .omd-box-bottom;
      border-left-width: 0;
      border-right-width: 0;
      pointer-events: none;
      &.is-expanded {
        margin-top: 1rem;
        border-left-width: 1px;
        border-right-width: 1px;
        h6::after {
          transform: translateY(-50%) rotate(180deg);
          margin-top: -1px;
        }
      }
      &:focus {
        outline: none;
      }
      &--bottom {
        display: none !important;
      }
      h6 {
        @extend .btn;
        @extend .omd-icon-down-chevron;
        @extend .secondary;
        margin-bottom: -22px;
        border-width: 1px;
        background: $color-white;
        pointer-events: all;
        & + * {
          display: none !important;
        }
      }
      &:not(.is-expanded) + .search-metadata .search-header__results-meta {
        @include breakpoint('medium') {
          margin-top: 0;
        }
      }
    }
    .search__facets {
      width: 337px;
      margin-right: 1.5rem;
      padding-block: 0;
      @include spacer(padding-inline, $spacer-1);
      font-family: inherit;
      background-color: $omd-light-grey;
      border: none;
      border-radius: 10px;
      @include breakpoint("lg") {
        float: none;
        width: 100%;
        max-width: none;
        margin-right: 0;
        margin-bottom: 0;
        border-bottom: none;
        &:not(.is-expanded) {
          display: none;
        }
      }
      .facets__header {
        @include spacer(padding-top, $spacer-1);
        @include spacer(padding-bottom, $spacer-0);
        padding-inline: 0;
        border-bottom-width: 1px;
        cursor: default;
        h3 {
          font-size: $font-size-24;
          line-height: $line-height-088;
          letter-spacing: -$letter-spacing-094;
        }
      }
      .facets__content {
        padding: 0;
        @include spacer(padding-top, $spacer-2);
        & > *:last-child {
          fieldset {
            margin-bottom: 0;
          }
        }
      }
      .facets__legend {
        @include spacer(padding-bottom, $spacer-1);
        h4 {
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: $font-size-20;
          letter-spacing: -$letter-spacing-1;
          color: $body-text-colour;
        }
      }
      .facets__badge-count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2rem;
        height: 1.2rem;
        font-size: $font-size-14;
        line-height: 1em;
        color: white;
        background-color: $omd-light-purple;
        border-radius: 0.75rem;
      }
      .facets__button--clear {
        margin-top: 3px;
        color: $color-black;
        font-size: $font-size-14;
        font-weight: $font-weight-600;
        text-decoration: none;
        line-height: 1.2;
        border-bottom: 2px solid $color-black;
        padding: 0;
        transition: border-bottom-color 0.2s;
        &:hover {
          border-bottom-color: $omd-light-purple;
        }
      }
      .facets__label {
        font-size: $font-size-16;
        line-height: $line-height-15;
        color: $color-black;
        &::before {
          width: $font-size-20;
          height: $font-size-20;
          margin-right: 10px;
          margin-top: 1px;
        }
        & + .facets__repeat-wrapper {
          @include spacer(margin-top, $spacer-0);
        }
      }
      .facets__label-count {
        font-size: $font-size-16;
        color: $color-black;
      }
      .facets__fieldset {
        margin: 0;
      }
      .facets__section:not(:first-child) {
        @include spacer(padding-top, $spacer-1);
      }
      .facets__section-item {
        @include spacer(padding-bottom, $spacer-0);
        &:last-child:not(:only-child) {
          padding-bottom: 0;
        }
      }
      .facets__section-list {
        @include spacer(padding-bottom, $spacer-0);
        &:last-child:not(:only-child) {
          padding-bottom: 0;
        }
        input[type="checkbox"]:checked + label {
          &.facets__label {
            font-weight: $font-weight-600;
            &::before {
              content: "";
              background-color: $omd-purple;
              border: none;
            }

            &::after {
              content: "";
              position: absolute;
              display: inline-block;
              transform: rotate(45deg);
              border-bottom: 2px solid $color-white;
              border-right: 2px solid $color-white;
              width: 5px;
              height: 11px;
              top: 1px;
              left: 0px;
              background: none;
            }
          }
        }
        input.partially-selected[type="checkbox"] + label {
          &.facets__label {
            &::before {
              content: "";
              background-color: $omd-purple;
              border: none;
              opacity: 0.5;
            }

            &::after {
              content: "";
              position: absolute;
              display: inline-block;
              transform: rotate(0deg);
              border-bottom: 2px solid $color-white;
              border-right: 0;
              width: 12px;
              height: 2px;
              top: 8px;
              left: 2px;
              background: none;
            }
          }
        }
        &--sub {
          padding-top: 0;
          @include spacer(padding-left, $spacer-3);
          @include spacer(padding-bottom, $spacer-0);
        }
      }
      .facets__button--more,
      .facets__button--less {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        @include spacer(margin-bottom, $spacer-0);
        color: $color-black;
        font-size: $font-size-14;
        font-weight: $font-weight-600;
        text-decoration: none;
        line-height: 1.2;
        border-bottom: 2px solid $color-black;
        padding: 0;
        transition: border-bottom-color 0.2s;
        &:hover {
          border-bottom-color: $omd-light-purple;
        }
        svg {
          display: none;
        }
      }

      input[type="radio"]:checked + .facets__label {
        &::after {
          content: "";
          left: 4px;
          top: 5px;
          width: 8px;
          height: 8px;
          background-color: white;
          border-radius: 50%;
        }
        &::before {
          background-color: $omd-purple;
          border: none;
        }
      }
      .facets__button--toggle-section {
        width: 12px;
        height: 16px;
        background: url(img/omd/minus.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transform: none;
        &.collapsed {
          background-image: url(img/omd/plus.svg);
          transform: none;
        }
        svg {
          display: none;
        }
      }
      .facets__button--collapse-subfacet {
        background: url(img/omd/minus.svg);
        background-repeat: no-repeat;
        background-size: contain;
        fill: transparent;
        width: 10px;
      }
      .facets__button--expand-subfacet {
        background: url(img/omd/plus.svg);
        background-repeat: no-repeat;
        background-size: contain;
        fill: transparent;
        width: 10px;
      }
      .facets__field-group {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        label {
          width: 100%;
          margin-bottom: 5px;
          font-size: $font-size-15;
          font-weight: $font-weight-600;
          color: $body-text-colour;
        }
        input {
          float: none;
          width: calc(100% - 42px);
          padding: 9px;
          border-radius: 20px;
          border: solid 1px rgba(142, 135, 154, 0.75);
          &::-webkit-input-placeholder,
          &::placeholder {
            font-style: normal;
            letter-spacing: -$letter-spacing-02;
            color: $body-text-colour;
          }
        }
        .input-group-btn {
          button {
            height: 100%;
          }
          svg {
            overflow: visible;
          }
        }
        &--from {
          overflow: visible;
          @include spacer(margin-top, $spacer-0);
          padding-bottom: 0;
        }
        &--to {
          overflow: visible;
          @include spacer(margin-top, $spacer-1);
        }
      }
      .datepicker .dropdown-menu {
        @include breakpoint("lg") {
          top: 100% !important;
          right: 0;
          left: auto !important;
          min-width: auto;
          max-width: 100%;
          margin-top: 10px;
          margin-left: 0;
        }
        @include breakpoint("medium") {
          right: 0;
        }
        table {
          th,
          td {
            padding: 0;
            border: none;
            &.text-center {
              text-align: center;
            }
            button {
              padding: 0;
              border-radius: 0;
              span,
              strong {
                font-family: inherit;
              }
            }
            small {
              font-family: inherit;
            }
          }
        }
      }
      .advanced-search-facet {
        & > div {
          display: flex;
          align-items: center;
          @include spacer(margin-bottom, $spacer-0);
          font-size: $font-size-16;
          color: $color-black;
        }
      }
    }
    .search-bar,
    .search-metadata,
    .search-results,
    .search-pagination__wrapper {
      margin-left: 325px;
      @include breakpoint("lg") {
        margin-left: 0;
      }
    }
    .search-bar {
      min-height: auto;
      padding: 0;
      @include spacer(margin-bottom, $spacer-0);
      background: transparent;
      &__label,
      &__footer {
        display: none;
      }
      &__content {
        z-index: 99;
      }
      &__input-wrapper {
        width: 100%;
        margin-right: 0;
      }
      &__field {
        height: 54px;
        padding: 0 30px;
        @include header-style($h5-font-size, $font-weight-600, $line-height-125, -$letter-spacing-125);
        color: inherit;
        border: solid 1px $color-black;
        border-right: none;
        border-radius: 34px 0 0 34px;
        @include breakpoint("medium") {
          @include header-style($h4-font-size, $font-weight-600, $line-height-125, -$letter-spacing-125);
        }
      }
      &__clear {
        right: 18px;
        top: 50%;
        width: 19px;
        height: 19px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
        &.active {
          opacity: 1;
          pointer-events: all;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          top: 0px;
          position: absolute;
          background-image: url(img/omd/icon_close_black.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
      &__icon {
        display: none;
        &--info,
        &--clear {
          display: inline-block;
        }
      }
      &__tips {
        @include spacer(margin-left, $spacer-2);
        &-button {
          font-family: $font-family-opensans;
          font-size: $font-size-16;
          color: $color-black;
          font-weight: $font-weight-600;
          color: $omd-purple;
          background: url(img/omd/light-bulb.svg) no-repeat left center;
          background-size: 14px;
          @include spacer(padding-left, $spacer-3);
          display: block;
          &:hover {
            text-decoration: none;
          }
          & > svg {
            display: none;
          }
          & > span {
            position: relative;
            &:hover:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -3px;
              width: 100%;
              height: 2px;
              background-color: $omd-purple;
            }
          }
        }
        &-panel {
          h3 {
            font-size: $font-size-24;
            letter-spacing: $letter-spacing-094;
          }
          *:not(h3) {
            font-family: $font-family-opensans;
            font-size: $font-size-16;
            color: $color-black;
            font-weight: revert;
          }
        }
      }
      &__submit {
        position: relative;
        width: 56px;
        height: 56px;
        margin-right: 0;
        padding: 0;
        background-color: $color-black;
        border: solid 1px $color-black;
        border-left: none;
        border-radius: 0 28px 28px 0;
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-image: url(img/omd/search_white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px 20px;
        }
      }
      &__headline-label {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
      &__footer-option {
        @include breakpoint("medium") {
          display: block;
          margin-right: 0;
        }
      }
    }
    .search-metadata {
      display: block;
      margin-bottom: 0;
      font-size: $font-size-18;
      color: inherit;
      .search-header {
        &__results-meta {
          @include spacer(margin-bottom, $spacer-1);
          padding-top: 0;
          font-size: 1rem;
          font-family: inherit;
          font-weight: 500;
          line-height: inherit;
          color: inherit;
          @include breakpoint("lg") {
            @include spacer(margin-top, $spacer-1);
          }
          @include breakpoint("medium-small") {
            display: block;
          }
          strong {
            font-weight: inherit;
          }
          .js-searchKeyword {
            font-style: italic;
            color: $omd-purple;
          }
        }
        &__facet-tag-list {
          display: none;
        }
      }
    }
    .search-sort {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 40px;
      margin: 0 0 -40px;
      padding: 0 15px;
      z-index: 1;
      @include breakpoint('medium') {
        float: none;
      }
      @include breakpoint('medium-small') {
        position: static;
        height: auto;
        margin-bottom: 1rem;
        padding: 0;
      }
      &__title {
        margin-right: 5px;
        font-size: inherit;
        letter-spacing: inherit;
      }
      &__control-list {
        li {
          margin-right: 5px;
          padding-right: 5px;
          border-right: 1px solid $color-black;
          &:last-child {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
          }
        }
      }
      &__control-type {
        .btn {
          padding: 0;
          color: inherit;
          font-size: inherit;
          font-weight: 600;
          letter-spacing: inherit;
          border: none;
          text-transform: none;
          text-decoration: none;
          span {
            position: relative;
            display: inline-block;
            text-decoration: none;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -3px;
              width: 100%;
              height: 2px;
              background-color: $omd-purple;
              opacity: 0;
              transition: opacity 0.2s;
            }
          }
          .sorting-arrows__arrow {
            fill: $color-black;
            &--up {
              margin-top: 0;
            }
          }
          &:hover {
            span::after {
              opacity: 1;
            }
          }
        }
        &.is-selected {
          .btn span {
            color: $omd-purple;
            &::after {
              opacity: 1;
            }
          }
          .sorting-arrows__arrow {
            fill: $omd-purple;
          }
        }
        &.is-sorted-up .sorting-arrows__arrow--up,
        &.is-sorted-down .sorting-arrows__arrow--down {
          opacity: 0.5;
        }
      }
      &__sort-by {
        align-items: center;
      }

      select {
        padding: 0.25rem 0.5rem;
        border: 1px solid $color-black;
        border-radius: 1rem;
      }
    }
    .search-results {
      @include breakpoint("medium") {
        order: 5;
      }
      &.headlines-only {
        .search-result {
          &__body {
            & > .search-result__pubdate {
              margin-bottom: 0;
              border-right: 0;
            }
            & > .search-result__content-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .search-pagination {
      &__wrapper {
        padding: 0;
        border-top: none;
        @include breakpoint("medium") {
          order: 7;
        }
      }
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include spacer(padding-top, $spacer-3);
        @include spacer(padding-bottom, $spacer-3);
        font-size: $font-size-16;
        border-top: 1px solid $color-black;
        @include breakpoint("medium") {
          display: block;
        }
        select {
          margin-left: 20px;
          padding: 3px 9px;
          border: 1px solid $color-black;
          width: 64px;
          font-family: inherit;
          font-weight: 600;
          font-size: $font-size-14;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          border-radius: 16px;
        }
      }
      &__pagination-list {
        margin-left: auto;
        @include breakpoint("medium") {
          width: auto;
          margin-left: -15px;
          margin-right: -15px;
          @include spacer(margin-top, $spacer-3);
          text-align: inherit;
        }
        li {
          margin: 0 14px;
          padding: 0;
          font-family: inherit;
          font-weight: 600;
          font-size: inherit;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          a {
            padding: 0 1px 1px;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-transform: inherit;
            line-height: inherit;
            text-decoration: none;
            border-bottom: 2px solid $color-black;
          }
          &.is-active {
            a {
              background: transparent;
              color: $omd-purple;
              border-color: $omd-purple;
            }
          }
        }
      }
    }
    .onoffswitch {
      min-width: 44px;
    }
    .onoffswitch.disabled {
      label, input, & + label {
        pointer-events: none;
      }
      .onoffswitch-label {
        opacity: 0.5;
      }
    }
    .onoffswitch-label {
      border: none;
      border-radius: 11px;
      .onoffswitch-inner {
        margin-left: -44px;
        &::before,
        &::after {
          color: $color-white;
          font-family: inherit;
          font-weight: 600;
          font-size: 0.5rem;
          letter-spacing: 0;
        }
        &::before {
          content: "ON";
          height: 20px;
          line-height: 20px;
          padding-left: 6px;
          background-color: $omd-purple;
        }
        &::after {
          content: "OFF";
          height: 20px;
          line-height: 20px;
          padding-right: 6px;
          background-color: #8e879a;
        }
      }
      .onoffswitch-switch {
        left: 0;
        top: 0;
        width: 12px;
        height: 12px;
        background-color: $color-white;
      }
    }
    .onoffswitch-checkbox:checked {
      & + .onoffswitch-label {
        .onoffswitch-inner {
          margin-left: 0;
        }
        .onoffswitch-switch {
          left: 24px;
        }
      }
    }
  }

  .selected-filters-tag {
    @include breakpoint("small") {
      display: none;
    }
    @include breakpoint("ipad") {
      display: none;
    }
    .inf-col-fluid {
      display: flex;
      flex-direction: row;
      .tags-container .tags {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        & > li {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    h5 {
      font-size: $font-size-24;
      white-space: nowrap;
    }
    .btn.tag {
      position: relative;
      font-size: $font-size-12;
      line-height: $line-height-125;
      padding: 6px 25px 6px 13px;
      user-select: none;
      cursor: pointer;

      &.clear-all {
        margin-left: 10px;
        padding: 0;
        font-size: $font-size-14;
        font-weight: $font-weight-600;
        line-height: 1.2;
        letter-spacing: normal;
        text-transform: none;
        border: 0;
        border-bottom: 2px solid $color-black;
        border-radius: 0;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          border-bottom-color: $omd-light-purple;
        }
        &::after {
          display: none;
        }
      }
      &:hover::after {
        background-image: url(img/omd/icon_close_black.svg);
      }
      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        vertical-align: top;
        margin-left: 8px;
        background-image: url(img/omd/icon_close_gray.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 175% auto;
        transition: background-image 0.2s;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .dismiss-button__icon {
      display: none;
    }
  }
  .js-saved-search-controller {
    .lightbox-modal__title {
      .lightbox-modal__title-icon {
        display: none;
      }
    }
    .dismiss-button.js-close-lightbox-modal {
      span {
        display: none;
      }
      .dismiss-button__icon {
        display: none;
      }
      position: relative;
      font-family: inherit;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        background-image: url(img/omd/icon_close_black.svg);
        background-repeat: no-repeat;
        background-size: 15px auto;
        background-position: center;
        width: 15px;
        height: 15px;
      }
    }
    .lightbox-modal__submit.button--filled.js-lightbox-modal-submit {
      width: 100%;
    }
  }
  .js-remove-search-modal {
    color: $color-black !important;
  }
  .tab {
    .tab-items {
      font-size: $font-size-16;
    }
  }
}

.pop-out.js-pop-out__save-search.search-custom.is-active,
.pop-out.js-pop-out__sign-in.is-active {
  font-family: $font-family-opensans !important;
  max-width: 330px;

  @media only screen and (max-width: 1024px) {
    left: auto;
    right: 0;
    width: auto;
    max-width: 330px;
  }

  .pop-out__save-search-container {
    margin-bottom: 0;
  }

  .dismiss-button {
    position: relative;
    font-family: inherit;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(img/omd/icon_close_black.svg);
      background-repeat: no-repeat;
      background-size: 15px auto;
      background-position: center;
      width: 12px;
      height: 12px;
    }
  }

  .dismiss-button__icon {
    width: 15px;
    height: 15px;
    margin: 0;

    use {
      display: none;
    }
  }

  .pop-out__internal {
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #faf9fb;
    border: 1px solid #faf9fb;
    @include spacer(padding, $spacer-0);
    min-width: initial;

    @include breakpoint("small") {
      min-width: auto;
    }

    .pop-out__header--tight,
    .pop-out__register-header.save-search-signin,
    .pop-out__register-header {
      font-size: 1.2rem;
      line-height: $line-height-15;
      letter-spacing: -0.94px;
      font-weight: 600;
      color: $color-black;
      @include spacer(margin-bottom, $spacer-0);
      @include spacer(padding-right, $spacer-1);
    }

    .pop-out__header--tight {
      @include spacer(margin-bottom, $spacer-1 / 2);
    }

    .pop-out__sign-in-submit, .button--filled {
      float: none !important;
      display: block !important;
      width: 100%;
      @include spacer(margin-top, $spacer-2);
      @include font(12px, $font-weight-600, 1.2, 1.07px);
    }

    .pop-out__save-search-email-alert input[type="checkbox"] {
      width: 20px;
      height: 20px;

      & + .checkbox-label {
        margin-left: 5px;
        color: $color-black;
        font-size: $font-size-14;
      }

      &:checked + label {
        position: relative;
        color: $color-black;
        font-size: $font-size-14;
        left: 0;
        top: 0;

        &::before {
          content: "";
          background-color: $omd-purple;
          border: none;
          width: 20px;
          height: 20px;
          display: inline-block;
          position: absolute;
          left: -28px;
          top: -1px;
          border-radius: 4px;
        }

        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          transform: rotate(45deg);
          border-bottom: 2px solid $color-white;
          border-right: 2px solid $color-white;
          width: 6px;
          height: 12px;
          top: 2px;
          left: -22px;
          background: none;
        }
      }
    }
  }
  .pop-out__tab {
    display: none;
  }
  .form-save-search {
    input[type="text"] {
      border-radius: 3px;
      @include spacer(padding, $spacer-2 / 2);
      @include spacer(margin-bottom, $spacer-2 / 2);
      border: 1px solid #acacb0;

      &:focus {
        border: 1px solid $color-black;
        outline-color: rgba(115, 26, 237, 0.6);
        box-shadow: inset 0 0 2px 2px $color-black;
      }
    }
  }
}

/*tooltip on bookmark icon*/
.popup {
  min-width: initial;
  background: $omd-light-grey;
  border: 1px solid $omd-medium-grey;
  box-shadow: 0 1px 5px $omd-dark-grey;
  line-height: normal;
  font-family: $font-family-opensans;
  font-size: $font-size-12;
  color: initial;
  z-index: 9;
  &.is-triangle-top.no-title {
    & > .popup__triangle {
      &:before {
        border-color: transparent transparent $omd-medium-grey;
      }
      &::after {
        border-color: transparent transparent $omd-light-grey;
      }
    }
  }
}