// arrow list
.arrow-list {
    & > li {
		display: flex;
		align-items: center;
		gap: 20px;
		align-self: stretch;
		font-size: $font-size-16;
		font-weight: $font-weight-600;
		letter-spacing: $letter-spacing-05;
		padding: map-get($spacers, 0)/2 0;
		@include breakpoint("medium-small") {
			padding: map-get($spacers, 1)/4 0;
		}
		& > svg {
			fill: $omd-dark-purple-2;
			width: 23px;
			height: 18px;
			transform-origin: left;
			transition: transform 300ms;
		}
		& > span,
		& > a {
			flex: 1;
		}
		&:hover {
			& > svg {
			fill: $color-black !important;
			transform: scale(1.2);
			}
		}
		& > a {
			color: $color-black;
			&:hover {
				color: $color-black;
				text-decoration: none;
			}
		}
    }
	&.no-arrow {
		& > li {
			& > svg {
				display: none;
			}
		}
	}
}