	
.article-left-rail {
    max-width: 790px;
    float: left;

    @include breakpoint('medium') {
        width: 100%;
        clear: both;
        float: none;
    }

    &--small {
        float: left;
        max-width: 290px;
        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }

    &--wide {
        float: left;
        max-width: 790px;
        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }
}

.article-right-rail {
    float: right;
    max-width: 290px;

    @include breakpoint('medium') {
        max-width: 100%;
        float: none;
    }

    &--small {
        float: right;
        max-width: 290px;
        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }

    &--wide {
        float: right;
        max-width: 790px;
        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }

    h2 {
        font-size: 26px;
        color: $color-zeus-black;
        margin-bottom: 1rem;
    }
}

.hidden-mobile-table-template {
    display: none;
}
.article-table--mobile-link {
    margin-bottom: 1rem;
}

.media-exhibit,
.article-paragraph,
.article-body-content > p,
.article-body-content p { // "top level" paragraphs, direct children of wrapper
    color: $color-abbey-gray;
    line-height: 1.6rem;
    margin: 0 0 1rem;
}

.article-legacy-publication {
    @include textMix('Roboto', 1rem, 400);
    font-style: italic;
}
