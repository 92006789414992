.inpage-nav-container {
  position: relative;
  .inpage-nav-title {
    position: absolute;
    bottom: 100%;
    color: $color-white;
    font-weight: 600;
    padding-bottom: 1rem;
    padding-left: 20px;

    @include breakpoint("medium") {
      left: 0;
      right: 0;
      padding-left: 0;
      text-align: center;
    }
  }
  .inpage-nav {
    display: flex;
    position: relative;
    z-index: 1;

    @include breakpoint("medium") {
      width: 100%;
      flex-grow: 1;
    }

    .link-container {
      display: flex;
      border-radius: 28px;
      padding: 18px 0 3px 0;
      border: 2px solid transparent;
      position: relative;
      background-color: $color-black;
      background-clip: padding-box;
      flex-wrap: wrap;

      @include breakpoint("medium") {
        width: 80%;
        flex-grow: 1;
        flex-direction: column;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-radius: inherit;
        background: linear-gradient(to bottom, $omd-blue, $omd-purple);
        margin: -2px;
      }

      li {
        padding: 0 20px;
        display: inline-flex;
        flex-basis: auto;
        padding-bottom: 15px;
        line-height: 1;

        @include breakpoint("medium") {
          padding: 0 15px 15px 15px;
          justify-content: center;
          display: flex;
          flex-grow: 1;
          align-items: center;
          text-align: center;
        }
        a {
          color: $color-white;
        }
      }
    }
  }
}
