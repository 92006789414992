.two-column-accordion-with-data {
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-117);
    display: flex;
    gap: 60px;
    @include breakpoint("medium") {
        margin: map-get($spacers, 1)*3.5 auto;
        flex-direction: column;
    }
    @include breakpoint("medium-small") {
        @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-117);
        padding: 0 map-get($spacers, 1)*0.75;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    & > .left,
    & > .right {
        float: left;
        width: calc(50% - 20px);
        & > iframe {
            border: none;
        }
        @include breakpoint("medium") {
            width: 100%;
        }
    }
    .accordion {
        margin-bottom: map-get($spacers, 1)*2;
    }
    .heading,
    .sub-heading {
        @include font($font-size-31, $font-weight-bold, $line-height-normal, $letter-spacing-025);
    }
    .heading {
        margin-bottom: map-get($spacers, 1)*2;
        letter-spacing: $letter-spacing-05;
    }
    .sub-heading {
        font-size: $font-size-25;
        margin-bottom: map-get($spacers, 1);
    }
    .content-block {
        display: flex;
        flex-direction: column;
        margin-bottom: map-get($spacers, 1)*2;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    & > .left {
        .content-block {
            margin-top: map-get($spacers, 1)*2;
        }
    }
}