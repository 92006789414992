.upcoming-events {
  margin: map-get($spacers, 1)*5 auto;
  @include breakpoint('medium') {
    margin: map-get($spacers, 1)*3.5 auto;
  }
  &.no-top-margin {
      margin-top: 0;
  }
  &.no-bottom-margin {
      margin-bottom: 0;
  }
  &__title {
    padding: 0 0 $spacer-2*1.5;
    @include font($font-size-32, $font-weight-bold, $line-height-normal, $letter-spacing-175);
    text-align: center;
  }
  &__wrapper {
    display: flex;
    gap: 20px;
    margin: 0 auto;
    max-width: 1260px;
    padding: 0 $spacer-2*1.25;
    @include breakpoint('ipad') {
      flex-direction: column;
      padding: 0 map-get($spacers, 1)*0.75;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    @include breakpoint('ipad') {
      order: 2;
    }
    @include breakpoint('small') { 
      width: 100%;
    }
    &--box {
      display: flex;
      padding: $spacer-2*1.25;
      align-items: flex-start;
      gap: 40px;
      background-color: $omd-light-grey;
      @include breakpoint('small') {
        flex-direction: column;
        gap: 20px; 
        padding: map-get($spacers, 1) map-get($spacers, 1)*0.75;
      }
      &:hover {
        background-color: $omd-very-light-purple;
      }
      &-left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        @include breakpoint('small') { 
          width: 100%;
        }
      }
      &-title {
        @include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-normal);
        color: $omd-black;
        @include breakpoint('small') {
          font-size: $font-size-20;
          letter-spacing: $letter-spacing-02;
        }
      }
      p {
        @include font($font-size-16, $font-weight-400, $line-height-15, $letter-spacing-normal);
        color: $omd-black;        
        display: -webkit-box;
        -webkit-line-clamp: 11;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint('small') {
          @include font($font-size-16, $font-weight-400, $line-height-22, $letter-spacing-1);
        }
      }

      &-right {
        display: flex;
        flex-wrap: wrap;
        width: 280px;
        gap: 10px;
        align-self: center;
        @include breakpoint('small') { 
          width: 100%;
          @include spacer(padding, map-get($spacers, 0));
        }        
        & > .cta {
          margin-top: 10px;
        }
        @include breakpoint('small') {
          flex-direction: column;
          padding: 0;
        }
      }
      &-pill {
        display: flex;
        align-items: center;
        justify-content: center;
        @include font($font-size-15, $font-weight-400, $line-height-1, $letter-spacing-175);
        border: 1px solid $color-black;
        border-radius: 60px;
        padding: map-get($spacers, 0)/2 map-get($spacers, 1);
        background-color: $color-black;
        color: $color-white;
        @include breakpoint('small') {
          width: fit-content;
          padding: map-get($spacers, 0)/2 map-get($spacers, 1);
          font-weight: normal;
          letter-spacing: $letter-spacing-150;
        }
      }
      &-date {
        @include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-normal);
        color: $omd-black;
        @include breakpoint('small') {
          font-size: $font-size-16;
        }
      }
      &-duration {
        display: flex;
        align-items: center;
        gap: 8px;
        @include font($font-size-14, $font-weight-600, $line-height-normal, $letter-spacing-normal);
        color: $omd-black;
        & > img {
          width: 18px;
          height: 18px;
        }
      }
      &-eventtype {
        display: flex;
        align-items: center;
        color: #666;
        gap: 10px;
        @include font($font-size-12, $font-weight-400, $line-height-normal, $letter-spacing-normal);
        div {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          @include font($font-size-12, $font-weight-400, $line-height-13, $letter-spacing-025);
          color: $color-medium-black;
          &:first-of-type {
            white-space: nowrap;
            padding: 3px map-get($spacers, 1)/2 3px 0;
            border-right: 1px solid $omd-light-grey-3;
          }
          &:last-of-type {            
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 410px;
    position: relative;
    padding: $spacer-2*1.25;
    @include breakpoint('ipad') {
      width: auto;
      justify-content: center;
      gap: 20px;
      order: 1;
    }
    @include breakpoint('small') { 
      width: 100%;
    }
    & > .cta {
      opacity: 0;
      z-index: 9;
      transition: opacity 300ms ease;
      position: absolute;
      bottom: $spacer-2*1.25;
      @include breakpoint('medium') {
        position: relative;
        bottom: initial;
        opacity: 1;
        line-height: $line-height-133;
      }
    }
    &::before {
      content: "";
      background-color: $omd-dark-purple-2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      transition: opacity 300ms ease;
    }
    &:hover {
      &::before {
        opacity: 1;
        @include breakpoint('medium') {
          opacity: 0;
        }
      }
      & > .cta {
        opacity: 1;
      }
      .upcoming-events__banner--title {
        top: 100%;
        transform: translateY(calc(-100% - 60px));
        @include breakpoint('medium') {
          transform: none;
          bottom: initial;
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &--title {
      @include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-05);
      color: $omd-white;
      position: relative;
      top: 0;
      transform: translateY(0);
      z-index: 1;
      transition: top 300ms ease, transform 300ms ease;
      @include breakpoint('medium') {
        @include font($font-size-24, $font-weight-bold, $line-height-125, $letter-spacing-05);
        position: relative;
        transform: none;
        bottom: initial;
      }
      @include breakpoint('small') {
        @include font($font-size-24, $font-weight-bold, $line-height-125, $letter-spacing-05);
        font-size: 25px;
      }
    }
  }
}