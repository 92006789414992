// must match vars in js
$hoverFadeDuration: 150ms;
$hoverFadeDelay: 300ms;
$toggleIconDuration: 200ms;
$toggleIconDelay: 200ms;
$followerMoveDuration: 300ms;
$searchOpenDuration: 300ms;
$mobileMenuOpenDuration: 400ms;

$headerBottomMobile: 44px;
$searchHeightMobile: 82px;
$searchBottomMobile: $headerBottomMobile + $searchHeightMobile;

&.mob-nav-open {
  overflow: hidden;
}

& > header {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9991;
  background-color: $color-white;
  .header {
    @include inf-container();
    display: flex;
    flex-direction: row;
    height: 101px;
    width: 100%;
    border: none;
    gap: 45px;
    font-family: inherit;
    font-size: inherit;
    background-color: $color-white;
    @include breakpoint('ipad') {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 30px;
    }    
    @include breakpoint('lg') {
      padding: 0 30px;
    }
    @include breakpoint('medium') {
      gap: 15px;
      padding: 0 15px;
    }
    @include breakpoint('medium-small') {
      height: 71px;
    }
    &__wrapper {
      background-color: $color-white;
      border: none;
      width: 100%;
      padding: 0;
      height: auto;
      box-shadow: 0 3px 25px rgba($color-black, 0.05);
      order: 2;
    }
  }
  
  .informa-ribbon {
    order: 1;
  }
  
  .header-logo {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 185px;
    flex: 1 0 auto;
    @include breakpoint('ml') {
      position: relative;
      width: 97px;
      margin: 10px 0;
      z-index: 71;
      max-width: 200px;
    }
    @include breakpoint('medium-small') {
      margin: 0;
    }
    & > img {
      width: 100%;
      height: auto;
      @include breakpoint('medium-small') {
        width: 150px;
      }
    }
  }
  .inf-menu-toggler {
    display: none;
    @include breakpoint('ml') {
      display: flex;
      align-items: center;
      align-self: center;
    }
    .inf-toggle-icon-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      div {
        height: 3px;
        background-color: $color-black;
        overflow: hidden;
        width: 100%;
        @include breakpoint('medium-small') {
          height: 2px;
        }
      }
    }
    .inf-toggle-icon-close {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      div {
        position: absolute;
        top: 0;
        left: calc(50% - 2px);
        width: 2px;
        height: 100%;
        background-color: $color-white;
        border-radius: 2px;
        overflow: hidden;
        transition: width $toggleIconDuration;
        &:nth-child(1) {
          transform: rotate(45deg) translateX(calc(25% - 1px));
        }
        &:nth-child(2) {
          transform: rotate(-45deg) translateX(calc(-25% + 1px));
        }
      }
    }
    &__button {
      position: relative;
      display: block;
      padding: 0;
      background: transparent;
      border: none;
      text-indent: -999em;
      width: 24px;
      height: 25px;
      transition: width $toggleIconDuration $toggleIconDelay;
      @include breakpoint('medium-small') {
        height: 18px;
        width: 18px;
      }
      &:focus {
        outline: none;
      }
    }
    &.opening, &.open {
      .inf-toggle-icon {
        &-menu {
          width: 0;
          transition: width $toggleIconDuration;
        }
        &-close {
          width: 24px;
          height: 24px;
          @include breakpoint('medium-small') {
            width: 20px;
            height: 20px;
          }
          div {
            transition: width $toggleIconDuration $toggleIconDelay;
          }
        }
      }
    }
    &.closing {
      .inf-toggle-icon {
        &-menu {
          width: 18px;
          transition: width $toggleIconDuration $toggleIconDelay;
        }
        &-close {
          div {
            width: 0%;
            transition: width $toggleIconDuration;
          }
        }
      }
    }
  }
  .inf-main-menu {
    flex: 1 0 auto;
    display: flex;
    justify-content: space-between;
    @include breakpoint('ml') {
      position: fixed;
      overflow: visible;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $color-white;
      z-index: 100;
      transform: translateX(100%);
      transition: transform $mobileMenuOpenDuration;
      flex-direction: column;
      justify-content: flex-start;
      @include spacer(padding, map-get($spacers, 1));
      padding-top: $spacer-2*2.5;
      &.open, &.opening {
        transform: translateX(0);
      }
    }
    .account-access {
      display: flex;
      gap: 1rem;
      order: 2;
      align-self: center;
      justify-content: center;
      height: 100%;
      @include breakpoint('ml') {
        flex-wrap: wrap;
        width: 100%;
        & > li {
          flex-grow: 1;
          &.username {
            width: 100%;
          }
          & > a {
            width: 100%;
          }
        }
      }      
      @include breakpoint('medium-small') {
        flex-direction: column;
        order: 1;
        @include spacer(margin-bottom, map-get($spacers, 125));
        & > li {
          width: 100%;
        }
      }
      .cta-secondary.cta-solid {
        &:hover, &:active, &:focus {
          color: $color-white;
          border-color: $color-black;
          background-color: $color-black;
        }
      }
      .my-account {
        position: relative;
        z-index: 60;
        .account-dropdown-btn {
          &:hover {
            &+.account-dropdown {
              display: block;
            }
          }
        }
        .account-dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          width: 265px;
          background-color: $color-white;
          display: none;
          box-shadow: 0 25px 25px rgba(0,0,0,.05);
          @include breakpoint('ml') {
            width: 100%;
            display: block;
          }
          &.open {
            display: block;
          }
          &::before, &::after {
            display: none;
          }
          ul {
            position: relative;
            overflow: hidden;
            z-index: 2;
            @include spacer(padding, map-get($spacers, 3));
            display: flex;
            flex-direction: column;
            gap: 14px;
            @include breakpoint('ml') {
              padding: 0;
            }
            & > li {
              & > a {
                display: block;
                color: $color-black;
                text-decoration: none;
                font-family: $font-family-opensans;
                @include font($font-size-14, $font-weight-600, $line-height-normal, $letter-spacing-150);
                position: relative;
                &:hover {
                  text-decoration: none;
                  color: $omd-dark-purple-2;
                  &::after {
                    content: "";
                    position: absolute;
                    left: -10px;
                    top: 0;
                    height: 100%;
                    width: 2px;
                    background-color: $omd-dark-purple-2;
                  }
                }
              }
            }
          }
        }
        & > img {
          display: block;
          overflow: hidden;
          height: 0;
        }
      }
      & > li {
        display: flex;
        align-items: center;
        & > span {
          @include font($font-size-14, $font-weight-600, $line-height-normal, $letter-spacing-1);
          font-family: $font-family-opensans;
        }
      }
    }

    .inf-menu-toggler {
      display: none;
      position: absolute;
      top: 20px;
      right: 20px;
      .inf-toggle-icon-close {
        div {
          background: $color-black;
        }  
      }
      @include breakpoint('ml') {
        display: block;
      }
    }

    .main-nav {
      display: flex;
      gap: 34px;
      order: 1;
      @include breakpoint('ml') {
        width: 100%;
        order: 2;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;      
        gap: 0;
      }
      & > li {
        position: relative;
        display: flex;
        align-items: center;
        &:last-child {
          display: flex;
        }
        @include breakpoint('ml') {
          width: 100%;
          padding: 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          &:last-child {
            display: none;
          }
        }
        // shared menu-link styling
        & > a {
          font-family: $font-family-opensans;
          @include font($font-size-14, $font-weight-600, $line-height-normal, $letter-spacing-150);
          color: inherit;
          text-decoration: none;
          text-align: center;
          transition: opacity $hoverFadeDuration;
          cursor: default;
          @include breakpoint('ml') {
            @include spacer(padding-top, map-get($spacers, 0));
            @include spacer(padding-bottom, map-get($spacers, 0));
            padding-left: 0;
            padding-right: 0;
            opacity: 1;
            width: 100%;
          }
        }
        &.hover {
          & > div {
            display: flex;
          }
        }

        // submenus
        & > div {
          display: none;
          background: $color-white;
          position: absolute;
          left: 0;
          top: 100%;
          pointer-events: all;
          @include spacer(padding, map-get($spacers, 3));
          @include spacer(margin-left, - map-get($spacers, 3));
          z-index: 60;
          box-shadow: 0 25px 25px rgba($color-black, 0.05);
          @include breakpoint('ml') {
            display: flex;
            flex-direction: column;
            gap: 40px;
            left: 0;
            width: 100%;
            padding-left: $spacer-2*2.5;
            padding-right: $spacer-2*2.5;
            padding-top: $spacer-2*3.5;
            margin-left: 0;
          }
          &.closing, &.open {
            transition: opacity $hoverFadeDuration;
          }
          // multicolumn support
          &[class*=nav-col] {
            @include spacer(padding-bottom, map-get($spacers, 3) / 2);
            column-gap: 30px;
          }
          & > div {
            width: 200px;
          }
          &.multiple-head {
            & > div.one-column {
              padding-right: 50px;
              @include breakpoint('ml') {
                padding-right: 0;
              }
            }
            & > div.two-column {
              width: 485px;
              padding: 0;
              @include breakpoint('ml') {
                width: 100%;
              }
              & > .nav-col-2 {
                column-gap: 50px;
              }
            }
          }
          & h3 {
            @include font($font-size-16, $font-weight-bold, $line-height-normal, $letter-spacing-150);
            font-family: $font-family-opensans;
            text-transform: uppercase;
            color: #9747FF;
            white-space: nowrap;
            margin-bottom: $spacer-2;
          }
          ul {
            &.nav-col-2 {
              column-count: 2;
              @include breakpoint('ml') {
                column-count: auto;
              }
            }
            &.nav-col-3 {
              column-count: 3;
              @include breakpoint('ml') {
                column-count: auto;
              }
            }
            &.nav-col-4 {
              column-count: 4;
              @include breakpoint('ml') {
                column-count: auto;
              }
            }
            & > li {
              margin-bottom: 14px;
              @include breakpoint('ml') {
                border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                white-space: normal;
                &:last-child {
                  border-bottom-color: transparent;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
              & > a {
                display: block;
                color: $color-black;
                font-family: $font-family-opensans;
                @include font($font-size-14, $font-weight-600, $line-height-normal, $letter-spacing-150);
                position: relative;
                &:hover {
                  text-decoration: none;
                  color: $omd-dark-purple-2;
                  &::after {
                    content: "";
                    position: absolute;
                    left: -10px;
                    top: 0;
                    height: 100%;
                    width: 2px;
                    background-color: $omd-dark-purple-2;
                  }
                }
                &:focus {
                  text-decoration: none;
                }
                span {
                  position: relative;
                  line-height: 1.4;
                  font-family: $font-family-opensans;
                  @include breakpoint('ipad') {
                    line-height: inherit;
                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mob-sub-trigger {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    .open {
      width: 0;
    }
  }
  .mob-sub-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include spacer(padding, map-get($spacers, 0));
    z-index: 61;
    &.open, &.opening {
      transform: translateX(0);
    }
    & > a {
      display: block;
      font-family: $font-family-opensans;
      font-size: $font-size-16;
      font-weight: 600;
      text-decoration: none;
      color: inherit;
      padding-left: 24px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 100%;
        background-image: url(img/omd/chevron_left_black.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 16px;
      }
    }
  }
  .mob-sub-container {
    overflow: visible;
    position: fixed !important;
    top: 0 !important;
    height: 100%;
    transform: translateX(100%);
    transition: transform $mobileMenuOpenDuration !important;
    padding-left: $spacer-2*2.5;
    padding-right: $spacer-2*2.5;
    padding-top: $spacer-2*3.5;
    &.open, &.opening {
      transform: translateX(0);
    }
  }
  .inf-main-menu.open {
    .mob-sub-container {
      &.open, &.opening {
        &::before {
          opacity: 0.7;
        }
      }
      &.open {
        overflow: auto;
      }
    }
  }
  .searchbar-header {
    position: absolute;
    top: 100%;
    width: 100%;
    display: none;
    order: 3;
    & > .searchbar {
      width: 100%;
    }
  }
}
&.scrolled {
  & > header > .header__wrapper {
    position: fixed;
    top: 0;
    width: 100%;
  }
}
.inf-menu-body-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background-color: $color-black;
  opacity: 0;
  pointer-events: none;
  transition: opacity $followerMoveDuration;
  transform: translateZ(0);
  &.active {
    opacity: 0.7;
    pointer-events: all;
  }
}
.search-toggle {
  width: 20px;
  height: 21px;
  margin: auto 0 auto auto;
  appearance: none;
  background-color: transparent;
  background-image: url(/dist/img/omdia/search.svg);
  background-repeat: no-repeat;
  background-size: 20px 21px;
  background-position: center;
  border: none;
  &:hover {
    background-image: url(/dist/img/omdia/search-active.svg);
  }
  &.search-mobile {
    display: none;
    @include breakpoint('ml') {
      display: block;
      width: 29px;
      height: 29px;
      background-size: 29px 29px;
    }
    @include breakpoint('medium-small') {
      width: 21px;
      height: 21px;
      background-size: 21px 21px;
    }
  }
}
> .search-active {
  & > .searchbar-header {
    pointer-events: all;
    display: flex;
  }
  
  .search-toggle {
    background-image: url(/dist/img/omdia/search-active.svg);
  }
}
