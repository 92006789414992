.timeline-wrapper {
  margin: 20px 0;
  .timeline-filter-wrapper {
    p {
      margin: 0 0 20px;
    }
    .timeline-filter-container {
      .filter-checkbox {
        margin: 0 45px 10px 0;
        display: inline-block;
        label {
          padding: 0 0 0 25px;
          position: relative;
        }
        input[type=checkbox] {
          opacity: 0;
          position: absolute;
          + label {
            &::before {
              content: "";
              border: none;
              width: 16px;
              height: 16px;
              margin-right: 5px;
              background-color: $color-white;
              border: 1px solid $omd-dark-grey;
              display: inline-block;
              border-radius: 2px;
              vertical-align: text-top;
              position: absolute;
              left: 0px;
              top: 3px;
            }
            &::after {
              content: "";
              width: 6px;
              height: 12px;
              position: absolute;
              left: 5px;
              top: 3px;
            }
          }
          &:checked {
            + label {
              &::before {
                background: $omd-purple;
                border-color: $omd-purple;
              }
              &::after {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                border-bottom: 2px solid $color-white;
                border-right: 2px solid $color-white;
              }
            }
          }
        }
      }
    }
  }
  .timeline-container {
    position: relative;
    display: flex;
    flex: 0 1 100%;
    margin: 30px 0;
    .timeline-navigation {
      position: relative;
      &.sticky {
        position: fixed;
        top: 121px;
        @include breakpoint('small') {
          top: 80px;
        }
      }
      &.anchor-bottom {
        position: absolute;
        bottom: 0;
      }
      // half of header height + 50%
      &.sticky-mid {
        position: fixed;
        top: calc(50px + 50%);
        @include breakpoint('small') {
          top: calc(35px + 50%);
        }
      }
      li {
        margin-bottom: 20px;
        position: relative;
        text-align: right;
        &:last-child {
          margin-bottom: 0;
        }
        &::after {
          content: "";
          width: 5px;
          height: 100%;
          position: absolute;
          display: block;
          right: -2.5px;
          top: 0;
        }
        &:hover,
        &.active {
          a {
            color: $color-black;
            text-decoration: none;
          }
          &::after {
            background: $color-black;
          }
        }
        a {
          display: block;
          color: $omd-dark-grey;
          padding-right: 25px;
          font-weight: $font-weight-600;
        }
      }
    }
    .timeline-content {
      padding: 0 25px;
      border-left: 1px solid $omd-dark-grey;
      flex: 1;
      max-width: 820px;
      .timeline-content-tile {
        padding: 30px 0;
        scroll-margin-top: 101px;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0px;
        }
        h5 {
          margin: 0 0 30px;
          font-size: $font-size-36;
          font-weight: $font-weight-bold;
          @include breakpoint('small') {
            margin-bottom: 15px;
          }
        }
        .tile-content {
          @include spacer(padding-bottom, map-get($spacers, 3));
          @include spacer(margin-bottom, map-get($spacers, 3));
          border-bottom: 1px solid $omd-dark-grey;
          position: relative;
          &:last-child {
            padding: 0px;
            margin: 0px;
            border-bottom: none;
          }
          .tile-link {
            display: inline-block;
            color: $color-black;
            font-size: $font-size-24;
            font-weight: $font-weight-600;
            @include spacer(padding-right, map-get($spacers, 3));
            &:hover,
            &:active,
            &:focus {
              text-decoration: none;
            }
          }
          .tile-published {
            margin: 15px 0 0;
            li {
              font-size: $font-size-14;
              letter-spacing: $letter-spacing-125;
              font-weight: $font-weight-400;
              text-transform: none;
              border-right: 1px solid $color-black;
              display: inline-block;
              padding: 0 15px;
              &:first-child {
                font-weight: $font-weight-600;
                text-transform: uppercase;
                padding-left: 0px;
              }
              &:last-child {
                border: none;
              }
              @include breakpoint('small') {
                border: none;
                display: block;
                padding: 0px;
              }
              a {
                color: $color-black;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
          .tile-cta {
            color: $color-black !important;
            display: block;
            overflow: hidden;
            position: relative;
            margin: 15px auto 0;
            text-decoration: none;
            font-weight: $font-weight-600;
            img,
            svg {
              width: 25px;
              height: 25px;
              float: left;
              margin: 0 20px 0 0;
              @include breakpoint('small') {
                margin: 0 10px 0 0;
              }
            }
            span {
              float: left;
              @include breakpoint('small') {
                width: calc(100% - 35px);
              }
            }
            &:hover {
              text-decoration: none;
            }
          }
          .search-result__bookmark {
            top: 4px;
            margin-left: 0;
          }
        }
      }
    }
  }
}