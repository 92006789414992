.hub {
    header {
        margin: 30px 0;
    }

    .hub__left-sidebar {
        min-width: 300px;
        max-width: 300px;
        margin-right: 20px;
        float: left;
        @include breakpoint('medium') {
            max-width: 100%;
            margin-right: 0px;
        }

        h3 {
            margin-bottom: .5rem;
        }

        .article-preview__headline {
            margin-top: .5rem;
        }
    }

    .hub__right-sidebar {
        width: calc(100% - 320px);
        float: left;
        @include breakpoint('medium') {
            width: 100%;
        }

        .search__facets {
            min-width: 75%;
            max-width: 100%;
            width: 75%;
            margin: 0px;
            border: none;
            @include breakpoint('medium') {
                min-width: 100%;
                width: 100%;
                display: block;
            }
            .facets__header {
                padding: 0px;
                border: none;
                float: left;
                margin: 9px 0 0;
                cursor: auto;
                @include breakpoint('medium') {
                    width: 100%;
                }
            }
            .facets__content {
                padding: 0px;
            }
        }

        .search-sort {
            width: 25%;
            float: left;
            margin: 9px 0 0;
            .search-sort__control-type.is-selected {
                span {
                    text-decoration: none;
                }
            }
            .search-sort__sort-by {
                @include breakpoint('medium') {
                    float: left;
                }
            }
        }

        .search-results {
            margin: 0px;
            float: left;
            .search-result {
                position: relative;
                min-height: 200px;
                display: flex;
                flex-wrap: wrap;
                @include breakpoint('small') {
                    display: block;
                }
                > a {
                    position: relative;
                    width: 215px;
                    height: 143px;
                    margin-right: 20px;
                    text-align: center;
                    border: 1px solid $color-grey-5;
                    @include breakpoint('medium-small') {
                        display: inline-block;
                    }
                    @include breakpoint('small') {
                        width: 100%;
                        height: auto;
                        margin-right: 0px;
                    }
                    img {
                        margin-bottom: 0px;
                    }
                }
                .article-metadata {
                    width: 100%;
                }
                .search-result__body {
                    flex: 1 1;
                }
                &.sponsored_cont {
                    padding: 10px;
                }
            }
        }

        .search-result__heading {
            display: inherit;
        }

        .search-result__tags {
            .search-result__taxonomy {
                &:first-child {
                    padding-left: 0px;
                }
            }
        }

        form {
            margin-bottom: 2px;
            fieldset {
                border: none;
                margin: 0px;
                padding: 0px;

                ul {
                    li {
                        width: 100%;
                        float: left;
                        margin-left: 10px;
                        @include breakpoint('medium') {
                            margin-left: 0px;
                            margin-top: 10px;
                        }
                        input {
                            width: 70%;
                            @include breakpoint('medium') {
                                width: 85%;
                            }
                        }
                        ul {
                            li {
                                ul {
                                    li {
                                        width: 50%;
                                        margin: 0px;
                                        @include breakpoint('medium') {
                                            width: 100%;
                                        }
                                        &.facets__field-group {
                                            padding-left: 0px;
                                            svg {
                                                margin-top: 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .search-pagination__wrapper {
            border: none;
            margin: 0px;
            float: left;
            width: 100%;
        }
    }

    .topic-featured-article__image {
        max-width: 100%;
        max-height: 100%;
        float: none;
        margin-right: 0px;
        @include breakpoint('small') {
            max-width: initial;
            max-height: initial;
        }
    }
    
}