.pr-single {
    display: flex;
    gap: 40px;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
        margin: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
        flex-direction: column;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    .omd-body,
    .omd-body p {
        @include font($font-size-20, $font-weight-400, $line-height-157, $letter-spacing-08);
        strong {
            @include font($font-size-18, $font-weight-700, $line-height-135, $letter-spacing-025);
            margin-bottom: map-get($spacers, 1)*0.75;
        }
        & > p {
            margin-bottom: map-get($spacers, 1)*1.5;
            &.highlight {
                padding: 10px 21px 10px 18px;
                background: #f8f3fe;
                border-left: 8px solid $omd-dark-purple-2;
                font-style: italic;
                @include font($font-size-20, $font-weight-600, $line-height-15, $letter-spacing-05);
            }
        }
        img {
            max-width: 100%;
        }
        a {
            color: $omd-dark-purple-2;
            text-decoration: none;
        }
    }
}