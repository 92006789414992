.fluid-carousel {
  margin: map-get($spacers, 1)*5 auto;
  @include breakpoint('medium') {
    margin: map-get($spacers, 1)*3.5 auto;
  }
  &.no-top-margin {
      margin-top: 0;
  }
  &.no-bottom-margin {
      margin-bottom: 0;
  }
  .owl-item {
    & > .item {
      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background: url(/dist/img/omdia/vector-bg.png) no-repeat right bottom;
        background-size: 70%;
        z-index: 1;
        @include breakpoint('medium-small') {
          right: 0;
          bottom: -15px;
          background-position: right bottom;
          background-size: 170%;
        }
      }
      img {
        height: 610px;
        object-fit: cover;
      }
    }
    &:nth-of-type(1) > .item > .item-wrapper,
    &:nth-of-type(6) > .item > .item-wrapper {
      .h1::before {
        background: $omd-enterprise-gradient;
      }
    }
    &:nth-of-type(2) > .item > .item-wrapper,
    &:nth-of-type(7) > .item > .item-wrapper {
      .h1::before {
        background: $omd-bright-purple;
      }
    }
    &:nth-of-type(3) > .item > .item-wrapper,
    &:nth-of-type(8) > .item > .item-wrapper {
      .h1::before {
        background: $omd-dark-purple;
      }
      & > .item-content > .cta {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:nth-of-type(4) > .item > .item-wrapper,
    &:nth-of-type(9) > .item > .item-wrapper {
      .h1::before {
        background: $omd-bright-blue;
      }
      & > .item-content > .cta {
        left: 100%;
        transform: translateX(-100%);
      }
    }
    &:nth-of-type(5) > .item > .item-wrapper,
    &:nth-of-type(10) > .item > .item-wrapper {
      .h1::before {
        background: $omd-dark-purple-2;
      }
    }
  }
  .item-wrapper {
    @include inf-container();
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: $color-white;
    z-index: 1;
    @include breakpoint('medium-small') {
      padding-bottom: map-get($spacers, 1)*3.5;
      padding-top: map-get($spacers, 1)*3.5;
      justify-content: flex-start;
    }
    & > .h1 {
      font-family: $font-family-opensans;
      font-size: $font-size-48;
      font-weight: bold;
      line-height: $line-height-56;
      letter-spacing: $letter-spacing-05;
      @include spacer(margin-bottom, map-get($spacers, 3));
      padding-left: 2.5rem;
      position: relative;
      @include breakpoint('medium-small') {
        font-size: $font-size-40;
        line-height: $line-height-50;
        letter-spacing: $letter-spacing-05;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;
      }
      @include breakpoint('medium-small') {
        padding-left: 1.8rem;
      }
    }
    & > .item-content {
      max-width: 600px;
      & > p {
        font-family: $font-family-opensans;
        font-size: $font-size-25;
        font-weight: bold;
        letter-spacing: $letter-spacing-125;
        line-height: $line-height-40;
        font-weight: normal;
        @include breakpoint('medium-small') {
          font-size: $font-size-20;
          line-height: $line-height-30;
          letter-spacing: $letter-spacing-02;
        }
        &.semibold {
          font-weight: bold;
        }
      }
      & > .cta {
        @include spacer(margin-top, map-get($spacers, 3));
      }
    }
  }
  .owl-controls {
    position: absolute;
    bottom: 81px;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: 0 auto;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint('medium-small') {
      bottom: 71px;
    }
    .owl-dots {display: flex;
      width: fit-content;
      margin-left: auto;
      gap: 24px;
      @include breakpoint('medium-small') {
        margin-right: auto;
      }
      .owl-dot {
        span {
          width: 16px;
          height: 16px;
          border: 2px solid $color-white;
          background: $color-white;
          margin: 0;
        }
        &:first-of-type.active {
          & > span {
            background: $omd-enterprise-gradient;
          }
        }
        &:nth-of-type(2).active {
          & > span {
            background: $omd-bright-purple;
          }
        }
        &:nth-of-type(3).active {
          & > span {
            background: $omd-dark-purple;
          }
        }
        &:nth-of-type(4).active {
          & > span {
            background: $omd-bright-blue;
          }
        }
        &:last-of-type.active {
          & > span {
            background: $omd-dark-purple-2;
          }
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  height: 610px;
  overflow: hidden;
  &.owl-loaded {
    display: inherit;
    flex-direction: inherit;
    height: inherit;
    overflow: inherit;
  }

  & > .item {
    position: relative;
    height: 100%;
    display: none;
    &:first-of-type {
      display: flex;
    }
    &:nth-of-type(1) > .item-wrapper {
      .h1::before {
        background: $omd-enterprise-gradient;
      }
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}