.inf-container.commissioned-research {
	margin: map-get($spacers, 1)*5 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  .topic-section {
    &-header {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      align-items: center;
      justify-content: end;
    }
    &-title {
      margin-right: auto;
    }
  }
  .tile-list {
    flex-basis: calc(50% - 10px);
  }
  .pagination-list, .pagination-list {
    width: fit-content;
    margin-left: auto;
    & > li {
      &::before {
        display: none;
      }
      &.active, &.is-active {
        border-color: $omd-dark-purple-2;
        & > a {
          color: $omd-dark-purple-2 !important;
        }
      }
      a {
        border-bottom: none !important;
      }
    }
    @include breakpoint('medium') {
      margin-top: map-get($spacers, 1)*1.75;
    }
  }
  .loading {
    margin: map-get($spacers, 5) auto;
    display: block;
  }
  .date-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 5px;
    .custom-select {
      width: auto;
      &::after {
        background: url(img/omd/chevron_down_purple.svg) center no-repeat;
      }
      .custom-select-value {
        border: none;
        padding: 0 50px 0 0;
        color: $omd-purple;
        font-size: $font-size-16;
      }
      .custom-select-list {
        &-box {
          right: 0;
          width: auto;
        }
        li {
          white-space: nowrap;
        }
      }
    }
  }
}