.search-result {
  $fraction-change: 0.8;

  $h1-font-size: 3.75rem * $fraction-change;
  $h2-font-size: 3rem * $fraction-change;
  $h3-font-size: 2.5rem * $fraction-change;
  $h4-font-size: 2rem * $fraction-change;
  $h5-font-size: 1.5rem * $fraction-change;
  $h6-font-size: 1.25rem * $fraction-change;
  $font-size-24: 1.5rem * $fraction-change;
  $font-size-20: 1.25rem * $fraction-change;
  $font-size-17: 1.05rem * $fraction-change;
  $font-size-18: 1.125rem * $fraction-change;
  $font-size-16: 1rem * $fraction-change;
  $font-size-14: 0.875rem * $fraction-change;
  $font-size-15: 0.938rem * $fraction-change;
  $font-size-12: 0.75rem * $fraction-change;
  $font-size-36: 2.25rem * $fraction-change;
  $font-size-40: 2.5rem * $fraction-change;
  $font-size-48: 3rem * $fraction-change;

  $spacer-0: 1rem * $fraction-change;
  $spacer-1: 1.25rem * $fraction-change;
  $spacer-2: 1.5rem * $fraction-change;
  $spacer-3: 2rem * $fraction-change;
  $spacer-4: 3rem * $fraction-change;
  $spacer-5: 4.5rem * $fraction-change;
  $spacer-6: 5rem * $fraction-change;

  position: relative;
  border-top: 1px solid $color-black;
  @include spacer(padding-block, $spacer-2);
  color: $body-text-colour;
  width: 100%;
  &__body {
    display: flex;
    flex-flow: row wrap;
    @include omd-light-af-hover(".search-result__heading");
    & > .search-result {
      &__media-type {
        padding-left: 6px;
        span {
          display: block;
          width: $font-size-20;
          height: $font-size-20;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &__pubdate {
        padding-left: $font-size-12;
        margin-left: $font-size-12;
        border-left: 1px solid #000;
        padding-right: $font-size-12;
        @include isIE {
          padding-right: 30px;
        }
        padding-right: $font-size-12;
        border-right: 1px solid $body-text-colour;
      }
      &__author {
        // to hide 'by'
        font-size: 0;
        padding-right: $font-size-12;
        margin-right: $font-size-12;
        @include isIE {
          padding-right: 30px;
        }
        a {
          color: $color-black;
        }
      }
      &__content-type,
      &__media-type,
      &__pubdate,
      &__author {
        @include spacer(margin-block, $spacer-0 / 2);
      }
    }
    .sign-posting {
      display: none;
    }
    .part-of-collection {
      @include spacer(margin-block, $spacer-0 / 2);
    }
  }
  &__line-separator,
  &__publication,
  &__tags {
    display: none;
  }
  &__heading {
    order: 1;
    width: 100%;
    @include header-style(
      $h5-font-size,
      $font-weight-600,
      $line-height-125,
      -$letter-spacing-094
    );
    color: inherit;
    text-decoration: none;
    padding-right: $spacer-3;
    @include breakpoint("medium") {
      @include header-style($h4-font-size, $font-weight-600, $line-height-125, -$letter-spacing-125);
    }
  }
  &__subtitle {
    order: 2;
    width: 100%;
    @include spacer(margin-bottom, $spacer-0);
    font-family: inherit;
    font-size: $font-size-18;
    color: $omd-dark-indigo;
    text-transform: inherit;
    line-height: inherit;
  }
  &__bookmark {
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 9;
    margin-inline: 0;
    @include spacer(margin-block, $spacer-0 / 2);
    &::before {
      @include inf-icon("img/omd/bookmark_black.svg", 17px, 23px);
    }
    &[data-is-bookmarked="true"] {
      &::before {
        @include inf-icon("img/omd/bookmark_solid_black.svg", 17px, 23px);
      }
    }
    svg {
      display: none;
    }
  }
  &__content-type {
    order: 3;
  }
  &__media-type {
    order: 4;
  }
  &__pubdate {
    padding-right: $font-size-12;
    margin-right: $font-size-12;
    order: 5;
    font-family: inherit;
    font-size: $font-size-14;
    color: inherit;
    text-transform: inherit;
    line-height: inherit;
    letter-spacing: 0;
  }
  &__author {
    position: relative;
    order: 6;
    font-family: inherit;
    font-weight: inherit;
    a {
      position: relative;
      font-family: inherit;
      font-size: $font-size-14;
      font-weight: inherit;
      color: $body-text-colour;
      text-transform: inherit;
      line-height: inherit;
      letter-spacing: 0;
      text-decoration: none;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $body-text-colour;
        transition: width 0.3s ease-out;
      }
      &:hover::after {
        width: 100%;
      }
    }
  }
  &__freelabel {
    order: 7;
    span {
      display: block;
      padding: 0 $font-size-12;
      font-family: inherit;
      font-size: $font-size-14;
      line-height: inherit;
      letter-spacing: $letter-spacing-1;
      color: $omd-white;
      background-color: $omd-purple;
    }
  }
  &__description {
    order: 8;
    width: 100%;
    font-family: inherit;
    font-size: $font-size-16;
    color: inherit;
    text-transform: inherit;
    line-height: inherit;
    p {
      @include spacer(margin-bottom, $spacer-0 / 2);
      &:empty {
        display: none;
      }
    }
  }
  &__entitlement {
    order: 9;
    width: 100%;
    font-family: inherit;
    font-weight: 600;
    font-size: $font-size-12;
    color: $omd-purple;
    text-transform: uppercase;
    line-height: inherit;
    letter-spacing: $letter-spacing-1;
    & > .lock__icon,
    & > .lock-open__icon {
      fill: $omd-purple;
      width: 10px;
      height: 10px;
      vertical-align: middle;
    }
    & > span {
      vertical-align: middle;
    }
  }
  &__assets {
    order: 10;
    width: 100%;
  }
  &__assetwrapper {
    @include spacer(margin-bottom, $spacer-0);
  }
  &__asset {
    display: block;
    @include spacer(margin-bottom, $spacer-0);
    &:last-child {
      margin-bottom: 0;
    }
    .inf-sr-asset {
      padding-left: 2rem;
      font-size: $font-size-16;
      &::before {
        top: auto;
        bottom: auto;
        width: $font-size-24;
        height: $font-size-24;
      }
    }
  }
  &__assetsmore {
    position: relative;
    padding-left: $font-size-40;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $omd-purple;
    }
  }
  &__content-type {
    font-family: inherit;
    font-size: $font-size-14;
    color: inherit;
    text-transform: inherit;
    line-height: inherit;
    letter-spacing: $letter-spacing-1;
  }
  &__heading,
  &__freelabel,
  &__entitlement,
  &__description,
  &__assets {
    @include spacer(margin-block, $spacer-0 / 2);
  }
}