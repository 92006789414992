.tile-list {
	&-banner {
		position: relative;
		background-color: $color-white;
		margin: map-get($spacers, 1)*5 0;
		@include breakpoint("medium") {
			margin: map-get($spacers, 1)*3.5 0;
		}
		&.no-top-margin {
			margin-top: 0;
		}
		&.no-bottom-margin {
			margin-bottom: 0;
		}
		&-wrapper {
			@extend .inf-col;
			position: relative;
			max-width: 1260px;
			margin: 0 auto;
			padding: 0 map-get($spacers, 1)*1.5;
			gap: 36px;
			@include breakpoint("medium") {
				padding: 0 map-get($spacers, 1)*0.75;
			}

            & > .pagination-list {
                margin-top: map-get($spacers, 1)*2;
            }
		}
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			& > a {
				color: $color-black;
				text-decoration: underline;
				&:hover {
					color: $omd-dark-purple-2
				}
			}
		}
		&-title {
			@include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
			color: $color-black;
			@include breakpoint("medium") {
				@include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
			}
		}
		&.dark-theme {
			padding: map-get($spacers, 1)*5 0;
			@include breakpoint("medium") {
				padding: map-get($spacers, 1)*2.5 0;
			}
			& > img {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.tile-list-banner-header {
				& > a {
					color: $color-white;
					&:hover {
						color: $omd-dark-purple-2					}
				}
			}
			.tile-list-banner-title {
				color: $color-white;
				@include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-02);
				max-width: 70%;
				@include breakpoint("medium") {
					@include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
				}
			}
			.tile-list-box {
				gap: 40px;
				& > .tile-list {
					flex-basis: calc(34.35% - 40px);
				}
				@include breakpoint("medium") {
					gap: 20px;
					flex: 1;
				}
			}
		}
		&-bottom {
			display: flex;
			gap: 40px;
			@include breakpoint('medium-small') {
				flex-direction: column-reverse;
				gap: 20px;
			}
		}
	}
	&-box {
		display: flex;
		flex-wrap: wrap;
		gap: 36px 20px;
		& > .tile-list {
			flex-basis: calc(33.85% - 20px);
			@include breakpoint("medium") {
				flex-basis: calc(50% - 8px);
			}
		}
		@include breakpoint("medium") {
			gap: 20px 15px;
		}
		@include breakpoint("medium-small") {
			flex-direction: column;
		}
	}
}