.pop-out__sign-in {
    overflow: auto;

    &-header {
        margin-top: -5px; // Boo magic number. Center with dismiss X
        margin-bottom: 1.5rem;
    }

    &-submit {
        @extend .button--filled;
        clear: both;
        float: right;
    }

    &-remember-me {
        color: $color-abbey-gray;
        font-size: 0.9375rem;

        & input[type=checkbox] {
            vertical-align: middle;
        }
    }

}

.pop-out__reset-password {
    @extend a;
    cursor: pointer;
}
