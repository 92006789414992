.GeneralRTEComponent {
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-117);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    @include breakpoint("medium") {
        margin: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
    }

    @include breakpoint("medium-small") {
        @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-117);
        padding: 0 map-get($spacers, 1)*0.75;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    .marketing-enhancement-content {
        width: 100%;
        & > iframe {
            display: flex;
            border: none;
            margin: 0 auto;
        }
        a {
            color: $omd-dark-purple-2;
            text-decoration: none;
        }
        & > .left,
        & > .right {
            float: left;
            width: calc(50% - 20px);
            & > iframe {
                border: none;
            }
            @include breakpoint("medium") {
                width: 100%;
            }
        }
        & > .right {
            margin-left: map-get($spacers, 1)*2;
            @include breakpoint("medium") {
                margin-top: map-get($spacers, 1)*2;
                margin-left: 0;
            }
        }
        ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        ul.arrow-list {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: map-get($spacers, 1)*2;
            & > li {
                position: relative;
                padding-left: map-get($spacers, 1)*2.1;
                @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
                &::before {
                    content: "";
                    background: url(/dist/img/omdia/dotted-arrow-purple.svg) left top no-repeat;
                    width: 22px;
                    height: 18px;
                    margin: 0;
                    background-size: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
        }        
    }
    & > div,
    & > p {
        @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-117);
        @include breakpoint("medium-small") {
            @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-117);
        }
    }
    .rte-heading,
    .rte--sub-heading {
        @include font($font-size-31, $font-weight-bold, $line-height-normal, $letter-spacing-025);
    }
    .rte--sub-heading {
        font-size: $font-size-25;
    }    

    .content-block {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}