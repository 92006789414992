.horizontal-menu {
  .sub-menu__wrapper {
    top: 50px;
    @include breakpoint('ipad') {
      top: 40px;
    }
  }
}

.sub-menu__wrapper {
  padding: 0 15px;
  box-shadow: 0 0 20px rgba(247, 177, 66, 0.3);
  position: relative;
  z-index: 99;
  position: sticky;
  top: 0px;
  @include breakpoint('ipad') {
    padding: 15px;
    top: 40px;
  }
  .sub-menu__container {
    max-width: 940px;
    margin: 0 auto;
    .sub-menu__row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .sub-menu__logo {
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .sub-menu__navigation {
        position: relative;
        top: initial;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 1rem;
        overflow: visible;
        transition: height .5s, padding .5s;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @include breakpoint('ipad') {
          width: 100%;
          height: 0;
          overflow: hidden;
          position: absolute;
          top: 80px;
          left: 0;
          flex-direction: column;
        }
        .sub-menu__navigation-list {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          height: 100%;
          width: 100%;
          @include breakpoint('ipad') {
            flex-direction: column;
          }
          > li {
            > [type="checkbox"] {
              display: none;
            }
            a {
              display: block;
              padding: 1.5rem 1rem;
              text-decoration: none;
              @include breakpoint('ipad') {
                padding: .5rem 1rem;
              }
            }
          }
          .dropdown {
            position: relative;
            z-index: 999999;
            label {
              position: absolute;
              right: 2px;
              width: 8px;
              height: 8px;
              top: 50%;
              margin-top: -4px;
              transform: rotate(135deg);
              transition: transform 300ms;
              @include breakpoint('ipad') {
                top: 13px;
                transform: rotate(45deg);
              }
            }
            .submenu {
              position: absolute;
              top: 100%;
              left: 20px;
              list-style: none;
              width: 150px;
              @include breakpoint('ipad') {
                display: none;
                position: relative;
                top: initial;
                left: 20px;
                width: auto;
              }
              li {
                a {
                  display: block;
                  padding: 12px 10px;
                  text-decoration: none;
                  @include breakpoint('ipad') {
                    padding: .5rem 2rem;
                  }
                }
              }
            }
            >[type="checkbox"]~.submenu,
            >[type="checkbox"]:checked~.submenu {
              display: none;
            }
            @include breakpoint('ipad') {
              >[type="checkbox"]:checked~.submenu {
                display: block;
              }
            }
            &:hover {
              >[type="checkbox"]~.submenu,
              >[type="checkbox"]:checked~.submenu {
                display: block;
              }
              @include breakpoint('ipad') {
                >[type="checkbox"]~.submenu {
                  display: none;
                }
                >[type="checkbox"]:checked~.submenu {
                  display: block;
                }
              }
            }
            >[type="checkbox"]:checked~label {
              transform: rotate(135deg);
            }
          }
        }
      }
      .sub-menu__hamburger-icon {
        display: none;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint('ipad') {
          display: block;
        }
        >.bar1,
        >.bar2,
        >.bar3 {
          width: 32px;
          height: 4px;
          margin: 4px 0;
          transition: 0.4s;
          display: block;
        }
      }
      #responsive-nav[type="checkbox"] {
        display: none;
        height: 20px;
        width: 20px;
      }
      #responsive-nav:checked+label>.bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
      }
  
      #responsive-nav:checked+label>.bar3 {
        -webkit-transform: rotate(45deg) translate(-5px, -5px);
        transform: rotate(45deg) translate(-5px, -5px);
      }
  
      #responsive-nav:checked+label>.bar2 {
        opacity: 0;
      }
  
      #responsive-nav:checked~.sub-menu__navigation {
        padding: 2rem 1rem .5rem;
        height: calc(100vh - 80px);
        width: 100%;
      }
    }
  }
}