.search-pagination {
  &__wrapper {
    padding: 10px 0 50px;
    border-top: 2px solid $color-light-gray;
    @include search-page-structure();

    @include breakpoint('medium') {
      order: 5;
    }
  }

  &__pagination-list {
    text-align: right;

    li {
      @include textMix('Roboto', 15px, 400);
      display: inline;

      &:not(:last-child) {
        margin-right: 10px;
      }

      a {
        padding: 4px 10px;
      }

      &.is-active a {
        background: $color-concrete-gray;
        color: $color-abbey-gray;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
