$omd-purple: #731aed;
$omd-light-purple: #b98df6;
$omd-dark-indigo: #200742;
$body-text-colour: black;
$color-black: black;
$color-light-black: #828282;
$color-medium-black: #424242;
$color-white: #ffffff;
$omd-blue: #4976e5;
$omd-ligher-blue: #E4EBEE;
$omd-darkblue:#1d1a28;
$omd-light-grey:#f4f3f6;
$omd-lighter-grey: #e5e5e5;
$omd-medium-grey:#d8d8d8;
$omd-medium-grey2: #dadada;
$omd-dark-grey:#acacb0;
$omd-darker-grey:#52515c;
$omd-box-hover-purple: #f8f3fe;

$h1-font-size : 3.75rem !default;
$h2-font-size : 3rem !default;
$h3-font-size : 2.5rem !default;
$h4-font-size : 2rem !default;
$h5-font-size : 1.5rem !default;
$h6-font-size : 1.25rem !default;
$font-size-24 : 1.5rem !default;
$font-size-20 : 1.25rem !default;
$font-size-17 : 1.05rem !default;
$font-size-18 : 1.125rem !default;
$font-size-16 : 1rem !default;
$font-size-14 : 0.875rem !default;
$font-size-15 : 0.938rem !default;
$font-size-12 : 0.75rem !default;
$font-size-36 : 2.25rem !default;
$font-size-40 : 2.5rem !default;
$font-size-48 : 3rem !default;

$font-family-opensans : "Open Sans", sans-serif !default;

$font-weight-600 : 600;
$font-weight-700 : 700;
$font-weight-400 : 400;
$font-weight-300 : 300;
$font-weight-bold : bold;
$font-weight-normal : normal;


$line-height-125 : 1.25;
$line-height-15 : 1.5;
$line-height-1 :1;
$line-height-2 :2;
$line-height-25 :2.5;
$line-height-157 : 1.57;
$line-height-156 : 1.56;
$line-height-normal : normal;
$line-height-179 : 1.79;
$line-height-135 : 1.35;
$line-height-088 : 0.88;
$line-height-133 : 1.33;
$line-height-12 :1.2;
$line-height-13 : 1.3 ;

// Letter spacing of -2px
$letter-spacing-1: 1px;
$letter-spacing-2 : 2px;
$letter-spacing-125 : 1.25px;
$letter-spacing-05 : .5px;
$letter-spacing-02 : .2px;
$letter-spacing-025 : .25px;
$letter-spacing-054 : .54px;
$letter-spacing-08: .8px;
$letter-spacing-175 : 1.75px;
$letter-spacing-117 : 1.17px;
$letter-spacing-normal : normal;
$letter-spacing-094 : 0.94px;

$font-stretch-normal: normal;
$font-style-normal: normal;


$banner-width-770 : 48.125rem;
$banner-width-668 :41.75rem;
$banner-width-290 :18.125rem;

$spacers: (
  0: 1rem, //16px
  1: 1.25rem, //20px
  2: 1.5rem, //24px
  3: 2rem, //32px
  4: 3rem, //48px
  5: 4.5rem, //72px
  6: 5rem //80px
);

//TO DO : Replace ith styles above
$spacer-0: 1rem;  //16px
$spacer-1: 1.25rem;  //20px
$spacer-2: 1.5rem; //24px
$spacer-3: 2rem; //32px
$spacer-4: 3rem; //48px
$spacer-5: 4.5rem; //72px
$spacer-6: 5rem;  //80px
