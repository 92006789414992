.split-banner {
    margin: map-get($spacers, 1)*5 auto;
    background-color: $omd-dark-purple-2;
    @include breakpoint('medium') {
      margin: map-get($spacers, 1)*3.5 auto;
    }
    position: relative;
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    &__wrapper {
        display: flex;
        margin: 0 auto;
        align-items: center;
        max-width: 1260px;
        padding: 0 $spacer-2*1.25;
        @include breakpoint('ipad') {
            padding: 0;
        }
        @include breakpoint('medium') { 
            display: block;
            flex-basis: 100%;
            width: 100%;
            padding: 0;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 50%;
            top: 0;
            position: absolute;
            @include breakpoint('medium') { 
                width: 100%;
                height: auto;
                top: inherit;
                position: relative;
                left: inherit;
                right: inherit;
            }
            @include breakpoint('medium-small') { 
                height: 119px;
            }
        }
    }
    &__content {
        flex-grow: 1;
        max-width: 50%;
        padding: $spacer-2*5 0;
        @include breakpoint('ml') {
            padding: $spacer-2*5 $spacer-2*2.5;
        }
        @include breakpoint('ipad') {
            padding: map-get($spacers, 1)*3;
        }
        @include breakpoint('medium') {
            max-width: 100%;
            width: 100%;
            padding: map-get($spacers, 1)*2 map-get($spacers, 1);
        }
        & > div {            
            display: flex;
            flex-direction: column;
            gap: 26px;
            max-width: 480px;
            @include breakpoint('medium') {
                max-width: initial;
                gap: 20px;
            }
        }
        p {
            @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-1);
            color: $omd-white;
            margin-bottom: 0;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @include breakpoint('medium') {
                font-size: $font-size-14;
            }
        }
        a {
            width: auto;
            @include breakpoint('medium') {
                font-size: $font-size-14;
                font-weight: $font-weight-600
            }
        }
    }
    &__title {
        @include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-05);
        color: $omd-white;
        @include breakpoint('medium') {
            font-size: $font-size-25;
        }
    }
    &.text-align-right {
        img {
            left: 0;
        }
        .split-banner__content,
        .split-banner__content > div {
            margin-left: auto;
        }
    }
    &.text-align-left {
        img {
            right: 0;
        }
        .split-banner__content,
        .split-banner__content > div {
            margin-right: auto;
        }
    }
    &.grid {
        background-color: transparent !important;
        @include breakpoint("ipad") {
            padding: 0 map-get($spacers, 1)*1.5;
        }
        @include breakpoint("medium") {
            padding: 0 map-get($spacers, 1)*0.75;
        }
        img {
            position: relative;
            top: auto;
            left: auto;
            width: calc(50% - 30px);
            height: 400px;
            @include breakpoint("medium") {
                width: 100%;
                height: 400px;
            }
        }
        .split-banner__content {
            padding: 0;
            & > div {
                max-width: calc(100% - 30px);
                margin: initial;
                padding-left: map-get($spacers, 1)*2;
                @include breakpoint("medium") {
                    width: 100%;
                    max-width: initial;
                    padding: 0;
                    margin-top: map-get($spacers, 1)*2;
                }
                & > .split-banner__title,
                & > p {
                    color: $color-black;
                    overflow: visible;
                    text-overflow: initial;
                    -webkit-line-clamp: initial;
                }
                a {
                    color: $color-black;
                }
                .cta.cta-primary {
                    @include cta-primary();
                    background: $color-black;
                    color: $color-white;
                    @include cta-solid();
                    &:hover {
                        border-color: $color-black;
                    }
                }
            }
        }
        &.text-align-left {
            img {
                order: 2;
            }
            .split-banner__content {
                order: 1;
                & > div {
                    padding-left: initial;
                    padding-right: map-get($spacers, 1)*2;
                }
            }
        }
    }
}