.search-sort {
    flex: 0 1 auto;

    &__sort-by {
        display: flex;
        align-items: flex-end;
    }

    &__title {
        text-transform: uppercase;
        margin-right: 15px;
    }

    &__line-separator {
        @extend .search-result__line-separator;
        margin-right: 0;
    }

    &__control-list {
        display: flex;
    }

    &__control-type {

        a {
            @include textMix('Roboto', 15px, 400);
        }

        &.is-selected span,
        &.is-selected a {
            text-decoration: underline;
        }

        .sorting-arrows__arrow--up {
            margin-top: 2px; // Better vertical alignment
        }

        .sorting-arrows__arrow {
            @include svg-fill($color-light-gray);
        }

        &.is-sorted-up .sorting-arrows__arrow--down,
        &.is-sorted-down .sorting-arrows__arrow--up {
            @extend .sorting-arrows__arrow.is-active;
        }

        &.is-sorted-up a,
        &.is-sorted-down a {
            @include on-event(true) {
                text-decoration: none;
            }
        }
    }

}
