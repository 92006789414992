.cta-banner {
    display: flex;
    align-items: center;
    gap: 25px;
    background: $color-white;
    position: relative;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
      margin: map-get($spacers, 1)*3.5 auto;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    &.light-theme {
        background: $omd-light-grey;
    }
    &-wrapper {
        max-width: 1260px;
        width: 100%;
        margin: 0 auto;
        padding: 0 30px;
        display: flex;
        flex-flow: row wrap;
        @include breakpoint('medium') {
            padding: 0;
        }
        & > .cta-banner-content > .button--outline {
            margin-left: 0;
            @include spacer(padding, map-get($spacers, 0));
            @include spacer(padding-left, map-get($spacers, 1));
            padding-right: 56px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                background: url(/dist/img/omd/arrow_right_black.svg) center no-repeat;
                right: 20px;
                width: 26px;
                height: 100%;
                top: 0;
            }
            &:hover {
                border-color: $color-black;
                &::after {
                    background: url(/dist/img/omd/arrow_right_white.svg) center no-repeat;
                }
            }
        }
    }
    &-image {
        display: flex;
        width: 850px;
        height: 100%;
        min-height: 250px;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        background-size: 100%;
        background-position: right top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1900px) {
            width: calc(45% - 30px);
        }
        @include breakpoint('ipad') {
            background-position: right center;
        }
        @include breakpoint('medium') {
            flex-direction: column;
            width: 100%;
            position: relative;
            background: none !important;
            min-height: initial;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right top;
            &.cta-banner-image-mobile {
                display: none;
                @include breakpoint('medium') {
                    display: block;
                    height: 310px;
                }
                @include breakpoint('small') {
                    height: 154px;
                }
            }
            &.cta-banner-image-desktop {
                display: block;
                @include breakpoint('medium') {
                    display: none;
                }
            }
        }
    }
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        padding: 100px 0;
        width: 55%;
        margin-left: auto;
        @include breakpoint('medium') {
            width: 100%;
            margin: 0;
            padding: 40px 25px;
            align-items: center;
        }
        & > .h1 {
            font-family: $font-family-opensans;
            @include font($font-size-48, $font-weight-bold, $line-height-normal, $letter-spacing-05);
            @include breakpoint('medium') {
              @include font($font-size-32, $font-weight-bold, $line-height-36, $letter-spacing-normal);
              text-align: center;
            }
        }
        & > .h4 {
            font-family: $font-family-opensans;
            @include font($font-size-25, $font-weight-bold, $line-height-40, $letter-spacing-025);
            @include breakpoint('medium') {
                @include font($font-size-20, $font-weight-600, $line-height-30, $letter-spacing-normal);
                text-align: center;
            }
        }
        & > ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            & > li {
                display: flex;
                align-items: center;
                gap: 20px;
                @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-05);
                @include breakpoint('medium-small') {                    
                    @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
                }
                & > svg {
                    fill: $omd-dark-purple-2;
                    width: 23px;
                    height: 18px;
                    transform-origin: left;
                    transition: transform 300ms;
                }
                & > span {
                    flex: 1;
                }
                &:hover {
                    & > svg {
                        fill: $color-black;
                        transform: scale(1.2);
                    }
                }
            }
        }
        & > p {
            letter-spacing: $letter-spacing-05;
            @include breakpoint('medium') {
                text-align: center;
            }
            @include breakpoint('medium-small') {                
                @include font($font-size-14, $font-weight-400, $line-height-15, $letter-spacing-05);
                margin-bottom: 0;
            }
        }
        & .cta {
            @include breakpoint('medium') {
                margin: auto;
            }
        }
    }
    &.dark-theme {
        .cta-banner-content {
            position: relative;
            color: $color-white;
            & > .cta {
                @include cta-secondary();
            }
            & > ul > li {
                & > svg {
                    fill: $color-white;
                }
                &:hover > svg {
                    fill: $color-white;
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $omd-purple-black-gradient;
            transform: rotate(180deg);
        }
    }
}