.col-4 {
  width: calc(33.3334% - 1rem) !important;
}
.col-8 {
  width: calc(66.6667% - 1rem) !important;
}
[class*='col-'] {
    @include breakpoint('small') {
        width: 100% !important;
    }
}

main {
	display: flex;
	margin-left: -330px;
	width: calc(100% + 330px);

	@include breakpoint('small') {
		width: 100%;
	}

	&.main--solo {
		margin-left: 0;
	}
}

.main__wrapper {
	float: left;
	height: 100%;
	width: calc(100% - 330px);
	padding-left: 70px;

	@include breakpoint('ipad') {
		padding: 0;
		flex: 0 0 auto;
		margin-left: 330px; // RH added on 2/26 to fix IIPP-572
	}
	@include breakpoint('small') {
		width: 100%;
		margin-left: 330px;
	}

	&--solo {
		width: 100%;
		margin-left: 0;
	}
}
.shift-content{
	margin-left: 330px;
	overflow-x: scroll;
	width: 100%;
}
.shift-main-content{
	left: 0;
	transform: translate3d(0, 0, 0) !important;
	-webkit-transform: translate3d(0, 0, 0) !important;
}
.main__content {
	max-width: 1240px;
	margin: 0px auto;
	padding: 0 20px;

	&:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
	}

	@include breakpoint('ipad') {
		width: auto;
		padding: 1rem 0;
		margin: 0 1rem;
		overflow: hidden;
	}

}
