// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  position: relative;
  clear: both;
  border-top: 1px solid #ccc;
  background-color: #f1f2f2;

  @include textMix("Roboto", 16px, 400);

  @include breakpoint("small") {
    text-align: center;
  }

  a,
  a:visited {
    color: #888;
    text-decoration: none;
  }
  a:hover,
  a:active {
    color: #888;
    text-decoration: underline;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &#informa-footer {
    border-top: 0;
    background-color: #454955;

    .divisional-footer {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 24px;
      overflow-x: unset;
      @media only screen and (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      & ~ .divisional-footer {
        .divisional-footer-container {
          margin-top: 50px;
          border-top: 1px solid #fff;
        }
      }
      .row {
        margin: 0 auto;
      }
      .divisional-footer-container {
        @media screen and (max-width: 992px) {
          text-align: center;
        }
        .alternate-menus {
          overflow: hidden;
          padding: 0 30px;
          @media screen and (max-width: 992px) {
            padding: 0;
          }
          &.long-lists {
            .menu-section {
              width: 32.5%;
              @media screen and (max-width: 992px) {
                width: 100%;
              }
              &:not(.social-icons) {
                .list-inline {
                  overflow: hidden;
                  li {
                    float: left;
                    width: 50%;
                    @media screen and (max-width: 992px) {
                      float: none;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
          .menu-section {
            display: table-cell;
            vertical-align: top;
            width: 32.5%;
            padding-left: 20px;
            padding-right: 20px;
            @media screen and (max-width: 992px) {
              display: block;
              padding-left: 0;
              padding-right: 0;
            }
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            &.long-list {
              width: 40%;
              @media screen and (max-width: 992px) {
                width: 100%;
              }
              .list-inline {
                overflow: hidden;
                li {
                  float: left;
                  width: 50%;
                  @media screen and (max-width: 992px) {
                    float: none;
                    width: 100%;
                  }
                }
              }
            }
            // if short-list
            &.short-list {
              width: 25%;
              @media screen and (max-width: 992px) {
                width: 100%;
              }
            }
            & + .social-icons {
              position: relative;
              width: 35%;
              @media screen and (max-width: 992px) {
                width: 100%;
                padding-left: 0;
              }
              &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: -20px;
                height: calc(100% - 77px);
                width: 1px;
                background-color: white;
                @media screen and (max-width: 992px) {
                  display: none;
                }
              }
            }
          }
          ul.list-inline > li {
            width: 100%;
          }
        }
        .bottom-menus {
          display: table;
          width: 100%;
          padding: 35px 0px;
          font-size: 0;
          border-bottom: 1px solid #fff;
          & > * {
            display: table-cell;
            vertical-align: middle;
            width: 33.334%;
            font-size: 1rem;
            @media screen and (max-width: 992px) {
              display: block;
              width: 100%;
            }
          }

          .logo {
            padding-left: 0px;
            padding-right: 0px;
            @media screen and (max-width: 992px) {
              padding: 80px 0px;
            }
            p {
              color: #fff;
              margin-top: 1em;
              font-size: 12px;
            }
          }
          .menu-section {
            text-align: right;
            @media screen and (max-width: 992px) {
              text-align: center;
            }
            &.social-icons {
              text-align: left;
              @media screen and (max-width: 992px) {
                text-align: center;
              }
            }
            & + .menu-section {
              @media screen and (max-width: 992px) {
                padding-top: 50px;
              }
            }
          }
        }
        ul {
          &.list-inline {
            font-size: 0;
            & > li {
              display: inline-block;
              font-size: 1rem;
              padding: 10px 15px 10px 0;
              word-break: break-word;
              @media screen and (max-width: 992px) {
                padding: 10px 15px;
              }
              a {
                color: #ffffff;
                font-size: 14px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &.social-icon {
              float: none;
              & > li {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                font-size: 1rem;
                padding-right: 15px;
                &:last-child {
                  padding-right: 0;
                  @media screen and (max-width: 992px) {
                    padding-right: 15px;
                  }
                }
                a {
                  width: 35px;
                  display: flex;
                  height: 35px;
                  background: #fff;
                  border-radius: 35px;
                  align-items: center;
                  justify-content: center;
                  font-size: 8px;
                  color: #525a5d;
                  text-decoration: none;
                  cursor: pointer;
                  i {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        .privacy-policy {
          font-size: 14px;
          text-align: right;
          padding: 35px 15px;
          max-width: 1200px;
          @media screen and (max-width: 992px) {
            text-align: center;
            padding: 35px 10px;
          }
          p {
            color: #ffffff;
            padding: 0 0px 0 18%;
            font-size: 14px;
            @media screen and (max-width: 992px) {
              padding: 0px;
            }
          }
        }
        .heading {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff;
          padding: 35px 0px 25px;
        }
      }
    }
  }

  .user-session-info {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, 0);
  }
  .footer__container {
    padding: 30px 0 0;
    overflow: hidden;
    @media only screen and (min-width: 1024px) and (max-width: 1360px) {
      margin-left: 70px;
    }
    @include breakpoint('ipad') {
      padding: 30px 24px 0;
    }
    @include breakpoint('medium-small') {
      padding: 30px 12px 0;
    }
    .footer__container-left {
      width: calc(50% - 10px);
      float: left;
      @include breakpoint('medium-small') {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .footer__container-right {
      width: calc(50% - 10px);
      float: left;
      margin-left: 20px;
      @include breakpoint('medium-small') {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}

.footer__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
  padding: 38px 24px;

  @extend .clearfix;
  @media only screen and (min-width: 1024px) and (max-width: 1360px) {
    margin-left: 70px;
  }
}

.footer__section {
  color: #6d6e71;
  flex: 0 1 auto;
  float: left;
  font-size: 15px;

  li {
    line-height: 36px;

    // Prevents <li> with lots of text from wrapping to the next column.
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;

    a {
      // <li> line-height determines spacing between each <li>
      // This allows links with lots of text to have better leading.
      display: inline-block;
      line-height: 20px;
    }
  }
}
.footer__section-header {
  @include textMix("Roboto Condensed", 15px, 700);
  color: #231f20;
  line-height: 36px;
  text-transform: uppercase;
}

// Separators between footer sections vary depending on the page width
// Desktop: one full-width separator between top and bottom halves (<hr>)
.footer__separator {
  background-color: #d1d3d4;
  border: 0;
  clear: both;
  display: block;
  height: 1px;
  margin: 28px 0;
  width: 100%;
}
// Tablet/smartphone: separators between each section (<ul> borders)
// @mixin allows for single source of styling truth, and for easily setting
// top or bottom border as needed, without duplicating code.
@mixin footer__section--faux-separator($border-location) {
  @if $border-location == "top" {
    border-top: 1px solid #d1d3d4;
    margin-top: 28px;
    padding-top: 28px;
  } @else if $border-location == "bottom" {
    border-bottom: 1px solid #d1d3d4;
    margin-bottom: 28px;
    padding-bottom: 28px;
  }
}

// Three potential widths for footer sections
.footer__section--single {
  width: 25%;

  ul {
    column-count: 1;
  }
}
.footer__section--double {
  width: 50%;

  ul {
    column-count: 2;
  }
}
.footer__section--triple {
  width: 75%;

  ul {
    column-count: 3;
  }
}

.footer__section--corporate {
  color: #808285;
  font-size: 12px;
  width: 25%;
}
.footer__logo {
  display: block;
  height: 60px;
  margin-bottom: 1rem;
}
.footer__logo-icon {
  a img {
    display: block;
    height: 60px !important;
    width: auto !important;
  }
  p {
    margin-bottom: 0;
  }
}
.footer__section--social {
  width: 25%;
}

.footer__share-icons {
  @include svg-fill($color-dusty-gray);
  margin-right: 20px;
  height: 24px;
  width: 24px;

  &:hover {
    @include svg-fill($color-zeus-black);
  }
}

@include breakpoint("medium") {
  .footer__section--social {
    order: 99; // Flex-order social block to "end" / bottom of page
    text-align: center;
    width: 100%;

    @include footer__section--faux-separator("top");

    li {
      display: inline;
      line-height: 28px;
      vertical-align: top;
    }

    .footer__section-header {
      margin-right: 22px;
    }
  }

  // 4 columns -> 3 columns
  .footer__section--corporate,
  .footer__section--single {
    width: calc(100% / 3);
  }

  .footer__section--double,
  .footer__section--triple {
    width: calc(100% * 2 / 3);
  }

  .footer__section--triple ul {
    column-count: 2;
  }
}

@include breakpoint("small") {
  .footer__wrapper {
    width: 100%;
    padding: 30px 12px;
  }

  // 3 columns -> 1 column

  .footer__section {
    float: none;

    &--corporate,
    &--social,
    &--single,
    &--double,
    &--triple {
      width: 100%;
      float: none;
    }

    &--double ul,
    &--triple ul {
      column-count: 1;
    }

    // Add separators (fake .footer__separator, basically)
    &--single {
      @include footer__section--faux-separator("bottom");
    }
  }

  .footer__logo {
    margin: 0 auto;
    height: 47px;
  }
}
