.btn-toggle-switch {
  width: 47px;
  height: 22px;
  position: relative;
  border-radius: 12px;
  background: $omd-dark-grey;
  border: none;
  &::before,
  &::after {
    position: absolute;
    text-transform: uppercase;
    line-height: 22px;
    top: 0;
    font-size: $font-size-20/2;
    font-weight: 600;
    transition: opacity 200ms ease-in;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &::before {
    content: "off";
    right: 5px;
    opacity: 1;    
    color: $color-white;
  }
  &::after {
    opacity: 0;
    left: 5px;
    content: "on";
    color: $color-white;
  }
  & > .handle {
    position: absolute;
    width: 14px;
    height: 14px;
    background: $color-white;
    border-radius: 20px;
    left: 3px;
    top: 3px;
    transition: left 200ms ease-in;
  }
  &.active {
    &::after {
      opacity: 1;
    }
    &::before {
      opacity: 0;
    }
    & > .handle {
      left: 27px;
    }
  }
}

.select-box {
  position: relative;
  & > .select-value {
    white-space: nowrap;
    &::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      width: 10px;
      height: 10px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      -webkit-transform: rotate(135deg) translateX(-5px) translateY(5px);
      transform: rotate(135deg) translateX(-5px) translateY(5px);
    }
  }
  & > .select-option {
    display: none;
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid $color-black;
    background: #fff;
    z-index: 1;
    & > li {      
      display: block;
      border-bottom: 1px solid $color-light-black;
      position: relative;
      white-space: nowrap;
      &:hover {
        background: $color-black;
        color: $color-white;
      }
      .btn {
        padding: 5px 15px 5px 30px;
        display: block;
        color: inherit;
        font-size: $font-size-14;
        font-weight: $font-weight-400;
        letter-spacing: inherit;
        border: none;
        text-transform: none;
        text-decoration: none;
      }
      &.is-selected {
        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 14px;
          width: 10px;
          height: 5px;
          border-top: 2px solid $omd-purple;
          border-right: 2px solid $omd-purple;
          transform: rotate(135deg) translateX(-4px);
        }
      }
    }
  }
}

#myDashboardEdit {
  @include inf-container-fluid();
  @include spacer(padding-bottom, map-get($spacers, 5));
  background: $omd-medium-grey2;
  
  a:active {
    color: $body-text-colour;
  }

  .search__content {
    @include inf-container();
    flex-flow: row;
    width: 100%;
    display: block;
    @include isIE {
      display: block;
      font-size: 0;
    }
    @include breakpoint("lg") {
      flex-direction: column;
      flex-flow: column;
    }

    .dashboard-settings {
      @include spacer(margin-bottom, map-get($spacers, 5));
    }
    .dashboardsettingsHead {
      border-bottom: none;
      padding-bottom: 0;
      @include spacer(margin-bottom, map-get($spacers, 1));
      padding-top: 50px;
    }

    .modal-view.preferences h3,
    .modal-view.preferences h4 {
      letter-spacing: normal;
      font-family: $font-family-opensans;
    }
    .buttonsPan {
      text-align: center;
      .saveview, .cancel {
        @include folow-btn(auto, 2rem, "", $color-black);
        float: none;
        padding: 0 2rem;
        margin: 0 5px;
        font-family: $font-family-opensans;
        @include breakpoint('small') {
          margin: 0px;
          padding: 0 2rem;
        }

        &.button--filled {
          @include folow-btn(auto, 2rem, $color-white, $color-black);
          float: none;
          background: $color-black;
          font-family: $font-family-opensans;
        }
      }
    }

    .column-left-wider {
      order: 0;
      @include col-with-pad(2, "lg");
      width: 60%;
      margin-left: 0;
      flex: 1;

      @include isIE {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
      }
    }
    .column-right {
      width: 40%;
    }
    
    .column-left-wider {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 30px;
        right: 2%;
        height: calc(100% - 30px);
        border-right: 2px solid $omd-dark-grey;
      }
    }

    .column-left-wider,
    .column-right {
      float: left;
      @include breakpoint('lg') {
        width: 100%;
        padding: 0;
        &::after {
          display: none;
        }
      }
    }
      
    .followAllBtn{
      @include followBtnLbl();
      min-width: 130px;
      margin-left: 12px;
      float: none;
      border: 1px solid #fab142;
      @include breakpoint('medium-small') {
        min-width: initial;
        min-width: auto;
      }
      @include breakpoint('small') {
        min-width: initial;
        min-width: auto;
      }
    }
    .followinglbl{
      @include folow-btn-lbl(#f7941e); 
      @include breakpoint('medium-small') {
        margin: 0px !important;
        float: left;
      }
    }
    .unfollowAllBtn{
      @include unfollowBtnLbl(#58595b);
      padding: 0 1rem;
      margin-left: 0.5rem;
      border: 1px solid #ADADAD;
      @media screen and (min-width: 600px){
        float: none;
      }
      @include breakpoint('medium-small') {
        min-width: initial;
        min-width: auto;
      }
      @include breakpoint('small') {
        min-width: initial;
        min-width: auto;
      }
      @media screen and (min-width: 667px) and (max-width: 767px){
        padding: 0 1.25rem;
      }
    }
    .followlbl{
      @include folow-btn-lbl(#58595B);
      padding: 0;
      cursor: text;
      @include breakpoint('medium-small') {
        min-width: initial;
        min-width: auto;
      }
      @include breakpoint('small') {
        min-width: initial;
        min-width: auto;
      }
      @include breakpoint('medium-small') {
        margin: 0px !important;
        float: left;
      }
    }
    .followBtn, .followingBtn {
      font-size: 0;
      padding: 0;
      min-height: initial;
      display: block;
      margin-left: auto;
    }
    .followingBtn {
      background: $omd-purple;
      border: 1px solid $omd-purple;
    }
    .followBtn {
      background: $omd-dark-grey;
      border: 1px solid $omd-dark-grey;
      &:hover{
        background: $omd-dark-grey;
      }
    }
    &.myview-settings-registration {
      .saveoption {
        width: 100%;
      }
    } 
    h1 {
      margin-bottom: 0rem;
      float: left;
      font-size: $font-size-40;
      font-weight: $font-weight-700;
      line-height: $line-height-135;
      letter-spacing: 1.2px;
      @include breakpoint('medium-small') {
        font-size: 2rem;
      }
    }
    .mysettingsHead {
      border-bottom: none;
      padding-bottom: 0;
      @include spacer(margin-bottom, map-get($spacers, 1));
      padding-top: 50px;
      @include breakpoint('small') {
        margin-bottom: 0rem;
        border-bottom: none;
        padding-top: 0;
      }
    }
    .dashboardsettingsPara {
      @include spacer(margin-bottom, map-get($spacers, 4));
      & > p {
        font-size: $font-size-16;
        font-weight: $font-weight-400;
        line-height: $line-height-125;
        letter-spacing: $letter-spacing-02;
      }
    }
    .mtop-10 {
      margin-top: 0.625rem;
    }
    .mb-10 {
      margin: 0.625rem 0 1.25rem;
    }
    .desktophide {
      display: none !important;
    }
    .sub-heading-text {
      font-size: 1.125em;
      margin-bottom: 50px;
      p {
        line-height: 1rem;
        font-style: normal;
        font-family: "PT Serif Caption";
        font-weight: 400;
        font-size: 1.125em;
        @include breakpoint('medium-small') {
          font-family: "Roboto";
          font-size: 0.85em;
          margin: 0rem;
          padding: 0rem;
          line-height: 1.375rem;
        }
      }
    }
    .publicationPan {
      border: none;
    }
    .table {
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      @include breakpoint('small') {
        display: block;
        position: relative;
        @include spacer(margin-bottom, map-get($spacers, 5));
      }
      thead {
        tr {
          th {
            padding: 0;
            font-size: $font-size-18;
            line-height: $line-height-125;
            font-weight: 600;
            @include breakpoint('small') {
              padding: 10px 0 0;
            }
            hr {
              margin-bottom: 0;
              background-color: $omd-dark-grey;
              height: 4px;
            }
          }
        }
      }      
      tr {
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease; 
        td {
          @include tableData(1px);
          vertical-align: middle;
          border-top: 15px solid $omd-medium-grey2;
        }
        &.devider {
          td {
            padding: 0;
            color: $omd-dark-grey;
            font-size: $font-size-14;
            hr {
              background-color: $omd-medium-grey;
              margin-bottom: 0;
            }
          }
        }
        &.followingrow,
        &.followrow {
          background: $color-white;
          @include breakpoint('small') {
            width: 100%;
          }
          .rowlines {
            @include getMove($color-black, 0.4375rem);
            position: relative;
            float: none;
            margin: 0;
            display: flex;
            align-items: center;
            .move {
              float: left;
              margin-right: 0.625rem;
              position: absolute;
              top: -4px; 
              right: 21px;
              color: #ff8f18;
              text-transform: uppercase;
              font-size: 0.875em;
              display: none;
              @include breakpoint('small') {
                z-index: -1;
              } 
            }
            &-btn {
              width: 35px;
              display: flex;
              flex-direction: column;
              gap: 3px;
              @include spacer(margin-right, map-get($spacers, 1));
              @include breakpoint('small') {
                width: 15px;
              }
              & > span {
                margin: 0;
              }
              &.lock-btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                
                & > svg {                    
                  width: 20px;
                  height: 20px;
                  fill: #868b8d;
                }
                @include breakpoint('medium') {
                  align-items: flex-start;
                }
                @include breakpoint('small') {
                  width: 15px;
                }
              }
            }
            &-text {
              @include font($font-size-20, $font-weight-600, $line-height-179, $letter-spacing-02);
              @include breakpoint('ipad') {
                line-height: $line-height-1;
              }
            }
          }
          &:hover {
            background: $color-white;
            cursor: move;
          }
          &.disabled {
            .rowlines > .move {
              display: none;
            }
            &:hover {
              cursor: auto;
            }
          }
        }
      }
      thead {
        th {
          @include tableData(0px);
          @include breakpoint('small') {
            padding: 0.5rem 0rem 0.625rem 0.625rem;
          }
          & .rowlines{
            @include getMove(#979797, 0rem); 
            float: right;
            @include breakpoint('medium-small') {
              top: 4px;
              margin-top: 0px;
            }
            @include breakpoint('large') {
              height: 30px;
              width: 30px;
            } 
          }
        }
      }
      .wd-55{
        width: 55%;
        @include breakpoint('medium-small') {
          width: 75%;
          padding: 12px 10px;
        }
        @include breakpoint('small') {
          width: 85%;
        }
      }
      .wd-25{
        width: 20%;
        text-align: right;
        @include breakpoint('medium-small') {
          width: 25%;
          padding: 12px 10px;
        }
        @include breakpoint('small') {
          width: 15%;
          text-align: right;
        }
      }
    }
    .saveoption {
      float: right;
      @include breakpoint('small') {
        width: 100%;
        padding: 0 10px;
      }
      .gotoview { 
        float: left;
        line-height: 2rem;
        @include breakpoint('small') {
          float: left;
        }
        a {
          padding: 9px 16px;
        }	
      }
      .saveview, .gotoview {
        @include folow-btn(auto, 2rem, "", $color-black);
        padding: 10px 20px;
        margin-left: 1.25rem;
        font-family: inherit;
        font-size: $font-size-16;
        line-height: $line-height-125;
        font-weight: $font-weight-600;
        text-transform: capitalize;
        @include breakpoint('small') {
          margin: 0px;
        }

        &.button--filled {
          @include folow-btn(auto, 2rem, $color-white, $color-black);
          background: $color-black;
          font-size: $font-size-16;
          line-height: $line-height-125;
          font-weight: $font-weight-600;
          font-family: $font-family-opensans;
        }
      }
      &.hidden-large {
        @include breakpoint('medium-small') {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .hidden-large {
      display: none;
      @include breakpoint('medium-small') {
        display: block;
      }
    }  
    .alert-success {
      margin-bottom: 35px;
    }
    .alert-error {
      margin-bottom: 35px;
      margin-top: -20px;
    }
    .alert-success,
    .alert-error {
      background-color: initial;
      color: $color-black;
      padding: 0;
      gap: 5px;
      & > p {
        margin: 0;
      }
      &.show {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include breakpoint('medium') {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .select-option {
    right: 0;
    left: auto;
  }
  .select-box  {
    & > .select-value {
      font-size: $font-size-14;
      color: $color-black;
      letter-spacing: $letter-spacing-05;
      @include spacer(padding-right, map-get($spacers, 3));
    }    
    &:hover .select-value {
      color: $omd-purple;
    }
  }
}