.search {
    * {
        box-sizing: border-box;
    }

// Includes top border, page title, and email & rss icons
  &__header {
    min-height: 60px;
    margin-bottom: 35px;

    @include breakpoint('medium') {
      border-top: 0;
      margin-bottom: 1rem;
    }

    .action-flags {
        text-align: right;

        @include on-event(true) {
            text-decoration: none;
        }
    }

    &-title {
      color: $color-zeus-black;
      font-family: "Roboto Condensed", Arial Narrow, sans-serif;
      font-size: 38px;
      font-weight: 700;


      @include breakpoint('medium') {
        margin-top: 30px;
        font-size: 36px;
      }
    }
  }

// Includes filters, search bar, and search results
  &__content {
    display: block;
  }

  // 2 sections are nested inside search__content:
  &__left-sidebar {
    min-width: 300px;
    max-width: 300px;
    margin-right: 15px;
    float: left;

    @include breakpoint('medium') {
        max-width: 100%;
    }
  }

  &__body {
    flex-grow: 3;
    order:2;
    margin-left: 15px;

    @include breakpoint('medium') {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }
  }
}

.saved-search-success-alert {
    margin-top: 0.5rem;
    right: 0px;
    float: right;

    &.is-active {
        display: inline-block;
    }
}

.lightbox-modal__backdrop--save-search {
    @extend .lightbox-modal__backdrop;
    display: none;

    &.is-active {
        display: block;
        z-index: 9800;
    }
}

/// Proper positioning of non-facet search page elements
@mixin search-page-structure() {
  & {
      margin-left: 330px;

      @include breakpoint('medium') {
          margin-left: 0;
      }
  }
}

.article-preview__bookmarker {
    span.action-flag__label {
        display:none;
    }
} 


