// tile list
.tile-list {
    @extend .inf-col;
    background-color: $omd-light-grey;
    @include spacer(padding, map-get($spacers, 1));
    gap: 5px;
    position: relative;
    transition: background-color 300ms ease;
    &:hover {
        background-color: $omd-very-light-purple;
    }
    & > .action-flag {
        position: absolute;
        right: 18px;
        top: 18px;
        margin: 0;
    }
    &-title {
        @include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-normal);
        padding-right: $spacer-2*1.5;
        color: $color-black;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $color-black;
        }
        & > a {
            color: $color-black;
            &:hover,
            &:focus {
                text-decoration: none;
                color: $omd-dark-purple-2;
            }
        }
        @include breakpoint('medium') {
            @include font($font-size-21, $font-weight-600, $line-height-125, $letter-spacing-normal);
        }
    }
    &-metas {
        display: flex;
        flex-wrap: wrap;
        @include font($font-size-13, $font-weight-400, $line-height-135, $letter-spacing-normal);
        text-transform: capitalize;
        gap: 6px;
        & > span,
        & > a {
            @include font($font-size-13, $font-weight-500, $line-height-135, $letter-spacing-normal);
            display: inline-block;
            border-left: 1px solid $omd-light-grey-5;
            color: $color-black;
            padding-left: 6px;
            &:first-of-type {
                border: none;
                padding: 0;
            }
            &:focus {
                color: $omd-dark-grey-2;
            }
            &.text-caps {
                text-transform: uppercase;
                font-size: $font-size-12;
            }
        }
        & > span {
            color: $color-black;
        }
        & > a {
            color: $color-black;
            &:hover {
                color: $omd-dark-purple-2;
            }
        }
        & > span {
            a {
                color: $color-black;
                &:hover {
                    color: $omd-dark-purple-2;
                }
            }
        }
    }
    & > p {
        @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-normal);
    }
    &-link {
        @include font($font-size-12, $font-weight-600, $line-height-135, $letter-spacing-normal);
        color: $omd-dark-purple-2;
        position: relative;
        padding-right: 26px;
        width: fit-content;
        text-transform: uppercase;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $omd-dark-purple-2;
        }
        &.readmore {
            font-size: $font-size-14;
            background: url(/dist/img/omdia/purple-arrow.svg) no-repeat center right;
            margin-left: auto;
            height: 20px;
            transition: font-size 300ms ease, color 300ms ease;
            text-transform: capitalize;
            margin-top: auto;
            &:hover,
            &:focus {
                background: url(/dist/img/omdia/black-arrow.svg) no-repeat center right;
                @include font($font-size-16, $font-weight-600, $line-height-135, $letter-spacing-normal);
                color: $color-black;
                text-decoration: none;
            }
        }
    }
}