// class-helpers.scss
// Define class helpers here
// Inspired from Helper Classes from https://html5boilerplate.com/


// visually hidden, but still available for screenreaders: h5bp.com/v
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// hide visually and from screenreaders, but maintain layout
.invisible {
    visibility: hidden;
}

// Clearfix: contain floats
//
// For modern browsers
//    1. The space content is one way to avoid an Opera bug when the `contenteditable` attribute is included anywhere else in
//       the document. Otherwise it causes space to appear at the top and bottom of elements that receive the `clearfix` class.
//    2. The use of `table` rather than `block` is only necessary if using `:before` to contain the top-margins of child elements.
.clearfix:before,
.clearfix:after {
    content: " "; // 1
    display: table; // 2
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

// element lables
// NOTE: for wireframing use only.
.label {
    display: inline-block;
    padding: rem(10);
    font-size: rem(13);
    text-transform: uppercase;
    color: color(wireframe, 500);
    background: color(wireframe, 100, 0.75);
    border: 1px solid color(wireframe, 100);
}

//Fullwidth Button
.main__wrapper a.full-width-button {
    width: 100% !important;
    display: block;
    text-align: center;
}
.full-width-button {
    width: 100% !important;
    display: block;
    text-align: center;
    &.update-password-btn {
        max-width:350px;
        float:left; 
    }
}
.update-password-text {
    margin-bottom: 1.25rem;
    font-family: 'Roboto';
}
.mb-20 {
    margin-bottom: 20px;
}