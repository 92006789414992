.cookie-banner {
  &.banner--cookie-policy {
    background-color: $color-white;
    color: $color-black;
    padding: 0 map-get($spacers, 1)*1.5;
    font-family: $font-family-opensans;
    font-size: 16px;

    .banner__wrapper {
      padding: 25px 0;
      .dismiss-button.js-dismiss-cookie-banner {
        .dismiss-button__icon {
          display: none;
        }
        &::after {
          content: '';
          background-image: url(img/omd/icon_close_black.svg);
		      background-repeat: no-repeat;
		      background-size: 17px auto;
		      background-position: center;
		      width: 14px;
          height: 14px;
          display: inline-block;
        }
      }

      a {
        text-decoration: none;
        color: $color-black;
        border-bottom: 2px solid $color-black;

        &:hover {
          color: $omd-purple;
          border-bottom: 2px solid $omd-purple;
        }
      }

      span {
        padding-right: 18px;
      }
    }
  }
}