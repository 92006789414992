// card list
.card-list {
        @extend .inf-col;
        transition: background 300ms ease;
        & > img {
        width: 100%;
        height: 174px;
        object-fit: cover;
    }
    &-content {
        @extend .inf-col;
        position: relative;
        padding: map-get($spacers, 1);
        flex: 1;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient($omd-very-light-purple, #fff);
            z-index: 0;
        }
        & > * {
            position: relative;
        }
        & > p {
            @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-1);
            margin-bottom: $spacer-2;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 10;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            a {
                color: $color-black;
            }
        }
        & > .cta {
            margin-top: auto;
            display: inline-block;
        }
    }
    &:hover {
        .card-list-content {
            &::before {
            background: $omd-very-light-purple;
            }
        }
    }
    &-title {
        @include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-02);
        margin-bottom: map-get($spacers, 1)/2;
        & > a {
            color: $color-black;
            &:hover {
                color: $omd-dark-purple-2;
                text-decoration: none;
            }
        }
    }
}