#informa-footer {
  background-color: $omd-light-grey-2;
  font-family: $font-family-opensans;
  padding-top: map-get($spacers, 0)*4;
  padding-bottom: map-get($spacers, 0)*4;
  @include breakpoint('medium-small') {
    padding-bottom: map-get($spacers, 1)*1.5;
  }
  .divisional-footer {
    padding: 0 $spacer-2*1.25;
    max-width: 1260px;
    width: 100%;
    @include breakpoint('medium') {   
      padding: 0 map-get($spacers, 1)*0.75;
    }
    .divisional-footer-container {
      border: none !important;
      text-align: left;
      @include breakpoint('medium') {
        text-align: left;
      }
      .heading {
        @include font($font-size-16, $font-weight-bold, $line-height-1, $letter-spacing-150);
        color: $omd-dark-purple-2;
        font-stretch: normal;
        font-style: normal;
        text-transform: uppercase;
        padding: 0 0 22px;
      }
      .alternate-menus {
        padding: 0;
        display: flex;
        flex: 70px;
        @include breakpoint('medium') {
          flex-direction: column;
        }
        .menu-section {
          &.long-list,
          &.short-list,
          &.social-icons {
            width: auto;
            @include breakpoint('medium') {
              @include spacer(margin-bottom, map-get($spacers, 5));
            }
          }
          &.social-icons {
            margin-left: auto;
            margin-bottom: 0;
            &::before {
              content: none;
            }
            @include breakpoint('medium') {
              margin-left: 0;
              margin-right: auto;
            }
            & > .social-icon {
              display: flex;
              justify-content: space-between;
              & > li {
                padding-right: 0;
                a {
                  width: auto;
                  height: auto;
                  i {
                    font-size: 22px;
                  }
                  background: none;
                  color: $omd-black;
                  &:hover {
                    color: $omd-dark-purple-2;
                  }
                }
              }
            }
          }
          &.long-list {
            & > .list-inline {
              display: block;
              column-count: 2;
              column-gap: 55px;
              li {
                float: none;
                width: auto;
                display: block;
              }
            }
          }
        }
      }
      .menu-section {
        ul.list-inline {
          li {
            float: none;
            width: auto;
            display: block;
            padding: 6px 0;
            line-height: 0;
            @include breakpoint('small'){
              padding: 3px 0;
            }
            a {
              @include font($font-size-14, $font-weight-600, $line-height-088, $letter-spacing-150);
              color: $omd-black;
              text-decoration: none;
              &:hover {
                color: $omd-dark-purple-2;
                text-decoration: none;
              }
              @include breakpoint('small'){
                line-height: $line-height-normal;
              }
            }
          }
        }
      }
      .privacy-policy-bottom-menu {
        display: flex;
        justify-content: space-between;
        @include breakpoint('medium'){
          flex-direction: column;
        }
        & > .content {
          max-width: 820px;
          @include font($font-size-12, $font-weight-400, $line-height-157, $letter-spacing-08);
          color: #626161;
          padding-right: $spacer-2*4;
          @include breakpoint('medium') {
            @include spacer(padding-left, map-get($spacers, 0));
            @include spacer(padding-right, map-get($spacers, 0));
            text-align: center;
          }
          & > .copyright {
            margin-bottom: 8px;
          }
          & > .links {
            display: flex;
            gap: 10px;
            @include breakpoint('medium'){
              justify-content: center;
            }
            & > a {
              font-size: 10px;
              font-weight: $font-weight-600;
              letter-spacing: $letter-spacing-05;
              text-transform: uppercase;
              color: #b5b5b5;
            }
          }
        }
        & > .logos {
          display: flex;
          gap: 67px;
          align-items: center;
          @include breakpoint('medium'){
            justify-content: center;
            @include spacer(margin-top, map-get($spacers, 1));
          }
          & > img {
            height: 50px;
            @include breakpoint('medium-small'){
              height: 45px;              
            }
          }
          & > img:not([src]) {
            display: none;
          }
        }
      }
    }
  }
  .divisional-footer~.divisional-footer .divisional-footer-container {
    margin-top: 20px;
  }
}
