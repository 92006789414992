.statistics-bar {
  background: $omd-black !important;
  width: 100%;
  padding-top: map-get($spacers, 3)*2;
  padding-bottom: map-get($spacers, 3)*2;
  margin: map-get($spacers, 1)*5 auto;
  @include breakpoint('ipad') {
    margin: map-get($spacers, 1)*3.5 auto;
    padding: map-get($spacers, 0)*4.2 0;
  }
  &.no-top-margin {
      margin-top: 0;
  }
  &.no-bottom-margin {
      margin-bottom: 0;
  }
  &-container {
    max-width: 1260px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 map-get($spacers, 1)*1.5;
    position: relative;
    @include breakpoint('ipad') {
      padding: 0 map-get($spacers, 1)*0.75;
    }
  }  
  &__box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    @include breakpoint('ipad') { 
      justify-content:center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      animation: autoplay1 12s infinite;
      &:nth-of-type(1) {
        animation-delay: 0s;
      }
      &:nth-of-type(2) {
        animation-delay: 4s;
      }
      &:nth-of-type(3) {
        animation-delay: 8s;
      }
    }
    img {
      display: block;
      max-width: 60px;
      max-height: 60px;
      @include breakpoint('ipad') { 
        max-width: 50px;
      }
      @include breakpoint('medium') {
        max-height: 50px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 9px;
      @include breakpoint("medium") {
        gap: 4px;
      }
      p {
        display: block;
        color: $omd-white;
        @include font($font-size-16, $font-weight-normal, $line-height-normal, $letter-spacing-05);
        @include breakpoint("medium") {
          font-size: $font-size-14;
          letter-spacing: $letter-spacing-150;
        }
      }
    }
    &__title {
      display: flex;
      align-items: center;
      letter-spacing: .5px;
      @include font($font-size-40, $font-weight-bold, $line-height-normal, $letter-spacing-025);
      color: $omd-dark-purple-2;
      @include breakpoint("ml") {
        font-size: $font-size-32;
        line-height: $line-height-1;
      }
      @include breakpoint("medium") {
        font-size: $font-size-24;
        line-height: $line-height-088;
      }
      span {
        display: block;
        margin-left: map-get($spacers, 1)/2;
        @include font($font-size-16, $font-weight-normal, $line-height-13, $letter-spacing-05);
        text-align: left;
        @include breakpoint("medium") {
          width: auto;
          font-size: $font-size-12;
          line-height: normal;
        }
      }
    }
  }
  @keyframes autoplay1 {
    0% {
        visibility: visible
    }
    33.33% {
        visibility: hidden
    }
  }
}

