.more-solutions {
    @extend .inf-col;
    margin-top: map-get($spacers, 1)*5;
    gap: 36px;
    @include breakpoint("medium") {
        padding: 0 map-get($spacers, 1)*0.75;
        margin: map-get($spacers, 1)*3.5 0;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    &-title {
        @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-05);
        color: $omd-dark-purple-2;
        @include breakpoint("medium") {
            @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-025);
        }
    }
    &-content {
        gap: 0 36px;
        & > .arrow-list {
            column-count: 2;
            gap: 0 36px;
            @include breakpoint("medium") {
                column-count: 1;
            }
        }
    }
}