.dismiss-button {
	@include textMix('Roboto', 0.75rem, 700);
	cursor: pointer;
	float: right;
	text-transform: uppercase;
	letter-spacing: 1px;

	span {
		color: $color-zeus-black;
		vertical-align: middle;
	}
    span.highlight-link { 
        color:$color-cardinal-red;
    }
    span.highlight-link:hover { 
        text-decoration:underline;
    }

	&__icon {
		width: 18px;
		height: 19px;
		vertical-align: middle;
		margin-left: 12px;
	}

	@include breakpoint('medium') {
		span {
			display: none;
		}
	}
}
