.lets-connect {
    @extend .inf-col;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
        padding: 0 map-get($spacers, 1)*0.75;
        margin: map-get($spacers, 1)*3.5 auto;
    }
    &__sub-heading {
        max-width: 890px;
        margin: auto auto map-get($spacers, 1)*1.5;
        text-align: center;
        @include font($font-size-20, $font-weight-600, $line-height-15, $letter-spacing-02);
        a,
        a:hover {
            color: $color-black;
        }
    }

    &__locations {
        @extend .inf-col;
        margin: map-get($spacers, 1)*5 auto;
        @include breakpoint('medium') {
            padding: 0 map-get($spacers, 1)*0.75;
            margin: map-get($spacers, 1)*3.5 auto;
        }
        &-title {
            text-align: center;
            @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-05);
            @include breakpoint('medium') {
                @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-025);
            }
        }
        &-box {
            background: $omd-light-grey;
            position: relative;
            overflow: hidden;
            margin-top: map-get($spacers, 1)*2;
            &-wrapper {
                max-width: 1260px;
                padding: 0 map-get($spacers, 1)*1.5;
                margin: 0 auto;
                display: flex;
                @include breakpoint('medium') {
                    padding: 0;
                }
            }
            & > iframe {
                border: none;
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
        &-detail {
            background: $omd-light-grey;
            width: 280px;
            margin-left: auto;
            padding: map-get($spacers, 1)*3;
            padding-right: 0;
            @extend .inf-col;
            gap: 25px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                width: 1500%;
                height: 100%;
                background: $omd-light-grey;
            }
            @include breakpoint('medium') {
                width: 50%;
                gap: 15px;
                padding: map-get($spacers, 1)*0.75;
            }
            &-title {
                @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-025);
                @include breakpoint('medium') {
                    @include font($font-size-20, $font-weight-bold, $line-height-30, $letter-spacing-02);
                }
            }
            & > p {
                @include font($font-size-20, $font-weight-600, $line-height-30, $letter-spacing-02);
                @include breakpoint('medium') {
                    @include font($font-size-14, $font-weight-700, $line-height-normal, $letter-spacing-normal);
                }
            }
        }
        &-list {
            max-width: 1260px;
            width: 100%;
            padding: 0 map-get($spacers, 1)*1.5;
            margin: map-get($spacers, 1)*5 auto 0;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 100px 15px;
            @include breakpoint("medium") {
                margin: map-get($spacers, 1)*3.5 auto;
                padding: 0;
                gap: 70px 15px;
                flex-direction: column;
            }
            & > .region-box {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                @include breakpoint("medium") {
                    flex-direction: column;
                }
                &.column-two {
                    flex-basis: calc(50% - 8px);
                }
                & > .region-title {
                    @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-025);
                    flex-basis: 100%;
                    color: $omd-dark-purple-2;
                    @include breakpoint("medium") {
                        @include font($font-size-25, $font-weight-bold, $line-height-30, $letter-spacing-025);
                    }
                }
                & > .region-items {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    gap: 10px;
                    @include breakpoint("medium") {
                        flex-direction: column;
                    }
                    &.item-horizontal {
                        flex-direction: row;
                        justify-content: space-between;
                        @include breakpoint("medium") {
                            flex-direction: column;
                        }
                    }
                    &.item-verticle {
                        flex-direction: column;
                        flex-wrap: initial;
                    }
                    &.column-two {
                        flex-basis: calc(50% - 10px);
                    }
                    &.column-three {
                        flex-basis: calc(33% - 15px);
                    }
                    &.column-four {
                        flex-basis: calc(25% - 15px);
                    }
                    & > .region-items-title {
                        @include font($font-size-25, $font-weight-bold, $line-height-30, $letter-spacing-025);
                        flex-basis: 100%;
                        @include breakpoint("medium") {
                            @include font($font-size-20, $font-weight-bold, $line-height-30, $letter-spacing-02);
                        }
                    }
                    & > .region-item {
                        @include font($font-size-20, $font-weight-400, $line-height-30, $letter-spacing-08);
                        @include breakpoint("medium") {
                            @include font($font-size-16, $font-weight-400, $line-height-22, $letter-spacing-02);
                        }
                        &.column-three {
                            flex-basis: calc(33% - 15px);
                        }
                        & > a {
                            @include font($font-size-14, $font-weight-600, $line-height-22, $letter-spacing-normal);
                            text-decoration: underline;
                            color: $omd-black;
                            display: inline-block;
                            margin-top: map-get($spacers, 1)/4;
                            &:hover {
                                color: $omd-dark-purple-2;
                            }
                        }
                    }
                }
            }
        }
    }
}

#letsConnectService iframe {
    border: none;
}