.page-registration {

    &__subheader {
        margin-bottom: 1rem;
    }

    hr {
        margin-bottom: 2rem;

        @include breakpoint('small') {
            margin-bottom: 1.5rem;
        }
    }

    &__required-hint {
        @include textMix('Roboto', 0.9375rem, 500);
        color: $color-dusty-gray;
        margin-bottom: 2rem;
    }

    &__labeled-checkbox,
    &__tos-confirm {
        margin-bottom: 1rem;
        
        label {
            @extend .checkbox-label;
        }
    }

    &__tos-confirm {
        margin-bottom: 2.5rem;

        @include breakpoint('small') {
            margin-bottom: 1rem;
        }
    }

    &__error {
        @extend .error-text;
        display: none;
    }

}
