.informa-ribbon {
    p, a {
        color: $color-black !important;
    }

    &__title {
    	height: 16px;
    	width: 82px;
    	background: url('/dist/img/omdia/informa.svg') no-repeat;
    	color: $omd-light-grey-3;
    	margin: auto;
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(-50%);
        transition: bottom 300ms ease;
        @include breakpoint("medium-ipad") {
            left: 15px;
            transform: none;
        }
        @include breakpoint("medium-small") {
            left: 15px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 7px;
            border: 5px solid transparent;
            border-top-color: $color-black;
            transition: top .3s ease, border-top-color .3s ease, border-bottom-color .3s ease;
        }
    }

    &__detail {
        @include breakpoint("medium") {
            padding: 0 15px;
        }
    }

    &__wrapper {
    	background: $omd-light-grey-3;
    	border-bottom: 1px solid $omd-light-grey-3;
        position: relative;
        padding: 0;

        & > .social-icons {
            display: flex;
            margin-left: auto;
            height: 43px;
            align-items: center;
            @include spacer(gap, map-get($spacers, 0));
            font-size: $font-size-18;
            max-width: 1260px;
            padding-right: map-get($spacers, 1)*1.5;
            margin: auto;
            justify-content: flex-end;
            @include breakpoint("lg") {
                padding-right: 15px;
            }
            @include breakpoint("medium-small") {
                gap: map-get($spacers, 1)/2;
            }
            a {
                &:hover {
                    color: $omd-dark-purple-2 !important;
                }
            }
        }
    }
    
    &.show {
        .informa-ribbon__title {
            @include breakpoint("medium") {
                transform: none;
            }
            &::after {
                top: 2px;
                border-top-color: transparent;
                border-bottom-color: $color-black;
            }
        }
    }
}
.social-tooltip {
    z-index: 9991;
    border: none;
    box-shadow: 0 2px 10px $omd-medium-grey;
}
