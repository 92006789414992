div.main-menu__section{
	.titleMyView{
		color: #231f20;
		cursor: pointer;
		font-weight: 600;
		letter-spacing: 1.2px;
		padding-left: 30px;
		text-transform: uppercase;
		width: 100%;
        padding-top: 10px;
        padding-bottom: 15px;
	}
	.edit-link{
		margin-left: 30px;
		font-size: 12px;
	}
	.navContent{
		font-size: 12px;
		margin: 6px 0 0 30px;
		line-height: 20px;
		color: #979799;
	}
}

 
.personalise_nav {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 90899; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.personalise_nav-content {
    background-color: $color-concrete-gray;
    margin: auto;
    padding: 20px;
    border: 1px solid $color-light-gray;
    width: 80%;
}

.personalise_close {
    color: $color-zeus-black;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.personalise_close:hover,
.personalise_close:focus {
    color: $color-zeus-black;
    text-decoration: none;
    cursor: pointer;
}