$omd-dark-purple: #6D2CA7;
$omd-dark-purple-2: #731AED;
$omd-bright-blue: #3472E4;
$omd-bright-purple: #A54EAC;
$omd-bright-pink: #EB4EAC;
$omd-bright-light-blue: #49ACF8;
$omd-light-cool-purple: #9D5FF2;
$omd-light-cool-purple-2: #B98CF6;
$omd-light-cool-purple-3: #996BC1;
$omd-light-cool-purple-4: #B695D3;
$omd-light-warm-purple: #C083C5;
$omd-light-warm-purple-2: #D2A6D5;
$omd-light-purple-2: #f8f3f3;
$omd-light-pink: #F183C5;
$omd-light-pink-2: #F5A6D5;
$omd-black: #000000;
$omd-white: #FFFFFF;
$omd-light-grey: #F4F4F4;
$omd-light-grey-2: #EBEBEB;
$omd-light-grey-3: #D9D9D9;
$omd-light-grey-4: #c9c8c8;
$omd-light-grey-5: #dadada;
$omd-dark-grey-2: #525a5c;
$omd-dark-grey-3: #868B8D;
$omd-purple-white-gradient: linear-gradient(#ffffff, rgba(#A54EAC, 0.1));
$omd-very-light-purple: #F8F3FE;
$omd-purple-black-gradient: linear-gradient(#000000, #6D2CA7);
$omd-consumer-gradient: linear-gradient(#6D2CA7, #B695D3);
$omd-telecoms-gradient: linear-gradient(#731AED, #A54EAC);
$omd-enterprise-gradient: linear-gradient(#3472E4, #A54EAC);
$omd-industrial-gradient: linear-gradient(#EB4EAC, #A54EAC);
$omd-pink-blue-gradient: linear-gradient(rgba(#A54EAC, 0.1), rgba(#3472E410, 0.1));
$omd-channel-gradient: linear-gradient(#731AED, #49ACF8);

// Font Size
$font-size-13 : 0.81rem !default;
$font-size-21 : 1.31rem !default;
$font-size-25 : 1.56rem !default;
$font-size-32 : 2rem !default;
$font-size-36 : 2.25rem !default;
$font-size-31 : 1.93rem !default;

// Letter Spacing
$letter-spacing-150 : 1.50px;
$letter-spacing-137 : 1.37px;
$letter-spacing-3 : 3px;

// Line Height
$line-height-22: 1.4rem;
$line-height-30: 1.87rem;
$line-height-36: 2.25rem;
$line-height-40: 2.5rem;
$line-height-45: 2.8rem;
$line-height-50: 3.125rem;
$line-height-56: 3.5rem;

// Font Weight
$font-weight-500 : 500;


