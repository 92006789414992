/*---
title: Headers
section: Typography
---
These headers aren't often used on their own as standalone &lt;hx&gt; tags. They're usually
extended (@extend in Sass/SCSS) and used as "foundational" styles for component-specific
headers/titles.

```example:html
<h1>H1 Header</h1>
<h2>H2 Header</h2>
<h3>H3 Header</h3>
<h4>H4 Header</h4>
<h5>H5 Header</h5>
<h6>H6 Header</h6>
```
*/

body {
  color: $color-abbey-gray;
  @include textMix('PT Serif', 16px, 400);
}

h1, h2, h3, h4, h5, h6 {
  	color: $color-zeus-black;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

h1, h2, h6 {
    @include textMix('Roboto Condensed', 38px, 700);
}
h3, h4, h5 {
    @include textMix('Roboto', 1.2rem, 700);
}

.main__content > h1:first-child {
    color: $color-zeus-black;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 30px;
}
h4 {
    font-size: 20px;
}
h5 {
    font-size: 16px;
    font-weight: 500;
}
h6 {
    color: $color-zeus-black;
    text-transform: uppercase;
    font-size: 15px;
}

p {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

// Classes for <ul> and <li>
.carrot-list {
    li {
        margin-left: 1.75rem;

        &:before {
            @include svg-fill($color-tree-poppy-orange);
        	background: url(data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22layers%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%2260px%22%20height%3D%2260px%22%20viewBox%3D%220%200%2060%2060%22%20style%3D%22enable-background%3Anew%200%200%2060%2060%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20fill%3D%22%23f7941e%22%20d%3D%22M18.185%2C60c-0.797%2C0-1.591-0.314-2.198-0.942c-1.213-1.258-1.213-3.301%2C0-4.564L38.88%2C30.758L14.524%2C5.505%0A%09c-1.213-1.26-1.213-3.301%2C0-4.562c1.216-1.258%2C3.183-1.258%2C4.398%2C0l26.555%2C27.535c1.213%2C1.258%2C1.213%2C3.301%2C0%2C4.562L20.384%2C59.058%0A%09C19.776%2C59.686%2C18.982%2C60%2C18.185%2C60z%22/%3E%0A%3C/svg%3E) no-repeat;
        	background-size: 11px auto;
        	background-position: center left;
        	content: "";
        	height: 22px;
        	width: 16px;
        	display: inline-block;
        	position: absolute;
        	margin-left: -1.75rem;
        }
    }
}



// Styling for "default"-style <ul>s and <ol>s
// Actual lists, not lists used for layout
.default-style-list {
    margin-bottom: 1.5rem;

    li {
        margin-left: 1.25rem;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.error-text {
    @include textMix('Roboto', 0.875rem, 600);
    font-style: italic;
    color: $color-cardinal-red;
}

.common-signposting-font h6 {
    color: #231f20;
    font-size: 16px;
    font-family: Roboto,Arial Narrow,sans-serif;
}

.content > .rolling-stream-article.clearfix.article-metadata ul {
    display: block;
}

.rolling-stream-article.clearfix.article-metadata > .signposting-custom > li {
    border-right: none;
	float: left;
}
ul.article-metadata.common-signposting-font li {
    float: left;
	border-right: none;
}
ul.article-metadata.common-signposting-font li h6, .signposting-custom h6 {
    font-size: 16px;
    line-height: 23px;
}
ul.common-signposting-font h5 {
    line-height: 23px;
}
.common-signposting-font li:after {
    content: '';
    height: 15px;
    width: 1px;
    background: #ccc;
    position: absolute;
    top: 4px;
    right: -2px;
}

