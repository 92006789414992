.featured-research {
	margin: map-get($spacers, 1)*5 0;
	@include breakpoint("medium") {
		margin: map-get($spacers, 1)*3.5 0;
	}
	&.no-top-margin {
		margin-top: 0;
	}
	&.no-bottom-margin {
		margin-bottom: 0;
	}
	& > .content-sections {
		&.omd-marg-b-4 {
			margin-bottom: 0;
		}
		& > .inf-row {
			@include breakpoint("medium") {
				flex-direction: column;
			}
		}
		.featured-section-title {
			margin-bottom: map-get($spacers, 0)*2;
			& > h4 {
				@include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
				@include breakpoint("medium") {
					@include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
				}
				color: $color-black;
			}
		}
		.featured-research-container {
			@include breakpoint("medium") {
				margin-bottom: map-get($spacers, 1);
				&:last-of-type {
					margin: 0;
				}
			}
			& > .asset_family_mod {
				gap: 5px;
				&::before {
					display: none;
				}
				& > .article-preview__headline {
					@include font($font-size-24, $font-weight-600, $line-height-125, $letter-spacing-normal);
					color: $color-black;
					& > a {
						color: $color-black;
						padding-right: 0;
						display: block;
						&:hover {
							color: $omd-dark-purple-2;
						}
					}
				}
				& > .article-preview__byline {
                    margin: 0;
                    @include font($font-size-13, $font-weight-500, $line-height-135, $letter-spacing-normal);
                    & > .action-flag {
                        padding: 0;
                    }
                    & > a {
                        letter-spacing: normal;
                        font-weight: $font-weight-500;
                        &:hover {
                            color: $omd-dark-purple-2 !important;
                        }
                        &::before {
                            display: none;
                        }
                    }
                    
                }
			}
		}
		.inf-ctas {
			margin-top: map-get($spacers, 1)*1.25;
			& > .btn {
				@include cta();
      			@include cta-arrow-right();
				@include breakpoint("medium") {
					margin: 0;
				}
			}
		}
	}
}