.header-banner {
  position: relative;
  margin: map-get($spacers, 1)*5 0;
  min-height: 550px;
  padding-top: $spacer-2*5.5;
  @include breakpoint('medium') {
    padding-top: map-get($spacers, 1)*2.5;
    margin: map-get($spacers, 1)*3.5 0;
  }
  &.no-top-margin {
      margin-top: 0;
  }
  &.no-bottom-margin {
      margin-bottom: 0;
  }
  & > img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 550px;
    top: 0;
    position: absolute;
    @include breakpoint('medium') {
      height: calc(100% - 150px);
    }
  }
  &.small {
    min-height: 412px;
    & > img {
      height: 412px;
      @include breakpoint('medium') {
        height: calc(100% - 150px);
      }
    }
    .header-banner-content {
      & > h1 {
        @include breakpoint('medium') {
          margin-bottom: map-get($spacers, 0)/2;
        }
      }
    }
    .header-banner-bottom {
      @include breakpoint('medium') {
        gap: map-get($spacers, 1)*0.75;
        align-items: center;
        margin-top: map-get($spacers, 1)/2;
      }
      & > img {
        display: block;
        @include breakpoint('medium') {
          width: 36px;
          height: 36px;
          transform: rotate(90deg);
        }
      }
    }
  }
  &.image {
    min-height: 550px;
    & > img {
      height: 550px;
      @include breakpoint('medium') {
        height: calc(100% - 150px);
      }
    }
    .header-banner-content {
      & > h1 {
        @include breakpoint('medium') {
          margin-bottom: map-get($spacers, 0)/2;
        }
      }
    }
    .header-banner-bottom {
      @include breakpoint('medium') {
        gap: map-get($spacers, 1)*0.75;
        align-items: center;
        margin-top: map-get($spacers, 1)/2;
      }
      & > img {
        display: block;
        @include breakpoint('medium') {
          width: 36px;
          height: 36px;
          transform: rotate(90deg);
        }
      }
    }
  }
  &.text-align-left {
    min-height: 640px;
    & > img {
      height: 640px;
    }
    & > .header-banner-wrapper {
      position: relative;
      & > .header-banner-content {
        max-width: none;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 90px;
        @include breakpoint('medium'){
          flex-direction: column;
          gap: 10px;
        }
        & > img {
          width: 375px;
          height: 375px;
          object-fit: cover;
          @include breakpoint('medium'){
            width: 200px;
            height: 200px;
          }
          @include breakpoint('medium-small'){
            width: 155px;
            height: 155px;
          }
        }
        & > .header-banner-content-text {
          display: flex;
          flex-direction: column;
          gap: 40px;
          margin: 0;
          text-align: left;
          color: $color-white;
          max-width: 655px;
          @include breakpoint('medium'){
            order: 2;
            gap: 30px;
            text-align: center;
          }
          h1 {
            @include font($font-size-48, $font-weight-bold, $line-height-56, $letter-spacing-05);
            display: block;
            @include breakpoint('medium'){
              margin-bottom: 0;
              @include font($font-size-40, $font-weight-bold, $line-height-50, $letter-spacing-08);
              text-align: center;
            }
            @include breakpoint('medium-small'){
              @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-08);
            }
          }
          .cta {
            @include breakpoint('medium'){
              width: fit-content;
              margin: auto;
            }
          }
        }
      }
      & > .header-banner-bottom {
        align-items: center;
        margin-top: map-get($spacers, 1)*2.5;
        @include breakpoint('medium-small'){
          margin-top: map-get($spacers, 0)*2;
        }
        & > img {
          position: absolute;
          top: 550px;
        }
        & > .header-banner-card {
          @include breakpoint("medium"){              
            padding-top: map-get($spacers, 0)*3;
            &::before {
              content: "";
              width: 36px;
              height: 27px;
              background: url(/dist/img/omdia/banner-arrow.svg) no-repeat center;
              background-size: 100%;
              position: absolute;
              top: -2px;
              left: 50%;
              transform: translateX(-50%) rotate(90deg);
            }
          }
        }
      }
    }
  }
  &.text-align-left-with-image {
    padding: map-get($spacers, 1)*6.5 0;
    min-height: 615px;
    display: flex;
    @include breakpoint('medium'){
      padding: map-get($spacers, 1)*2.5 0 map-get($spacers, 1);
      height: auto;
    }
    & > img {
      height: 100%;
    }
    & > .header-banner-wrapper {
      & > .header-banner-content {
        max-width: none;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;
        @include breakpoint('medium'){
          flex-direction: column-reverse;
          gap: 30px;
        }
        & > img {
          width: 50%;
          object-fit: cover;
          border: 5px solid $omd-dark-purple-2;
          @include breakpoint('medium'){
            width: 100%;
          }
        }
        & > .header-banner-content-text {
          display: flex;
          flex-direction: column;
          gap: 50px;
          margin: 0;
          text-align: left;
          color: $color-white;
          max-width: 610px;
          @include breakpoint('medium'){
            order: 2;
            gap: 30px;
            text-align: center;
          }
          h1 {
            @include font($font-size-36, $font-weight-bold, $line-height-45, $letter-spacing-05);
            display: block;
            word-break: break-word;
            @include breakpoint('medium'){
              margin-bottom: 0;
              @include font($font-size-36, $font-weight-bold, $line-height-45, $letter-spacing-08);
              text-align: center;
            }
            @include breakpoint('medium-small'){
              @include font($font-size-31, $font-weight-bold, $line-height-36, $letter-spacing-08);
            }
          }
          p {
            @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-137);
            margin: 0;
          }
          .cta {
            @include breakpoint('medium'){
              width: fit-content;
              margin: auto;
              text-align: left;
              line-height: $line-height-125;
            }
          }
        }
      }
    }
  }
  &.no-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 412px;
    padding-bottom: $spacer-2*5.5;
    @include breakpoint('medium') {
      min-height: initial;
      padding-bottom: map-get($spacers, 1)*2.5;
      padding-top: map-get($spacers, 1)*2.5;
    }
    & > .header-banner-wrapper {
      & > .header-banner-content {
        max-width: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    & > img {
      height: 412px;
      @include breakpoint('medium') {
        height: 100%;
      }
    }
    h1 {
      margin: 0;
    }
  }
  &-wrapper {
    display: flex;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1260px;
    width: 100%;
    padding: 0 map-get($spacers, 1)*1.5;
    @include breakpoint('medium') {
      padding: 0 map-get($spacers, 1)*0.75;
    }
  }
  &-content {
    text-align: center;
    position: relative;
    z-index: 1;
    max-width: 690px;
    margin: 0px auto;
    @include breakpoint('medium') {
        width: 100%;
    }
    & > h1 {
      @include font($font-size-48, $font-weight-bold, $line-height-125, $letter-spacing-05);
      color: #fff;
      margin-bottom: map-get($spacers, 0)/2;
      @include breakpoint('medium') {
        font-size: $font-size-40;
        margin-bottom: map-get($spacers, 1)*1.5;
      }
    }  
    & > p {
      @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-137);
      text-align: center;
      color: #fff;
      & > a {
        color: $color-white;
        &:hover {
          text-decoration: none;
        }
      }
      @include breakpoint('medium') {
        font-size: $font-size-16;
      }
    }
  }
  &.image {
    .header-banner-card {
      &__right {
        padding: 5px;
      }
    }
  }
  &-bottom {
    display: flex;
    @include spacer(margin-top, map-get($spacers, 5));
    align-items: flex-start;
    gap: map-get($spacers, 1);
    @include breakpoint('medium') {
      flex-direction: column;
      gap: map-get($spacers, 1)*0.75;
    }
    & > img {
      z-index: 1;
      width: 70px;
      @include breakpoint('medium') {
        display: none;
      }
    }
  }
  &-card {
    display: flex;
    justify-content: space-between;
    max-width: 1056px;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-left: auto;
    @include breakpoint('medium') {
        flex-direction: column;
        gap: map-get($spacers, 1)*0.75;
    }
    &__left {
      @extend .inf-col;
      background: $omd-light-grey;
      width: 42%;
      padding: map-get($spacers, 1)*1.5;
      gap: 13px;
      @include breakpoint('medium') {
        gap: 15px;
        width: 100%;
        padding: map-get($spacers, 1)*0.75 map-get($spacers, 1);
      }
      & > h2 {
        @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
        @include breakpoint('medium') {
          font-size: $font-size-20;
        }
      }
      & > p {
        @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-02);
        margin: 0;
        @include breakpoint('medium-small') {
          @include font($font-size-14, $font-weight-400, $line-height-15, $letter-spacing-02);
        }
      }
      li {
        a {
          color: $omd-black;
          &:hover {
            text-decoration: none;
          }
        }
        &:hover {
          a {
            text-decoration: none;
          }
          & > svg {
            fill: $color-black !important;
          }
        }
      }
    }  
    &__right {
      @extend .inf-col;
      gap: 13px;
      width: calc(58% - 20px);
      padding: map-get($spacers, 1)*1.5;
      position: relative;
      background-color: $omd-dark-purple-2;
      @include breakpoint('medium') {
        gap: 15px;
        width: 100%;
        padding: map-get($spacers, 1)*0.75 map-get($spacers, 1);
      }
      & > img {
        width: 100%;
        min-height: 400px;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @include breakpoint('medium') {
          position: relative;
          height: 220px;
          min-height: initial;
        }
      }
      & > p {
        @include font($font-size-20, $font-weight-400, $line-height-15, $letter-spacing-08);
        color: $color-white;
        @include breakpoint('medium') {
          font-size: $font-size-14;
          line-height: $line-height-15;
        }
        a {
          color: $omd-white;
        }
      }
      & > h2 {
          color: $omd-white;
          @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
          @include breakpoint('medium') {
            font-size: $font-size-20;
          }
      }
      & > ul {
        & > li {
          color: $omd-white;
          svg {
            fill: $omd-white;
          }
          a {
            color: $omd-white;
          }
          &:hover {                 
            svg {
              fill: $omd-black;
            } 
          }
        }
      }
    }
  }
}
#custom-popup {
  iframe {
    border: none;
  }
}
  