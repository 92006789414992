.omd-right-arrow-black {
  @include omd-right-arrow-black();
}

#dashboardTop {
  @include inf-container-fluid();
  @include spacer(padding-bottom, map-get($spacers, 5));
  background: $omd-medium-grey2;
  .search__body {
    h3{
      padding-bottom: 24px;
    }
    h4{
      font-size: 2.5rem;
      line-height: 1.25;
      letter-spacing: -2px;
      font-weight: 600;
    }
  }
  &[ng-cloak] {
    visibility: hidden;
  }
  .custom-checkbox {
    [type="checkbox"] {
      &:checked {
        & + label {
          &::before {
            background: $omd-purple;
            border-color: $omd-purple;
          }
        }
      }
    }
    label {
      border-radius: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
      @include spacer(padding-right, map-get($spacers, 0));
      @include spacer(padding-left, map-get($spacers, 3));
      text-transform: capitalize;
      position: relative;
      margin-bottom: 10px;
      border: 1px solid $omd-medium-grey;
      background: $color-white;
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      font-size: $font-size-16;
      font-weight: $font-weight-600;
      line-height: normal;
      @include breakpoint("medium") {
        padding-left: 2rem;
      }
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;
        background: $color-white;
        color: $color-white;
        border-color: $color-black;
        line-height: 10px;
        font-size: 11px;
        cursor: pointer;
        border-radius: 1px;
      }
      &::after {
        border-width: 2px;
        top: 50%;
        left: 14px;
        height: 8px;
        width: 5px;
        margin-top: -6px;
      }
      &:hover {
        border-color: $omd-purple;
        color: $omd-purple;
      }
    }
  }

  .custom-radio {
    position: relative;
    input[type=radio] {
      position: absolute;
      opacity: 0;
    }
    label {
      display: inline-block;
      font-size: 15px;
      line-height: 23px;
      position: relative;
      margin-bottom: 10px;
      border-radius: 60px;
      padding: 5px 10px 5px 30px;
      border: 1px solid $omd-medium-grey;
      background: $color-white;
    
      &::before {
        content: " ";
        display: inline-block;
        flex: 0 0 auto;
        height: 12px;
        width: 12px;
        border: 1px solid $color-black;
        background: $omd-light-grey;
        border-radius: 50%;
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }
    
    input[type=radio]:checked+label:after {
      content: "\2022";
      position: absolute;
      left: 11px;
      top: 5.5px;
      font-size: 28px;
      color: $omd-purple;
    }
  }
  
  a:active {
    color: $body-text-colour;
  }

  .button--outline {
    padding: 10px 20px;
    font-size: $font-size-16;
    line-height: $line-height-125;
    font-weight: $font-weight-600;
    letter-spacing: $letter-spacing-05;
    text-transform: capitalize;
    border-radius: 48px;
    border: 1px solid #000;
    color: #000;
    background: inherit;
    transition: all 300ms ease;
    &:hover {
      background: $color-black;
      color: $color-white
    }
  }

  header.search__header {
    @include inf-container();
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    @include spacer(padding-top, map-get($spacers, 4));
    @include spacer(padding-bottom, map-get($spacers, 3));
    border-top: none;
    @include breakpoint("small") {
      flex-direction: column;
    }
    & > .search__header-title {
      @include spacer(margin-top, 0);
      font-size: $font-size-40;
      font-weight: $font-weight-700;
      line-height: $line-height-135;
      letter-spacing: 1.2px;
      @include breakpoint("small") {
        text-align: center;
      }
    }
    & > div {
      display: flex;
      justify-content: center;
      gap: map-get($spacers, 0);
      @include breakpoint("small") {
        @include spacer(margin-top, map-get($spacers, 0));
      }
      & > .button--outline {
        height: fit-content;
        margin: 0;
      }
    }
  }
  .search__content {
    @include inf-container();
    flex-flow: row;
    width: 100%;
    @include isIE {
      display: block;
      font-size: 0;
    }
    @include breakpoint("lg") {
      flex-direction: column;
      flex-flow: column;
    }
    .search-bottom {
      display: flex;
      flex-direction: column;
      @include col-with-pad(4, "lg");
      min-width: 33.33333%;
      .advertising {
        @include breakpoint("lg") {
          text-align: center;
        }
        .advertising__label {
          display: none;
        }
      }
    }
    .my_products {
      @include isIE {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
      }
      @include spacer(padding-bottom, map-get($spacers, 0));
    }
    .myproduct-container {
      padding: 0px;
      border: 0px;
    }
    .my-prod-title {
      @include spacer(margin-top, map-get($spacers, 4));
    }
    .omd-box-bottom {
      border: 0px none;
      text-align: left;
      &::before {
        border-bottom: 0px none;
      }
    }
    .product-headline {
      margin-bottom: 0px;
      @include breakpoint('lg') {
        @include spacer(margin-top, map-get($spacers, 5));
        padding-top: 14px;
      }
    }
    .no-prods-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: -0.2px;
      @include spacer(margin-bottom, map-get($spacers, 2));
      @include spacer(margin-top, map-get($spacers, 2));
    }
    .no-prod-highlight {
      font-weight: 600;
    }
    .my-products-container {
      @include spacer(margin-bottom, map-get($spacers, 2));
      @include spacer(margin-top, map-get($spacers, 2));
      @include breakpoint("lg") {
        @include inf-row();
      }
      @include breakpoint("small") {
      }
      .product-item {
        display: flex;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include spacer(margin-bottom, map-get($spacers, 0));
        @include spacer(padding-bottom, map-get($spacers, 0));
        border-bottom: 1px solid rgba(142, 135, 154, 0.5);

        @include breakpoint("lg") {
          flex: 45%;
          margin-right: 3%;
          margin-left: 2%;
          margin-bottom: 0px;
          padding-bottom: 0px;
        }

        @include breakpoint("small") {
          flex: 100%;
          margin-right: 15px;
          margin-left: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }
        .prod-info {
          flex: 90%;

          @include breakpoint("lg") {
            padding-bottom: 16px;
            padding-top: 16px;
          }
        }
        .omd-product-name {
          @include omd-body();
          color: $body-text-colour;
          text-decoration: none;
          font-weight: 600;
          transition: all 0.2s;
          &:hover {
            color: $omd-purple;
            &::after {
              background-image: url(img/omd/arrow_right_purple.svg);
            }
          }
          &::after {
            @include omd-right-arrow-black();
            right: 0px;
            top: 35%;
          }
        }
        .prod-arrow {
          overflow: hidden;
          flex: 0 0 50px;
          position: relative;
          @include breakpoint("medium") {
            margin: 0px auto;
            margin-bottom: 0px;
          }
        }
        .info {
          & > *:last-child {
            margin-bottom: 0;
          }
          .name {
            word-break: break-all;
          }
        }
      }
    }
    .no-prods-container {
      @include spacer(margin-bottom, map-get($spacers, 0));
    }

    .card {
      background: $color-white;
      border-radius: 12px;
      border: 1px solid $omd-medium-grey;
      color: $color-black;
      @include spacer(margin-bottom, map-get($spacers, 3));
      box-shadow: 0px 0px 30px 0px rgba(49, 7, 86, 0.10);
      &:last-of-type{
        margin-bottom: 0;
        @include breakpoint('medium'){
          @include spacer(margin-bottom, map-get($spacers, 3));
        }
      }

      
      .js-remove-analyst-modal,
      .js-add-analyst-modal {
        & > .lightbox-modal__title {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-header-purple,
      &-header-black {
        color: $color-white;
        fill: $color-white;
        .search-bar__tips-panel {
          color: $color-black;
        }
      }
      &-header-purple {
        background-color: $omd-purple;
      }
      &-header-black {
        background-color: $color-black;
      }
      &-header-grey {
        background-color: #B7B4C5;
        .card-header__icon {
          fill: #000;
        }
      }
      
      &-header {
        display: flex;
        justify-content: space-between;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        @include spacer(padding-top, map-get($spacers, 0));
        @include spacer(padding-bottom, map-get($spacers, 0));
        @include spacer(padding-left, map-get($spacers, 1));
        @include spacer(padding-right, map-get($spacers, 1));
        & > .heading-text {
          margin-right: auto;
          & > h4 {
            text-transform: uppercase;
            @include font($font-size-18, $font-weight-700, $line-height-15, $letter-spacing-125)
          }
          & > p {
            @include font($font-size-14, $font-weight-400, $line-height-15, $letter-spacing-05)
          }
        }
        &-purple {
          & > .heading-text {
            & > h4 {
              line-height: $line-height-15;
            }
          }
        }        
        &__icon {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 10px;
          cursor: pointer;
          fill: #fff;
        }
        & > a {
          color: inherit;
        }
        .view-all {
          color: $color-white;
          font-size: $font-size-16;
          line-height: 22px;
          font-weight: 400;
          display: flex;
          align-items: center;
          letter-spacing: .5px;
          white-space: nowrap;
          &:hover{
            text-decoration: none;
          }
        }
        &-grey {
          .view-all {
            color: $color-black;
          }
        }
      }

      &-ribbon {
        display: flex;
        flex-wrap: wrap;
        background: $omd-lighter-grey;
        padding-top: .5rem;
        padding-bottom: .5rem;
        @include spacer(padding-left, map-get($spacers, 1));
        @include spacer(padding-right, map-get($spacers, 1));
        color: $color-black;
        @include breakpoint("small") {
          flex-direction: column
        }
      }
      .selected-analyst {
        background: #f4f4f4;
        @include spacer(padding-left, map-get($spacers, 1));
        @include spacer(padding-right, map-get($spacers, 1));
        @include spacer(padding-top, map-get($spacers, 1));
        max-height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 10px;
        }        
        /* Track */
        &::-webkit-scrollbar-track {
          background: $omd-medium-grey2;
        }         
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color-light-black;
          border-radius: 5px;
          border: 2px solid $omd-medium-grey2;
        }
        & > ul > li {
          display: flex;
          align-items: center;
          position: relative;
          gap: 15px;
          @include spacer(margin-bottom, map-get($spacers, 0));
          @include breakpoint("medium-small") {
            margin-bottom: $spacer-2*1.25;
            flex-wrap: wrap;
            align-items: flex-start;
            min-height: 80px;
            gap: 0;
          }

          & > img,
          & > a > img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            @include breakpoint("medium-small") {
              position: absolute;
            }
          }

          & > .dismiss-button {
            order: 3;
            margin-right: 0;
            padding: map-get($spacers, 0)/2 map-get($spacers, 0);
            font-size: $font-size-12;
            @include breakpoint("medium-small") {
              order: 3;
              margin-left: map-get($spacers, 1)*5;
              padding: map-get($spacers, 0)/3 map-get($spacers, 0)/2;
            }
            &:hover {
              & > svg,
              & > span {
                color: $color-black;
                fill: $color-black;
              }
            }
            &.analyst-following {
              background: #939393;
              border-color: #939393;
              &:hover {
                color: #939393;
                background: white;
                & > svg,
                & > span {
                  fill: #939393;
                }
              }
            }
            & > svg {
              width: 20px;
              height: 20px;
              vertical-align: middle;
              fill: $color-white
            }
            & > span {
              color: $color-white;
              display: inline-block;
            }
          }

          & > div > .selected-analyst-name {
            font-weight: 600;
            color: $color-black;
            &:hover {
              color: $omd-purple;
              text-decoration: none;
            }
          }

          & > div > .selected-analyst-title {
            font-size: 13px;
          }
        }
        &-content {
          flex: 1;
          @include breakpoint("medium-small") {
            flex: none;
            margin-left: map-get($spacers, 1)*5;
            margin-bottom: map-get($spacers, 1)/2;
            width: calc(100% - 100px);
          }
        }
      }
      .my-research-view-all {
        margin: 6px auto $spacer-0 auto;
        letter-spacing: $letter-spacing-1/2;
        display: flex;
        align-items: center;
        width: fit-content;
        position: relative;
        color: $color-black;
        text-decoration: none;
        @include spacer(padding-right, map-get($spacers, 3));

        &::before {
          content: "";
          position: absolute;
          background-image: url(/dist/img/omdia/banner-arrow-black.svg);
          right: 0px;
          width: 19px;
          height: 19px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:hover {
          color: $omd-purple;
          text-decoration: none;
          &::before {
            background-image: url(/dist/img/omdia/dotted-arrow-purple.svg);
          }
        }
        &:active {
          color: $omd-purple;
        }
      }
    }

    .column-left {
      order: 0;
      @include col-with-pad(2, "lg");
      width: 60%;
      margin-left: 0;
      flex: 1;
      display: flex;
      flex-direction: column;

      @include isIE {
        display: inline-block;
        vertical-align: top;
        font-size: 1rem;
      }
    }
    .column-right {
      width: 40%;
      display: flex;
      flex-direction: column;
      @include breakpoint("medium") {
        width: initial;
      }
    }

    .search__facets {
      display: flex;
      overflow: hidden;
      float: none;
      width: auto;
      min-width: auto;
      max-width: none;
      margin: 0;
      padding: 0;
      font-family: inherit;
      border: none;
      .facets__content {
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 0;
      }
      .facets__section {
        font-size: inherit;
        line-height: inherit;
        padding-top: 0;
        padding-bottom: 0;
        @include spacer(padding-left, map-get($spacers, 6));
        @include spacer(padding-right, map-get($spacers, 6));
        @include spacer(padding-top, map-get($spacers, 3));
        @include spacer(padding-bottom, map-get($spacers, 3));
        overflow-y: auto;
        border: 0;
        @include breakpoint("medium") {
          @include spacer(padding, map-get($spacers, 3));
        }
      }
      .selectivity-input {
        width: calc(50% - 60px);
        margin-left: 20px;
        @include breakpoint("medium") {
          width: 100%;
          margin-left: 0;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
      .selectivity-single-select {
        position: relative;
        height: 50px;
        font-size: inherit;
        border: none;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid $color-black;
          border-radius: 50px;
        }
        &.hover,
        &.open {
          &::after {
            border-width: 2px;
          }
        }
        &.open {
          .selectivity-arrow::before {
            transform: rotate(180deg);
          }
        }
      }
      .selectivity-single-select-input {
        display: none;
      }
      .selectivity-single-result-container {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 10.5px 50px 10.5px 20px;
      }
      .selectivity-arrow {
        top: calc(50% - 10px);
        right: 20px;
        width: 20px;
        height: 20px;
        &::before {
          @include inf-icon("img/omd/chevron_down_black.svg", 100%, 100%);
        }
        .alert__icon {
          display: none;
        }
      }
      .selectivity-dropdown {
        position: absolute;
        margin-top: 15px;
        padding: 0;
        background-color: transparent;
        border: none;
        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 25px;
          top: -4px;
          width: 13px;
          height: 13px;
          transform: rotate(-45deg) skew(7deg, 7deg);
          z-index: 1;
        }
        &::before {
          background: $color-black;
          margin-top: -2.5px;
        }
        &::after {
          background: $color-white;
        }
      }
      .selectivity-results-container {
        overflow: hidden;
        max-height: 295px;
        font-size: 1rem;
        font-weight: 400;
        background-color: $color-white;
        border: 2px solid $color-black;
        border-radius: 10px;
      }
      .selectivity-results-wrapper {
        overflow: auto;
        height: 100%;
        max-height: 295px;
      }
      .selectivity-load-more,
      .selectivity-result-item {
        position: relative;
        padding: 14px 20px;
        user-select: none;
        z-index: 2;
        &.highlight {
          background-color: $omd-purple;
          color: $color-white;
        }
      }
      .datepicker .dropdown-menu {
        table {
          th, td {
            padding: 0;
            border: none;
            &.text-center {
              text-align: center;
            }
            button {
              padding: 0;
              border-radius: 0;
              span, strong {
                font-family: inherit;
              }
            }
            small {
              font-family: inherit;
            }
          }
        }
      }
    }
    .search-bar,
    .search-metadata,
    .search-sort,
    .search-results,
    .search-pagination__wrapper {
      margin-left: 0;
    }
    .custom-modal {
      height: 100%;
      & > .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
      }
      &-header,
      &-content {
        &.disabled {
          pointer-events: none;
        }
      }
    }
    .my-research {
      &-filter-selected {
        @include spacer(padding-left, map-get($spacers, 1));
        @include spacer(padding-right, map-get($spacers, 1));
        @include spacer(margin-top, map-get($spacers, 1));
        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .btn.tag {
          background: $omd-light-grey;
          padding: 8px;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-125;
          letter-spacing: 0.5px;
          border: 0;
          text-transform: capitalize;
          border-radius: 1rem;
          cursor: pointer;

          & > .dismiss-button__icon {
            margin-left: 0;
            width: 16px;
            height: 16px;
            fill: $color-black;
          }

          &:hover {
            color: $omd-purple;
            & > .dismiss-button__icon {
              fill: $omd-purple;
            }
          }

          &.clear-all {
            background: none;
            border: none;
            font-size: $font-size-14;
            font-weight: $font-weight-600;
            cursor: pointer;
          }
        }
      }
      &-metaheader {
        display: flex;
        @include spacer(margin-left, map-get($spacers, 1));
        @include spacer(margin-right, map-get($spacers, 1));
        @include spacer(margin-top, map-get($spacers, 1));
        border-bottom: 1px solid $omd-medium-grey;

        & > .search-metadata {
          display: flex;
          color: inherit;
          margin-left: 0;
          margin-right: auto;
          font-size: $font-size-14;
          font-family: inherit;
          line-height: $line-height-125;
          letter-spacing: $letter-spacing-025;
          color: inherit;
          align-items: center;

          & > a {
            text-decoration: none;
            color: $color-black;
            &.view-all {
              &:hover {
                color: $omd-purple;
              }
            }
          }

          & > span:hover {
            color: $omd-purple;
          }
    
        }

        .select-value {
          position: relative;
          background-color: $color-white;
          cursor: pointer;
          @include spacer(padding-right, map-get($spacers, 3));
          font-size: $font-size-14;
          text-transform: capitalize;

          &::after {
            content: "";
            position: absolute;
            right: 10px;
            top: 50%;
            width: 10px;
            height: 10px;
            border-top: 2px solid $color-black;
            border-right: 2px solid $color-black;
            transform: rotate(135deg) translateX(-5px) translateY(5px);
          }

          &:hover {
            color: $omd-purple;
          }
        }
      }
    }
    .dashboard {
      &-filter-selected {
        @include spacer(padding-left, map-get($spacers, 1));
        @include spacer(padding-right, map-get($spacers, 1));
        @include spacer(margin-top, map-get($spacers, 1));
      }

      &-modal-filter {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      &-modal-filter-selected {
        order: 4;
        width: 100%;
        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .btn.tag {
          background: $omd-light-grey;
          padding: 8px;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-125;
          letter-spacing: 0.5px;
          border: 0;
          text-transform: capitalize;
          border-radius: 1rem;
          cursor: pointer;

          & > .dismiss-button__icon {
            margin-left: 0;
            width: 16px;
            height: 16px;
            fill: $color-black;
          }

          &:hover {
            color: $omd-purple;
            & > .dismiss-button__icon {
              fill: $omd-purple;
            }
          }

          &.clear-all {
            background: none;
            border: none;
            font-size: $font-size-14;
            font-weight: $font-weight-600;
            cursor: pointer;
          }
        }
      }

      &-modal {
        color: $color-black !important;
        max-width: 1200px;
        width: 100%;
        z-index: 99999;
        left: 50%;
        padding-right: 0 !important;
        transform: translate(-50%, -50%);
        @include breakpoint("medium") {
          margin: auto;
          left: 0;
          right: 0;
          max-width: 93%;
          padding-right: 0 !important;
          transform: translate(0, -50%);
        }

        & > .custom-modal-dialog {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          max-height: 100%;
          overflow: hidden;
          background-color: white;
          @include breakpoint("medium") {
            overflow: auto;
          }
        }

        &-header {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          @include spacer(padding-left, map-get($spacers, 6));
          @include spacer(padding-right, map-get($spacers, 6));
          @include spacer(padding-top, map-get($spacers, 3));
          padding-bottom: 4rem;
          @include spacer(row-gap, map-get($spacers, 0));
          color: $color-black;
          background: $omd-medium-grey;
          @include breakpoint("medium") {
            @include spacer(padding-left, map-get($spacers, 0));
            @include spacer(padding-right, map-get($spacers, 0));
            @include spacer(padding-top, map-get($spacers, 0));
          }
          @include breakpoint("medium-small") {
            flex-direction: column;
          }

          & > h4 {
            font-size: $h4-font-size;
            line-height: $line-height-125;
            font-weight: $font-weight-bold;
            display: flex;
            align-items: center;
            letter-spacing: $letter-spacing-05;
            & > svg {
              width: 28px;
              height: 28px;
              @include spacer(margin-right, map-get($spacers, 0));
            }
          }

          &-purple {
            background: $omd-purple;
            color: $color-white;
            @include spacer(padding-left, map-get($spacers, 5));
            @include spacer(padding-right, map-get($spacers, 5));
            @include breakpoint('medium') {
              @include spacer(padding-right, map-get($spacers, 0));
            }
            h4 {
              margin-bottom: $spacer-2*0.9;
              position: relative;
              &::before {
                content: "";
                width: 13px;
                height: 13px;
                border-top: 4px solid $color-black;
                border-right: 4px solid $color-black;
                transform: rotate(135deg) translate(5px, -10px);
                position: absolute;
                left: -42px;
              }
              & > .lock__icon {
                width: 25px;
                height: 25px;
                margin-left: 10px;
              }
              @include breakpoint('medium') {
                padding-right: map-get($spacers, 5)+map-get($spacers, 1);
                &::before {
                  left: -32px;
                }
              }
              @include breakpoint('small') {
                &::before {
                  left: -25px;
                  top: -5px;
                }
              }
            }
            p {
              letter-spacing: $letter-spacing-02;
            }

            & > .dashboard-modal-btns > .close {
              border-color: $color-white;
              color: $color-white;
              &:hover{
                background: $color-white;
                color: $color-black;
              }
            }
          }

          & > .search-bar__footer-option {
            order: 3;
            max-width: initial;
            flex: auto;
            margin: 0;
          }
        }

        &-btns {
          display: flex;
          order: 2;
          height: fit-content;
          z-index: 1;
          @include breakpoint("medium") {
            position: initial;
            text-align: center;
            order: 2
          }
          @include breakpoint('medium-small') {
            flex-direction: column;
            gap: 1rem;
          }

          & > .close {
            border: 1px solid $color-black;
            padding: $spacer-2*.5 0;
            @include spacer(padding-left, map-get($spacers, 1));
            @include spacer(padding-right, map-get($spacers, 1));
            @include spacer(margin-left, map-get($spacers, 0));
            border-radius: 60px;
            font-size: $font-size-16;
            color: $color-black;
            letter-spacing: $letter-spacing-05;
            text-shadow: none;

            @include breakpoint("medium-small") {
              margin-left: 0;
            }

            &.apply {
              background-color: $color-black;
              color: $color-white;
              text-shadow: none;

              &:hover{
                background-color: $omd-purple;
                border-color: $omd-purple;
              }
            }

            &:hover {
              text-decoration: none;
              background-color: $color-black;
              color: $color-white;
            }
          }
        }

        &-content {
          display: flex;
          overflow: hidden;
          margin-top: -50px;
          padding: 0;
          color: $color-black;
          @include breakpoint("medium") {
            overflow: visible;
          }
          & > .search-results {
            @include spacer(padding-left, map-get($spacers, 5));
            @include spacer(padding-right, map-get($spacers, 5));
            @include spacer(padding-top, map-get($spacers, 2));
            @include spacer(padding-bottom, map-get($spacers, 2));
            max-height: 550px;
            overflow-y: auto;
          }

          .facets__section-item {
            & > .facets__label {
              font-size: $font-size-16;
              font-weight: 600;
            }
          }
          .facets__section-footer {
            & > .facets__field-group {
              & > input {
                width: auto;
              }
            }
          }
        }

        &-filter {
          .tab {
            .tab-items {
              background-color: transparent;
              border-bottom: 2px solid $omd-dark-grey;
              justify-content: center;
              @include breakpoint("medium") {
                flex-direction: column;
              }

              & > .tab-item {
                padding: 7px 2px;
                border: 2px solid transparent;
                margin: 0 10px;
                margin-bottom: -3px;
                position: relative;
                z-index: 1;
                @include breakpoint("medium") {
                  display: none;
                }
                
                & > label {
                  border: 1px solid $omd-medium-grey;
                  border-radius: 60px;
                  padding: 4px 12px;
                  height: auto;
                  white-space: nowrap;
                  letter-spacing: $letter-spacing-025;
                  font-size: $font-size-16;
                  font-weight: $font-weight-600;
                  &:hover {
                    color: $omd-purple;
                  }
                  &::after {
                    background-color: transparent;
                  }
                  span {
                    margin-left: 5px;
                  }                  
                  @include breakpoint("medium") {
                    background-color: $color-white;
                  }
                }
                
                &.active {
                  background-color: $color-white;
                  border-top-left-radius: 25px;
                  border-top-right-radius: 25px;
                  border: 2px solid $omd-dark-grey;
                  border-bottom: 2px solid $color-white;
                  @include breakpoint("medium") {
                    display: block;
                    order: -1;
                  }
                  
                  & > label {
                    border: 1px solid transparent;
                    padding: 4px 34px 4px 12px;
    
                    &::after {
                      content: "";
                      width: 10px;
                      height: 10px;
                      border: 2px solid $color-black;
                      border-top: none;
                      border-left: none;
                      transform: rotate(45deg) translateY(-100%);
                      position: absolute;
                      top: 50%;
                      right: 20px;
                      left: auto;
                    }
                    &:hover {
                      color: $color-black;
                    }
                  }
                }
              }

              &:hover {
                & > .tab-item {
                  display: block;
                }
              }
            }

            & > .tab-content {
              &.show {
                display: block;
              }
              &.disabled {
                pointer-events: none;
              }
            }
          }

          &-list,
          .facets__fieldset,
          .facets__section-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            column-gap: 40px;
            padding-bottom: 0;

            &.three-column {              
              display: block;
              column-count: 3;
              @include breakpoint('medium') {
                column-count: 2;
              }
              @include breakpoint('small') {
                column-count: 1;
              }
              & > li {              
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
                @include spacer(margin-bottom, map-get($spacers, 2));
              }
            }

            &.servicearea {
              & > li {
                width: 100%;
                margin-bottom: $spacer-2*1.25;
                @include breakpoint('medium') {
                  width: 100%;
                }
                .facets__label {
                  height: auto;
                  min-height: 40px;
                  font-size: $font-size-18;
                }
              }
            }

            &.regions > li,
            &.authorName > li {              
              @include breakpoint('medium') {
                width: 100%;
              }
            }

            &-option,
            .facets__repeat-wrapper {
              &-heading {
                font-size: $h5-font-size;
                font-weight: $font-weight-bold;
                color: $omd-dark-grey;
                border-bottom: 1px solid $omd-medium-grey;
                margin-bottom: 10px;
              }
            }

            &--sub {
              padding-left: 0;
            }
            &.geography .facets__section-list--sub,
            &.regions .facets__section-list--sub,
            &.servicearea .facets__section-list--sub {
              @include spacer(padding-left, map-get($spacers, 2));
              display: block;
              & > li > .custom-checkbox label {
                font-size: $font-size-12;
                line-height: $line-height-13;
              }
            }
            &.authorName .facets__section-list--sub{
              display: block;
            }
            
          }
          & > .facets__section-item {
            & > .facets__label {
              font-size: $font-size-14;
              font-weight: 600;
              display: inline-flex;
            }
          }
        }
      }
      
    }

    .search-sort {
      margin-bottom: 0;
      line-height: 1;
      &__title {
        font-size: $font-size-14;
        font-weight: $font-weight-400;
      }
      
      &__sort-by {
        display: block;
        align-items: center;
        align-items: flex-start;
        position: relative;
        letter-spacing: $letter-spacing-025;
        font-size: $font-size-14;
        @include breakpoint("medium") {
          margin-bottom: 0;
        }
        & > .select-list {
          min-width: 110px;
        }
      }

      &__control-list {
        background-color: $color-white;
        position: absolute;
        flex-direction: column;
        border: 1px solid $color-light-black;
        z-index: 10;
        top: 100%;
        right: 0;
        /*width: 100%;*/
      }

      &__control-type {
        display: block;
        border-bottom: 1px solid $color-light-black;
        position: relative;
        white-space: nowrap;

        .btn {
          padding: 5px 15px 5px 30px;
          display: block;
          color: inherit;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          letter-spacing: inherit;
          border: none;
          text-transform: none;
          text-decoration: none;
        }

        &.is-selected {
          &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 14px;
            width: 10px;
            height: 5px;
            border-top: 2px solid $omd-purple;
            border-right: 2px solid $omd-purple;
            transform: rotate(135deg) translateX(-4px);
          }
        }

        &.no-tick-mark {
          .btn {
            @include spacer(padding-left, map-get($spacers, 0));
          }
          &::before {
            border-color: transparent;
          }
        }
      }
    }
    
    .search-header {
      &__results-meta {
        position: relative;
        padding-left: 5px;
        display: flex;
        align-items: center;
        strong {
          font-weight: inherit;
        }
        & > .select-list {
          min-width: 70px;
        }
      }      
      &__facet-tag-list {
        display: none;
      }
    }

    .information__tips {
      position: relative;
      white-space: nowrap;
      order: initial;
      display: flex;
      align-items: center;
      @include breakpoint("lg") {
        display: none;
      }

      & > .information__tips-panel {
        display: none;
        position: absolute;
        border: 1px solid $omd-medium-grey;
        white-space: normal;
        z-index: 1000;
        right: auto;
        left: -20px;
        top: calc(100% + 15px);
        width: 450px;
        background: $omd-light-grey;
        color: $color-black;
        box-shadow: 0 2px 10px $omd-medium-grey;
        @include spacer(padding, map-get($spacers, 0));
        text-transform: initial;
        line-height: normal;
        font-family: $font-family-opensans;
        font-size: $font-size-12;
        font-weight: normal;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          top: -3px;
          left: 25px;
          right: auto;
          transform: rotate(45deg) translateX(-50%);
          border-left: 1px solid $omd-medium-grey;
          border-top: 1px solid $omd-medium-grey;
          background: #eee;
        }
        & > p {
          line-height: normal;
          font-family: $font-family-opensans;
          font-size: $font-size-12;
          font-weight: normal;
        }
      }
      &.open {
        position: relative;
        z-index: 1;
        & > .information__tips-panel {
          display: block;
          & > :not(h3) {
            font-family: "Open Sans",sans-serif;
            line-height: $line-height-125;
          }
        }
      }
    }
    
    .search-bar {
      min-height: auto;
      padding: 0;
      @include spacer(margin-bottom, map-get($spacers, 3));
      background: transparent;
      &__label,
      &__footer {
        display: none;
      }
      &__tips {
        order: initial;
        display: flex;
        align-items: center;

        & h4 {
          text-transform: uppercase;
          @include font($font-size-18, $font-weight-bold, $line-height-1, $letter-spacing-05);
        }

        & p {
          @include font($font-size-12, $font-weight-300, $line-height-088, $letter-spacing-08);
        }
        
        &.open .search-bar__tips-panel {
          max-width: 400px;
          top: 0;
          left: 30px;
          background: $omd-light-grey;
          border: none;
          box-shadow: 0 0 6px $omd-dark-grey;
          font-family: "Open Sans",sans-serif;
          padding-right: 60px;

          & > :not(h3) {
            font-family: "Open Sans",sans-serif;
            line-height: $line-height-125;
          }
          &::before {
            background: none;
            top: 5px;
            right: auto;
            left: -20px;
            width: initial;
            height: initial;
            border: 10px solid transparent;
            border-right: 10px solid $omd-light-grey;
          }
        }
      }
      &__close-tips {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
        text-decoration: none;
        color: $color-black;
        text-transform: uppercase;
        font-size: 13px;
      }
      &__input-wrapper {
        margin-right: 0;
        height: 100%;
      }
      &__field {
        height: 40px;
        padding: 0 25px 0 15px;
        border: solid 1px $omd-medium-grey2;
        border-right: none;
        border-radius: 34px 0 0 34px;
        font-size: $font-size-16;
        font-weight: $font-weight-600;
        line-height: $line-height-13;
        letter-spacing: $letter-spacing-025;
        color: $omd-darker-grey;
        appearance: none;
        -webkit-appearance: none;

        &:focus {
          outline: none;
        }
      }
      &__clear {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;

        & > svg {
          width: 15px;
          opacity: 0;
        }
        
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          position: absolute;
          background-image: url(img/omd/icon_close_black.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        &.active {
          display: block;
        }
      }
      &__icon {
        display: block;
        fill: $color-medium-black;
        width: 18px;
      }
      &__submit {
        position: relative;
        width: 50px;
        margin-right: 0;
        padding: 0;
        background-color: $color-white;
        border: solid 1px $omd-medium-grey;
        border-left: none;
        border-radius: 0 34px 34px 0;
        cursor: pointer;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          use {
            fill: $color-black;
          }
        }
        @include breakpoint('medium') {
          border: solid 1px $omd-medium-grey;
          border-radius: 0;
        }
      }
      &__content,
      &__footer-option {
        flex: 1;
      }
      &__content {
        align-items: initial;
        max-width: 300px;
        margin-left: auto;
        @include breakpoint("medium") {          
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
          width: 100%;
          flex: initial;
        }
        @include breakpoint("small") {          
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
        }

        &:hover {
          .search-bar__field,
          .search-bar__submit {
            border-color: $omd-purple;

            & > svg use {
              fill: $omd-purple;
            }
          }
          .search-bar__field::placeholder {
            color: $omd-purple;
          }
        }
      }
      &__filter-btn {
        background: $color-white;
        border-radius: 40px;
        border: 1px solid $omd-medium-grey2;
        display: flex;        
        height: 40px;
        align-items: center;
        padding: 5px 13px;
        @include spacer(margin-right, map-get($spacers, 1));
        cursor: pointer;
        @include breakpoint("medium") {
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
        }
        @include breakpoint("small") {
          margin-right: 0;
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
        }

        & > svg {
          margin-right: 5px;
        }

        & > span {
          white-space: nowrap;
          font-size: $font-size-16;
          font-weight: 600;
          line-height: 21px;
          color: $omd-darker-grey;
          letter-spacing: $letter-spacing-05;
        }

        &:hover {
          border-color: $omd-purple;

          & span {
            color: $omd-purple;
          }
          & > svg {
            fill: $omd-purple;
            color: $omd-purple;
          }
        }
      }
      &__headline-label {
        line-height: $line-height-125;
        color: $color-black;
        letter-spacing: $letter-spacing-025;
      }
      &__footer-option {
        display: flex;
        width: 100%;
        margin-bottom: 0;
        align-items: center;
        max-width: 300px;
        margin-right: 0;
        @include breakpoint("medium") {
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
        }
        @include breakpoint("small") {
          order: 5;
          margin-top: map-get($spacers, 0)/2;
          margin-bottom: map-get($spacers, 0)/2;
        }
        .onoffswitch-label {
          border: none;
          border-radius: 11px;
          .onoffswitch-inner {
            margin-left: -47px;
            &::before,
            &::after {
              color: $color-white;
              font-family: inherit;
              font-weight: 600;
              font-size: 0.625rem;
              letter-spacing: 0;
            }
            &::before {
              content: "ON";
              padding-left: 6px;
              background-color: $omd-purple;
            }
            &::after {
              content: "OFF";
              padding-right: 6px;
              background-color: #8e879a;
            }
          }
          .onoffswitch-switch {
            left: 0;
            top: 0;
            background-color: $color-white;
          }
        }
        .onoffswitch-checkbox:checked {
          & + .onoffswitch-label {
            .onoffswitch-inner {
              margin-left: 0;
            }
            .onoffswitch-switch {
              left: 24px;
            }
          }
        }
        &__title {
          margin-right: 10px;
          font-size: inherit;
          letter-spacing: inherit;
        }
      }
    }    
    
    .loading {
      margin: 30px auto;
      display: block;
    }

    .no-result {
      @include spacer(padding-top, map-get($spacers, 2));
      @include spacer(padding-bottom, map-get($spacers, 2));
      @include breakpoint("medium") {
        order: 6;
      }
      p {
        @include spacer(margin-bottom, map-get($spacers, 2));
      }
    }

    .search-results {
      min-height: 100px;
      @include spacer(padding-left, map-get($spacers, 1));
      @include spacer(padding-right, map-get($spacers, 1));
      @include breakpoint("medium") {
        order: 6;
      }

      &-info {
        font-size: $font-size-14;
        letter-spacing: $letter-spacing-025;
        color: $color-medium-black;
        line-height: $line-height-2;
        border-bottom: 1px solid $omd-medium-grey;
      }

      & > .search-result {
        @include spacer(padding-top, map-get($spacers, 1));
        @include spacer(padding-bottom, map-get($spacers, 1));
        border: none;
        font-size: $font-size-12;
        letter-spacing: $letter-spacing-025;
        & > .search-result__links {
          text-decoration: none;
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: $color-black;
          font-size: 13px;
          font-weight: $font-weight-400;
          line-height: $line-height-1;

          & > span {
            margin-left: 5px;
          }
        }

        & > .btn-upgrade {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          background: $color-black;
          color: $color-white;
          padding: 0.5rem 1rem;
          @include spacer(margin-bottom, map-get($spacers, 0));

          &:hover {
            background: $omd-purple;
            border-color: $omd-purple;
          }
          & > .lock__icon {
            width: 15px;
            margin-right: 5px;
            vertical-align: sub;
            fill: $color-white;
          }
        }
      }

      .search-result__bookmark {
        @include breakpoint("medium") {
          margin-right: 0;
          width: 20px;
        }
      }

      .search-result__body {
        &:hover {
          .search-result__heading{
            color: $color-black;
          }
        }
        &::before {
          display: none;
        }
        & > .search-result__pubdate,
        & > .search-result__author,
        & > .search-result__content-type,
        & > .search-result__entitlement {
          font-size: 13px;
          line-height: normal;
          color: $color-black;
          letter-spacing: $letter-spacing-05;
          font-weight: $font-weight-400;
          margin-block: 0;
          cursor: initial;
          a {
            font-size: $font-size-12;
            &:hover{
              color: $omd-purple;
            }
            &::after {
              display: none;
            }
          }
        }
        & > .search-result__content-type {
          text-transform: uppercase;
          font-weight: $font-weight-600;
        }

        & > .search-result__media-type {
          margin-block: 0;
          span {
            width: 18px;
            height: 18px;
          }
        }

        & > .search-result__freelabel {
          margin-left: 5px;
          margin-block: 0;
          span {
            padding: 0 8px;
            font-size: $font-size-12;
          }
        }

        & > .search-result__heading {
          margin-bottom: 5px;
          font-size: $font-size-16;
          line-height: $line-height-133;
          letter-spacing: $letter-spacing-025;
          color: $color-black;
          padding-right: map-get($spacers, 1)*2;
          &:hover{
            color: $omd-purple;
          }
        }
        & > .search-result__content-type,
        & > .search-result__pubdate {
          padding: 0;
          border: none;
          & ~ .search-result__pubdate,
          & ~ .search-result__author,
          & ~ .search-result__entitlement {
            margin-left: 5px;
            margin-right: 0;
            padding-left: 5px;
            padding-right: 0;
            border-left: 1px solid $omd-dark-grey;
            border-right: none;
          }
        }
        & > .search-result__description {
          order: 1;
          margin-bottom: 1rem !important;
          padding-right: map-get($spacers, 1)*2;

          display: block;
          display: -webkit-box;
          max-width: 100%;
          height: 50px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .search-result__entitlement {
          font-size: 12px;
          font-weight: $font-weight-600;
          width: auto;
          color: $omd-purple;
          & > .lock__icon {
            fill: $omd-purple;
            width: 10px;
            height: 10px;
            vertical-align: middle;
          }
          & > span {
            vertical-align: middle;
          }
        }
      }
    }
    .saved-search,
    .recommended-content,
    .recommended-product,
    .my-history,
    .resource-content {
      & > .search-result {
        & > .search-result__body {
          & > .search-result__heading {
            padding-right: 0;
          }
        }
      }
    }
    .saved-search {
      & > .search-result {
        & > .search-result__body {
          & > .search-result__author {
            padding: 0;
            margin-left: 0;
            border: none;
          }
        }
      }
    }
    .search-pagination {
      &__wrapper {
        padding: 0;
        border-top: none;
        @include breakpoint("medium") {
          order: 7;
        }
      }
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        @include spacer(padding-top, map-get($spacers, 3));
        @include spacer(padding-bottom, map-get($spacers, 3));
        @include breakpoint("medium") {
          display: block;
        }
        select {
          margin-left: 20px;
          padding: 3px 9px;
          border: 1px solid $color-black;
          width: 64px;
          font-family: inherit;
          font-weight: 600;
          font-size: 0.875rem;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          border-radius: 16px;
        }
      }
      &__pagination-list {
        margin-left: auto;
        @include breakpoint("medium") {
          width: auto;
          margin-left: -15px;
          margin-right: -15px;
          @include spacer(margin-top, map-get($spacers, 3));
          text-align: inherit;
        }
        li {
          margin: 0 14px;
          padding: 0;
          font-family: inherit;
          font-weight: 600;
          font-size: inherit;
          color: inherit;
          text-transform: inherit;
          line-height: inherit;
          a {
            padding: 0 1px 1px;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
            text-transform: inherit;
            line-height: inherit;
            text-decoration: none;
            border-bottom: 2px solid $color-black;
          }
          &.is-active {
            a {
              background: transparent;
              color: $omd-purple;
              border-color: $omd-purple;
            }
          }
        }
      }
    }
    #recommended-content-modal {
      & > .modal-dialog {
        & > .card-ribbon {
          width: 100%;
          @include spacer(padding-left, map-get($spacers, 5));
          @include spacer(padding-right, map-get($spacers, 5));
        }
      }
    }
    #recommended-product-modal,
    #recommended-content-modal {
      max-width: 860px;
      @include breakpoint("medium") {
        max-width: 93%;
      }
      & > .modal-dialog {
        & > .dashboard-modal-header {
          @include spacer(padding-bottom, map-get($spacers, 2));
          & > .dashboard-modal-header-content {
            width: 100%;
            padding-right: 150px;
            position: relative;
            @include breakpoint('medium') {
              padding-right: 0;
            }
            & > .dashboard-modal-btns {
              position: absolute;
              top: 0;
              right: 0;
              & > a {
                border-color: $color-white;
                color: $color-white;
                &:hover {                    
                  background: $color-white;
                  color: $color-black;
                }
              }
            }
          }
        }
        & > .dashboard-modal-content {
          margin: 0;
          & > .search-results {
            @include breakpoint('medium') {
              padding: 0;
              @include spacer(padding-left, map-get($spacers, 0));
              @include spacer(padding-right, map-get($spacers, 0));              
            }
            & > .search-result {
              font-size: $font-size-14;
              font-weight: $font-weight-600;
              & > a {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
  #my-products {
    .search-results {
      display: flex;
      flex-wrap: wrap;
      .search-results-info {
        flex: 3;
        padding-right: 1rem;
        @include breakpoint('medium') {
          padding-right: 0;
        }
        & + .search-results-info {
          flex: 1;
          padding-right: 0;
          text-align: right;
          @include breakpoint('medium') {
            display: none;
          }
        }
      }
      .search-result {
        &__body {
          display: flex;
          @include breakpoint('medium') {
            display: block;
          }
          & > span {
            flex: 1;
            font-size: $font-size-14;
            letter-spacing: $letter-spacing-025;
            line-height: $line-height-133;
            text-align: right;
            &::before {
              @include breakpoint('medium') {
                content: "Renewal date: ";
              }
            }
          }
        }
        &__heading {
          flex: 3;
          margin: 0;
          padding-right: 1rem;
          order: 0;
          @include breakpoint('medium') {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.lets-connect {
  gap: 35px;
  @extend .inf-col;
  &__heading {
    @include font($font-size-40, $font-weight-bold, $line-height-088, $letter-spacing-125);
    @include breakpoint('medium-small') {
      text-align: center;
      font-size: 2rem;
    }
  }
  &__box{
    display: flex;
    gap: 1rem;
    @include breakpoint("medium") {
      flex-direction: column;
    }
  }
  &--tile {
    flex: 1;
    @include breakpoint("medium") {
      flex: auto;
    }
    &-content {
      @include spacer(padding, map-get($spacers, 2));
      height: 290px;
      position: relative;
      transition: opacity 300ms ease;
      &::before {
        content: "";
        position: absolute;
        left: calc(50% - 20px);
        top: calc(100% - 50px);
        width: 40px;
        height: 30px;
        background: url(/dist/img/omd/chevron_dotted_down_white.svg) no-repeat;
        background-size: 36px;
        z-index: 1;
        transition: opacity 300ms ease;
      }
      &:hover > .lets-connect--tile-hover {
        opacity: 1;
      }
      & > h4 {
        letter-spacing: $letter-spacing-125;
        font-weight: $font-weight-bold;
        transition: color 300ms ease;
        @include breakpoint('medium-small') {
          font-size: 2rem;
        }
      }
    }
    &-hover {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $omd-purple;
      left: 0;
      top: 0;
      z-index: 2;
      @include spacer(padding, map-get($spacers, 2));
      color: $color-white;
      display: flex;
      flex-direction: column;
      transition: opacity 300ms ease;
      & > p {
        letter-spacing: $letter-spacing-05;
        line-height: $line-height-15;
        font-size: $font-size-18;
        font-weight: $font-weight-400;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > .btn.omd-icon-arrow.secondary {
        color: $color-white;
        margin: auto 0 6px;
        font-size: $font-size-14;
        line-height: normal;
        font-weight: $font-weight-600;
        letter-spacing: $letter-spacing-08;
        @include spacer(padding-top, map-get($spacers, 0));
        @include spacer(padding-bottom, map-get($spacers, 0));
        padding-left: 25px;
        padding-right: 55px;
        border-width: 1px;
        width: fit-content;
        &::after {
          background-size: 23px;
          background-image: url(/dist/img/omd/arrow_right_white.svg);
          right: 20px;
          width: 26px;
          height: 20px;
        }
      }
    }
  }
  &--tile-one {
    & .lets-connect--tile-content {
      &::before {
        background: url(/dist/img/omd/chevron_dotted_down_black.svg) no-repeat;
        background-size: 36px;
      }
    }
  }
  &--tile-one,
  &--tile-three {
    & .lets-connect--tile-content {
      background: $omd-light-grey;
      & > .lets-connect--tile-hover {
        & > .btn.omd-icon-arrow.secondary {
          border-color: $color-white;
          color: $color-white;
          &:hover {
            background: $color-white;
            color: $color-black;
            &::after {
              background-image: url(img/omd/arrow_right_black.svg);
            }
          }
        }
      }
    }        
  }
  &--tile-two,
  &--tile-four {
    & .lets-connect--tile-content {
      .lets-connect--tile-hover {
        background: none;
        & > .btn.secondary {
          background: $omd-purple;
          border-color: $omd-purple;
          color: $color-white;
          &:hover {
            background: $color-black;
            border-color: $color-black;
          }
        }
      }
    }
  }
  &--tile-two {
    & .lets-connect--tile-content {
      & > h4 {
        opacity: 1;
        transition: opacity 300ms ease;
      }
      &:hover {
        & > h4 {
          opacity: 0;
        }
        &::before {
          opacity: 0;
        }
      }
      background-repeat: no-repeat;
      background-size: cover;
      color: $color-white;
    }
  }
  &--tile-three {
    & .lets-connect--tile-content {
      background: $omd-purple;
      color: $color-white;
      &:hover > .lets-connect--tile-hover {
        background: $color-black;
      }
    }
  }
  &--tile-four {
    & .lets-connect--tile-content {
      background-repeat: no-repeat;
      background-size: cover;
      color: $color-white;
      &:hover > .lets-connect--tile-hover {
        background: $omd-medium-grey;
        color: $color-black;
      }
    }
  }
}