.details-card {
    display: flex;
    flex-direction: column;
    &-content {
        display: flex;
        flex-direction: column;
        background: $omd-pink-blue-gradient;
        padding: map-get($spacers, 1);
        gap: 11px;
        color: $color-black;
        flex: 1;
        @include breakpoint("medium-small") {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }
        &-title {
            @include font($font-size-25, $font-weight-700, $line-height-125, $letter-spacing-normal);
            color: $omd-dark-purple;
            @include breakpoint("medium-small") {
                width: 100%;
                margin-left: map-get($spacers, 1)*3;
            }
        }
        & > svg,
        & > img {
            width: 84px;
            height: 84px;
            margin-bottom: map-get($spacers, 1);
            @include breakpoint("medium-small") {
                width: 40px;
                height: 40px;
                margin-bottom: 0;
                margin-right: 9px;
            }
        }
        p {
            @include font($font-size-16, $font-weight-400, $line-height-135, $letter-spacing-normal);
            margin-bottom: map-get($spacers, 1)/2;
            @include breakpoint("medium-small") {
                width: 100%;
                margin-left: map-get($spacers, 1)*3;
                margin-bottom: 0;
                overflow: visible;
                -webkit-line-clamp: initial;
            }
        }
        & > .cta {
            margin-left: auto;
        }
    }
}