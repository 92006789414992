.tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  [type="radio"] {
    display: none;
    &:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
    &:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
    &:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
    &:nth-of-type(4):checked ~ .tab-content:nth-of-type(4),
    &:nth-of-type(5):checked ~ .tab-content:nth-of-type(5) {
      display: flex;
    }
    &:nth-of-type(1):checked ~ .tab-items .tab-item:nth-of-type(1) label::after,
    &:nth-of-type(2):checked ~ .tab-items .tab-item:nth-of-type(2) label::after,
    &:nth-of-type(3):checked ~ .tab-items .tab-item:nth-of-type(3) label::after,
    &:nth-of-type(4):checked ~ .tab-items .tab-item:nth-of-type(4) label::after,
    &:nth-of-type(5):checked ~ .tab-items .tab-item:nth-of-type(5) label::after {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
    &:nth-of-type(1):checked ~ .tab-items .tab-item:nth-of-type(1) label,
    &:nth-of-type(2):checked ~ .tab-items .tab-item:nth-of-type(2) label,
    &:nth-of-type(3):checked ~ .tab-items .tab-item:nth-of-type(3) label,
    &:nth-of-type(4):checked ~ .tab-items .tab-item:nth-of-type(4) label,
    &:nth-of-type(5):checked ~ .tab-items .tab-item:nth-of-type(5) label {
      .sd-label {
        font-weight: $font-weight-bold;
      }
    }
  }
  .tab-items {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    &::after {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    > .tab-item {
      padding: 0 15px;
      > label {
        position: relative;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        font-weight: $font-weight-600;
        &:hover {
          &::after {
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }
        &:last-child {
          label {
            margin-right: 0;
          }
        }
      }
    }
  }
  .tab-content {
    display: none;
    flex-wrap: wrap;
    padding: 15px;
    .search-result {
      &:first-child {
        margin-top: -15px;
        border-top: none;
      }
      &:last-child {
        margin-bottom: -15px;
      }
    }
  }
}