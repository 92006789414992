.banner__wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include spacer(padding-top, map-get($spacers, 4));
  @include spacer(padding-bottom, map-get($spacers, 4));
}

.banner {
  margin-bottom: 0px;
  .banner__wrapper {
    @include breakpoint("lg") {
      display: flex;
      flex-direction: column;
    }
  }
  .banner-text {
    padding-right: 20px;
    margin-right: 0px;
    @include breakpoint("lg") {
      width: 60%;
      @include spacer(padding-bottom, map-get($spacers, 1));
    }
    @include breakpoint("small") {
      width: 100%;
    }
    img {
      display: none;
    }
    strong {
      font-weight: normal;
    }
  }
  .button--filled:not([disabled]) {
    min-width: 84px !important;
    padding: 8px 16px;
    @include font(
      $font-size-12,
      $font-weight-600,
      $line-height-1,
      $letter-spacing-1
    );
    color: black;
    background: inherit;
    border-color: black;
    &:hover {
      color: white;
      background: black;
      border-color: white;
    }
  }
}

.page-account {
  .checkbox-label,
  .showallproducts,
  .hideallproducts {
    cursor: pointer;
  }
  .labeled-checkbox input[type="checkbox"]:checked + .checkbox-label {
    border-radius: 0px !important;
    &::after {
      visibility: visible;
    }
    &::before {
      background-color: $omd-purple;
      border: none;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
  input[type="checkbox"] + .checkbox-label {
    &::before {
      border-radius: 0px !important;
    }
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      left: 6.5px;
      top: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      visibility: hidden;
      pointer-events: none;
    }
  }
  input[type="checkbox"] + .checkbox-label::before,
  input[type="radio"] + .checkbox-label::before {
    content: " ";
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 18px;
    width: 18px;
    border: 1px solid #d1d3d4;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 0px;
    position: relative;
    top: 3px;
  }
  input[type="checkbox"] + .checkbox-label,
  input[type="radio"] + .checkbox-label {
    display: flex;
    position: relative;
    margin-bottom: 0;
  }

  .sortable-table__header {
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    td {
      padding: 20px 30px 20px 0px;

      @include breakpoint("small") {
        font-size: 16px;
        font-weight: normal;
        padding: 20px 0px 0px;
      }
    }
  }

  #form-search-preferences,
  #form-search-preferences-none,
  .form-remove-saved-document {
    [data-lightbox-modal-title="Remove Saved Search"].button--outline:not(
        [disabled]
      ),
    [data-lightbox-modal-title="Remove Article"].button--outline:not(
        [disabled]
      ) {
      background-color: transparent;
      color: transparent;
      border: none;
      background-image: url(img/omd/trash.svg);
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: 0 0;
      width: 22px;
      height: 24px;
      padding: 0;
      text-align: center;
      outline: none;
      text-indent: -9000;
      border-radius: 0px;

      @include breakpoint("small") {
        color: $color-black;
        background-position: 4px 0;
        padding-left: 32px;
        width: auto;
        text-indent: 0;
      }
    }

    @include breakpoint("small") {
      .sortable-table__submit-wrapper {
        text-align: left;
      }
    }
  }

  .sortable-table__col {
    @include breakpoint("small") {
      padding: 20px 0 0 20px;
    }

    .sorting-arrows {
      width: 10px;

      .sorting-arrows__arrow {
        width: 9px;
        vertical-align: unset;
        margin-left: 3px;
        margin-top: 0px;
      }
      svg.sorting-arrows__arrow.sorting-arrows__arrow--down,
      svg.sorting-arrows__arrow.sorting-arrows__arrow--up {
        use {
          display: none;
        }
        background-image: url(img/omd/chevron_down_white.svg);
        background-repeat: no-repeat;
        background-size: 9px 9px;
        line-height: 30px;
      }
      svg.sorting-arrows__arrow.sorting-arrows__arrow--up {
        transform: rotate(180deg);
      }
    }
    .labeled-checkbox {
      @include breakpoint("medium") {
        display: block;
        margin-bottom: 20px;
      }
    }

    .labeled-checkbox input[type="radio"]:checked + .checkbox-label {
      &::after {
        content: "";
        left: 5px !important;
        top: 50%;
        transform: translateY(-50%);
        font-size: 34px;
        color: $color-white;
        height: 8px;
        width: 8px;
        position: absolute;
        background-color: $color-white !important;
        border-radius: 50%;
      }
      &::before {
        background-color: $omd-purple;
        border: none;
      }
    }
  }
  hr {
    margin: 0px;
  }
}
.next-top {
  @include spacer(padding-top, map-get($spacers, 3));
  @include spacer(padding-bottom, map-get($spacers, 3));
  border-bottom: 1px solid #d8d8d8;
  text-align: right;
}
.next-bottom,
.email-verification-buttons {
  @include spacer(padding-top, map-get($spacers, 3));
  @include spacer(padding-bottom, map-get($spacers, 5));
  @include spacer(margin-top, map-get($spacers, 4));
  border-top: 1px solid #d8d8d8;
  text-align: right;
  margin-bottom: 0px;
}

.page-account__header {
  @include spacer(margin-top, map-get($spacers, 4));
}
.tellus {
  @include colwidth(5, "lg");
}
.tellus-header {
  @include spacer(margin-bottom, map-get($spacers, 2));
}

.tellus-content {
  @include spacer(margin-bottom, map-get($spacers, 4));
}

.page-preferences__submit-wrapper {
  @include spacer(padding-bottom, map-get($spacers, 3));
  .full-width-button.update-password-btn {
    max-width: 230px;
    float: left;
  }
  .button--filled:not([disabled]) {
    @include btn-legacy-icon("outline", "arrow_right", 26px, 20px);
    color: black;
    background: white;
    border-color: black;
    &:hover {
      color: white;
      background: black;
      border-color: white;
    }
  }
}

.page-preferences {
  hr {
    background-color: #d8d8d8;
    margin-bottom: 32px;
  }
}
.form-update-account-contact
  .page-account-contact__column
  .page-account__subheader {
  display: none;
}

.form-update-account-contact .page-account__text-label {
  text-transform: uppercase !important;
}

.form-update-account-contact {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  h2.page-account__subheader {
    display: none !important;
  }
  ​ page-account input[type="text"] {
    border: solid 1px #acacb0;
  }

  .js-form-error-general + div {
    display: inline-block;
    width: 100%;
  }
  .page-account-contact__column {
    @include spacer(margin-bottom, map-get($spacers, 4));
    @include breakpoint("medium") {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}
.general-header__navigation-scroller--left {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0,
    #ffffff 66%,
    #ffffff 100%
  );
  margin-top: -3.2rem;
}
.general-header__navigation-scroller--right {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    #ffffff 66%,
    #ffffff 100%
  );
  margin-top: -3.2rem;
}

.general-header {
  @include breakpoint("lg") {
    padding-top: 1px;
    margin-top: -1px;
  }
}
.form-update-account-contact {
  @include breakpoint("small") {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
}

#mktform,
.form-update-account-contact {
  .selectivity-single-select-input {
    background-image: url(img/omd/chevron_down_white.svg);
    background-repeat: no-repeat;
    background-position: center right 18px;
    background-size: 13px 13px;
    line-height: 30px;
  }
}

.general-header__navigation {
  @include breakpoint("small") {
    margin-top: 22px !important;
  }
}
.general-header__navigation-scroller--right.is-visible {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 8px;
    width: 16px;
    height: 100%;
    background-image: url(img/omd/chevron_down_white.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    background-position: center;
    background-size: auto 16px;
  }
  svg {
    display: none;
  }
}

.general-header__navigation-scroller--left.is-visible {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 8px;
    width: 16px;
    height: 100%;
    background-image: url(img/omd/chevron_down_white.svg);
    background-repeat: no-repeat;
    transform: rotate(-270deg);
    background-position: center;
    background-size: auto 16px;
  }
  svg {
    display: none;
  }
}

.form-update-account-contact .page-account-contact__column {
  @include colwidth(4, "lg");
  @include breakpoint("lg") {
    width: 49%;
  }
  @include breakpoint("small") {
    width: 100%;
  }
  .js-form-error-Required {
    display: inline-flex;
    width: 100%;
    background-color: white;
  }
}

.page-account-contact__column {
  padding-right: 30px;
  border: 0px;
  margin: 0px;
  @include inf-col-fluid();

  p {
    @include spacer(margin-bottom, map-get($spacers, 2));
    label {
      @include font(
        $font-size-14,
        $font-weight-600,
        $line-height-1,
        $letter-spacing-117
      );
      height: $font-size-14;
    }

    .asterisk-required:before {
      color: #000000;
    }
  }
  .selectivity-input {
    margin-top: -14px;
    width: 100%;
  }

  .page-account__text-label {
    margin-bottom: 13px;
  }
}
.form-update-account-contact .page-account-contact__column .selectivity-input {
  margin-top: 0px;
}
.form-product-preferences {
  input[type="checkbox"] {
    height: 13px !important;
    padding: 0;
  }
  .sortable-table__header {
    @include breakpoint("small") {
      display: block;
      padding-top: 20px;
    }
  }

  .email-preference-mobile {
    @include breakpoint("small") {
      display: none;
    }
  }
  .sortable-table__col .labeled-checkbox {
    @include breakpoint("small") {
      float: none;
    }
  }
  .page-account__table {
    margin-bottom: 25px;
  }
}
.page-preferences.js-preferences-user-container .page-account-contact__column {
  width: 50%;
  @include breakpoint("medium") {
    width: 100%;
  }
  .page-account-contact_username {
    margin-bottom: 15px;
  }
  .page-account__subheader {
    display: flex;
  }
  .page-account__subheader {
    @include font(
      $font-size-24,
      $font-weight-600,
      $line-height-15,
      -$letter-spacing-094
    );
    margin-bottom: 15px;
  }
  .page-account__text-label,
  p span a {
    @include font(
      $font-size-16,
      $font-weight-600,
      $line-height-15,
      -$letter-spacing-02
    );
  }
  p span a {
    color: #000000;
  }
  p {
    @include font(
      $font-size-16,
      $font-weight-normal,
      $line-height-15,
      -$letter-spacing-02
    );
  }
}

.page-account__field-wrapper {
  margin-bottom: 0px;
}
.page-account__subheader {
  display: none;
}

.area-of-interest {
  @include spacer(margin-bottom, map-get($spacers, 4));
  label {
    @include font(
      $font-size-16,
      $font-weight-normal,
      $line-height-125,
      -$letter-spacing-02
    );
  }
  .inf-col-fluid {
    .labeled-checkbox {
      margin-bottom: 2rem;
    }
  }
}

.get-latest-content {
  margin-bottom: 2rem;
}
.agree-terms {
  @include spacer(margin-bottom, map-get($spacers, 4));
  a {
    font-weight: 600;
    color: #000000 !important;
    border-bottom: 1px solid #000000;
    text-decoration: none !important;
    cursor: pointer;
  }
}

.page-account__table-headline {
  @include spacer(margin-bottom, map-get($spacers, 2));
}

.area-of-interest label,
.get-latest-content label,
.agree-terms {
  @include font(
    $font-size-16,
    $font-weight-normal,
    $line-height-125,
    -$letter-spacing-02
  );
  color: #000000;
}

.inf-email-confirmation {
  @include inf-container();
  @include spacer(padding-top, map-get($spacers, 4));
}
.hideallproducts,
.hideallproductsnone {
  display: none;
}

div#page-account,
.page-account {
  @include inf-container();
  margin: 0 auto !important;
  max-width: 1260px !important;
  padding: 0 30px !important;
  strong {
    font-weight: normal;
  }
  .resend-email-verification {
    background-color: inherit;
    color: #000000;
    border-color: #000000;
    margin-right: 16px;
    &:hover {
      color: #ffffff;
      background-color: #000000;
    }
  }
  .email-verification-heading {
    @include spacer(margin-top, map-get($spacers, 4));
    @include spacer(margin-bottom, map-get($spacers, 2));
  }
  .email-verification-description {
    @include font(
      $font-size-20,
      $font-weight-normal,
      $line-height-15,
      -$letter-spacing-025
    );
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
  .email-verification-buttons {
    @include spacer(margin-top, map-get($spacers, 3));
    @include spacer(margin-bottom, map-get($spacers, 5));
  }
  .page-account__table-headline {
    @include spacer(margin-bottom, map-get($spacers, 2));
    padding-bottom: 0px;
  }
  .productsAlertsSection {
    .page-account__table {
      border: 1px solid $color-zeus-black;
      border-collapse: initial;
      border-radius: 10px;
      @include spacer(margin-bottom, map-get($spacers, 2));
      overflow: hidden;

      .sortable-table__header {
        background-color: $color-zeus-black;
        border-top: none;
        td {
          &:first-child {
            @include spacer(padding-left, map-get($spacers, 2));
          }
          &.sortable-table__col {
            color: $color-white;
            font-size: $font-size-17;
          }
          .checkbox-label{
            color: $color-white;
            &:before{
              border-color: $color-white;
            }
          }
        }
      }

      tbody {
        tr:nth-child(even) {
          background-color: $color-grey-7;
          overflow: hidden;
        }
        td{
          &:first-child {
            @include spacer(padding-left, map-get($spacers, 2));
          }
        }
      }

    }
  }
  .togglebuttons {
    @include spacer(margin-bottom, map-get($spacers, 4));
  }
  .sortable-table {
    border: 0px none;
    & > tbody tr {
      border-bottom: 1px solid $omd-medium-grey;
  
      @include breakpoint("small") {
        border: none;
        border-bottom: 1px solid $omd-medium-grey;
      }
      &.hidden {
        display: none;
      }
    }
    &__header {
      background-color: $color-white;
      border-bottom: 1px solid $omd-medium-grey;
    }
    &__col {
      @include font(
        $font-size-16,
        $font-weight-600,
        $line-height-normal,
        -$letter-spacing-02
      );
      color: $color-black;
      padding-left: 0px;
  
      @include breakpoint("medium") {
        display: block;
        width: 100%;
        text-align: left;
      }
    }
  }
  .checkbox-label {
    @include font(
      $font-size-16,
      $font-weight-normal,
      $line-height-15,
      -$letter-spacing-02
    );
  }
  .product-item {
    @include inf-row();
    margin: 0px;
  }
  .prod-info {
    flex-grow: 1;
    .omd-product-label {
      @include font(
        $font-size-16,
        $font-weight-600,
        $line-height-normal,
        -$letter-spacing-02
      );
    }
  }
  .prod-values {
    @include inf-row();
    flex-grow: 2;
    justify-content: flex-end;
    margin: 0px;
    .labeled-checkbox {
      padding-right: 50px;
    }
  }
  .hideallproducts,
  .showallproducts,
  .hideallproductsnone,
  .showallproductsnone {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 38px;
    cursor: pointer;
    &:after {
      right: 8px;
    }
  }
  .showallproducts,
  .showallproductsnone {
    &:hover {
      &::after {
        background-image: url(img/omd/plus_white.svg);
      }
    }
    &::after {
      background-image: url(img/omd/plus.svg);
    }
  }
  .hideallproducts,
  .hideallproductsnone {
    &:hover {
      &::after {
        background-image: url(img/omd/minus_white.svg);
      }
    }
    &::after {
      background-image: url(img/omd/minus.svg);
    }
  }
  .omd-box-bottom {
    border: 0px none;
    &::before {
      border: 0px none;
    }
  }
}

.page-account input[type="text"] {
  padding: 15px !important;
  border: 1px solid $omd-dark-grey !important;
}

.page-account .selectivity-single-result-container {
  left: 15px;
  top: 15px;
}

.page-account input,
.page-account textarea,
#mktForm .selectivity-single-select,
.page-account .selectivity-single-select {
  padding: 15px;
  height: 50px;
  border-radius: 3px;
  border: solid 1px #acacb0 !important;
}

// page-account input[type=text]
.general-header {
  & > .general-header {
    border-bottom: 0.5px solid #d8d8d8;
  }

  .general-header__navigation {
    height: 52px;
    @include inf-container();
    background-color: transparent;
    max-width: 1260px;
    @include spacer(margin-top, map-get($spacers, 5));
    flex-direction: row;
    justify-content: flex-start;

    a {
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.5px;

      &.is-active {
        border-bottom: 3px solid $color-black;
      }

      &:hover {
        border-bottom: 3px solid $color-black;
      }
    }
  }
}

.save-search {
  @include spacer(margin-top, map-get($spacers, 3));
  @include spacer(margin-top, map-get($spacers, 5));
  border-radius: 24px;
}
#contactInfo {
  @include breakpoint("small") {
    width: 100%;
  }
}
.page-account__submit-wrapper {
  button[disabled]#contactInfo {
    border-radius: 24px;
    position: relative;
    padding: 13px 30px;
    margin: 2rem 0 auto;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 1.07px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    @include breakpoint("small") {
      width: 100%;
    }
  }
}
.page-account__submit-wrapper .button--filled:not([disabled]) {
  @include spacer(margin-top, map-get($spacers, 3));
  @include spacer(margin-bottom, map-get($spacers, 5));
  border-radius: 24px;
}
.saved-preferences
  #form-search-preferences
  .page-account__submit-wrapper
  .save-search,
.saved-preferences
  #form-search-preferences-none
  .page-account__submit-wrapper
  .save-search {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
main
  .saved-preferences
  #form-search-preferences
  .page-account__submit-wrapper
  .button,
main
  .saved-preferences
  #form-search-preferences-none
  .page-account__submit-wrapper
  .button {
  &--filled {
    display: inline-block;
    position: relative;
    padding: 13px 30px;
    margin: 0 auto;
    @include font(15px, $font-weight-600, 1.2, 1.07px);
    text-transform: uppercase;
    text-decoration: none;
    border: solid 2px #f1f2f2;
    transition: all 0.2s;
  }
}

#contactInfo {
  margin-top: $spacer-3 !important;
  margin-bottom: $spacer-5 !important;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
}

#natureOfEnquiry,
#contactInfoForm {
  .selectivity-dropdown.open {
    padding-left: 0px;
  }

  .selectivity-result-item.highlight {
    background-color: #f4f3f6;
  }
  .selectivity-result-item {
    padding: 15px;
  }
}

// use of id and class for strength as this is an override
#page-account.page-account-preferences {
  .form-product-preferences{
    position: relative;
    margin-bottom:map-get($spacers, 1)*3.5; //brijesh
    
  }
  .solutions__banner {
    margin-bottom: $spacer-3 * 1.25;
    @include breakpoint("medium") {
      margin-bottom: map-get($spacers, 1) * 0.75;
    }
    & + p {
      font-size: $font-size-18;
      font-weight: $font-weight-400;
      line-height: $line-height-15;
    }    
  }
  // copy of solutions__title w mods
  .area-of-interest {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
    gap: 10px;
    margin: 0;
    @include breakpoint("ipad") {
      grid-template-columns: repeat(5, 1fr);
    }
    @include breakpoint("medium") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include breakpoint("medium-small") {
      grid-template-columns: repeat(2, 1fr);
      word-break: break-word;
    }
    @include breakpoint("small") {
      grid-template-columns: 1fr;
    }
    // copy of .solutions.coverage .solutions__tile__wrapper w mods
    .inf-col-fluid {
      padding: 0;
      width: auto;
      word-break: break-word;
      &::before {
        transform: rotate(180deg);
      }
      @include breakpoint("medium-small") {
        word-break: break-word;
      }
    }
    .labeled-checkbox {
      position: relative;
      text-decoration: none;
      height: 100%;
      margin: 0;
      // copy of solutions__titile__wrapper w mods
      .checkbox-label {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 20px;
        margin: 0;
        padding: map-get($spacers, 1) map-get($spacers, 1) / 4;
        background: $omd-light-grey;
        @include breakpoint("medium-small") {
          padding: map-get($spacers, 1) * 0.75 map-get($spacers, 1) / 3;
          gap: 10px;
        }
        & > img {
          width: 65px;
          height: 65px;
          filter: invert(84%) sepia(63%) saturate(3853%) hue-rotate(265deg)
            brightness(93%) contrast(90%);
          user-select: none;
          @include breakpoint("medium-small") {
            width: 36px;
            height: 36px;
          }
        }
        & > span {
          color: $omd-black;
          @include font(
            $font-size-13,
            $font-weight-bold,
            $line-height-133,
            $letter-spacing-1
          );
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;
        }
      }
      & > input:checked + .checkbox-label,
      & > .checkbox-label:hover {
        background: $omd-purple-black-gradient;
        & > img {
          filter: none;
        }
        & > span {
          color: $color-white;
        }
      }
      input,
      .checkbox-label::before,
      .checkbox-label::after {
        display: none;
      }
    }
  }

  .agree-terms {
    @include spacer(margin-top, map-get($spacers, 3));
  }
  .page-account__introduction {
    @include spacer(margin-bottom, map-get($spacers, 2))
  }
  .product-banner-cta {
    position: sticky;
    @include spacer(bottom, map-get($spacers, 1)*1.5);
    @include spacer(margin-right, map-get($spacers, 1)/4);
    box-shadow: 0 0 $spacer-0/2 #959393;
    border-color: transparent;
    float: right;
    &:hover {
      border-color: transparent;
    }
    &.fixed {
        box-shadow: 0 0px 2px #959393;
        margin-right: 0px;
    }
    &:disabled{
      cursor: not-allowed;
    }
  }
}

.saved-search-title-wrap {
  background-color: #000000;
  @include spacer(margin-bottom, map-get($spacers, 2));
  .saved-search-page-title {
      color: #ffffff ;
      max-width: 1260px;
      margin: 0 auto ;
      padding: 2.4rem 30px;
      font-size: 2rem;
      @include breakpoint("small") {
          padding: 15px;
      }
      @include breakpoint("medium") {
          margin-bottom: 1.2rem;
          padding-top: 1.6rem;
          padding-bottom: 1.6rem;
      }
  }
}

.page-account-bookmark .sortable-table,
#form-search-preferences-none,
#form-search-preferences {
  overflow: hidden;
  border: 1px solid $color-zeus-black;
  border-collapse: initial;
  border-radius: $spacer-1/2;
  @include spacer(margin-bottom, map-get($spacers, 4));
  td {
    &:first-child {
      @include spacer(padding-left, map-get($spacers, 2));
    }

    &:nth-child(2) {
      text-align: left;
    }
  }

  .sortable-table__header {
    background-color: $color-zeus-black;
    border-top: none;

    td.sortable-table__col {
      color: $color-white;
            font-size: $font-size-17 ;

      .sorting-arrows {
        svg.sorting-arrows__arrow {
          background-image: url(/dist/img/omd/chevron_down_white.svg);
        }
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $color-grey-7;
      overflow: hidden;
    }
  }
}
.theme-omdia #form-search-preferences .sortable-table__header td.sortable-table__col:nth-child(3),
.theme-omdia #form-search-preferences-none .sortable-table__header td.sortable-table__col:nth-child(3){
  cursor: default;
}
main {
  .saved-preferences {
    position: relative;
    @include spacer(margin-bottom, map-get($spacers, 1)*3.5);
    .save-search {
        position: sticky;
        @include spacer(bottom, map-get($spacers, 1)*1.5);
        @include spacer(margin-right, map-get($spacers, 1)/4);
        margin-top: 0px;
        box-shadow: 0 0 $spacer-0/2 #959393;
        @include spacer(padding-top, map-get($spacers, 0)*0.81);
        @include spacer(padding-bottom, map-get($spacers, 0)*0.81);
        @include spacer(padding-left, map-get($spacers, 1)*1.5);
        @include spacer(padding-right, map-get($spacers, 1)*1.5);       
        border-color: transparent;
        @include font($font-size-15,$font-weight-600,$line-height-12,1.07px);
        &:hover {
          border-color: transparent;
        }
        &.fixed {
            box-shadow: 0 0px 2px #959393;
            margin-right: 0px;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }
  
    .load-spinner {
        left: 0;
        top: 0;
        display: none;
        align-items: center;
        justify-content: center;
        &.show {
            display: flex;
        }
    }
  }
}
.page-account-saved-searches {
  .page-account__introduction {
      strong {
          font-weight: $font-weight-600;
          font-size: 1.2rem;
          line-height: 1.25rem;
      }
  }    
  form {
      margin-bottom: 0px;
  }
}