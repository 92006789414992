.article-pullquote, // Old, overly specific class name
.quote {
    @include textMix('PT Serif', 22px, 500);
    font-style: italic;
    border-bottom: 1px solid $color-tree-poppy-orange;
    border-top: 1px solid $color-tree-poppy-orange;
    clear: both;
    color: $color-zeus-black;
    line-height: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding: 1.75rem 0.75rem;

    p {
        color: $color-zeus-black;
    }
    
    @include breakpoint('medium') {
        font-size: 20px;
        margin: 2rem 0px;
        padding: 1rem 0.75rem;
    }
}
