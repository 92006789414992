.accordian-table {
	@include textMix('Roboto', 1rem, 400);
	border: 1px solid #8f8f8f;
	color: $color-abbey-gray;
	width: 100%;
  margin-bottom: 3.25rem;
  &__row {
    display: flex;
    width: 100%;
    border-top: 1px solid #8f8f8f;
    & > * {
      box-sizing: content-box;
      margin: auto;
      padding: 1rem 1.25rem;
      @include breakpoint('medium') {
        padding: 1rem;
      }
      &:nth-child(1) {
        display: flex;
        flex: 0 0 100px;
        padding-right: 0;
        @include breakpoint('medium') {
          flex: 0 0 60px;
        }
        & > * {
          margin: auto;
        }
      }
      &:nth-child(2) {
        flex: 1 1 auto;
      }
    }
  }

  &__header {
    position: relative;
    align-items: center;
    background-color: $color-concrete-gray;
    border-top: none;
    .ui-col {
      position: relative;
    }
    .collapsed-label {
      display: none;
      font-weight: bold;
      font-size: 0.875rem;
      text-align: center;
    }
    button {
      padding: 0;
      appearance: none;
      background: none;
      border: none;
      font-weight: bold;
      font-size: 0.875rem;
      text-align: center;
      span {
        display: none;
      }
    }
    h3 {
      padding-right: 3.5rem;
      font-size: 1rem;
      font-weight: 400;
      @include breakpoint('medium') {
        padding-right: 3rem;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      padding: 0;
      @include breakpoint('medium') {
        right: 1rem;
      }
    }
  }
}

// interaction
.js-accordian {
  position: relative;
  &.ready {
    // defaults after JS init, expanded
    .js-accordian-expander {
      cursor: pointer;
      user-select: none;
      svg {
        transform: rotate(0deg);
        transition: transform 0.3s;
      }
    }
    .account-email-multicheck {
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.3s;
    }
    .js-accordian-body {
      position: relative;
      opacity: 1;
      pointer-events: all;
      transition: height 0.3s;
    }
    &:not(.expanded) {
      // defaults after JS init, collapsed
      .js-accordian-expander svg {
        transform: rotate(-180deg);
      }
      .collapsed-label {
        display: block;
      }
      .account-email-multicheck {
        display: none;
        opacity: 0;
        pointer-events: none;
      }
      .js-accordian-body {
        height: 0 !important;
      }
    }
  }
  // defaults before JS init
  .account-email-multicheck {
    opacity: 0;
    pointer-events: none;
    .select-all {
      display: block;
    }
  }
  .js-accordian-body {
    overflow: hidden;
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }
}
.modal-view {
  position: fixed;
  width: 27rem;
  height: 17.5rem;
  top: 50%;
  left: 50%;
  z-index: 9999;
  margin-left: -13.5rem;
  margin-top: -8.75rem;
  background: $color-white;
  padding: 4.375rem 2.5rem 2.5rem;
  display: none;
  font-family: "Roboto";
  @media screen and (max-width: 480px) {
    width: 21.875rem;
    margin-left: -10.9375rem;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    margin-left: 5%;
    left: 0px;
  }
  &.preferences {
    h3 {
      @include textMix("Roboto", 1.2rem, 700);
      text-align: center;
      margin: 10px 0 20px;
      color: #231f20;
    }
    h4 {
      @include textMix("Roboto", 0.875rem, 400);
      margin-bottom: 2rem;
      color: #626262;
      font-weight: normal;
      text-align: center;
    }
    .buttonsPan {
      width: 17.5rem;
      margin: 0px auto;
    }
    .saveview {
      @include textMix("Roboto", 0.6875rem, 700);
      text-align: center;
      float: left;
      width: 4.375rem;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 2rem;
      padding: 0px;
      float: left;
      width: 8.125rem;
      height: 2rem;
      color: #fff;
      margin-right: 20px;
      @media screen and (max-width: 320px) {
        width: 90px;
      }
    }
    .registrationBtn.validationChk {
      @include textMix("Roboto", 0.6875rem, 700);
      text-align: center;
      float: left;
      width: 4.375rem;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 2rem;
      padding: 0px;
      float: left;
      width: 8.125rem;
      height: 2rem;
      // color: #f8; <<< unknown expected value
      margin-right: 20px;
    }
    .cancel {
      @include textMix("Roboto", 0.6875rem, 700);
      text-align: center;
      float: left;
      width: 8.125rem;
      height: 2rem;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 2rem;
      padding: 0px;
      color: #747474;
      border: 1px solid #adadad;
      @media screen and (max-width: 320px) {
        width: 90px;
      }
    }
  }
  .modal-inner {
    display: inline-block;
    text-align: left;
    background-color: #eee;
    color: #000;
    padding: 1.25rem;

    @include breakpoint("small") {
      padding: 0.625rem;
    }
  }

  .close-modal {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    color: $color-zeus-black;
    font-family: "Roboto";
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    background: url(/dist/img/sprite-image.png) no-repeat right -72px top -592px;
    font-size: 0.625rem;
    padding-right: 1rem;
    height: 14px;
    width: 14px;
  }
}
.modal-overlay {
  &.in {
    background: #000;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 9990;
    opacity: 0.5;
  }
}