.article-preview {
  &__freelabel {
    span {
        display: block;
        padding: 0 8px;
        font-family: inherit;
        font-size: $font-size-12;
        line-height: 1.7em;
        letter-spacing: $letter-spacing-1;
        color: $omd-white;
        background-color: $omd-purple;
      }
  }
}
