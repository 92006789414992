main {
  display: block !important;
  margin: 0px !important;
  width: auto !important;
}

.main__wrapper {
  float: none !important;
  height: 100%;
  width: auto !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.main__content {
  max-width: none !important;
  margin: 0px !important;
  padding: 0px !important;
}


//Overriding legacy nav
.nav{
  @include notIE {
    display: flex;
    flex-direction: column;
  }
  p{
    margin-bottom: 20px;
  }
  p+h5{
    padding-top: 28px;
  }
  h5{
    padding-bottom: 24px;
  }

}



//Replace as and when needed
/*.header__wrapper {
  height: 60px;
  padding: 0 0 0 80px;
}

.main-menu {
  position: absolute;
  height: calc(100% - 37px);
  border-right: none;
  transform: translate3d(-330px,-60px,0);
  &.is-active {
      transform: translate3d(0,-60px,0);
  }
  &.fixed {
      top: auto;
  }
  .menu-toggler{
      height: 60px !important;
      margin-right: 0;
      background: transparent;
  }
}

.manually-curated-content__main-well {
  float: none;
  width: 100%;
}*/
