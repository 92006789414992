/*---
title: Global Header
section: Global Components
---

```example:html
<header>
    <div class="header__wrapper clearfix">
    
        <div class="menu-toggler menu-toggler--mini js-toggle-menu">
            <button class="menu-toggler__button">
                <span>Toggle Menu</span>
            </button>
            Menu
        </div>

        <div class="header__logo">
            <a href="/"><img src="/-/media/scriplogo.jpg"></a>
        </div>

        <div class="header-search__wrapper">
            <input class="inf-standard-form-field header-search__field" type="search" placeholder="Search the site">
            <svg class="header__icons header__icons--search js-header-search-trigger">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#search"></use>
            </svg>
            <svg class="header__icons header__icons--dismiss-search js-header-search-trigger">
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#x"></use>
            </svg>
        </div>

        <div class="header-account-access">
                <div class="header-account-access__my-account pop-out__trigger js-pop-out-trigger" data-pop-out-id="header-register" data-pop-out-type="register">

                    <svg class="header__icons icon-register">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#register"></use>
                    </svg>
					<span class="header-account-access__label">Register</span>
                </div>
                <div class="header-account-access__sign-in-out pop-out__trigger js-pop-out-trigger" data-pop-out-id="header-signin" data-pop-out-align="right" data-pop-out-type="sign-in" data-pop-out-tab-height="87">
                    <svg class="header__icons icon-sign-in">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#sign-in"></use>
                    </svg>
					<span class="header-account-access__label">Sign In</span>
                </div>
        </div>
    </div>
</header>
```
*/

$main-menu-desktop-width: 0px;
$main-menu-tablet-width: 70px;
$main-menu-smartphone-width: 62px;

.header {

	// -------------------------------------------------------------------------
	// Wraps main header content, below Informa banner and ad banner
	// -------------------------------------------------------------------------
	&__wrapper {
		@include textMix('Roboto', 13px, 400);
		border: 1px solid #ccc;
		border-left: 0;
		border-right: 0;
		height: 120px;
		padding: 2rem;
		display: flex;
		justify-content: space-between;

		@include breakpoint('ipad') {
			display: block;
			position: relative;
			height: auto;
			padding: 0;
		}
	}

	// -------------------------------------------------------------------------
	// Header logo image
	// -------------------------------------------------------------------------
	&__logo {
		height: 100%;
		flex: 0 0 auto;
		align-self: center;
		width: auto;
		@include breakpoint('small') {
			align-self: auto;
			height: calc(100% - 1.25rem);
			flex: 1 0 auto;
			margin-right: 100px; // Prevents overlapping search icon
            z-index: 20; // Position above search form, even when search is hidden
		}
		img {
			display: block;
			height: 100%;
			margin-right: 2rem;

			@include breakpoint('ipad') {
                margin-left: 12px;
                margin-right: 0;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
		}
	}

	// -------------------------------------------------------------------------
	// General styling for header icons
	// -------------------------------------------------------------------------
	&__icons {
		@include svg-fill($color-dusty-gray);
		height: 1.375rem;
		width: 1.375rem;
		vertical-align: middle;
	    margin-right: 0.4375rem;

	    &--search,
		&--dismiss-search {
			height: 1rem;
			width: 1rem;
			margin-left: 0.5rem;
			cursor: pointer;
			z-index: 9999;
		}
		&--dismiss-search { // Hide on larger screens, no need to dismiss
			display: none;
		}

		@include breakpoint('ipad') {
			position: relative;
			top: 0;
			height: 1rem;
			width: 1rem;
			margin: 6px 0;

			&--search,
			&--dismiss-search {
				margin: 2rem 0 2rem 0.5rem;
			}
		}

		@include breakpoint('small') {
			margin: 5px 0;
			&--search,
			&--dismiss-search {
				margin: 1.5rem 0 1rem 0.5rem;
			}
		}
	}

	// HEADER SEARCH
	&-search {

		&__wrapper {
			flex: 1 0 auto;
			height: 100%;
			vertical-align: middle;
			padding: 0.5625rem 0;

			@include breakpoint('ipad') {
				position: static;
				float: right;
				height: auto;
				margin: 0 12px 10px 0;
				padding: 0;
				text-align: right;

				&.is-active {
                    z-index: 25;

					.header__icons--dismiss-search {
						display: inline-block;
					}
					.header-search__field {
						display: inline-block;
					}
					.header__icons--search {
						display: none;
					}
				}
			}
			@include breakpoint('small') {
				margin-bottom: 10px;
			}
		}


		&__field {
			@extend .inf-standard-form-field;
			max-width: 310px;
			width: calc(100% - 60px);
			border-radius: 0;
			&[type=search] {
				-webkit-appearance: none;
			}

			@include breakpoint('ipad') {
				position: absolute;
				font-size: 1rem;
				line-height: 1.2;
				right: 0;
				bottom: -1px;
				width: calc(50% - 70px);;
				max-width: none;
				height: 49px;
				margin: 0;
				padding: 0 8px;
			}

			@include breakpoint('small') {
				display: none;
				left: 62px;
				right: 0;
				width: calc(100% - 62px);
				padding: 0;
				text-indent: 12px;
				z-index: 1;
			}
		}
	}
}
