.landscape-image-component {
    margin-bottom: 2rem;
    width: 100%;

    & img {
        display: block;
        height: auto;
        margin: 0px auto;
        width: 100%;
    }
}
