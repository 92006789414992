.header-publication-links {
    float: right;
    font-size: 14px;
    height: 25px;
    top: 10px;
    position: absolute;
    text-align: right;
    right: 0px;
    padding-right: 2rem;

    @include breakpoint('ipad') {
        top: 0.5rem;
        right: 12px;
        padding-right: 0;
    }
    @include breakpoint('small') {
        display: none;
    }
}

.header-account-access {
    float: right;
    font-size: 1rem;
    height: 100%;
    margin-left: 1.5rem;
    padding: 1rem 0;
    flex: 0 0 auto;

    @include breakpoint('ipad') {
        float: left;
        width: auto;
        margin: 0 0 10px 12px;
        border-top: none;
        font-size: 15px;
        padding: 2.5rem 1rem;
        height: auto;
    }

    @include breakpoint('small') {
        font-size: 13px;
        margin: -2rem 0.75rem 0 74px;
        padding: .4375rem 0 0;
        width: calc(100% - 105px);
        margin-bottom: 11px;
    }

    .header-account-right-access {
        display: flex;
        column-gap: 2rem;
        float: right;
        padding: 1rem 0;
        font-weight: lighter;
        @include breakpoint('ipad') {
            float: left;
            clear: none;
            column-gap: 1rem;
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 7px;
            border-left: 1px solid #d1d3d4;
            &:first-child {
                padding-left: 0;
                border-left: none;
            }
            // Don't show the friendly welcome at smartphone widths
            .header-account-access__friendly-greeting {
                display: none;
                & + [class*="header-account-access"]::before {
                    display: none !important;
                }
            }
        }
        &.nomyView{
            @media only screen and (min-width: 680px) and (max-width: 1000px){
                margin-right: 1rem;
            }
        }
        & > * {
            display: flex;
            position: relative;
            align-items: center;
            &:not(:first-child)::before {
                content: "";
                display: block;
                position: absolute;
                left: -1rem;
                height: 100%;
                width: 1px;
                background-color: $color-light-gray;
                @include breakpoint('ipad') {
                    left: -0.5rem;
                }
            }
            &:not([class*="header-account-access"]) {
                display: none;
            }
        }
        .btn {
            padding: 7px 10px;
            color:white;
            text-decoration:none;
            @include breakpoint('ipad') {
                margin: 0;
                font-size: .75em;
                text-indent: 0;
            }
            &:hover{
                text-decoration:none;
            }
        }
    }

    &__error-msg {
        float: right;
        width: 100%;
        text-align: right;
        font-style: italic;

        @include breakpoint('small') {
            margin-top: 0;
        }
    }

    &__my-account {
        color: $color-cardinal-red;
    }

    &__sign-in-out {
        color: $color-cardinal-red;
        margin-right: 0;
    }

    &__free-trial {
        .header-account-access__label:hover {
            text-decoration: none;
        }

        a {
            color: white !important;
            padding: 7px 10px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__logout-form {
        float: right;

        @include breakpoint('ipad') {
            float: left;
        }
    }

    &__label:hover {
        text-decoration: underline;
    }
}