.article-call-to-action-v2 {
  input[type="radio"] {
    appearance: none;
    position: absolute;
    &:checked {
      // toggles other UI elements
      &[value=free] ~ * [data-value]:not([data-value=free]),
      &[value=subscribe] ~ * [data-value]:not([data-value=subscribe]) {
        display: none;
      }
      // toggles check/radio UI elements
      &[value=free] ~ * [for=regtype-free],
      &[value=subscribe] ~ * [for=regtype-subscribe] {
        &::after {
          border: 2px solid $omd-purple;
        }
        .pseudo-input {
          background-color: $omd-purple;
          border: none;
          &::after {
            display: block;
          }
        }
      }
    }
  }
  .pseudo-input {
    position: relative;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background-color: white;
    border: 1px #adadad solid;
    user-select: none;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 0.375rem;
      height: 0.75rem;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      background: none;
      display: none;
    }
  }
  .paywall-message {
    padding: 1rem 2rem;
    background-color: #000000;
    border: 1px solid rgba(130, 128, 128, 0.3);
    border-bottom: none;
    border-radius: 0.625rem 0.625rem 0 0;
    & > p {
      margin-bottom: 0;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.1px;
      color: #ffffff;
    }
  }
  &__form {
    padding: 1.5rem 2rem;
    border: 1px solid rgba(130, 128, 128, 0.3);
    border-top: none;
    border-radius: 0 0 0.625rem 0.625rem;
  }
  &__columns {
    display: grid;
    column-gap: 3rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.5rem;
  }
  &__column {
    position: relative;
    padding: 0.75rem 1.25rem;
    min-height: 10rem;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border: 1px solid #828080;
      border-radius: 0.625rem;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0.5rem;
    span {
      font-weight: 700;
      line-height: 1.5rem;
    }
  }
  &__subtitle {
    display: block;
    font-size: 0.875rem;
    line-height: 1.33rem;
  }
  &__footer {
    display: flex;
    align-items: center;
    span {
      flex: 1 0 auto;
      font-size: 0.875rem;
      .signin-link {
        color: $omd-purple;
        font-weight: 700;
        border-bottom: none;
        text-decoration: none;
        &:hover {
          color: #000000;
        }
      }
    }
  }
}
