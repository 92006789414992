/*

	SHAME
	http://csswizardry.com/2013/04/shame-css-full-net-interview/

	Hacks. Ugly fixes. Browser duct-tape. !important.
	Hopefully, this is empty.

*/

.article-topics__li:before,
.quickfactsbulleted li:before {
	@include svg-fill($color-tree-poppy-orange);
	background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2260%22%20height%3D%2260%22%20viewBox%3D%220%200%2060%2060%22%3E%3Cpath%20fill%3D%22%23f7941e%22%20d%3D%22M18.18%2060c-.8%200-1.6-.3-2.2-.94-1.2-1.26-1.2-3.3%200-4.57l22.9-23.75L14.52%205.5c-1.2-1.26-1.2-3.3%200-4.56%201.22-1.26%203.2-1.26%204.4%200l26.56%2027.54c1.2%201.26%201.2%203.3%200%204.56l-25.1%2026.02c-.6.63-1.4.94-2.2.94z%22/%3E%3C/svg%3E%0A);
	background-size: 10px auto;
	background-position: center left;
	background-repeat: no-repeat;
	content: "";
	height: 18px;
	width: 16px;
	display: inline-block;
	position: absolute;
	margin-left: -20px;
}
.quickfactsbulleted li {
	margin-left: 1.125rem;

	&:before {
		margin-top: 3px;
	}
}
