.card-list {
	&-banner {
		position: relative;
		background-color: $color-white;
		margin: map-get($spacers, 1)*5 0;
		@include breakpoint("medium") {
			margin: map-get($spacers, 1)*3.5 0;
		}
		&.no-top-margin {
			margin-top: 0;
		}
		&.no-bottom-margin {
			margin-bottom: 0;
		}
		&-wrapper {
            @extend .inf-col;
			position: relative;
			max-width: 1260px;
			margin: 0 auto;
			padding: 0 map-get($spacers, 1)*1.5;
			@include breakpoint("medium") {
				padding: 0 map-get($spacers, 1)*0.75;
			}

            & > .pagination-list {
                margin-top: map-get($spacers, 1)*2;
            }
		}
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 36px;
			gap: 40px;
			@include breakpoint("medium") {
				flex-direction: column;
				gap: 20px;
			}
			& > a {
				color: $color-black;
				text-decoration: underline;
				&:hover {
					color: $omd-dark-purple-2;
				}
			}
			&.header-with-select-box {
				@include breakpoint("medium") {
					gap: 15px;
					flex-direction: column;
				}
			}
		}
		&-title {
			@include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
			color: $color-black;
			@include breakpoint("medium") {
				@include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-02);
			}
		}
	}
	&-box {
		display: flex;
		flex-wrap: wrap;
		gap: 36px 20px;
		& > .card-list {
			flex-basis: calc(33.85% - 20px);
		}
		@include breakpoint("medium") {
			flex-direction: column;
			gap: 20px 15px;
		}
	}
}