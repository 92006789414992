.omd-casset-header {
  @include font(
    $font-size-20,
    $font-weight-600,
    $line-height-125,
    -$letter-spacing-1
  );
}

.om-casset-date {
  @include font(
    $font-size-14,
    $font-weight-600,
    $line-height-179,
    $letter-spacing-117
  );

  @include breakpoint("medium-small") {
    margin-bottom: 20px;
  }

  @include breakpoint("small") {
    margin-bottom: 20px;
  }
}

.version-note {
  padding-right: 20px;
  @include font(
    $font-size-16,
    $font-weight-bold,
    $line-height-15,
    -$letter-spacing-02
  );
  & + .btn.secondary {
    border: none;
    &:not(:hover) {
      background-color: $omd-white;
    }
  }
}

.viewmore-versions {
  text-transform: uppercase;
  @include font(
    $font-size-14,
    $font-weight-600,
    $line-height-15,
    $letter-spacing-175
  );
}

.omd-prev-version-content {
  cursor: pointer;
  border: 0px none;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.viewmore-container {
  @include breakpoint("small") {
    padding: 0px;
    justify-content: start;
  }
  @include breakpoint("medium-small") {
    padding: 0px;
    justify-content: start;
  }
}

.latest-version {
  border: 0px none;
  text-decoration: none;
  padding-right: 40px;
  color: inherit;
  &:hover {
    color: inherit;
  }
  &::after {
    content: "";
    background: url(img/omd/arrow_right_white.svg) no-repeat;
    position: absolute;
    width: 28px;
    height: 32px;
    content: "";
    top: -3px;
    right: 0px;
  }
}
