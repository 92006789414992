#searchTop.mrktlist {
  @include spacer(margin-top, map-get($spacers, 4));
  .search__content {
    .search__body {
      margin-left: 25%;
      margin-right: 25%;
      @include breakpoint('lg') {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .search-results,
    .search-pagination__wrapper {
      margin-left: 0;
    }
    .search-result {
      position: relative;
      border-top: 1px solid $color-black;
      @include spacer(padding-top, map-get($spacers, 3));
      @include spacer(padding-bottom, map-get($spacers, 3));
      color: $body-text-colour;
      &:first-child {
        border-top: 0;
      }
      &__body {
        & > .search-result__pubdate {
          padding-left: 0;
          @include isIE {
            padding-left: 0;
          }
          padding-right: 0;
          border-right: none;
        }
      }
    }
    &.has-advert {
      flex-direction: row;
      @include breakpoint('lg') {
        flex-direction: column;
      }
      .search__body {
        @include colwidth(6, 'lg');
        margin-left: 100% / $gridsize;
        margin-right: 0;
        @include breakpoint('lg') {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .search__advert {
        display: block;
      }
    }
    .search__advert {
      display: none;
      order: 4;
      @include colwidth(3, 'lg');
      margin-left: 100% / $gridsize;
      margin-right: 100% / $gridsize;
      @include breakpoint('lg') {
        margin-left: 0;
        margin-right: 0;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}