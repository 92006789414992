.article-call-to-action {
  position: relative;
  &--sign-in-up, &--subscribe {
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }
  .paywall-message {
    order: 1;
    padding: 24px 0;
    margin-bottom: 24px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    hr {
      display: none;
    }
    ul > li {
      padding-left: 23px;
      &::before {
        margin-left: -23px;
      }
    }
    & > p {
      &:first-child {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: -$letter-spacing-05;
        strong {
          font-weight: inherit;
        }
      }
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    & + .article-call-to-action__column {
      order: 3;
      margin-bottom: 20px;
      border: solid 1px black;
      // make button--filed appear like outline
      .button--filled:not([disabled]) {
        @include btn-legacy-icon('outline', 'arrow_right', 26px, 20px);
        color: black;
        background: white;
        border-color: black;
        &:hover {
          color: white;
          background: black;
          border-color: white;
        }
      }
    }
  }
  .article-call-to-action__column:last-child {
    order: 2;
    margin-bottom: 20px;
    color: white;
    background: black;
    .button--filled:not([disabled]) {
      @include btn-legacy-icon('filled', 'arrow_right', 26px, 20px);
    }
  }
  .article-call-to-action__column {
    position: relative;
    padding: 25px 235px 25px 42px;
    border: none;
    @include breakpoint('lg') {
      padding-right: 30px;
    }
    .article-call-to-action {
      &__title {
        margin-bottom: 10px;
        padding-bottom: 0;
        font-size: 1.563rem;
        line-height: 1.28;
        letter-spacing: -$letter-spacing-05;
      }
      &__subtitle {
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 1rem;
        line-height: 1.56;
        letter-spacing: -0.13px;
        @include breakpoint('lg') {
          margin-bottom: 10px;
        }
      }
    }
    .js-login-container {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint('lg') {
        position: static;
        right: auto;
        top: auto;
        transform: none;
      }
      p {
        display: none;
      }
    }
  }
}