.accordian-title {
  display: flex;
  align-items: center;
  gap: 20px;
  @include breakpoint("medium-small") {
    justify-content: center;
  }
  @include breakpoint("small") {
    flex-direction: column;
  }
  a {
    margin: 0;
  }
}
.accordian-content {
  display: none;
}
.accordian-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include breakpoint("medium-small") {
    justify-content: center;
    margin-top: 2.5rem;
  }
  cursor: pointer;
  .hide-accordian {
    display: none;
  }
  .show-accordian {
    display: inline;
  }
  &:after {
    content: "";
    display: inline-block;
    background: url(img/omd/chevron_down_white.svg) no-repeat;
    width: 14px;
    height: 14px;
  }
  &.open {
    &:after {
      transform: rotate(180deg);
    }
    .accordianicon {
      display: none;
    }
    .accordian-hide {
      display: block;
    }
  }
}
.accordianicon {
  display: block;
}
.accordian-hide {
  display: none;
}
.accordian-button {
  position: relative;
  margin-bottom: 22px;
  text-align: center;
  border: solid 1px black;
  border-top: none;
  border-left-width: 0px;
  border-right-width: 0px;
  display: none;
  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: solid 1px black;
  }
  & > .btn {
    margin-bottom: -22px;
    border-width: 1px;
  }
  @include breakpoint("medium-ipad") {
    display: block;
  }
  @include breakpoint("small") {
    display: block;
  }
}
