@mixin theme-links($color) {

    a,
	.msg-text h3,
	.red,
	.dismiss .dismiss-button__icon,
    .facets__button,
    .facets__button svg,
    .sorting-arrows__arrow.is-active,
    .article-key-document__icon {
        @include on-event(true) {
            color: $color;
            //@include svg-fill($color); IPMP-2360
        }
    }

    footer a,
	.general-header__navigation a,
    .sortable-table__col a {
        color: inherit;
    }

    .header-account-access {
        &__my-account,
        &__sign-in-out,
        &__error-msg {
            color: $color;
        }
    }

    .article-preview__headline,
	.li-content-company__headline,
    .topic-featured-article__headline {
        &,
        & a {
            color: $color-zeus-black;

            &:hover {
                color: $color;
            }
        }
    }
}


@mixin theme-buttons($border, $background, $text, $border-hover: $border, $background-hover: $background, $text-hover: $text) {

    .button--filled:not([disabled]),
    .search-bar__submit,
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
        background: $background;
        background-color: $background;
        color: $text;

        &:hover {
            background: $background-hover;
            background-color: $background-hover;
        }
    }
    .button--gilded {
		border-color: $border;
    }
    .button--outline:not([href]){
        border: 1px solid $border;
        &:hover {
            background-color: $background-hover;
            border-color: $border-hover;
            color: $text-hover;
        }

    }
    .button--outline:not([disabled]) {
        border: 1px solid $border;
        &:hover {
            background-color: $background-hover;
            border-color: $border-hover;
            color: $text-hover;
        }
    }
    .accordian-table__header button,
    .accordian-table__header .collapsed-label {
        color: $background;
    }
    .facets__section-list input[type="checkbox"]:checked + label:before,
    input[type=checkbox]:checked {
		background-color: $background;
    }
    .carousel-infographic.owl-carousel {
        .owl-nav [class*=owl-] {
            background-color: $background;
        }
        .owl-dots .owl-dot.active span {
            background-color: $background;
        }
    }
}

@mixin theme-horizontal-menu($primary-theme-color, $secondary-theme-color) {
    &.horizontal-menu  main .main-menu {
        @include breakpoint('desktop') {
            .main-menu__section {
                &.main-menu__home .main-menu__section-title {
                    a {
                        &:active, &:focus, &:hover {
                            background-color: $primary-theme-color;
                        }
                    }
                }
                .main-menu__section-title {
                    a {
                        &:active, &:focus, &:hover {
                            background-color: $primary-theme-color;
                        }
                    }
                    &.js-toggle-menu-section.active {
                        background-color: $primary-theme-color;
                        a {
                            &:active, &:focus, &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
                .titleMyView {
                    background: $primary-theme-color; 
                    &:active, &:focus, &:hover {
                        background: $secondary-theme-color;
                    }
                }
            }
        }
        .main-menu__section-title {
            & + .main-menu__section-wrapper a {
                &:active, &:focus, &:hover {
                    color: $primary-theme-color;
                }
            }
        }
    }
}

@mixin theme-main-menu($hover-bars, $footer-links) {
    .main-menu {

        &__hoverable:hover:before {
            background-color: $hover-bars;
        }
		
		&__hoverable.activate:before {
            background-color: $hover-bars;
        }

        &__section-title a {
            color: inherit;
        }

        &__footer-link a {
            color: $color-abbey-gray;

            @include on-event(false) {
                color: $footer-links;
            }
        }
        &__section-link {
            @include on-event(true) {
                color: $color-abbey-gray;
            }
        }
    }
}


@mixin theme-subnav($underline) {
    .general-header__navigation a.is-active,
    .general-header__navigation a:hover {
        border-bottom-color: $underline;
    }
}


@mixin theme-banners($background, $text) {
    //.banner--cookie-policy,
    .banner--register,
	.banner--alert {
        background-color: $background;

		&,
		& span,
		& svg {
			@include svg-fill($text);
			color: $text;
		}

        a {
            @include on-event(true) {
                color: $text;
            }
        }
    }
}


@mixin theme-call-out-boxes($background, $border) {
    .newsletter-signup,
    .quick-facts {
        background-color: $background;
    }
	.newsletter-signup {
		border-top: 4px solid $border;
	}
	.messaging_webUsers {
		border-top: 4px solid $border;
	}
	.messaging_webUsers_white{
		border-top: 4px solid $border;
		border-bottom: 1px solid $border;
	}
}

@mixin theme-quick-facts($background, $divider) {
	.quick-facts {
		background-color: $background;

		hr {
			background-color: $divider;
		}
	}
}


@mixin theme-bordered-elements($color) {
    .article-pullquote,
    .quote,
    .article-sidebar,
    .topic-subtopic__header {
        border-top-color: $color;
        border-bottom-color: $color;
        border-color: $color;
    }

    .latest-news__header:before {
        background-color: $color;
    }
    .theme-header__underline {
        border-bottom: 4px solid $color;
        padding: 1.5rem 0;
    }
    .article-list-sidebar__wrapper {
        border-bottom: 4px solid $color;
    }
    .theme-hover__header a {
        color: $color-zeus-black;
        &:hover {
            color: $color;
        }
    }
    .rolling-stream {
        h3 {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 4px;
                width: calc( 100% - 15px);
                background: $color;
                left: 0;
            }
            &[data-target-tab]:hover {
                background-color: $color;
            }
        }
        h5 {
            a {
                color: $color-zeus-black;
                &:hover {
                    color: $color;
                }
            }
        }
        .article-related-content__date {
            color: $color;
        }
        & > .full-diminished-button {
            color: $color;
        }
    }
	
	.header-line-control:after{
        background-color: $color;
	}
	.package-control-articles__wrapper{
		border-bottom: 4px solid $color;
	}
    .article-preview.latest-news-flexi .comp-headline::after {
        background-color: $color;
    }
    .podcast-widget .topic-subtopic__title {
        border-bottom-color: $color;
    }
}


@mixin theme-interviews($question-back, $question-border, $answer-back, $answer-border) {
    .article-interview__question:before {
        background-color: $question-back;
        border-color: $question-border;
    }

    .article-interview__answer {
        position: relative;
        &,
        &:before {
            border-color: $answer-border;
        }

        &:before {
            background-color: $answer-back;
        }
    }
}

@mixin theme-table($primary-color, $seconday-color, $tertiary-color, $table-hover-color) {
    .idi-table-heading, .controls, .merge-heading,
    .ID-Responsive-Table .table_head,
    .table-responsive .table .table_head,
    .table.theme-table thead tr:first-child th {
        background-color: $primary-color;
    }
    .label-wrap, .show-largest-btn, .merge-form-items,
    .table-wrapper .tableHead,
    .table.theme-table thead tr th {
        background-color: $seconday-color;
    }
    #tanker-fixtures.table-responsive .table .table_head, #casualty-detail-table .table_head {
        background-color: $tertiary-color;
    }
    .ID-Responsive-Table {
        .owl-dots{
          float: left;
          margin-top: 20px;
          .owl-dot {
            span {
                background: $seconday-color;
            }
            &.active {
                span {
                    background: $color-white !important;
                }
                &:hover {
                    span {
                        background: $color-white;
                    }
                }
            }
            &:hover {
                span {
                    background: $seconday-color;
                }
            }
          }
        }
    }
    .year_heading {
        background-color: $seconday-color !important;
    }
    .merge-acquistion tr:hover,
    //.activate-hover,
    .tableRow:hover .tableCell {
        background-color: $table-hover-color !important;
    }
    .table .table_head tr.visible-lg {
        background-color: $seconday-color;
    }
	.table .table_head tr.blueBg {
        background-color: $seconday-color;
    }
    #cockett-Bunker .table_head {
        background-color: $seconday-color !important;
        tr:first-child {
            background-color: $primary-color !important;
        }
    }
}

// legacy to be removed when all verts copy pharma
@mixin sponsored_content($seconday-color, $secondary-color-ten){
	.sponsored_cont {
        background: transparentize($seconday-color, 0.9);
			.topic-featured-article__inner-wrapper:after, &.article-preview--small:after 
		{
			background: linear-gradient(to bottom, $secondary-color-ten  0%, $secondary-color-ten 65%, $secondary-color-ten  100%);
			background: -webkit-linear-gradient(top, $secondary-color-ten  0%, $secondary-color-ten 65%, $secondary-color-ten  100%); 
			background: -moz-linear-gradient(top, $secondary-color-ten  0%, $secondary-color-ten  65%, $secondary-color-ten 100%); 
		}
	}
}

// new, to be renamed when all vert copy pharma
@mixin sponsored_content_new($background-color){
	.sponsored_cont {
		 background: $background-color;
			.topic-featured-article__inner-wrapper:after, &.article-preview--small:after 
		{
			background: linear-gradient(to bottom, $background-color  0%, $background-color 65%, $background-color  100%);
			background: -webkit-linear-gradient(top, $background-color  0%, $background-color 65%, $background-color  100%); 
			background: -moz-linear-gradient(top, $background-color  0%, $background-color  65%, $background-color 100%); 
		}
	}
}

@mixin promotional-sidebar-top-border($color) {
    .article-inline-promotional-sidebar {
        border-top: 4px solid $color;
    }
}

@mixin theme-myview-header($c){
	header .header-account-access .myView-placeholder .btn-my-view{
		@include breakpoint('ipad') {
			color: $c;
			&:hover{
				color: $c;
			}
		} 
	}
}

@mixin theme-my-view-settings($tc, $b, $mobg){
	.myview-settings {
		.publicationPan{
			&.active{
				border: 1px solid $b;
			}
		}
		.followingBtn{ 
			@media screen and (min-width: 480px){
				background: $tc;
				border: 1px solid $tc;
				&:hover{
					background: #a7a7a7;
					border: 1px solid #a7a7a7;
					@media screen and (min-width: 768px) and (max-width: 1024px){
						color: $color-white;
					}
				}
			}
		}
		.followBtn{
 			&:hover{
				@media screen and (min-width: 1025px){
					background: $tc;
					border: 1px solid $tc;
				}
				@media screen and (min-width: 768px) and (max-width: 1024px){
					border: 1px solid #ADADAD;
					color: #58595b;
					background: none;
				}
			}
		}
		.followingrow{
			.rowlines{
				// @include getMove($tc !important, 0.4375rem);
                float: right;
                margin-top: 0.4375rem;
                .pull-left{
                    float: left;
                    @include breakpoint('medium-small') {
                        height: 1.5rem;
                        width: 1.5rem;
                        display: block;
                    }
                }
                span{
                    height: 0.125rem;
                    width: 1.3125rem;
                    background: $tc !important;
                    display: block;
                    margin-bottom: 3px;
                } 
			} 
			&:hover{
				background: $mobg !important;
			}
		}
		.followingbg{
			.sorting_arrow--up{
				&.act{
					fill: $tc !important;
				}
			}
			.expandTxt{
				color: $tc !important;
			}
			.rowlines{
				.mv{
					background: $tc !important;
				}
			}
		}
		.followinglbl, .mvTxt{
			color: $tc !important;
		}
		.followAllBtn{
			color: $tc;
		}
		.gotoview{
			a{
				color: $tc !important;
				border:  1px solid $tc !important;
				&:hover{ 
					color: #fff !important;
					//background: $mobg;
				}
			}
		}
		.followinglbl-txt{
			.collapsed{
				.active{
					@media screen and (min-width: 1025px){
						background: $mobg !important;
					}
				}
			}
		}
		.sorting_arrow--down{ 
			&.act{
				fill: $tc !important;
			}
		}
		.subscribed{
			a:hover{
				color: $tc;
			}
		}
		.rowlines{ 
			.move{ 
				color: $tc !important;
			}
		}
		.table{
			span{ 
				fill: $b;
				&.button--outline{
					border: 1px solid $b;
					&:hover{
						border: 1px solid $tc;
					}
				}
				&.button--filled{
					color: #fff;
					&:hover{
						border: 1px solid $tc;
					}
				}
			} 
		}
	}
	/************ drag and drop styles ***********/
	.drop {
		-webkit-animation: drop 1s ease;
		-moz-animation: drop 1s ease;
		-ms-animation: drop 1s ease;
		-o-animation: drop 1s ease;
		animation: drop 1s ease;
	}

	.followingrow.over{ 
		background: $tc;
	}
}

@mixin theme-personalisation($bgc){
	@media screen and (min-width: 768px){
		.latestSubject{
			.fllatestSub{
				background-color: $bgc;
			} 
		}
	}
	.personalisationhome .article-preview--small .article-preview_rheadline a:hover{
		color: $bgc;
	}
	@media screen and (max-width: 767px){
		.personalisationhome .latestSubject span.sub{
			border-left: 0.625rem solid $bgc;
		}
	}
}

@mixin theme-subscription($c){
	.mySubscribe{
		border: 1px solid $c !important;
    }
	#mySubscriptionTab{
		tbody{
			td.sortable-table__col:nth-child(3) a{
				border: 1px solid $c;
				&:hover {
					background-color: $c;
				}
			}
		}
		.button--outline{
			border:1px solid $c;
			&:hover{
				background: $c;
			}
		}
	}
}

// horizontal sub menu - micro sites
@mixin theme-horizontal-sub-menu($sub-menu-link, $sub-menu-link-hover, $sub-menu-link-highlight, $sub-menu-link-chevron, $sub-menu-nav-bg-hover, $sub-menu-nav-link, $sub-menu-hamburger-icon) {
    .sub-menu__wrapper {
        .sub-menu__container {
            .sub-menu__row {
                .sub-menu__navigation {
                    .sub-menu__navigation-list {
                        > li {
                            &:hover {
                                a {
                                    color: $sub-menu-link-hover;
                                }
                                label {
                                    border: 1px solid $sub-menu-link-hover;
                                    border-bottom: none;
                                    border-left: none;
                                }
                            }
                            a {
                                color: $sub-menu-link;
                                &.highlight {
                                    color: $sub-menu-link-highlight;
                                }
                            }
                        }
                        .dropdown {
                            label {
                                border: 1px solid $sub-menu-link-chevron;
                                border-bottom: none;
                                border-left: none;
                            }
                            .submenu {
                                li {
                                    a {
                                        color: $sub-menu-nav-link;
                                        &:hover {
                                            background-color: $sub-menu-nav-bg-hover;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .sub-menu__hamburger-icon {
                    >.bar1,
                    >.bar2,
                    >.bar3 {
                        background-color: $sub-menu-hamburger-icon;
                    }
                }
            }
        }
    }
}

// horizontal sub menu - micro sites
@mixin theme-tabing($tabing-border, $tabing-active-border, $tabing-background) {
    .tab {
        [type="radio"] {
            &:nth-of-type(1):checked ~ .tab-items .tab-item:nth-of-type(1) label::after,
            &:nth-of-type(2):checked ~ .tab-items .tab-item:nth-of-type(2) label::after {
                background-color: $tabing-active-border;
            }
        }
        .tab-items {
            background-color: $tabing-background;
            border-radius: 10px 10px 0 0;
            &::after {
                background: $tabing-border;
            }
            > .tab-item {
                > label {
                    &:hover {
                        &::after {
                            background-color: $tabing-active-border;
                        }
                    }
                }
            }
        }
    }
}
