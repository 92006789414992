.insights {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 1260px;
  padding: 0 30px;
  margin: map-get($spacers, 1)*5 auto;
  @include breakpoint('medium') {
    margin: map-get($spacers, 1)*3.5 auto;
    padding: 0 map-get($spacers, 1)*0.75;
  }
  &.no-top-margin {
      margin-top: 0;
  }
  &.no-bottom-margin {
      margin-bottom: 0;
  }
  &-title {
    @include font($font-size-32, $font-weight-bold, $line-height-125, $letter-spacing-normal);
    color: #000;
    text-align: center;
    padding: 0 0 $spacer-2*1.25;
    &.text-align-left {
      text-align: left;
    }
  }
  &-banner {
    position: relative;
    height: 270px;
    padding: $spacer-2*1.25;
    margin-bottom: $spacer-2*1.5;
    background-color: $omd-dark-purple-2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint('medium') {
      margin-bottom: map-get($spacers, 1)*1.5;
    }
    @include breakpoint('small') {
      margin-bottom: map-get($spacers, 1);
      height: auto;
      gap: 10px;
      @include spacer(padding, map-get($spacers, 0));
    }
    &:hover {
      & > .insights-banner-title {
        top: 100%;
        transform: translateY(calc(-100% - 60px));
        @include breakpoint('small') {
          margin-top: 0;
          transform: none;
        }
      }
      img {
        opacity: 0;
        @include breakpoint('small') {
          opacity: 1;
        }
      }
      & > .cta {
        opacity: 1;
      }
    }
    &-title {      
      @include font($font-size-40, $font-weight-bold, $line-height-125, $letter-spacing-05);
      text-transform: capitalize;
      color: #fff;
      position: relative;
      z-index: 2;
      top: 0;
      transform: translateY(0);
      transition: top 300ms ease, transform 300ms ease;
      @include breakpoint('small') {
        font-size: $font-size-20;
        line-height: $line-height-15;
      }
    }
    & > img {
      max-width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      transition: opacity 300ms ease;
    }
    & > .cta {
      opacity: 0;
      transition: opacity 300ms ease;
      @include breakpoint('small') {
        opacity: 1;
        margin-left: auto;
      }
    }
  }
  &-cards {
    display: flex;
    width: 100%;
    gap: $spacer-2*1.5 map-get($spacers, 1);
    flex-wrap: wrap;
    @include breakpoint('small') { 
      flex-direction: column;
      gap: map-get($spacers, 1);
      cursor: pointer;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;
      background: $omd-dark-purple-2;
      overflow: hidden;
      width: calc(25% - 15px);
      justify-content: space-between;
      padding: map-get($spacers, 1) map-get($spacers, 1) 0;
      height: 380px;
      transition: height 300ms ease;
      @include breakpoint('medium') {
        width: calc(50% - 10px);
      }
      @include breakpoint('small') {
        width: 100%;
        height: 115px;
        flex-direction: row;
        padding: map-get($spacers, 0);
        gap: 15px;
        align-items: center;
      }
      &:hover {
        & > .insights-cards-item-overlay {
          opacity: 1;
          & > img {
            height: 100%;
          }
          @include breakpoint('small') {
            opacity: 0;
            display: none;
          }
        }
      }
      &.active {
        @include breakpoint('small') {
          height: 240px;
          & > .insights-cards-item-overlay {
            display: flex;
            opacity: 1;
            & > p {
              -webkit-line-clamp: 6;
            }
            & > img {
              height: 100%;
            }
          }
        }
      }
      &-heading {
        display: block;
        @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-05);
        margin: map-get($spacers, 1)/2;
        font-stretch: normal;
        font-style: normal;
        color: #fff;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint('small') { 
          flex: 2;
          margin: 0;
          font-size: $font-size-20;
        }
      }
      & > img {
        display: block;
        width: 100%;
        transition: 0.5s ease;
        height: 173px;
        object-fit: cover;
        @include breakpoint('small') { 
          position: relative;
          width: 96px;
          height: 65px;
          left: inherit;
          top: inherit;
          bottom: inherit;
          right: inherit;
          margin-left: 10px;
        }
      }
      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
        background: rgba($color-white, 0.7);
        @include spacer(padding, map-get($spacers, 3));
        transition: opacity 300ms ease;
        @include breakpoint('small') { 
          background: $omd-dark-purple-2;
          height: 240px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba($color-black, 0.7);
          z-index: 1;
        }
        & > * {
          position: relative;
          z-index: 2;
        }
        & > img {
          height: 70%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
          z-index: 0;
          transition: height 400ms ease;
        }
        & > p {
          position: relative;
          @include font($font-size-16, $font-weight-400, $line-height-133, $letter-spacing-02);
          color: $color-white;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @include breakpoint('small') { 
            font-size: $font-size-14;
            line-height: $line-height-15;
          }
        }
      }
      &:nth-of-type(4) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $omd-bright-purple;
        padding-bottom: map-get($spacers, 1);
        gap: 20px;
        @include breakpoint('small') { 
          height: auto;
          order: 8;
          gap: 10px;
        }
        & > .insights-cards-item-heading {
          text-align: center;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
