.custom-modal,
.custom-popup,
.modal {
  position: fixed;
  z-index: 9999;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &.show,
  &.in {
    display: block;
  }

  &-dialog {
    position: relative;
    width: auto;
    margin: 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;    
  }
  
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $color-white;
    padding: 35px 20px 25px;

    & > button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 11px;
      position: absolute;
      right: 5px;
      top: 5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      & > img {
        width: 100%;
      }
    }
  }
}
.modal[id^=follow-analyst] {
  & > .modal-dialog {
    max-width: 400px;
    transform: translate(0,0);
    font-size: $font-size-12;
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
    background-color: $color-white;
    border: 1px solid $color-white;
    &-centered {
      margin: auto;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
    }
    & > .modal-header {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      & > .close {
        font-size: $font-size-36;
      }
    }
    & > .modal-content {
      background-color: $omd-light-grey !important;
      border: none;
      color: $color-black;
      & > .modal-body {
        @include spacer(padding, map-get($spacers, 2));
        letter-spacing: $letter-spacing-05;
        line-height: $line-height-125;
        & > svg {
          fill: $omd-purple;
          width: 50px;
          margin-right: 5px;
        }
        & > h6 {
          font-size: $font-size-20;
          @include spacer(margin-bottom, map-get($spacers, 0));
          @include spacer(padding-right, map-get($spacers, 2));
        }
        & > p {
          font-size: $font-size-16;
          color: $color-medium-black;
        }
        & > .btn {
          width: 100%;
          @include spacer(margin-top, map-get($spacers, 2));
          text-align: center;
          &:hover {
            background-color: $omd-purple;
            border-color: $omd-purple;
            color: $color-white;
          }
        }
        & > .custom-checkbox {
          & > input{
            vertical-align: middle;
            margin-right: $spacer-2*.26;
            &:checked {
              background-color: $omd-purple;
              border-color: $omd-purple;
              &::before {
                border-color: $color-white;
              }
            }
          }
          & > label {
            font-size: $font-size-16;
            vertical-align: middle;
          }
        }
      }
    }
  }
}