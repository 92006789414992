// pagination
.omd-col-main .pagination-list,
.pagination-list {
    display: flex;
    gap: 23px;
    margin-left: auto;
    @include breakpoint('medium-small') {
        margin-right: auto;
    }
    & > li {
        display: inline-block;
        border-bottom: 1px solid $color-black;
        line-height: $line-height-1;
        & > a {
            color: $color-black;
            @include font($font-size-16, $font-weight-700, $line-height-125, $letter-spacing-normal);
            &:hover {
                text-decoration: none;
            }
        }
        &.active {
            border-color: $omd-dark-purple-2;
            & > a {
                color: $omd-dark-purple-2;
            }
        }
    }
}