/* Adding the styles outside header section to make it reusable across the application*/
.searchbar {
  @include inf-container-fluid();
  padding-top: $spacer-2*2.5;
  padding-bottom: $spacer-3*2;

  &-wrapper {
    max-width: 925px;
    margin: auto;
    width: 100%;
    .menu-follower-line {
      display: none;
    }
    @include breakpoint('medium') {
      @include spacer(padding-left, map-get($spacers, 0));
      @include spacer(padding-right, map-get($spacers, 0));
    }
  }
  &-title {
    text-align: center;
    margin: auto;
    @include breakpoint('medium') {
      width: 80%;
    }
    .h4 {
      font-size: $font-size-32;
      letter-spacing: - $letter-spacing-02;
      font-weight: $font-weight-bold;
      @include breakpoint('medium') {
        font-size: $font-size-25;
      }
      @include breakpoint('medium-small') {
        letter-spacing: $letter-spacing-02;
      }
    }
  }
  &-container {
    position: relative;
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    padding: 0 map-get($spacers, 1)*1.5;
    @include breakpoint('medium') {
      padding: 0;
    }
  }
  &-close {
    display: none;
    position: absolute;
    top: -20px;
    right: 30px;
    cursor: pointer;
    @include breakpoint('medium') {
      top: -40px;
      right: 15px;
    }
  }
  form {
    display: flex;
    margin-bottom: $spacer-3*1.25;
    margin-top: $spacer-2*.75;
    transition: background-color $searchOpenDuration;
    & > * {
      &:focus {
        outline: none;
      }
    }
    & > button, .button {
      width: 48px;
      appearance: none;
      background-color: transparent;
      background-image: url(/dist/img/omdia/search_black.svg);
      background-repeat: no-repeat;
      background-size: 17px auto;
      background-position: center;
      border: 1px solid $omd-light-grey-4;
      border-right: none;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      order: 1;
    }
    & > input[type="search"] {
      order: 2;
      width: 100%;
      @include omd-main-menu-links();
      color: $color-black;
      border: 1px solid $omd-light-grey-4;
      border-left: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      font-weight: normal;
      padding: map-get($spacers, 1)/2 0;
      background-color: transparent;
      transition: opacity $searchOpenDuration;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @include breakpoint('medium') {
        padding: map-get($spacers, 1)/2 0;
        font-size: $font-size-16;
        color: $color-black;
        -webkit-appearance: none;
      }
      &::-webkit-input-placeholder, &::placeholder {
        font-style: normal;
        letter-spacing: $letter-spacing-117;
        color: $omd-light-grey-4;
        @include breakpoint('medium') {
          color: rgba(0, 0, 0, 0.4);
        }
      }
      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
        -webkit-text-fill-color: $color-black;
        box-shadow: 0 0 0px 1000px $omd-light-grey inset;
        @include breakpoint('medium') {
          -webkit-text-fill-color: $color-black;
          box-shadow: 0 0 0px 1000px $omd-light-grey inset;
        }
      }
    }
  }
}

.searchbar-header {
  .searchbar-close {
    display: block;
  }
}

.trending {
  &_title {
    display: block;
    @include font($font-size-16, $font-weight-bold, $line-height-1, $letter-spacing-08);
    color: $omd-dark-purple-2;
    text-transform: uppercase;
    @include spacer(margin-bottom, map-get($spacers, 0));
    @include breakpoint('medium') {
      @include spacer(margin-bottom, map-get($spacers, 1));
    }
  }

  &_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    & > li {
      & > a {
        text-decoration: none;
        @include font($font-size-14, $font-weight-400, $line-height-1, $letter-spacing-150);
        text-decoration: none;
        display: inline-flex;
        padding: $spacer-2/3 $spacer-2;
        border-radius: 35px;
        border: 1px solid $color-black;
        &:hover {
          text-decoration: none;
          border: 1px solid $omd-dark-purple-2;
          background-color: $omd-dark-purple-2;
          color: $color-white !important;
        }
      }
    }
  }
}

.inf-bg-black, .inf-bg-dark {
  .searchbar {
    form {
      color: $color-white;
      input[type="search"] {
        border-color: $color-white;
      }
      & > button, .button {
        background-image: url(/dist/img/omd/search_white.svg);
        border-color: $color-white;
      }
    }
  }

  .trending {
    &_tags {
      & > li > a {
        color: $color-white;
        border: 1px solid rgba(255, 255, 255, 0.5);
        &:hover {
          background-color: $color-white;
          color: $color-black;
        }
      }
    }
  }
}