.action-flags-bar {
	border-top: 2px solid $color-light-gray;
	clear: both;
	margin-bottom: 16px;
	margin-top: 16px;
}

.action-flags {

}

.action-flag {
	display: inline-block;
	margin-left: 2rem;
	padding-top: 6px;

	&:first-child {
		margin-left: 0;
	}

	a {
		@include on-event(true) {
			text-decoration: none;
		}
	}

	@include breakpoint('small') {
		margin: 0 0.5rem;
	}


	&__label {
		@include textMix('Roboto Condensed', 14px, 700);
		color: $color-zeus-black;
		margin-right: 0.4375rem;
		text-transform: uppercase;
		vertical-align: middle;

		@include breakpoint('medium') {
			display: none;
		}
	}


	&__icon {
		@include svg-fill($color-dusty-gray);
		height: 22px;
		vertical-align: middle;
		width: 20px;

		&--email {
			width: 21px;
			height: 21px;
			margin-top: -3px;
		}

		&--bookmark {
			margin-top: -1px;
		}

		&--linkedin {
			margin-top: -4px;
			margin-right: 1rem;
			@media only screen and (min-width: 480px) and (max-width: 564px) {
	    		margin-bottom: 0.875rem;
	    	}
		}

		&--twitter {
			margin-top: -3px;
			width: 23px;
			height: 23px;
			margin-left: 0;
		}
	}

	&:hover .action-flag__icon {
		@include svg-fill($color-zeus-black);
	}

	&--social {
		@extend .action-flag;

		&:hover .action-flag__icon {
			@include svg-fill($color-dusty-gray);
		}

		& a:hover .action-flag__icon {
			@include svg-fill($color-zeus-black);
		}
	}

	&--red {
		color: $color-cardinal-red;
	}
}
