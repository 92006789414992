/*---
title: Pop-outs
section: UI Elements
---

```example:html
<div class="pop-out js-pop-out__sign-in is-active" style="position: relative;">
    <div class="pop-out__tab" style="height: 54px; width: 52px; left: auto; right: 0px; top: -53px; z-index: 9998;"></div>
    <div class="pop-out__internal">
        <div class="pop-out__sign-in">
            <div class="dismiss-button" data-target-element=".js-pop-out__sign-in">
                <svg class="dismiss-button__icon">
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="svg-sprite.svg#x"></use>
                </svg>
            </div>

            <h3 class="pop-out__register-header">You must sign in to use this functionality</h3>

    <div class="js-login-container">
        <div class="pop-out__form-error">
            Your login and/or password information does not match our records. Please try again.
        </div>
        <form method="post" class="js-sign-in-submit">
            <input name="username" class="pop-out__input header__hover-input" placeholder="Email Address (User Name)" type="text">
            <input name="password" class="pop-out__input header__hover-input" placeholder="Password" type="password">

            <div class="pop-out__form-actions-wrapper">
                <div>
                    <span class="pop-out__form-actions pop-out__reset-password js-show-forgot-password">Forgot your password?</span>
                    <div class="pop-out__sign-in-remember-me">
                        <input name="persist" type="checkbox" data-checkbox-type="boolean"> Remember Me
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
```
*/

.pop-out {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 440px;
    text-align: left;

    @media only screen and (max-width: 1024px) {
        max-width: none;
        width: 100%;
    }

    .pop-out__internal {
        left: 0;
        min-width: 440px;
        padding: 1.5rem 1.25rem;
        top: 0;
        width: 100%;
    }

    &.is-active {
        display: block;
        z-index: 9999;
    }

    &__tab {
        content: "";
        margin: auto;
        background-color: #f1f2f2;
        border: 1px solid #d1d3d4;
        border-bottom: 0;
        display: block;
        position: absolute;

        @include breakpoint('ipad') {
            margin: 0;
        }
    }

    &__trigger {
        position: relative;
        cursor: pointer;
        
    }

    &__internal {
        @include textMix('Roboto', 14px, 400);
        background-color: #f1f2f2;
        border: 1px solid #d1d3d4;
        padding: 20px 24px 12px;
        text-transform: none;
        width: 380px;
        z-index: 8;
    }

    &__header {
        margin: 0 0 1rem;

        &--tight {
            margin-bottom: 0.5rem;
        }
    }

    &__input {
        @extend .inf-standard-form-field;
        margin-bottom: 1rem;
        padding: 0.5rem 0.75rem;
        width: 100%;

        &--tight {
            margin-bottom: 0.5rem;
        }

    }
    &__textarea {
        height: 8rem;
    }

    &__run-in {
        @include textMix('Roboto', 18px, 500);
    }

    &__form-error {
        display: none;
        font-style: italic;
        color: $color-cardinal-red;
        line-height: 1.4375rem;
        margin-bottom: 1rem;
    }

    &__details {
        clear: both;
        color: $color-dusty-gray;
        line-height: 1.25rem;
        margin: 0 0 0.75rem;

        &--inset {
            padding: 0 0.75rem;
        }
    }

    &__error {
        @extend .error-text;
        display: none;
        margin-bottom: 0.5rem;
    }

    // Bottom section of pop-out form, below input fields
    // Organizes common actions like "Forgot Password", "Remember Me", etc.
    &__form-actions {
        display: block;
        margin-bottom: 2.5rem;
        font-size: 0.8125rem;

        &-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 1rem;
        }

        &--remember-me {
            color: $color-abbey-gray;
            font-size: 0.9375rem;
        }
    }

    // Some pop-outs are "split", with two distinct containers where one might be
    // shown or hidden depending on user state/behavior.
    // ex. a Forgot Password container that can appear below the Sign In container
    &__show-hide {
        &--bordered-bottom {
            border-bottom: 1px solid $color-light-gray;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        }

        &--bordered-top {
            border-top: 1px solid $color-light-gray;
            padding-top: 1.5rem;
            margin-top: 1.5rem;
        }
    }

    &__forgot-password {
        border-top: 1px solid $color-light-gray;
        display: none;
        margin-top: 1.5rem;
        padding-top: 1.625rem;

        &-header {
            margin-bottom: 0.75rem;
        }

        & p:last-child {
            margin-bottom: 0;
        }

        .alert-success {
            margin-bottom: 2rem;
        }

        &.is-active {
            @extend .is-active;
            clear: both;
        }

    }

    &__save-search-container {
        margin-bottom: 1.5rem;
        overflow: auto;
    }
}
