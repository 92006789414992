.press-center {
	display: flex;
	gap: 40px;
	max-width: 1260px;
	padding: 0 map-get($spacers, 1)*1.5;
	margin: map-get($spacers, 1)*5 auto;
	@include breakpoint('medium') {
		margin: map-get($spacers, 1)*3.5 auto;
		padding: 0 map-get($spacers, 1)*0.75;
		flex-direction: column;
		gap: 70px;
	}
	&.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
	&_left {
		@extend .inf-col;
		flex: 1;
		gap: 36px;
		& > .pagination-list {
			margin-top: map-get($spacers, 1)*2;
			margin-left: 0;
			@include breakpoint('medium') {
				margin-top: map-get($spacers, 1)*1.75;
				margin-left: auto;
			}
		}
	}
	&-title {
		@extend .tile-list-banner-title;
		text-transform: capitalize;
		max-width: initial;
		@include breakpoint('medium') {
			text-align: center;
		}
	}	
	.tile-list {
		flex-basis: calc(100%/2 - 20px);
		@include breakpoint('medium') {
			flex-basis: calc(100%/2 - 10px);
		}
		&-box {
			gap: 40px;
			@include breakpoint('medium') {
				gap: 20px;
			}
		}
	}
	&_right {
		width: 375px;
		overflow: hidden;
		@include breakpoint('medium') {
			width: auto;
		}
		& > .in-the-news-title {
			@extend .tile-list-banner-title;
			margin-bottom: map-get($spacers, 1);
			text-transform: capitalize;
			color: $omd-dark-purple-2;
			max-width: initial;
		}
		& > iframe {
			border: none;
			position: static; 
			visibility: visible; 
			width: 340px;
			height: 430px;
			display: block; 
			flex-grow: 1;
			width: 100%;
			margin-bottom: map-get($spacers, 1)*2;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&-bottom {
		display: flex;
		gap: 40px;
		@include breakpoint('medium-small') {
			flex-direction: column-reverse;
			gap: 20px;
		}
	}
}