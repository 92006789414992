// custom select
.omd-col-main .custom-select,
.custom-select {
    position: relative;
    height: fit-content;
    width: 320px;
    &::after {
        content: '';
        position: absolute;
        top: calc(50% - 10px);
        right: 20px;
        width: 20px;
        height: 20px;
        width: 20px;
        background: url(img/omd/chevron_down_black.svg) no-repeat center;
        background-size: contain;
        transition: all .2s;
    }
    &.open {
        &::after {
            transform: rotate(180deg);
        }
    }
    @include breakpoint("medium") {
        width: 100%;
    }
    &-value {
        position: relative;
        width: 100%;
        border: 1px solid $color-black;
        border-radius: 48px;
        height: 48px;
        padding: 13px 50px 13px 22px;
        font-size: $font-size-18;
        font-weight: $font-weight-600;
        cursor: pointer;
        background-color: transparent;
        z-index: 1;
        @include breakpoint("medium") {
            margin-left: 0;
        }
        &:focus {
            outline: none;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
    &-list {
        &-box {				
            display: none;
            position: absolute;
            margin-top: map-get($spacers, 0)*1.5;
            width: 100%;
            max-height: 295px;
            background-color: $color-white;
            border: 1px solid $color-black;
            border-radius: 10px;
            padding: 8px 0;
            z-index: 9;
            box-shadow: 0 3px 25px rgba($color-black, 0.05);
            &::before,
            &::after {
                content: "";
                position: absolute;
                right: 22px;
                top: -6px;
                width: 13px;
                height: 13px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                z-index: 1;
            }
            &::before {
                background: $color-black;
                margin-top: -1.5px;
            }
            &::after {
                background: $color-white;
            }
        }

        font-size: $font-size-20;
        font-weight: $font-weight-600;
        max-height: 275px;
        overflow-y: auto;
        padding-right: 5px;

        &::-webkit-scrollbar {
            width: 10px;
            border-radius: 5px;
        }				
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
        }				
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #aaa;
            border-radius: 5px;
        }
        
        & > li {
            position: relative;
            padding: 7px 20px;
            user-select: none;
            z-index: 2; 
            cursor: pointer;
            &::before {
                display: none;
            }
            & > a {
                color: $color-black;
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover,
            &.active {
                color: $omd-dark-purple-2;
                a {
                    color: $omd-dark-purple-2;
                }
            }
            &.active{
                pointer-events: none;
            }
        }
    }
}