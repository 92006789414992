.blog-single {
    display: flex;
    gap: 40px;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
        margin: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
        flex-direction: column;
    }
    &.no-top-margin {
        margin-top: 0;
    }
    &.no-bottom-margin {
        margin-bottom: 0;
    }
    &.single-col {
        flex-direction: column;
        .blog-single_left {
            max-width: none;
        }
    }
    &_left {
        flex: 1;
        max-width: calc(100% - 350px);
        @include breakpoint('medium') {
            width: 100%;
            max-width: 100%;
        }
        .omd-body,
        .omd-body p {
            @include font($font-size-20, $font-weight-400, $line-height-157, $letter-spacing-08);
            strong {
                @include font($font-size-18, $font-weight-700, $line-height-135, $letter-spacing-025);
                margin-bottom: map-get($spacers, 1)*0.75;
            }
            li {
                strong {
                    font-size: $font-size-16;
                }
            }
            & > p {
                margin-bottom: map-get($spacers, 1)*1.5;
                &.highlight {
                    padding: 10px 21px 10px 18px;
                    background: #f8f3fe;
                    border-left: 8px solid $omd-dark-purple-2;
                    font-style: italic;
                    @include font($font-size-20, $font-weight-600, $line-height-15, $letter-spacing-05);
                }
            }
            img {
                max-width: 100%;
            }
            a {
                color: $omd-dark-purple-2;
                text-decoration: none;
            }
        }
    }
    &_right {
        width: 310px;
        margin-left: auto;
        overflow: hidden;
        @include breakpoint('medium') {
            width: 100%;
            margin: initial;
        }
        & > h5 {
            @include font($font-size-25, $font-weight-bold, $line-height-156, $letter-spacing-025);
            color: $omd-dark-purple-2;
            margin-bottom: map-get($spacers, 1)*0.75;
        }
        .more-authors {
            @extend .inf-col;
            gap: 15px;
            margin-top: map-get($spacers, 1)*2.5;
            &:first-of-type {
                margin: 0;
            }
            & > h5 {
                @include font($font-size-25, $font-weight-bold, $line-height-156, $letter-spacing-025);
                color: $omd-dark-purple-2;
            }
            &-image {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                overflow: hidden;
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-content {
                @extend .inf-col;
                gap: 10px;
                h5 {
                    @include font($font-size-18, $font-weight-600, $line-height-125, $letter-spacing-normal);
                    & > a {
                        color: $omd-black;
                        text-decoration: none;
                    }
                }
                h6 {
                    @include font($font-size-14, $font-weight-400, $line-height-15, $letter-spacing-normal);
                    text-transform: uppercase;
                }
                p {
                    @include font($font-size-14, $font-weight-400, $line-height-13, $letter-spacing-normal);
                    margin-bottom: 0;
                    display: none;
                    &:nth-of-type(1){
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    & > a {
                        color: $omd-black;
                        text-decoration: underline;
                    }
                }
                & > .cta {
                    margin-top: map-get($spacers, 1);
                }

                ul.arrow-list {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    & > li {
                        position: relative;
                        padding-left: map-get($spacers, 1)*2.1;
                        @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
                        margin: 0;
                        &:before {
                            content: "";
                            background: url(/dist/img/omdia/dotted-arrow-purple.svg) left top no-repeat;
                            width: 22px;
                            height: 18px;
                            margin: 0;
                            background-size: 100%;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }

        .more-articles {
            @extend .inf-col;
            gap: 20px;
            margin-top: map-get($spacers, 1)*1.5;
        }

        & > .register-banner-cta {
            margin-top: map-get($spacers, 1)*3;
        }
    }
}

.pr-single ul.article-list--ul, .blog-single ul.article-list--ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    & > li {
        position: relative;
        padding-left: map-get($spacers, 1)*2.1;
        @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
        margin: 0;
        strong {
            @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
        }
        &:before {
            content: "";
            background: url(/dist/img/omdia/dotted-arrow-purple.svg) left top no-repeat;
            width: 22px;
            height: 18px;
            margin: 0;
            background-size: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        ul {
            li::before {
                content: "\2022";
                color: $omd-purple;
                margin: 0;
                background: none;
                margin-left: -20px;
                padding-right: 10px;
                height: 22px;
                width: 16px;
                display: inline-block;
                position: absolute;
            }
        }
    }
}
.omd-content-mod-container.version-2 {
    display: flex;
    gap: 40px;
    max-width: 1260px;
    padding: 0 map-get($spacers, 1)*1.5;
    margin: map-get($spacers, 1)*5 auto;
    @include breakpoint('medium') {
        margin: map-get($spacers, 1)*3.5 auto;
        padding: 0 map-get($spacers, 1)*0.75;
        flex-direction: column;
    }
    & > .omd-content-container {
        & > .mkt-content-title {
            & h5 {
                @include font($font-size-31, $font-weight-bold, $line-height-125, $letter-spacing-02);
            }
        }
        & > .omd-body {
            & > .article-list--ul {
                display: flex;
                flex-direction: column;
                gap: 30px;
                & > li {
                    position: relative;
                    padding-left: map-get($spacers, 1)*2.1;
                    @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
                    margin: 0;
                    a {
                        color: $color-black;
                        text-decoration: none;
                        &:hover {
                            color: $omd-dark-purple-2;
                        }
                    }
                    strong {
                        @include font($font-size-16, $font-weight-600, $line-height-15, $letter-spacing-025);
                    }
                    &:before {
                        content: "";
                        background: url(/dist/img/omdia/dotted-arrow-purple.svg) left top no-repeat;
                        width: 22px;
                        height: 18px;
                        margin: 0;
                        background-size: 100%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                    ul {
                        li::before {
                            content: "\2022";
                            color: $omd-purple;
                            margin: 0;
                            background: none;
                            margin-left: -20px;
                            padding-right: 10px;
                            height: 22px;
                            width: 16px;
                            display: inline-block;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}