.custom-popup {
    max-width: 1200px;
    background-color: $color-white !important;
    color: $color-black !important;
    width: 100%;
    z-index: 99999;
    left: 50%;
    padding-right: 0 !important;
    transform: translate(-50%,-50%);
    @include breakpoint('ipad') {
        width: calc(100% - 30px);
    }

    & > .custom-popup-dialog {
        margin: 0 auto;
        height: auto;
        display: flex;
        flex-direction: column;
        
        & > .custom-popup-header {
            padding: map-get($spacers, 1)*4 map-get($spacers, 0)*4.5;
            padding-top: map-get($spacers, 0)*3.75;
            background: $omd-purple;
            color: $color-white;
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            @include breakpoint('medium') {
                padding: map-get($spacers, 0)*1.5 map-get($spacers, 0);
                padding-left: map-get($spacers, 1)*2.5;
                gap: 20px;
            }
            & > .custom-popup-btns {
                width: fit-content;
                height: fit-content;
                position: absolute;
                right: map-get($spacers, 0)*4.5;
                z-index: 9;
                @include breakpoint('medium') {
                    right: map-get($spacers, 0);
                }
                & > .cta {
                    @include breakpoint('medium') {
                        padding: map-get($spacers, 1)*.5 map-get($spacers, 1);
                        font-size: $font-size-12;
                        font-weight: $font-weight-400;
                    }
                }
            }
            h4 {
                @include font($font-size-32, $font-weight-bold, $line-height-125, $letter-spacing-normal);
                position: relative;
                padding-right: map-get($spacers, 6)*1.75;
                @include breakpoint('medium') {
                    @include font($font-size-25, $font-weight-bold, $line-height-125, $letter-spacing-normal);
                    padding-right: map-get($spacers, 6)*1.3;
                }
                &::before {
                    content: "";
                    width: 13px;
                    height: 13px;
                    border-top: 4px solid #000;
                    border-right: 4px solid #000;
                    -webkit-transform: rotate(135deg) translate(5px,-10px);
                    transform: rotate(135deg) translate(5px,-10px);
                    position: absolute;
                    left: -42px;
                    @include breakpoint('medium') {
                        left: -35px;
                        top: -5px;
                    }
                }
            }
            p {
                padding-right: map-get($spacers, 6)*1.75;
                @include breakpoint('medium') {
                    padding-right: map-get($spacers, 0);
                }
            }
        }

        & > .custom-popup-content {
            padding: map-get($spacers, 1)*2 map-get($spacers, 0)*4.5;
            color: $color-black;
            @include breakpoint('medium') {
                padding: map-get($spacers, 0)*1.5 map-get($spacers, 0);
            }
        }
    }
}