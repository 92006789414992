// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


/// Regular font family
@mixin textMix($stack, $size, $weight) {
  @if $stack == 'Roboto' {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
  } @else if $stack == 'Roboto Condensed' {
    font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  } @else if $stack == 'RobotoCondensed' {
    font-family: "Roboto Condensed", Arial Narrow, sans-serif;
  } @else if $stack == 'PT Serif' {
    font-family: "PT Serif", Georgia, serif;
  } @else if $stack == 'Raleway light' {
    font-family: "Raleway", sans-serif;
  } @else if $stack == 'Open Sans' {
    font-family: 'Open Sans', sans-serif;
  }

  font-size: $size;
  font-weight: $weight;
}


@mixin smooth-transition($target: all, $timing: 200ms, $easing: ease-in-out) {
  transition: $target $timing $easing;
}

/// Responsive manager
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
/// @link http://sass-guidelin.es/#breakpoint-manager Sass Guidelines - Breakpoint Manager
@mixin breakpoint($breakpoint) {
  $query: map-get($breakpoints, $breakpoint);

  @if not $query {
    @error 'No value found for `#{$breakpoint}`. Please make sure it is defined in `$breakpoints` map.';
  }

  @media #{if(type-of($query) == 'string', unquote($query), inspect($query))} {
    @content;
  }
}

@mixin isIE {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin notIE {
  @supports (display: block) {
    @content;
  }
}

/********* START - GLOBAL NEW CUSTOME GRID LAYOUT *********/

@mixin colwidth($count, $bpfullwidth) {
  width: 100% / $gridsize * $count;
  @include breakpoint($bpfullwidth){
    width: 100%;
  }
}

//Adding right padding is 16% of column width
@mixin col-padding($count, $bpfullwidth) {
  padding-right : (100% / $gridsize * $count) * 16 /100;
}

//Create columns with padding right as 16% of column width
@mixin col-with-pad($count, $bpfullwidth) {
  @include col-with-padding-percent($count, $bpfullwidth, 16);
}

@mixin col-with-padding-percent($count, $bpfullwidth, $padding-percent) {
  $width : 100% / $gridsize * $count;
  width: $width;
  padding-right : $width * $padding-percent /100; // Padding is 16% of column width
  @include breakpoint($bpfullwidth){
    width: 100%;
    padding-right: 0;
  }
}



@mixin inf-container(){
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;

  @include isIE {
    display: block;
  }

  max-width: 1260px;
  padding : 0 30px;

  @include breakpoint('lg') {
    padding : 0 15px;
  }

  @include breakpoint('medium-ipad') {
    padding : 0 50px;
  }

  @include breakpoint('small') {
    padding : 0 15px;
  }
}

@mixin inf-container-fluid {
  display: flex;
  flex-direction: column;
  @include isIE {
    display: block;
  }
}

@mixin inf-row(){
  display: flex;
  flex-flow: row wrap;
  margin-left: -30px;
  margin-right: -30px;
  @include isIE {
    display: block;
    font-size: 0;
  }
  @include breakpoint('lg') {
    margin-left: -15px;
    margin-right: -15px;
  }
  & > .inf-col-fluid,
  & > .inf-col{
    padding-left: 30px;
    padding-right: 30px;
    @include isIE {
      display: inline-block;
      vertical-align: top;
      font-size: 1rem;
    }
    @include breakpoint('lg') {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include breakpoint('small'){
      min-width: 100%;
    }
  }
}

// TODO: need to change option to array of elements
@mixin inf-row-custom-col($column-selector){
  @include inf-row();
  & > #{$column-selector} {
    padding-left: 30px;
    padding-right: 30px;
    @include isIE {
      display: inline-block;
      vertical-align: top;
    }
    @include breakpoint('lg') {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@mixin inf-col-fluid(){
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include isIE {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin inf-justify-center(){
  display: flex;
  justify-content: center;
  @include isIE {
    display: block;
    text-align: center;
    & > * {
      text-align: left;
    }
  }
}

/********* END - GLOBAL NEW CUSTOME GRID LAYOUT *********/

/// SVG fill
/// @param {String} $hex - Hex color for filling SVG
/// `use>svg` allows for accurate targeting in Firefox
/// http://stackoverflow.com/questions/27866893/svg-fill-not-being-applied-in-firefox
/// (Based on a bug that's 10+ years old, jeez.)
@mixin svg-fill($hex) {
    fill: $hex;
    & use>svg {
        fill: $hex;
    }
}


/// Mixin to prefix a property
/// @author Hugo Giraudel
/// @param {String} $property - Property name
/// @param {*} $value - Property value
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($property, $value) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, "" {
        #{$prefix}#{$property}: $value;
    }
}

/// Simple grid / column mixin
@mixin columnize($count, $margin: false, $separator: false) {
  box-sizing: border-box;
  display: block;
  float: left;
  position: relative;
  width: calc(100% / #{$count});

  @if $margin {
      width: calc(#{100 / $count}% - #{$margin / #{$count - 1}}rem);
  }

  @if $separator {
      border-left: $separator;
      @if $margin {
          margin-left: $margin;
          padding: 0 #{$margin / 2}rem;
      }

      &:nth-child(#{$count}n-#{$count - 1}) {
          border-left: 0;
          margin-left: 0;
          padding-left: 0;
      }
      &:nth-child(#{$count}n) {
          padding-right: 0;
      }
  }

  @include breakpoint('small') {
      width: 100%;
      border: 0;
      padding: 0;
      @if $margin {
          margin: 0 #{$margin / 2};
      }
  }
}


@mixin callout($type: 'default') {
	background-color: $color-concrete-gray;
	border-top: 4px solid $color-cardinal-red;
	float: right;
	line-height: 1.5rem;
	margin: 1rem 0 1rem 1rem;
	overflow: auto; // Sneaky way to force-`clear` any floating elements
	padding: 1rem;
	@if $type == 'sidebar' {
		width: 100%;
	} @else {
		width: 50%;
	}

	.advertising + & {
		margin-top: 1rem;
	}

	h3 {

		@if $type == 'sidebar' {
			@include textMix('Roboto', 1.2rem, 500);
			color: $color-abbey-gray;
		} @else {
			@include textMix('Roboto Condensed', 1.2rem, 600);
			color: $color-zeus-black;
		}

		line-height: 1.7rem;
		margin-bottom: 0.5rem;
		font-style: normal;
	}

	img {
		height: auto;
		max-width: 100%;
	}

	&__body + &__footer {
		margin-top: 1.5rem;
	}

	&__byline,
	&__date {
		@include textMix('Roboto', 1rem, 400);
		display: block;
	}

	&__byline {
		margin-bottom: 0.25rem;
	}

	&__date {
		margin-bottom: 1rem;
	}

	&__footer {
		text-align: right;
	}

	&__read-more {
		@include textMix('Roboto', 1rem, 400);
		float: right;

		&:after {
			content: "\276f";
			display: inline-block;
			font-size: 10px;
			line-height: 1rem;
			vertical-align: middle;
		}
	}

	p {
		@if $type == 'sidebar' {
			color: $color-abbey-gray;
		} @else {
			color: $color-zeus-black;
		}
		font-size: 1rem;
		line-height: 1.75rem;
	}

	@include breakpoint('small') {
		width: 100%;
		float: none;
		margin: 1rem 0;
		padding: 1rem;
	}
}
